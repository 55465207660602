import { Component, OnInit, ViewChild } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import {
  BsModalService,
  BsModalRef,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { MsalService, BroadcastService } from "@azure/msal-angular";
import * as Msal from "msal";
import { DatePipe } from "@angular/common";
import * as credentialData from "credential.json";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs/Subscription";
import { Title } from "@angular/platform-browser";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  credDataObj: any = (credentialData as any).default;
  private subscription: Subscription;
  appVersion = "4.4"; //this.credDataObj.appVersionNo;
  updatedDate = "Jan 2021"; //this.credDataObj.updatedDate;
  msalConfig: any = {
    auth: {
      clientId: this.credDataObj.clientID,
      response_type: this.credDataObj.response_type,
      expireOffsetSeconds: 1200,
      authority: this.credDataObj.authority,
      validateAuthority: this.credDataObj.validateAuthority,
    },
    cache: {
      cacheLocation: this.credDataObj.cacheLocation,
      storeAuthStateInCookie: this.credDataObj.storeAuthStateInCookie,
    },
  };
  requestObj: any = {
    scopes: [this.credDataObj.clientID],
  };
  loginRequestObj: any = {
    scopes: this.credDataObj.consentScopes,
  };
  userInfo: any;
  city: any;
  country: any;
  userId: any;
  getFilterObj() {
    var filter = {
      fromDate: "",
      toDate: "",
      type: "",
      isFromDate: "",
      isToDate: "",
      clientid: "",
      investment_amount: "",
      immediate_fund: "",
      region: "",
      // adjusted_risk_score: "",
      // from_amount: "1000000",
      // to_amount: "100000000",
      // from_risk_score: "1",
      // to_risk_score: "100",
      isColumn: "",
      sortBy: "",
      max: 25,
      offset: 0,
    };
    return filter;
  }
  calendarRef() {
    throw new Error("Method not implemented.");
  }
  isPatterns: any;
  loading: boolean;
  loadingGoalseek: boolean;
  ipAddress: any;
  isLoadingText: string = "";
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private toastr: ToastrService,
    private http: HttpClient,
    private titleService: Title
  ) {
    this.isPatterns = this.getPatterns();
    this.loading = false;
    this.loadingGoalseek = false;
    this.authCallback = this.authCallback.bind(this);
    TimeAgo.addLocale(en);
  }
  pagesListObj: any = {
    dashborad: true,
    invDashborad: true,
    investmentSummary: false,
    currentAndFuture: false,
    inputProposal: false,
    proposedAa: false,
  };
  @ViewChild("unauthErrorMsgModalBox", { static: false })
  unauthErrorMsgMdlBxRef: ModalDirective;
  ngOnInit() {
    // this.broadcastService.subscribe("msal:acquireTokenSuccess", payload => {
    // });
    this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {
      this.authService.loginRedirect(this.loginRequestObj.scopes);
    });
    // this.getIpAddress().subscribe(res => {
    //   this.ipAddress = res['ip'];
    //     this.city = res['city'];
    //     this.country = res['country_name'];
    // });
    var routesList = JSON.parse(sessionStorage.getItem("pageRouts"));
    if (routesList) {
      this.pagesListObj = routesList;
    }
    //this.unauthErrorMsgMdlBxRef.show();
  }

  setAppTitle(newTitle: string) {
    this.titleService.setTitle("AA Automation - " + newTitle);
    if (newTitle == "RM Dashboard" || newTitle == "INV Dashboard") {
      this.pagesListObj.investmentSummary = false;
      this.pagesListObj.currentAndFuture = false;
      this.pagesListObj.inputProposal = false;
      this.pagesListObj.proposedAa = false;
    }
    if (newTitle == "Investment Summary") {
      this.pagesListObj.investmentSummary = true;
    }
    if (newTitle == "Current And Future Portfolio") {
      this.pagesListObj.currentAndFuture = true;
    }
    if (newTitle == "Input Future Proposal") {
      this.pagesListObj.inputProposal = true;
    }
    if (newTitle == "Detailed Proposed AA") {
      this.pagesListObj.proposedAa = true;
    }
    sessionStorage.setItem("pageRouts", JSON.stringify(this.pagesListObj));
  }

  getPatterns() {
    var isPatterns = {
      alpha: "[a-zA-Z ]*",
      alphaNumeric: "[a-zA-Z0-9 ]*",
      alphaNumericSpl: "[a-zA-Z0-9 |-]*",
      alphaSpl: "[a-zA-Z |-]*",
      numeric: "[0-9 ]{13}$",
      numericSpl: "[0-9 |-]*",
      mobileNo: "^[0-9]{5,25}$",
      email: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$",
    };
    return isPatterns;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 46) {
      //Check if the text already contains the . character
      if (evt.target.value.indexOf(".") === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    }
    return true;
  }
  isCommSepratedNumber(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  /* Get Date Format */
  getFormattedDate(date, format) {
    var formatDate;
    formatDate = this.datePipe.transform(new Date(date), format);
    return formatDate;
  }

  /*Page Navigation*/
  navigateThis(page) {
    if (page != "back") {
      let route = page;
      this.router.navigate([route]);
    } else {
      window.history.back();
    }
  }

  /*   goToDashboardWithTimeout() {
      setTimeout(() => {
        this.router.navigate(["/home/crrDashboard"]);
      }, 1000);
    }
   */
  /* navigateThisWithBlank(page) {
    if (page) {
      let route = page;
      //this.router.navigate([route]);
      this.router.navigate([]).then(result => {  window.open(route, '_blank'); });
    } else {
      this.toastr.error("Pass Params");
    }
  } */

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logout();
  }

  /*Show Loader*/
  getLoader(value) {
    this.loading = value;
    if (!value) {
      this.isLoadingText = "";
    }

  }
  getGoalseekLoader(value) {
    this.loadingGoalseek = value;
  }

  getHTTPHeaders() {
    return new Promise((resolve) => {
      var myMSALObj = new Msal.UserAgentApplication(this.msalConfig);
      //var location = this.city + "(" + this.country + ")";
      //var location = '';
      if (myMSALObj.getAccount()) {
        myMSALObj
          .acquireTokenSilent(this.requestObj)
          .then((tokenResponse) => {
            setTimeout(() => {
              var silentToken = localStorage.getItem("msal.idtoken");
              this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
              // if (this.userInfo.aaaUserId) {
              //   this.userId = this.userInfo.aaaUserId;
              // }
              // else {
              //   this.userId = "";
              // }
              /* var isHeaders = {
              Authorization: `Bearer ${silentToken}`,
              //ipAddress: '',
              //location: location,
              // userId: this.userId.toString()
            }; */
              let isHeaders = new HttpHeaders();
              isHeaders = isHeaders.set(
                "Authorization",
                "Bearer " + silentToken
              );
              resolve(isHeaders);
            }, 500);
          })
          .catch((error) => {
            console.log("error", error);
            if (error.name === "InteractionRequiredAuthError") {
              return myMSALObj
                .acquireTokenPopup(this.requestObj)
                .then((response) => {
                  setTimeout(() => {
                    var silentToken = localStorage.getItem("msal.idtoken");
                    /* var isHeaders = {
                  Authorization: `Bearer ${silentToken}`,
                  //ipAddress: '',
                  //location: location,
                  // userId: this.userId.toString()
                }; */
                    let isHeaders = new HttpHeaders();
                    isHeaders = isHeaders.set(
                      "Authorization",
                      "Bearer " + silentToken
                    );
                    resolve(isHeaders);
                  }, 500);
                })
                .catch((error) => {
                  this.authService.loginRedirect(this.loginRequestObj.scopes);
                });
            } else {
              this.authService.loginRedirect(this.loginRequestObj.scopes);
            }
          });
      } else {
        this.authService.loginRedirect(this.loginRequestObj.scopes);
      }
    });
  }

  authCallback = (erroDesc, token, error, tokenType) => {
    var myMSALObj = new Msal.UserAgentApplication(this.msalConfig);
    if (tokenType == "id_token") {
      myMSALObj.acquireTokenSilent(this.requestObj).then(
        function (accessToken) { },
        function (error) {
          console.log(error);
        }
      );
    }
  };

  /* sessionTimerSec: number = 0;
  myInterval: any;
  countingForReload: number = 4;
  autoReloadIs: boolean = true; */
  errorHandler(errorObj) {
    console.log("errorObj", errorObj);
    var errorMsg = "Error: " + errorObj.statusText;
    if (errorObj.error == "Unauthorized") {
      this.unauthErrorMsgMdlBxRef.show();
      errorMsg = "Error, " + errorObj.error + " :- 'Reload Please'";
      this.unauthErrorMsgMdlBxRef.show();
      // this.toastr.error(errorMsg, errorObj.status).onHidden.pipe().subscribe(() => {
      //   //this.startTimerAutoReload();

      // });
    } else {
      this.toastr.error(errorMsg, errorObj.status);
    }
    this.getLoader(false);
  }

  /* timerForAutoReload() {
    this.countingForReload--;
    if (this.countingForReload == this.sessionTimerSec) {
      this.stopTimerAutoReload();
      this.selectedCompReloadFun();
    }
  }

  startTimerAutoReload() {
    this.myInterval = setInterval(() => {
      this.timerForAutoReload();
    }, 1000);
  }

  stopTimerAutoReload() {
    clearInterval(this.myInterval);
    this.countingForReload = 4;
  } */

  selectedCompReloadFun() {
    /* var currUrl = this.router.url;
    this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
      setTimeout(() => this.router.navigate([currUrl]));
    }); */
    location.reload();
  }

  getTimeAgo(myDate) {
    var oDatePublished = new Date(myDate);
    const timeAgo = new TimeAgo("en-US");
    var actualDay = timeAgo.format(oDatePublished);
    return actualDay;
  }

  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.toastr.clear();
  }

  /* Convert Base64 into File */
  convertToFile(dataUrl, clientID) {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var fileName = clientID + "_file.pptx";
    return new File([u8arr], fileName, { type: mime });
  }

}
