import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { Ng5SliderModule } from 'ng5-slider';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { ModalModule, PopoverModule } from "ngx-bootstrap";
import { QuestionnaireBasicComponent } from "./questionnaire-basic/questionnaire-basic.component";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from 'ngx-toastr';
import { DeckPreviewComponent } from "./deck-preview/deck-preview.component";
import { InvestmentSummaryComponent } from "./investment-summary/investment-summary.component";
import { MsalModule, MsalInterceptor } from "@azure/msal-angular";
import { InvestmentDashboardComponent } from "./investment-dashboard/investment-dashboard.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxDocViewerModule } from "ngx-doc-viewer";
import *  as  credentialData from 'credential.json';

import { CurrencyFormatDirective } from './currency-format.directive';
import { CamelCaseDirective } from './camel-case.directive';
import { SimulatorComponent } from './simulator/simulator.component';
import { SummaryViewComponent } from './summary-view/summary-view.component';
import { QuestionnairePrintComponent } from './questionnaire-print/questionnaire-print.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {NgxImageCompressService} from 'ngx-image-compress';

import { JsonEditorComp } from './json-editor/json-editor.component'
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { CurrentFuturePortfolioComponent } from './current-future-portfolio/current-future-portfolio.component';
import { InputFutureProposalComponent } from './input-future-proposal/input-future-proposal.component';
import { DetailedProposedAAComponent } from './detailed-proposed-aa/detailed-proposed-aa.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { EmaTriggerComponent } from './ema-trigger/ema-trigger.component';
import { ExcelUploaderComponent } from './excel-uploader/excel-uploader.component';
import { CrrConfigurationComponent } from './crr-configuration/crr-configuration.component';
import { CrrDashboardComponent } from './crr-dashboard/crr-dashboard.component';
import { CrrRpaProgressComponent } from './crr-rpa-progress/crr-rpa-progress.component';
import { InputSolutionComponent } from './input-solution/input-solution.component';
import { OnboardingQuestionnaireFormComponent } from './onboarding-questionnaire-form/onboarding-questionnaire-form.component';
import { VideoGenPptComponent } from './video-gen-ppt/video-gen-ppt.component';
import { CrrImageComponent } from './crr-image/crr-image.component';

export function loggerCallback(logLevel, message, piiEnabled) {
  console.log("Logs", message);
}

var credDataObj = (credentialData as any).default;
const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HomeComponent,
    QuestionnaireBasicComponent,
    DeckPreviewComponent,
    InvestmentSummaryComponent,
    InvestmentDashboardComponent,
    PageNotFoundComponent,
    CamelCaseDirective,
    CurrencyFormatDirective,
    SimulatorComponent,
    SummaryViewComponent,
    QuestionnairePrintComponent,
    JsonEditorComp,
    CurrentFuturePortfolioComponent,
    InputFutureProposalComponent,
    DetailedProposedAAComponent,
    EmaTriggerComponent,
    ExcelUploaderComponent,
    CrrConfigurationComponent,
    CrrDashboardComponent,
    CrrRpaProgressComponent,
    InputSolutionComponent,
    OnboardingQuestionnaireFormComponent,
    VideoGenPptComponent,
    CrrImageComponent
  ],
  imports: [
    NgJsonEditorModule,
    Ng5SliderModule,
    FormsModule,
    ChartsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      progressBar: true,
      maxOpened: 1,
      closeButton: true,
      timeOut: 3000,
      preventDuplicates: true
    }),
    NgCircleProgressModule.forRoot({
      radius: 100,
      space: -10,
      outerStrokeGradient: true,
      outerStrokeWidth: 10,
      outerStrokeColor: "'#a28755'",
      innerStrokeColor: "'#222222'",
      outerStrokeGradientStopColor: "'#a28755'",
      innerStrokeWidth: 10,
      animateTitle: false,
      animationDuration: 1000,
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      outerStrokeLinecap: "square",
      backgroundOpacity: 1,
      backgroundStrokeWidth: 5,
      
    }),
    MsalModule.forRoot({
      clientID: credDataObj.clientID,
      authority: credDataObj.authority,
      validateAuthority: credDataObj.validateAuthority,
      redirectUri: credDataObj.redirectUri,
      cacheLocation: credDataObj.cacheLocation,
      storeAuthStateInCookie: credDataObj.storeAuthStateInCookie, // set to true for IE 11
      postLogoutRedirectUri: credDataObj.postLogoutRedirectUri,
      navigateToLoginRequestUrl: credDataObj.navigateToLoginRequestUrl,
      popUp: !isIE,
      consentScopes: credDataObj.consentScopes,
      logger: loggerCallback,
      correlationId: credDataObj.correlationId,
      piiLoggingEnabled: credDataObj.piiLoggingEnabled
    }),
    BsDropdownModule.forRoot(), NgxDocViewerModule, TooltipModule.forRoot(), PaginationModule.forRoot(), AccordionModule.forRoot()
  ],
  providers: [Title, ThemeService, NgxImageCompressService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }