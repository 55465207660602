import { Component, OnInit } from '@angular/core';
import pptxgen from "pptxgenjs";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-video-gen-ppt',
  templateUrl: './video-gen-ppt.component.html',
  styleUrls: ['./video-gen-ppt.component.scss']
})
export class VideoGenPptComponent implements OnInit {
  getSelectedClientObj: any;
  getLoginUserData: any = {};
  responceDataMainObj: any;
  constructor(public app: AppComponent, private toastr: ToastrService,  private http: CommonservicesService,) {}
  ngOnInit() {
    this.getSelectedClientObj = JSON.parse(
      sessionStorage.getItem("selectedClientObj")
    );
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
    setTimeout(() => {
      this.downloadCVideo();
    }, 100);
  }

  async downloadCVideo() {
    this.app.getLoader(true);
    var sendData = {
      client_id: this.getSelectedClientObj.clientid,
      user_id: this.getLoginUserData.aaaUserId,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.downloadCRRVideo(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.getSelectedClientObj.clientMasterId =
            response.client_prospect_id;
          sessionStorage.setItem(
            "selectedClientObj",
            JSON.stringify(this.getSelectedClientObj)
          );
          sessionStorage.setItem(
            "preSettingData",
            JSON.stringify(response.slide_data)
          );
          response.selectAllIs = false;
          this.responceDataMainObj = response;
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  downloadCRRVideoDeck(){
    
    let pres = new pptxgen();
    pres.defineLayout({ name: "A4", width: 13.333, height: 7.5 });
    pres.layout = "A4";

    pres.defineSlideMaster({
      title: "MASTER_SLIDE",
      bkgd: "FFFFFF",
      objects: [
        {
          image: {
            x: 0.75,
            y: 6.9,
            w: 0.15,
            h: 0.45,
            path: "./assets/img/tfo-logo-large.png",
          },
        },
        {
          text: {
            text: "The Family Office | Page ",
            options: {
              x: "75%",
              w: "3",
              y: "92%",
              fontSize: 9,
              fontFace: "Gotham Book",
              color: "40412F",
            },
          },
        },
      ],
      slideNumber: {
        x: "9.13",
        y: "6.92",
        h: "0.25",
        w: "0.5",        
        fontSize: 9,
        fontFace: "Gotham Book",
        color: "40412F",
      },
    });
    
    var slide = pres.addSlide("MASTER_SLIDE");
    slide.addText("RELATIONSHIP OVERVIEW",{
      x: 0.5,
      y: 0.12,
      h: 0.4,
      w: 12.47,
      color: 'A28755',
      fontSize: 18,
      align: "center",
      fontFace: "Gotham Bold",
    });

    slide.addImage({
      path: "./assets/img/Picture1.png",
      x: 0.51,
      y: 1.27,
      w: 0.77,
      h: 0.74,
    });
    slide.addText("Portfolio 37000",{
      x: 0.45,
      y: 1.99,
      h: 0.45,
      w: 0.94,
      color: 'A28755',
      fontSize: 10.5,
      align: "center",
      fontFace: "Gotham Light",
    });
    slide.addShape(pres.ShapeType.leftBracket, {y: 1.08, x: 1.51, w: 0.12, h: 1.46, line: { type: "solid",color: "385723", width: 5} });
    slide.addText(
      [
          { text: "Total AUM: USD 21.5 million", options: { fontSize: 11, align: "left", breakLine: true, bullet: true } },
          { text: "Total Return inception to date of Overall Portfolio: 4.21% per annum", options: { fontSize: 11, align: "left", breakLine: true, bullet: true }},
          { text: "Total Return inception to date of Illiquid Portfolio: 5.30% per annum", options: { fontSize: 11, align: "left", bullet: true }},
      ],
      { x: 1.61, 
        y: 1.06, 
        w: 4.39, 
        h: 1.4,             
      }
    );
    
    slide.addImage({
      path: "./assets/img/Picture2.png",
      x: 0.51,
      y: 3.47,
      w: 0.77,
      h: 0.74,
    });
    slide.addText("Performance",{
      x: 0.29,
      y: 4.3,
      h: 0.29,
      w: 1.3,
      color: 'A28755',
      fontSize: 10.5,
      align: "center",
      fontFace: "Gotham Light",
    });
    slide.addShape(pres.ShapeType.leftBracket, {y: 2.95, x: 1.51, w: 0.12, h: 2.05, line: { type: "solid",color: "385723", width: 5} });
    slide.addText(
      [
          { text: "33.4% of the invested capital has been invested for less than two years", options: { fontSize: 11, align: "left", breakLine: true, bullet: true } },
          { text: "The average age of the deals in the portfolio is 3.55 years", options: { fontSize: 11, align: "left", breakLine: true, bullet: true }},
          { text: "Your allocation is", options: { fontSize: 11, align: "left", breakLine: true, bullet: true }},
          { text: "59% towards Capital Growth and", options: { fontSize: 11, align: "left", breakLine: true, bullet: true, indentLevel:2 }},
          { text: "41% towards Capital Yielding", options: { fontSize: 11, align: "left", breakLine: true, bullet: true, indentLevel:2 }},
          { text: "Your total distributions since inception are USD 3.9 million", options: { fontSize: 11, align: "left", breakLine: true, bullet: true }},
          { text: "The total profit and loss since inception is USD 1.1 million", options: { fontSize: 11, align: "left", breakLine: true, bullet: true }},
      ],
      { x: 1.62, 
        y: 2.92, 
        w: 4.82, 
        h: 2.32,        
      }
    );

    slide.addImage({
      path: "./assets/img/Picture3.png",
      x: 0.5,
      y: 5.5,
      w: 0.77,
      h: 0.74,
    });
    slide.addText("Maximum Drop",{
      x: 0.32,
      y: 6.22,
      h: 0.46,
      w: 1.18,
      color: 'A28755',
      fontSize: 10.5,
      align: "center",
      fontFace: "Gotham Light",
    });
    slide.addShape(pres.ShapeType.leftBracket, {y: 5.5, x: 1.51, w: 0.13, h: 1.07, line: { type: "solid",color: "385723", width: 5} });
    slide.addText(
      [
          { text: "The relationship started in July 2013. The maximum drop inception to date in the portfolio is:", 
            options: { fontSize: 11, align: "left", breakLine:true} },
          { text: "7.2%, compared to 34.3% for the MSCI World Index", options: { fontSize: 11, align: "left", bullet: true }}         
      ],
      { x: 1.63, 
        y: 5.64, 
        w: 4.6, 
        h: 0.84,        
      }
    );

    slide.addText("Past performance is not indicative of and does not guarantee future performance. Exit timelines, prices and related projections are estimates only, and exits could happen sooner or later than expected, or at a higher or lower valuation than expected, and are conditional, among other things, on certain assumptions and future performance relating to the financial and operational health of each business and macroeconomic conditions.",
    {
      x: 1.28,
      y: 6.9,
      h: 0.47,
      w: 8.71,      
      fontSize: 7,      
      fontFace: "Gotham Light",
    });

    pres.writeFile("Sample Presentation.pptx");
  }
}
