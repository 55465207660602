import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { Subscription } from "rxjs";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { ToastrService } from "ngx-toastr";
//import { Options, LabelType } from 'ng5-slider';
import * as credentialData from "credential.json";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  credDataObj: any = (credentialData as any).default;
  modalRef: BsModalRef;
  summaryModalRef: BsModalRef;
  filter: any;
  isPatterns: any;
  returnedArray: any = [];
  showPagination: boolean;
  /* For Calendar Component */

  dateCalendar: string;
  dataSummary: any = { data: [] };
  isCalendar: Subscription;
  existingProsName: string;
  activefilter: any;
  showGeneratedURLis: boolean = false;
  existingProsObj: any;
  genUrlFormObj: any = {
    existingProsId: "",
    existingPros: "",
    clientId: "",
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    url: "",
    token: "",
    rmId: "",
    rmName: "",
    type: "",
  };
  amtType: boolean = true;
  getSelectedProsType: string;
  isDataList: any;
  commonService: any;
  infoText: string;
  currentPage: number;
  constructor(
    public app: AppComponent,
    private modalService: BsModalService,
    private http: CommonservicesService,
    private toastr: ToastrService
  ) {
    this.filter = this.app.getFilterObj();
    this.filter.type = "";
    this.filter.ema_status = "";
    this.filter.maxDate = new Date();
    this.showPagination = false;
  }
  riskScoreSort = false;
  totalAumSort = false;
  immediateFundSort = false;
  totalItems: any;
  getLoginUserData: any = {};
  selfDirectedEmailPendingModelRef: BsModalRef;
  @ViewChild("selfDirectedEmailPending", { static: false })
  public templateref: TemplateRef<any>;

  modalConfig = {
    class: "modal-md",
    backdrop: true,
    ignoreBackdropClick: true,
  };

  ngOnInit() {
    this.app.setAppTitle("RM Dashboard");
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
    this.activefilter = "";
    this.getSelectedProsType = "";
    this.genUrlFormObj.rmName = this.getLoginUserData.name;
    this.isPatterns = this.app.isPatterns;
    setInterval(() => {
      this.getProsAAList("reload");
    }, 30000);
    setTimeout(
      function () {
        this.getProsAAList("normal");
        // this.isDisabled();
        //this.app.paginationCallBack("rmDashboard");
        // if(this.amtType==false)
        // {
        //   this.filter.from_amount=  this.dataSummary.min_investment_amount ;
        //   this.filter.to_amount=this.dataSummary.max_investment_amount ;
        // }
        // else
        // {
        //   this.filter.from_amount=  this.dataSummary.min_immediate_fund ;
        //   this.filter.to_amount=this.dataSummary.max_immediate_fund ;
        // }
      }.bind(this),
      100
    );
  }

  /* options: Options = {
    floor: 1000000,
    ceil: 100000000,
    step: 1000000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min :</b> $' + value;
        case LabelType.High:
          return '<b>Max :</b> $' + value;
        default:
          return '$' + value;
      }
    }
  };
  options1: Options = {
    floor: 1,
    ceil: 100,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + '<b> %';
        case LabelType.High:
          return value + '<b> %';
        default:
          return value + '%';
      }
    }
  }; */

  prospectNameList: any = [];
  clientIdList: any = [
    {
      client_id: 37001,
    },
    {
      client_id: 37002,
    },
    {
      client_id: 37003,
    },
    {
      client_id: 37082,
    },
    {
      client_id: 37061,
    },
  ];
  regionlist: any = [];

  selectFilter(value) {
    this.showList(true, "");
    this.filter.offset = 0;
    this.filter.max = 25;
    this.currentPage = 1;
    this.searchData();
  }

  sortedBy = "";
  clearSort() {
    this.filter.isColumn = "";
    this.sortedBy = "";
    this.filter.sortBy = "";
    this.riskScoreSort = false;
    this.totalAumSort = false;
    this.immediateFundSort = false;
    this.getProsAAList("normal");
  }

  filterClear() {
    this.filter.type = "";
    this.filter.ema_status = "";
    this.filter.clientid = "";
    this.filter.fromDate = "";
    this.filter.toDate = "";
    this.filter.selectedDate = undefined;
    this.activefilter = "";
    this.currentPage = 1;
    this.filter.offset = 0;
    this.filter.max = 25;
    this.filter.region = "";
    this.getProsAAList("normal");
  }

  dateSort = false;
  /* Sort by Header */
  sortList(type, label) {
    /* Sort by Header */
    if (type == "Prospect Name") {
      this.dataSummary.data.sort((a, b) => {
        return (
          <any>new Date(b.date).getTime() - <any>new Date(a.date).getTime()
        );
      });
    }
    this.filter.isColumn = type;
    if (type == "risk_score_per") {
      this.riskScoreSort = !this.riskScoreSort;
      this.filter.sortBy = this.riskScoreSort == true ? "desc" : "asc";
      this.totalAumSort = false;
      this.immediateFundSort = false;
    }
    if (type == "investment_amount") {
      this.totalAumSort = !this.totalAumSort;
      this.filter.sortBy = this.totalAumSort == true ? "desc" : "asc";
      this.riskScoreSort = false;
      this.immediateFundSort = false;
    }
    if (type == "immediate_fund") {
      this.immediateFundSort = !this.immediateFundSort;
      this.filter.sortBy = this.immediateFundSort == true ? "desc" : "asc";
      this.riskScoreSort = false;
      this.totalAumSort = false;
    }
    this.sortedBy = label;
    this.showList(true, "");
    this.searchData();
  }
  pageChanged(event: PageChangedEvent): void {
    this.filter.offset = (event.page - 1) * event.itemsPerPage;
    this.filter.max = 25;
    this.showList(true, "");
    this.searchData();
  }
  /* Search Data */
  searchData() {
    this.getProsAAList("normal");
  }

  async getProspect() {
    var sendData = {
      id: this.getLoginUserData.aaaUserId,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getProspectList(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.prospectNameList = response.data.prospect;
          this.clientIdList = response.data.client;
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async getRegion() {
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getRegionList(headerObj).subscribe(
      (response: any) => {
        if (response) {
          this.regionlist = response.data;
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  showList(value, callback) {
    this.isDataList = value;
    if (callback == "clear") {
      this.searchData();
    }
  }

  // getListForFilter: any;
  // setFilter(filterName) {
  //   var listForTemp = [];
  //   if (filterName == 'all') {
  //     this.dataSummary.data = this.getListForFilter;
  //   } else {
  //     for (let d = 0; d < this.getListForFilter.length; d++) {
  //       const get_obj = this.getListForFilter[d];
  //       if (get_obj.rm_status == filterName) {
  //         listForTemp.push(get_obj);
  //       }
  //     }
  //     this.dataSummary.data = listForTemp;
  //   }
  //   if (this.dataSummary.data.length == 0) {
  //     this.infoText = "No Data...";
  //   }
  //   this.activefilter = filterName;
  // }

  setFilter(filterName) {
    if (this.activefilter != filterName) {
      this.activefilter = filterName;
      this.filter.offset = 0;
      this.currentPage = 1;
      this.getProsAAList("normal");
    }
  }

  dateSelected() {
    var date = this.filter.selectedDate;
    if (date != undefined) {
      this.filter.fromDate = this.app.getFormattedDate(date[0], "yyyy-MM-dd");
      this.filter.toDate = this.app.getFormattedDate(date[1], "yyyy-MM-dd");
      this.filter.offset = 0;
      this.filter.max = 25;
      this.currentPage = 1;
      this.getProsAAList("normal");
    }
  }

  async getProsAAList(type) {
    this.infoText = "Searching...";
    if (type == "reload") {
      this.app.getLoader(false);
    } else {
      this.app.getLoader(true);
    }
    var sendData = {
      // id: "b6e21bcf-06c5-4707-823e-b130b5cad309",
      id: this.getLoginUserData.id,
      name: this.getLoginUserData.name,
      email: this.getLoginUserData.email,
      client_id: this.filter.clientid,
      type: this.filter.type,
      ema_status: this.filter.ema_status,
      fromdate: this.filter.fromDate,
      todate: this.filter.toDate,
      region: this.filter.region,
      offset: this.filter.offset,
      max: this.filter.max,
      isColumn: this.filter.isColumn,
      sortBy: this.filter.sortBy,
      status: this.activefilter,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.fetchCrtAssestAllocationList(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.dataSummary = response;
          if (this.dataSummary.data.length > 0) {
            this.dataSummary.data.forEach((element, i) => {
              if (element.rm_status == "sent_to_inv") {
                this.dataSummary.data[i].rm_status = "sent_to_inv";
              }
              if (element.rm_status == null) {
                this.dataSummary.data[i].rm_status = "pending";
              }
            });
          } else {
            this.infoText = "No Data Found";
          }
          /* if (this.filter.ema_status == 'pending') {
          this.totalItems = this.dataSummary.pending_ema;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else if (this.filter.ema_status == 'done') {
          this.totalItems = this.dataSummary.done_ema;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } */
          var fieldName = "";
          switch (this.filter.ema_status) {
            case "pending":
              fieldName = "pending_ema";
              break;
            case "done":
              fieldName = "done_ema";
              break;
          }
          switch (this.activefilter) {
            case "pending":
              fieldName = "pending";
              break;
            case "approved":
              fieldName = "approved";
              break;
            case "sent_to_inv":
              fieldName = "sent_to_inv";
              break;
            case "rejected":
              fieldName = "rejected";
              break;
            case "Questionnaire filled":
              fieldName = "Questionnaire_filled";
              break;
            case "allocation created":
              fieldName = "allocation";
              break;
            case "accepted_by_ceo":
              fieldName = "accepted_by_ceo";
              break;
            case "rejected_by_ceo":
              fieldName = "rejected_by_ceo";
              break;
            case "sent_to_ceo":
              fieldName = "sent_to_ceo";
              break;
            case "self_directed":
              fieldName = "self_directed";
              break;
            default:
              fieldName = "total";
          }
          this.totalItems = this.dataSummary[fieldName];
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
          /* if (this.activefilter == 'pending') {
          this.totalItems = this.dataSummary.pending;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else if (this.activefilter == 'approved') {
          this.totalItems = this.dataSummary.approved;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else if (this.activefilter == 'sent_to_inv') {
          this.totalItems = this.dataSummary.sent_to_inv;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else if (this.activefilter == 'rejected') {
          this.totalItems = this.dataSummary.rejected;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else if (this.activefilter == 'Questionnaire filled') {
          this.totalItems = this.dataSummary.Questionnaire_filled;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else if (this.activefilter == 'allocation created') {
          this.totalItems = this.dataSummary.allocation;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else {
          this.totalItems = this.dataSummary.total;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } */
          if (this.prospectNameList.length == 0) {
            this.getLoginUserData.aaaUserId = this.dataSummary.aaaUserId;
            localStorage.setItem(
              "userInfo",
              JSON.stringify(this.getLoginUserData)
            );
            this.getProspect();
          }
          if (this.regionlist.length == 0) {
            this.getRegion();
          }
        } else {
          this.infoText = "No Data...";
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.infoText = "Connection Issue";
        this.app.errorHandler(error);
      }
    );
  }

  selectingProsObj(event) {
    this.genUrlFormObj.existingProsId = event.item.id;
    this.genUrlFormObj.clientId = event.item.client_id;
    this.genUrlFormObj.email = event.item.email_id;
    /* this.genUrlFormObj.firstname = event.item.first_name;
    this.genUrlFormObj.lastname = event.item.middle_name; */
  }

  selectingClientEmail(event) {
    this.genUrlFormObj.email = event.item.email_id;
  }

  cltIdListForUniqueLink: any = [];
  emailIdListForUniqueLink: any = [];
  async customForm(modalTemp: TemplateRef<any>, getParam) {
    if (getParam == "ExistingClient") {
      this.selectedClientFlow = getParam;
      this.app.getLoader(true);
      var sendData = {
        id: this.getLoginUserData.aaaUserId,
        rm_name: this.getLoginUserData.name,
        group: this.getLoginUserData.userRole
      };
      var headerObj = await this.app.getHTTPHeaders();
      this.http.getClientEmailId(sendData, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            this.cltIdListForUniqueLink = response.data;
            this.emailIdListForUniqueLink = response.data;
            this.modalRef = this.modalService.show(modalTemp);
            this.modalService.onHide.subscribe((reason: string) => {
              this.clearForm();
            });
          } else {
            this.toastr.info("Issue Found");
          }
          this.app.getLoader(false);
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    } else {
      this.modalRef = this.modalService.show(modalTemp);
      this.modalService.onHide.subscribe((reason: string) => {
        this.clearForm();
      });
    }
    this.getSelectedProsType = getParam;
  }

  async getQformToken() {
    var sendData = {};
    if (this.getSelectedProsType == "ExistingClient") {
      if (this.genUrlFormObj.existingPros && this.genUrlFormObj.email) {
        var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailRegex.test(this.genUrlFormObj.email)) {
          this.toastr.error("You have entered an invalid email address!");
          return;
        }
        sendData = {
          userId: this.getLoginUserData.aaaUserId,
          rmId: this.getLoginUserData.id,
          id: this.genUrlFormObj.clientId,
          Email: this.genUrlFormObj.email,
          type: "client",
        };
      } else {
        this.toastr.error("All Fields Are Mandatory");
        return;
      }
    } else if (this.getSelectedProsType == "New") {
      if (
        this.genUrlFormObj.firstname &&
        this.genUrlFormObj.lastname &&
        this.genUrlFormObj.email
      ) {
        var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailRegex.test(this.genUrlFormObj.email)) {
          this.toastr.error("You have entered an invalid email address!");
          return;
        }
        /* var phoneNoRegex = /^\d{10}$/;
        if (!this.genUrlFormObj.mobile.match(phoneNoRegex)) {
          this.toastr.error("You have entered an invalid mobile number!");
          return;
        } */
        sendData = {
          Fname: this.genUrlFormObj.firstname,
          Lname: this.genUrlFormObj.lastname,
          Email: this.genUrlFormObj.email,
          Mobile: this.genUrlFormObj.mobile ? this.genUrlFormObj.mobile : "",
          rmId: this.getLoginUserData.id,
          rm_name: this.genUrlFormObj.rmName,
          type: "new",
        };
      } else {
        this.toastr.error("All Fields Are Mandatory");
        return;
      }
    } else {
      if (this.genUrlFormObj.existingProsId) {
        sendData = {
          id: this.genUrlFormObj.existingProsId,
          rm_name: this.genUrlFormObj.rmName,
          type: "existing",
        };
      } else {
        this.toastr.error("Select Prospect");
        return;
      }
    }
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.genCustomFormUniqueLink(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          var getResObj = response.userData;
          this.genUrlFormObj.email = getResObj.Email;
          if (this.getSelectedProsType == "ExistingClient") {
            this.genUrlFormObj.url =
              this.credDataObj.config.questionnaireFormLinkForClient +
              getResObj.key;
            this.genUrlFormObj.token = getResObj.key;
          } else {
            this.genUrlFormObj.url =
              this.credDataObj.config.questionnaireFormUrl + getResObj.key;
          }
          this.showGeneratedURLis = true;
          this.app.getLoader(false);
        } else {
          this.toastr.error("There is No Email ID");
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async sendUniqueLink() {
    var sendData = {};
    if (this.getSelectedProsType == "ExistingClient") {
      sendData = {
        // url: this.genUrlFormObj.url,
        token: this.genUrlFormObj.token,
        //id: this.getLoginUserData.id,
        clientId: this.genUrlFormObj.clientId,
        type: "client",
        email: this.genUrlFormObj.email,
      };
    } else {
      sendData = {
        url: this.genUrlFormObj.url,
        id: this.getLoginUserData.id,
        email: this.genUrlFormObj.email,
        firstname: this.genUrlFormObj.firstname,
        lastname: this.genUrlFormObj.lastname,
        rm_name: this.genUrlFormObj.rmName,
      };
    }
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.sendMailWithURL(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.toastr.success("Mail sent successfully");
          this.modalRef.hide();
          this.app.getLoader(false);
        } else {
          this.toastr.error(response.msg);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  customFormURLCopy(inputElement) {
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
    this.toastr.success("URL Copied Successfully");
  }
  customFormURLCopyEC(inputElement) {
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
    console.log(inputElement);

    this.toastr.success("URL Copied Successfully");
  }
  clearForm() {
    this.genUrlFormObj.existingProsId = "";
    this.genUrlFormObj.clientId = "";
    this.genUrlFormObj.existingPros = "";
    this.genUrlFormObj.firstname = "";
    this.genUrlFormObj.lastname = "";
    this.genUrlFormObj.email = "";
    this.genUrlFormObj.rmName = this.getLoginUserData.name;
    this.genUrlFormObj.mobile = "";
    this.genUrlFormObj.url = "";
    this.genUrlFormObj.rmId = "";
    this.genUrlFormObj.type = "";
    this.genUrlFormObj.token = "";

    this.showGeneratedURLis = false;
  }

  //
  downloadPptFile(getObj) {
    // console.log(getObj, "getObj");

    if (getObj.ema_status != "Done" && getObj.rm_status == "self_directed") {
      if (getObj.type != "client") {
        getObj.createdAt = getObj.date;
      }
      sessionStorage.setItem("selctedPersonaObj", JSON.stringify(getObj));
      this.selfDirectedEmaPending(this.templateref);
    } else {
      sessionStorage.setItem("selctedPersonaObj", JSON.stringify(getObj));
      this.app.navigateThis("/home/deckPreview");
    }
  }

  selfDirectedEmaPending(template: TemplateRef<any>) {
    this.selfDirectedEmailPendingModelRef = this.modalService.show(
      template,
      this.modalConfig
    );
    var getStatusData = JSON.parse(sessionStorage.getItem("selctedPersonaObj"));
    console.log(getStatusData);

    // this.downloadPptFile(getStatusData);
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  getThisProsAaView(getObj) {
    if (getObj.type == "client") {
      sessionStorage.setItem("selctedPersonaObj", JSON.stringify(getObj));
      if (getObj.rm_status == "pending") {
        this.app.navigateThis("home/currentFuturePortfolio");
      } else if (getObj.rm_status == "Questionnaire filled") {
      } else {
        this.app.navigateThis("home/detailedProposedAA");
      }
    } else {
      getObj.createdAt = getObj.date;
      sessionStorage.setItem("selctedPersonaObj", JSON.stringify(getObj));
      if (
        getObj.rm_status == "Questionnaire filled" ||
        (this.formatDate(getObj.updated) < "2020-12-26" &&
          getObj.rm_status != "pending")
      ) {
      } else {
        this.app.navigateThis("home/investmentSummary");
      }
    }
  }

  selectExistProspect(event) {
    this.existingProsObj = event.item;
    this.existingProsObj.id = event.item.id;
    this.existingProsObj.type = "client";
  }

  async setProspectDetails() {
    if (this.selectedClientFlow == "ExistingClient") {
      if (this.existingProsObj) {
        /* Manual ID */
        //this.existingProsObj.reconciliationDate = "2020-05-31";
        sessionStorage.setItem(
          "selctedPersonaObj",
          JSON.stringify(this.existingProsObj)
        );
        this.app.navigateThis("home/currentFuturePortfolio");
      } else {
        this.toastr.warning("Select Client ID");
      }
    } else {
      var sendData = {
        id: this.existingProsObj.id,
      };
      var headerObj = await this.app.getHTTPHeaders();
      this.http.existingMyself(sendData, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            var res = response.data;
            sessionStorage.setItem("selExistingProsObj", JSON.stringify(res));
            sessionStorage.removeItem("selctedPersonaObj");
            this.app.getLoader(false);
            this.app.navigateThis("home/questionnaire");
          }
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    }
  }

  selectedClientFlow: any = "";
  async fillQuestionnaireForm(getParam, modalTemplate: TemplateRef<any>) {
    if (getParam == "New") {
      sessionStorage.removeItem("selExistingProsObj");
      sessionStorage.removeItem("selctedPersonaObj");
      this.app.navigateThis("home/questionnaire");
    } else if (getParam == "NewOnboarding") {
      sessionStorage.removeItem("selExistingProsObj");
      sessionStorage.removeItem("selctedPersonaObj");
      this.app.navigateThis("home/onboardingQuestionnaire");
    } else {
      this.selectedClientFlow = getParam;
      this.app.getLoader(true);
      var sendData = {
        id: this.getLoginUserData.aaaUserId,
        rm_name: this.getLoginUserData.name,
        group: this.getLoginUserData.userRole
      };
      var headerObj = await this.app.getHTTPHeaders();
      this.http.getClientEmailId(sendData, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            this.cltIdListForUniqueLink = response.data;
            this.modalRef = this.modalService.show(modalTemplate);
            this.modalService.onHide.subscribe((reason: string) => {
              this.existingProsObj = {};
              this.existingProsName = "";
            });
          } else {
            this.toastr.info("Issue Found");
          }
          this.app.getLoader(false);
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    }
  }

  reloadFunc() {
    this.getProspect();
    this.filterClear();
    this.getRegion();
    this.filter.ema_status = "";
    this.cltIdListForUniqueLink = [];
  }

  /* showSummary(modalTemp, viewData) {
    viewData.createdAt = viewData.date;
    viewData.viewIs = true;
    sessionStorage.setItem("selctedPersonaObj", JSON.stringify(viewData));
    this.summaryModalShow(modalTemp);
  } */

  showQuesnnairePdf(getObj) {
    getObj.createdAt = getObj.date;
    sessionStorage.setItem("selctedPersonaObj", JSON.stringify(getObj));
    this.app.navigateThis("home/questionnairePrint");
  }

  /* summaryModalShow(template: TemplateRef<any>) {
    this.summaryModalRef = this.modalService.show(template, Object.assign({}, { class: "modal-lg investSummaryViewMdlBox" }));
  } */

  ngOnDestroy() {
    // sessionStorage.removeItem("selctedPersonaObj");
    if (this.modalRef) {
      this.modalRef.hide();
    }
    if (this.summaryModalRef) {
      this.summaryModalRef.hide();
    }
    this.toastr.clear();
  }
}
