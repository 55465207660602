import { Component, OnInit } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AppComponent } from "../app.component";
import { Router, NavigationEnd } from "@angular/router";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  mySubscription: any;
  currPageUrl: string = "";
  getLoginUserData: any = {};
  sideMenuOpenIs: boolean = false;
  sideMenuListObj: any = [
    {
      id: 1,
      name: "Dashboard",
      iconClass: "fas fa-pager",
      pageLink: "/home/dashboard",
      permission: "RM",
    },
    {
      id: 2,
      name: "EMA View",
      iconClass: "fas fa-envelope-square",
      pageLink: "/home/emaView",
      permission: "RM",
    },
    {
      id: 3,
      name: "Dashboard",
      iconClass: "fas fa-pager",
      pageLink: "/home/investmentDashboard",
      permission: "INV",
    },
    {
      id: 4,
      name: "Master JSON Editor",
      iconClass: "fas fa-edit",
      pageLink: "/home/assumptionMasterEdit",
      permission: "INV",
    },
    // {
    //   id: 5,
    //   name: "Simulator",
    //   iconClass: "fab fa-simplybuilt",
    //   pageLink: "/home/simulator",
    //   permission: "INV",
    // },
    {
      id: 6,
      name: "Dashboard",
      iconClass: "fas fa-pager",
      pageLink: "/home/crrDashboard",
      permission: "CS",
    },
    {
      id: 7,
      name: "Input Solution Editor",
      iconClass: "fas fa-pager",
      pageLink: "/home/inputSolution",
      permission: "INV",
    },
  ];

  constructor(
    public app: AppComponent,
    private modalService: BsModalService,
    private router: Router
  ) {
    router.events.subscribe((val: any) => {
      this.currPageUrl = val.url;
      this.sideMenuOpenIs = false;
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
  }

  sideMenuToggleFun() {
    this.sideMenuOpenIs = !this.sideMenuOpenIs;
  }

  switchRoleFunc() {
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
    if (this.getLoginUserData.role == "RM") {
      this.getLoginUserData.role = "INV";
      localStorage.setItem("userInfo", JSON.stringify(this.getLoginUserData));
      this.router.navigate(["/home/investmentDashboard"]);
    } else if (this.getLoginUserData.role == "INV") {
      this.getLoginUserData.role = "RM";
      localStorage.setItem("userInfo", JSON.stringify(this.getLoginUserData));
      this.router.navigate(["/home/dashboard"]);
    }
    //location.reload();
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
