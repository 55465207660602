import { Component, OnInit } from "@angular/core";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { ToastrService } from "ngx-toastr";
import "core-js";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
@Component({
  selector: "app-questionnaire-print",
  templateUrl: "./questionnaire-print.component.html",
  styleUrls: ["./questionnaire-print.component.scss"],
})
export class QuestionnairePrintComponent implements OnInit {
  questionnareData: any;
  totalAum: any;
  constructor(
    public app: AppComponent,
    private http: CommonservicesService,
    private toastr: ToastrService
  ) {
    am4core.useTheme(am4themes_animated);
  }
  viewPrintData: any = {};
  risk_Profile: {};
  basic: {};
  questionnaire: any = [];
  responsivenessLevel: any = "";
  risk_score_perc: any;
  first_name: any = "";
  middle_name: any = "";
  last_name: any = "";
  gender: any = "";
  location: any = "";
  contact_no: any = "";
  email: any = "";
  dob: any = "";
  selectedPersonaType: any = "";
  client_id: any = "";
  selectProspect: any = "";
  selectedprospect: any;
  questionsArray: any = {
    que1: "",
    que2: "",
    que3: "",
    que4: "",
    que5: "",
    que6: "",
    que7: "",
    que8: "",
    que9: {
      liquidity: "",
      year1: "",
      year2: "",
      year3: "",
      total_wealth: "",
    },
    que10: "",
    que11: "",
    que12: "",
    que13: "",
    que14: {
      riskProfile1: [],
      riskProfile2: [],
      riskProfile3: [],
      riskProfile4: [],
      riskProfile5: [],
      responsiveness2: [],
      responsiveness1: [],
      responsiveness4: [],
      responsiveness3: [],
    },
    que15: "",
    que16: {
      cg: {
        para1: {},
        para2: {},
        para3: {},
      },
      cy: {
        para1: {},
        para2: {},
        para3: {},
      },
    },
    que17: "",
    que18: "",
  };
  signedIps: any = {};
  geoAssetModel: any = {
    capitalGrowth: [true, false, false],
    capitalYielding: [false, true, true],
  };
  fundingSchedule: any = ["Immediately", "Year 1", "Year 2", "Above 2 Years"];
  liquidOptionWithdrawal: any = {
    year1: "",
    year2: "",
    year3: "",
  };

  AAAmount: number = 0;
  ngOnInit() {
    this.selectedprospect = JSON.parse(
      sessionStorage.getItem("selctedPersonaObj")
    );
    this.app.setAppTitle("Questionnaire Print");
    setTimeout(
      function () {
        this.getprintData();
      }.bind(this),
      100
    );
  }

  printComponent(cmpName) {
    var originalTitle = document.title;
    if (this.selectedprospect.type == "client") {
      document.title =
        this.selectedprospect.client_id + "'s Questionnaire Form";
    } else {
      document.title =
        this.selectedprospect.first_name +
        " " +
        this.selectedprospect.last_name +
        "'s Questionnaire Form";
    }

    window.print();
    window.close();
    document.title = originalTitle;
    //let printContents = document.getElementById(cmpName).innerHTML;
    //let originalContents = document.body.innerHTML;
    //document.body.innerHTML = printContents;
    //document.body.innerHTML = originalContents;
    //location.reload();
  }

  async getprintData() {
    var sendData = {
      instanceId: this.selectedprospect.instanceId,
      // this.selectedprospect.prospect_id
    };
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getprintData(sendData, headerObj).subscribe(
      (response: any) => {
        const dataResponse = response;
        if (dataResponse.success) {
          this.viewPrintData = dataResponse.data;
          this.first_name = this.viewPrintData.basic.first_name;
          this.middle_name = this.viewPrintData.basic.middle_name;
          this.last_name = this.viewPrintData.basic.last_name;
          this.location = this.viewPrintData.basic.location;
          this.contact_no = this.viewPrintData.basic.contact_no;
          this.email = this.viewPrintData.basic.email;
          this.dob = this.viewPrintData.basic.dob;
          this.gender = this.viewPrintData.basic.gender;
          this.selectedPersonaType = this.viewPrintData.basic.type;
          this.client_id = this.viewPrintData.basic.client_id;
          this.questionnaire = this.viewPrintData.questionnare;
          this.responsivenessLevel = this.viewPrintData.risk_Profile.responsiveness;
          this.risk_score_perc = this.viewPrintData.risk_Profile.risk_score_perc;
          this.totalAum = this.viewPrintData.risk_Profile.investment_amount;
          this.signedIps = this.viewPrintData.signedIps;
          for (let x = 0; x < this.questionnaire.length; x++) {
            const data_obj = this.questionnaire[x];
            if (data_obj.question_no == 1) {
              this.questionsArray.que1 = data_obj.answer;
            }
            if (data_obj.question_no == 2) {
              var question_2 = JSON.parse(data_obj.answer)[0];
              this.questionsArray.que2 = question_2;
            }
            if (data_obj.question_no == 3) {
              this.questionsArray.que3 = data_obj.answer;
            }
            if (data_obj.question_no == 4) {
              this.questionsArray.que4 = data_obj.answer;
            }
            if (data_obj.question_no == 5) {
              this.questionsArray.que5 = data_obj.answer;
            }
            if (data_obj.question_no == 6) {
              this.questionsArray.que6 = data_obj.answer;
            }
            if (data_obj.question_no == 7) {
              this.questionsArray.que7 = data_obj.answer;
            }
            if (data_obj.question_no == 8) {
              this.questionsArray.que8 = data_obj.answer;
            }
            if (data_obj.question_no == 9) {
              if (data_obj.answer) {
                let liqData = JSON.parse(data_obj.answer).length;
                if (liqData > 1) {
                  let qque9 = JSON.parse(data_obj.answer);
                  this.questionsArray.que9.liquidity = qque9[0].liquidity;
                  if (this.questionsArray.que9.liquidity == "Yes") {
                    this.questionsArray.que9.year1 = qque9[1].year1
                      ? parseInt(qque9[1].year1)
                      : 0;
                    this.questionsArray.que9.year1 = qque9[1].year1;
                    this.questionsArray.que9.year2 = qque9[2].year2
                      ? parseInt(qque9[2].year2)
                      : 0;
                    this.questionsArray.que9.year3 = qque9[3].year3
                      ? parseInt(qque9[3].year3)
                      : 0;

                    this.liquidOptionWithdrawal.year1 = qque9[1].fund;
                    this.liquidOptionWithdrawal.year2 = qque9[2].fund;
                    this.liquidOptionWithdrawal.year3 = qque9[3].fund;
                    this.questionsArray.que9.total_wealth =
                      parseInt(this.questionsArray.que9.year1) +
                      parseInt(this.questionsArray.que9.year2) +
                      parseInt(this.questionsArray.que9.year3);
                  }
                  if (this.questionsArray.que9.liquidity == "Yes") {
                    var immediateAmt = parseInt(
                      this.questionsArray.que2.immediately
                    );
                    var calAAAmount =
                      this.questionsArray.que9.total_wealth > 0
                        ? immediateAmt - this.questionsArray.que9.total_wealth
                        : 0;
                    this.AAAmount = calAAAmount / 2;
                  } else {
                    this.AAAmount =
                      parseInt(this.questionsArray.que2.immediately) / 2;
                  }
                } else {
                  let qque9 = JSON.parse(data_obj.answer)[0];
                  this.questionsArray.que9 = qque9;
                  var year1 =
                    qque9.year1 != ""
                      ? parseInt(this.questionsArray.que9.year1)
                      : 0;
                  var year2 =
                    qque9.year2 != ""
                      ? parseInt(this.questionsArray.que9.year2)
                      : 0;
                  var year3 =
                    qque9.year3 != ""
                      ? parseInt(this.questionsArray.que9.year3)
                      : 0;
                  if (this.questionsArray.que9.liquidity == "Yes") {
                    this.questionsArray.que9.total_wealth =
                      year1 + year2 + year3;
                    var immediateAmt = parseInt(
                      this.questionsArray.que2.immediately
                    );
                    var calAAAmount =
                      this.questionsArray.que9.total_wealth > 0
                        ? immediateAmt - this.questionsArray.que9.total_wealth
                        : 0;
                    this.AAAmount = calAAAmount / 2;
                  } else {
                    this.AAAmount =
                      parseInt(this.questionsArray.que2.immediately) / 2;
                  }
                }
              }
            }
            if (data_obj.question_no == 10) {
              this.questionsArray.que10 = data_obj.answer;
            }
            if (data_obj.question_no == 11) {
              this.questionsArray.que11 = data_obj.answer;
            }
            if (data_obj.question_no == 12) {
              this.questionsArray.que12 = data_obj.answer;
            }
            if (data_obj.question_no == 13) {
              var question_13 = JSON.parse(data_obj.answer)[0];
              this.questionsArray.que13 = question_13;
            }
            if (data_obj.question_no == 14) {
              let question_14 = JSON.parse(data_obj.answer);
              this.questionsArray.que14.riskProfile1 =
                question_14.riskProfile[0].answer;
              this.questionsArray.que14.riskProfile2 =
                question_14.riskProfile[1].answer;
              this.questionsArray.que14.riskProfile3 =
                question_14.riskProfile[2].answer;
              this.questionsArray.que14.riskProfile4 =
                question_14.riskProfile[3].answer;
              this.questionsArray.que14.riskProfile5 =
                question_14.riskProfile[4].answer;
              this.questionsArray.que14.responsiveness1 =
                question_14.responsiveness[0].answer;
              this.questionsArray.que14.responsiveness2 =
                question_14.responsiveness[1].answer;
              this.questionsArray.que14.responsiveness3 =
                question_14.responsiveness[2].answer;
              this.questionsArray.que14.responsiveness4 =
                question_14.responsiveness[3].answer;
            }
            if (data_obj.question_no == 16) {
              if (data_obj.answer && data_obj.answer != "null") {
                let question_16 = JSON.parse(data_obj.answer);
                this.questionsArray.que16.cg.para1 =
                  question_16.capitalGrowth[0];
                this.questionsArray.que16.cg.para2 =
                  question_16.capitalGrowth[1];
                this.questionsArray.que16.cg.para3 =
                  question_16.capitalGrowth[2];
                this.questionsArray.que16.cy.para1 =
                  question_16.capitalYielding[0];
                this.questionsArray.que16.cy.para2 =
                  question_16.capitalYielding[1];
                this.questionsArray.que16.cy.para3 =
                  question_16.capitalYielding[2];
              }
            }
            if (data_obj.question_no == 17) {
              this.questionsArray.que17 = data_obj.answer;
            }
            if (data_obj.question_no == 18) {
              this.questionsArray.que18 = data_obj.answer;
            }
          }
          setTimeout(
            function () {
              this.startRenderResChart();
              this.app.getLoader(false);
            }.bind(this),
            1000
          );
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  startRenderResChart() {
    // create chart
    var chart = am4core.create("respnsnssChartView", am4charts.GaugeChart);
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

    chart.innerRadius = -45;
    chart.startAngle = 200;
    chart.endAngle = 340;
    chart.y = 0;
    chart.logo.disabled = true;

    var axis = chart.xAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
    );
    axis.min = 0;
    axis.max = 3;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.disabled = true;
    axis.renderer.grid.template.strokeOpacity = 1;
    axis.renderer.grid.template.stroke = am4core.color("#fff");
    axis.renderer.grid.template.strokeWidth = 4;

    var range0 = axis.axisRanges.create();
    range0.value = 0;
    range0.endValue = 1;
    range0.axisFill.fillOpacity = 1;
    range0.axisFill.fill = am4core.color("#8eda5c");
    range0.axisFill.zIndex = -1;

    var range1 = axis.axisRanges.create();
    range1.value = 1;
    range1.endValue = 2;
    range1.axisFill.fillOpacity = 1;
    range1.axisFill.fill = am4core.color("#ffb914");
    range1.axisFill.zIndex = -1;

    var range2 = axis.axisRanges.create();
    range2.value = 2;
    range2.endValue = 3;
    range2.axisFill.fillOpacity = 1;
    range2.axisFill.fill = am4core.color("#ff7b6d");
    range2.axisFill.zIndex = -1;

    var hand = chart.hands.push(new am4charts.ClockHand());
    hand.fill = am4core.color("#343a40");
    hand.stroke = am4core.color("#343a40");
    hand.axis = axis;
    //hand.pin.disabled = true;
    hand.pin.radius = 5;
    hand.startWidth = 10;
    hand.endWidth = 0;
    hand.radius = am4core.percent(70);
    hand.innerRadius = am4core.percent(0);

    if (this.responsivenessLevel.toLowerCase() == "low") {
      hand.showValue(0.5);
    } else if (this.responsivenessLevel.toLowerCase() == "moderate") {
      hand.showValue(1.5);
    } else {
      hand.showValue(2.5);
    }
  }

  ngOnDestroy() {
    /* if (this.modalRef) {
      this.modalRef.hide();
    } */
    this.toastr.clear();
  }
}
