import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AppComponent } from "../app.component";
import { ToastrService } from "ngx-toastr";
import { CommonservicesService } from "../commonservices.service";
import { Router } from "@angular/router";
import pptxgen from "pptxgenjs";
import domtoimage from 'dom-to-image';
import { Label, MultiDataSet, Color, BaseChartDirective } from 'ng2-charts';
import { Chart, ChartOptions, ChartType } from 'chart.js';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
@Component({
  selector: "app-crr-configuration",
  templateUrl: "./crr-configuration.component.html",
  styleUrls: ["./crr-configuration.component.scss"],
})

export class CrrConfigurationComponent {
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  crrStaticData: any = {
    avgAgeOfDeals: "0.00",
    capitalGrowth: "40",
    capitalLessThanTwoYears: "0.00",
    capitalYield: "60",
    clientId: "37824",
    currentAum: "11.6k",
    maximumDropdown: "52.10",
    msciWorldIndex: "-33.69",
    previousAumValues: [
      {
        amount: 0.010139559999999999,
        year: 2012
      },
      {
        amount: 0.01032047,
        year: 2013
      },
      {
        amount: 0.010348659999999999,
        year: 2014
      },
      {
        amount: 0.01019343,
        year: 2015
      },
      {
        amount: 0.01013195,
        year: 2016
      },
      {
        amount: 0.01049485,
        year: 2017
      },
      {
        amount: 0.010277389999999999,
        year: 2018
      },
      {
        amount: 0.01114425,
        year: 2019
      },
      {
        amount: 0.01156442,
        year: 2020
      },
    ],
    relationshipStartedIn: "2012-08-13T00:00:00.000Z",
    totalDistributions: 0,
    totalProfit: "1.6k",
    totalReturnIlliquid: "0.00",
    totalReturnOverall: "0.72",
    totalUncalledCommitement: "2.72",
  }

  isCRRDeckData: any = {};
  isUploadFile: any = {
    isCRRDeckFile: File,
    isCRRImageFile: File,
    isCRRVideoFile: File
  }
  modalRef: BsModalRef;
  fileUploadService: any;
  getSelectedClientObj: any;
  responseDataMainObj: any;
  slideSelectedCount: number = 0;
  totalSlideCount: number;
  footNoteModelInput: any;
  addFootnoteSlideName: any;
  lasttUpdateDate: any;
  footNoteModalObj: any = {
    slideIndex: null,
    footNoteImg: "",
    footNoteTxt: "",
    footNoteTitle: "",
  };
  selectedPersonaDtlsObj: any = {};
  excelFileObj: any = {
    fileUrl: "",
    fileName: "",
    fileObj: {},
  };
  regenerate_is: boolean = true;
  slideImagePath: string;
  deckPreviewUrl: any;
  generationStartedIs: boolean = false;
  rpaSlideUnSelectedIs: boolean = false;
  getLoginUserData: any = {};
  cg: number;
  cy: number;
  LessThanTwoYears: number;
  totalAum: number;
  maxDropdown: number;
  msci: number;
  ReturnOverall: number;
  ReturnIlliquid: number;
  year1: number;
  year2: number;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  constructor(
    private modalService: BsModalService,
    public app: AppComponent,
    private toastr: ToastrService,
    private http: CommonservicesService,
    private router: Router,
  ) {
    this.slideImagePath = this.app.credDataObj.config.rootURL;
    am4core.useTheme(am4themes_kelly);
    am4core.useTheme(am4themes_animated);
  }
  preSettingIs: boolean = false;
  isStatusText: boolean = false;
  ngOnInit() {
    this.app.setAppTitle("CRR Config");
    this.getSelectedClientObj = JSON.parse(
      sessionStorage.getItem("selectedClientObj")
    );
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
    setTimeout(() => {
      this.lastUpdateOn(null);
      this.getDeckSettings();
    }, 100);
  }

  lineChartOptions = {

    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          maxTicksLimit: 5,
          maxRotation: 0,
          minRotation: 0,
          fontSize: 20
        },
        gridLines: { color: '#111111' },
        fontSize: 30
      }],
      yAxes: [{
        ticks: {
          fontSize: 20,
          beginAtZero: true,
          min: 0,
          maxTicksLimit: 5,
          callback: function (value, index, values) {
            return value + 'm';
          },

        },
        gridLines: { color: '#111111' },
      }]
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  lineChartColors: Color[] = [
    {
      borderColor: '#e1e1e1',
    },
  ];
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';

  drowCgCyChartComp() {
    var currCmprsnChart = am4core.create(
      "chartCompForCurrCmparsn",
      am4charts.PieChart
    );
    // Add data
    currCmprsnChart.data = [
      {
        value: this.cg,
        title: "Capital Growth",
        color: am4core.color("#B99855"),
        fontSize: "70",
      },
      {
        value: this.cy,
        title: "Capital Yielding",
        color: am4core.color("#A5D2DC"),
        fontSize: "70",
      },

      // {
      //   value: 10,
      //   title: "Opportunistic",
      //   color: am4core.color("#5DA683"),
      //   fontSize: "35",
      // },
    ];

    // Set inner radius
    currCmprsnChart.innerRadius = am4core.percent(40);
    var currPieSeries = currCmprsnChart.series.push(new am4charts.PieSeries());
    currPieSeries.dataFields.value = "value";
    currPieSeries.dataFields.category = "title";
    currPieSeries.slices.template.propertyFields.fill = "color";
    currCmprsnChart.logo.disabled = true;
    currPieSeries.ticks.template.disabled = true;
    currPieSeries.alignLabels = false;
    currPieSeries.labels.template.text = "{value.value}%";
    currPieSeries.labels.template.fontSize = 30;
    // currPieSeries.alignLabels = true;
    currPieSeries.labels.template.radius = am4core.percent(-30);
    currPieSeries.labels.template.fill = am4core.color("black");


    currPieSeries.labels.template.adapter.add("text", (label, target, key) => {
      if (target.dataItem.value == 0) {
        return "";
      } else {
        return label;
      }
    });
  }

  async getDeckSettings() {
    this.app.getLoader(true);
    var sendData = {
      client_id: this.getSelectedClientObj.clientid,
      user_id: this.getLoginUserData.aaaUserId,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getDeckSettings(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.getSelectedClientObj.clientMasterId =
            response.client_prospect_id;
          sessionStorage.setItem(
            "selectedClientObj",
            JSON.stringify(this.getSelectedClientObj)
          );
          sessionStorage.setItem(
            "preSettingData",
            JSON.stringify(response.slide_data)
          );
          response.selectAllIs = false;
          this.responseDataMainObj = response;
          this.selectOneByOneCheckBox(false);
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  allSelectedCheckFun(type) {
    if (type == "select") {
      this.responseDataMainObj.selectAllIs = true;
      for (
        let index = 0;
        index < this.responseDataMainObj.slide_data.length;
        index++
      ) {
        this.responseDataMainObj.slide_data[index].slideEnableIs = true;
      }
    } else {
      for (
        let index = 0;
        index < this.responseDataMainObj.slide_data.length;
        index++
      ) {
        this.responseDataMainObj.slide_data[
          index
        ].slideEnableIs = this.responseDataMainObj.selectAllIs;
      }
    }
    if (this.responseDataMainObj.selectAllIs) {
      this.slideSelectedCount = this.responseDataMainObj.slide_data.length;
      this.rpaSlideUnSelectedIs = false;
    } else {
      this.slideSelectedCount = 0;
      this.rpaSlideUnSelectedIs = true;
    }
    this.checkChangeFun();
  }
  selectOneByOneCheckBox(checkFunCallIs) {
    if (this.responseDataMainObj.slide_data) {
      var slideCount = this.responseDataMainObj.slide_data.length + 1;
      var checkSlideTrue = 0;
      for (
        let index = 0;
        index < this.responseDataMainObj.slide_data.length;
        index++
      ) {
        if (this.responseDataMainObj.slide_data[index].slideEnableIs) {
          checkSlideTrue++;
          if (this.responseDataMainObj.slide_data[index].slide_id == 8) {
            this.rpaSlideUnSelectedIs = false;
          }
        } else {
          if (this.responseDataMainObj.slide_data[index].slide_id == 8) {
            this.rpaSlideUnSelectedIs = true;
          }
        }
      }
      this.slideSelectedCount = checkSlideTrue++;
      if (slideCount == checkSlideTrue) {
        this.responseDataMainObj.selectAllIs = true;
      } else {
        this.responseDataMainObj.selectAllIs = false;
      }
      if (checkFunCallIs) {
        this.checkChangeFun();
      }
    }
  }

  checkChangeFun() {
    var preSettData = JSON.parse(sessionStorage.getItem("preSettingData"));
    for (let index = 0; index < preSettData.length; index++) {
      var preElementObj = preSettData[index];
      for (let k = 0; k < this.responseDataMainObj.slide_data.length; k++) {
        var secElementObj = this.responseDataMainObj.slide_data[k];
        if (preElementObj.slide_name == secElementObj.slide_name) {
          if (preElementObj.slideEnableIs == secElementObj.slideEnableIs) {
            this.preSettingIs = false;
          } else {
            this.preSettingIs = true;
            break;
          }
        }
      }
      if (this.preSettingIs) {
        break;
      }
    }
  }

  reloadePreSettingObj() {
    this.responseDataMainObj.slide_data = JSON.parse(
      sessionStorage.getItem("preSettingData")
    );
    this.preSettingIs = false;
    this.selectOneByOneCheckBox(false);
  }

  fileOnOverIs: boolean = false;
  async lastUpdateOn(setModalTemp) {
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getLastUpdatedDate(headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          if (response.data) {
            this.lasttUpdateDate = response.data[0].createdAt;
          }
        } else {
          this.toastr.info("Last updated date not found");
        }
        if (setModalTemp) {
          this.settingModel(setModalTemp);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  getSelectedFile(event) {
    var selectedFileObj = event;
    this.excelFileObj.fileName = selectedFileObj.name;
    this.excelFileObj.fileObj = selectedFileObj;
  }

  fileOnDropped(event) {
    event.preventDefault();
    event.stopPropagation();
    let dataTransfer = event.dataTransfer.files[0];
    this.getSelectedFile(dataTransfer);
    this.fileOnOverIs = false;
  }

  fileOnDragover(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOnOverIs = true;
  }

  fileOnDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOnOverIs = false;
  }

  fileClear() {
    this.excelFileObj.fileUrl = "";
    this.excelFileObj.fileName = "";
  }

  async excelUploadFunc() {
    if (this.excelFileObj.fileName) {
      var reg = /(.*?)\.(pptx|ppt)$/;
      if (!this.excelFileObj.fileName.match(reg)) {
        this.toastr.warning("Only PPT Files Are Allowed");
        return;
      }
      this.app.getLoader(true);
      const formData = new FormData();
      formData.append("deckname", this.excelFileObj.fileObj);
      formData.append("user_id", this.getLoginUserData.aaaUserId);
      var headerObj = await this.app.getHTTPHeaders();
      this.http.uploadMarketDataFile(formData, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            this.toastr.success(response.msg);
            setTimeout(
              function () {
                this.modalRef.hide();
              }.bind(this),
              1000
            );
          } else {
            this.toastr.error("Issue Found");
          }
          this.app.getLoader(false);
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    } else {
      this.toastr.warning("Select a file");
    }
  }

  addFootNotePartSlide(indexObj, index, modalTempId) {
    this.footNoteModalObj.slideIndex = index;
    this.footNoteModalObj.footNoteImg = indexObj.img;
    this.footNoteModalObj.footNoteTxt = indexObj.footnote;
    this.footNoteModalObj.footNoteTitle = indexObj.slide_name;
    this.openModal(modalTempId);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "modal-lg" })
    );
  }

  settingModel(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.fileClear();
  }

  getEnterCodeForSubmit(event) {
    var shiftPlusEnterIs = event.code == "Enter" && event.ctrlKey;
    var shiftPlusNumPadEnterIs = event.code == "NumpadEnter" && event.ctrlKey;
    if (shiftPlusEnterIs || shiftPlusNumPadEnterIs) {
      event.preventDefault();
      this.addFootnoteForDeck();
    }
  }

  maxLength: any = 250;
  async addFootnoteForDeck() {
    //if (this.footNoteModalObj.footNoteTxt) {
    if (this.footNoteModalObj.footNoteTxt.length <= this.maxLength) {
      this.responseDataMainObj.slide_data[
        this.footNoteModalObj.slideIndex
      ].footnote = this.footNoteModalObj.footNoteTxt;
      if (this.footNoteModalObj.footNoteTxt) {
        this.toastr.success("Footnotes Added Successfully");
        setTimeout(
          function () {
            this.modalRef.hide();
          }.bind(this),
          1000
        );
      } else {
        this.modalRef.hide();
      }
    } else {
      this.toastr.error(
        "The Footnote length should be less than " + this.maxLength
      );
    }
  }

  statusViewWindow: any;
  openConfigView() {
    localStorage.setItem(
      "selectedClientObj",
      JSON.stringify(this.getSelectedClientObj)
    );
    let newRelativeUrl = this.router.createUrlTree(["home/rpaProgress"]);
    let baseUrl = window.location.href.replace(this.router.url, "");
    this.statusViewWindow = window.open(
      baseUrl + newRelativeUrl,
      "CRR RPA Status View",
      "height=1600,width=1800,close=no"
    );
    this.statusViewWindow.moveTo(0, 0);
    this.statusViewWindow.resizeTo(screen.availWidth, screen.availHeight);
    this.generationStartedIs = true;

    setTimeout(() => {
      this.goToDashboardWithTimeout();
    }, 10000);
  }

  goToDashboardWithTimeout() {
    this.app.navigateThis("/home/crrDashboard");
  }

  async postSettingData(en,ar) {
    this.app.getLoader(true);
    var sendData = {
      clientMasterId: this.getSelectedClientObj.clientMasterId,
      userId: this.getLoginUserData.aaaUserId,
      slide_data: this.responseDataMainObj.slide_data,
      lang: en || ar,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.saveDeckSettingsAndProgress(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.getSelectedClientObj.clientLogMasterId =
            response.data.clientLogMasterId;
          this.getSelectedClientObj.clientType = this.getSelectedClientObj.type;
          sessionStorage.setItem(
            "selectedClientObj",
            JSON.stringify(this.getSelectedClientObj)
          );
          if (this.rpaSlideUnSelectedIs) {
            this.getDownloadCrrDeck(en,ar);
          } else {
            this.toastr.success("Setting Added Successfully");
            setTimeout(() => {
              this.openConfigView();
              this.app.getLoader(false);
            }, 500);
          }
        } else {
          this.toastr.info("Issue Found: " + response.msg);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async getDownloadCrrDeck(en,ar) {
    this.app.getLoader(true);
    var sendData = {
      clientLogMasterId: this.getSelectedClientObj.clientLogMasterId,
      clientMasterId: this.getSelectedClientObj.clientMasterId,
      clientId: this.getSelectedClientObj.clientid,
      lang: en||ar
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.downloadCRRDeck(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.deckPreviewUrl = this.slideImagePath + response.path;
          window.open(this.deckPreviewUrl);
          this.toastr.success("Deck Download Successfully");
          setTimeout(() => {
            this.goToDashboardWithTimeout();
          }, 2000);
        } else {
          this.toastr.info("Issue Found: " + response.msg);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async postSettingDataForVideo() {
    this.app.getLoader(true);
    var sendData = {
      clientMasterId: this.getSelectedClientObj.clientMasterId,
      userId: this.getLoginUserData.aaaUserId,
      slide_data: this.responseDataMainObj.slide_data,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.saveDeckSettingsAndProgress(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.getSelectedClientObj.clientLogMasterId =
            response.data.clientLogMasterId;
          this.getSelectedClientObj.clientType = this.getSelectedClientObj.type;
          sessionStorage.setItem(
            "selectedClientObj",
            JSON.stringify(this.getSelectedClientObj)
          );

          this.downloadCRRVideo();

        } else {
          this.toastr.info("Issue Found: " + response.msg);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  lineChartList: any = [];
  lineChartLabelList: any = [];
  lineChartData: Chart.ChartDataSets[] = [
    { data: [] },
  ];
  lineChartLabels: Label[];
  updateChart() {
    this.lineChartData[0].data = this.lineChartList;
    this.lineChartLabels = this.lineChartLabelList;
    this.chart.update();
  }

  /* Download CRR Video Data */
  async downloadCRRVideo() {
    this.app.getLoader(true);
    var isLineChartLabel = [];
    var isLineChartData = [];
    var sendData = {
      clientMasterId: this.getSelectedClientObj.clientMasterId,
      clientId: this.getSelectedClientObj.clientid,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.downloadCRRVideo(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          sessionStorage.setItem(
            "preSettingData",
            JSON.stringify(response.slide_data)
          );
          response.selectAllIs = false;
          this.isCRRDeckData = response.data;

          var cgg = this.isCRRDeckData.capitalGrowth;
          this.cg = parseFloat(cgg);
          //console.log("cg", this.cg);
          var cyy = this.isCRRDeckData.capitalYield
          this.cy = parseFloat(cyy);
          var LessThanTwoYearss = this.isCRRDeckData.capitalLessThanTwoYears;
          this.LessThanTwoYears = parseFloat(LessThanTwoYearss);
          var totalAums = this.isCRRDeckData.currentAum;
          this.totalAum = parseFloat(totalAums);
          //console.log(this.totalAum, "totalAum");
          var maxDropdowns = this.isCRRDeckData.maximumDropdown;
          this.maxDropdown = parseFloat(maxDropdowns);
          //console.log(this.maxDropdown, "this.maxDropdown");
          var mscis = this.isCRRDeckData.msciWorldIndex;
          this.msci = parseFloat(mscis);
          var ReturnOveralls = this.isCRRDeckData.totalReturnOverall;
          this.ReturnOverall = parseFloat(ReturnOveralls);
          var ReturnIlliquids = this.isCRRDeckData.totalReturnIlliquid;
          this.ReturnIlliquid = parseFloat(ReturnIlliquids);
          var previousAumValue = this.isCRRDeckData.previousAumValues;

          previousAumValue.forEach((item, index) => {
            var value = item.amount.toFixed(4);
            isLineChartLabel.push(item.year.toString());
            isLineChartData.push(value);
          });

          setTimeout(() => {
            this.lineChartLabelList = isLineChartLabel;
            this.lineChartList = isLineChartData;
            this.drowCgCyChartComp();
            this.updateChart();
          }, 100);

        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
        setTimeout(() => {
          this.crrVideoGen();
        }, 500);
      },
      (error) => {
        this.app.errorHandler(error);
      }

    );
    //this.crrVideoGen();
  }

  formatDate(date) {
    // date = new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return mm + "" + yyyy;
  }

  /* CRR Deck Generation */
  crrVideoGen() {
    this.app.getLoader(true);
    this.app.isLoadingText = "Generating CRR deck..";
    let pres = new pptxgen();
    pres.defineLayout({ name: "A4", width: 13.333, height: 7.5 });
    pres.layout = "A4";

    pres.defineSlideMaster({
      title: "MASTER_SLIDE",
      bkgd: "000000",
      objects: [
        {
          image: {
            x: 0,
            y: 0,
            w: 13.33,
            h: 7.5,
            path: "./assets/img/backnew.png"
          },
        },
        {
          image: {
            x: 0.52,
            y: 0.52,
            w: 1.05,
            h: 0.46,
            path: "./assets/img/logoslide.png"
          },
        },
        {
          text: {
            text: "Portfolio Summary",
            options: {
              x: 3.98,
              y: 0.48,
              w: 3.39,
              h: 0.45,
              color: "FFFFFF",
              fontSize: 21,
              fontFace: "GOTHAM-LIGHT"
            },
          },
        },
        {
          image: {
            x: 0.27,
            y: 6.84,
            w: 0.15,
            h: 0.5,
            path: "./assets/img/footerimg.png"
          },
        },
        {
          text: {
            text: "Past performance is not indicative of and does not guarantee future performance. Exit timelines, prices and related projections are estimates only, and exits could happen sooner or later than expected, or at a higher or lower valuation than expected, and are conditional, among other things, on certain assumptions and future performance relating to the financial and operational health of each business and macroeconomic conditions.",
            options: {
              x: 0.53,
              y: 6.76,
              h: 0.66,
              w: 5.80,
              fontSize: 8,
              fontFace: "Gotham Light",
              color: "A6A6A6",
            },
          },
        },
      ],
    });

    var slide1 = pres.addSlide("MASTER_SLIDE");
    slide1.addText(
      [
        { text: "Relationship since ", options: { breakLine: false } },
        { text: this.app.getFormattedDate(new Date(this.isCRRDeckData.relationshipStartedIn), "MMMM yyyy"), options: { breakLine: false } }
      ],
      {
        x: 8.47,
        y: 0.58,
        w: 3.05,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF",
        align: "right"
      }
    );
    slide1.addShape(pres.ShapeType.ellipse, { x: 11.52, y: 0.72, w: 0.05, h: 0.05, align: "center", fill: { type: "solid", color: "FFFFFF" } });
    slide1.addText(
      [
        { text: "Account #" + this.getSelectedClientObj.clientid, options: { breakLine: false } },
      ],
      {
        x: 11.55,
        y: 0.58,
        w: 1.53,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF"
      }
    );
    slide1.addText("Total AUM", { x: 0.39, y: 2.64, w: 3.39, h: 0.35, color: "FFFFFF", fontSize: 15, fontFace: "GOTHAM-LIGHT" });
    slide1.addText("$", { x: 4.98, y: 2.45, w: 4.46, h: 0.4, color: "FFFFFF", fontSize: 15, fontFace: "GOTHAM-LIGHT" });
    slide1.addText(
      [
        { text: this.isCRRDeckData.currentAum ? this.isCRRDeckData.currentAum : "--", options: {} },

      ],
      {
        x: 5.19,
        y: 2.43,
        w: 3.39,
        h: 0.71,
        color: "FFFFFF",
        fontSize: 35,
        fontFace: "Gotham Thin"
      }
    );
    let dataChart = [
      {
        name: "Actual Sales",
        labels: [],
        values: [],
      },
    ];

    for (let b = 0; b < this.isCRRDeckData.previousAumValues.length; b++) {
      dataChart[0].labels.push(this.isCRRDeckData.previousAumValues[b].year);
      dataChart[0].values.push(parseFloat(this.isCRRDeckData.previousAumValues[b].amount));
    };

    slide1.addChart(pres.ChartType.line, dataChart, {
      x: 0.42,
      y: 3.28,
      w: 6.45,
      h: 2.2,
      serAxisLabelFrequency: "5",
      catAxisLabelFrequency: "5",
      catAxisLabelColor: '7F7F7F',
      valAxisLabelColor: '7F7F7F',
      catAxisBaseTimeUnit: "year",
      chartColors: ["FFFFFF"],
      radarStyle: "marker",
      border: { color: '111111' },
      dataLabelColor: '0088CC',
      color: '111111',
      catAxisLineShow: false,
      valAxisLineShow: false,
      lineSmooth: true,
      lineDataSymbol: "none",
      catAxisMajorUnit: 2,
      catGridLine: { color: '111111' },
      valGridLine: { color: '111111' },
      valAxisLabelFontFace: 'GOTHAM-LIGHT',
      valAxisLabelFontSize: 10.5,
      catAxisLabelFontFace: 'GOTHAM-LIGHT',
      catAxisLabelFontSize: 10.5


    });

    var slide2 = pres.addSlide("MASTER_SLIDE");
    slide2.addText(
      [
        { text: "Relationship since ", options: { breakLine: false } },
        { text: this.app.getFormattedDate(new Date(this.isCRRDeckData.relationshipStartedIn), "MMMM yyyy"), options: { breakLine: false } }
      ],
      {
        x: 8.47,
        y: 0.58,
        w: 3.05,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF",
        align: "right"
      }
    );
    slide2.addShape(pres.ShapeType.ellipse, { x: 11.52, y: 0.72, w: 0.05, h: 0.05, align: "center", fill: { type: "solid", color: "FFFFFF" } });
    slide2.addText(
      [
        { text: "Account #" + this.getSelectedClientObj.clientid, options: { breakLine: false } },
      ],
      {
        x: 11.55,
        y: 0.58,
        w: 1.53,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF"
      }
    );

    if (parseFloat(this.isCRRDeckData.totalReturnOverall) >= 0) {
      slide2.addImage({ path: "./assets/img/uparrow1.png", x: 1.95, y: 2.68, h: 0.71, w: 0.38 });
    } else {
      slide2.addImage({ path: "./assets/img/downArrow.png", x: 1.95, y: 2.68, h: 0.71, w: 0.38 });
    }
    slide2.addText(
      [
        { text: this.isCRRDeckData.totalReturnOverall ? this.isCRRDeckData.totalReturnOverall : "--", options: { breakLine: false } },
        { text: "%", options: { breakLine: false } },
        { text: "  per annum", options: { breakLine: false, color: "FFFFFF", fontSize: 13, fontFace: "GOTHAM-LIGHT" } },
      ],
      {
        x: 2.81,
        y: 2.39,
        w: 3.5,
        h: 0.71,
        color: "FFFFFF",
        fontSize: 35,
        fontFace: "Gotham Thin"
      }
    );
    slide2.addText("Total return of overall portfolio since inception", { x: 2.81, y: 3.06, w: 2.4, h: 0.4, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT" });

    if (parseFloat(this.isCRRDeckData.totalReturnIlliquid) >= 0) {
      slide2.addImage({ path: "./assets/img/uparrow1.png", x: 1.95, y: 4.18, h: 0.71, w: 0.38 });
    } else {
      slide2.addImage({ path: "./assets/img/downArrow.png", x: 1.95, y: 4.18, h: 0.71, w: 0.38 });
    }

    slide2.addText(
      [
        { text: this.isCRRDeckData.totalReturnIlliquid ? this.isCRRDeckData.totalReturnIlliquid : "--", options: { breakLine: false } },
        { text: "%", options: { breakLine: false } },
        { text: "  per annum", options: { breakLine: false, color: "FFFFFF", fontSize: 13, fontFace: "GOTHAM-LIGHT" } },

      ],
      {
        x: 2.81,
        y: 4.02,
        w: 3.5,
        h: 0.69,
        color: "FFFFFF",
        fontSize: 35,
        fontFace: "Gotham Thin"
      }
    );
    // slide2.addText("per annum", { x: 4.34, y: 4.32, w: 2.77, h: 0.32, color: "FFFFFF", fontSize: 13, fontFace: "GOTHAM-LIGHT" });
    slide2.addText("Total return of illiquid Portfolio since inception", { x: 2.81, y: 4.65, w: 2.43, h: 0.4, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT" });

    var slide3 = pres.addSlide("MASTER_SLIDE");
    slide3.addText(
      [
        { text: "Relationship since ", options: { breakLine: false } },
        { text: this.app.getFormattedDate(new Date(this.isCRRDeckData.relationshipStartedIn), "MMMM yyyy"), options: { breakLine: false } }
      ],
      {
        x: 8.47,
        y: 0.58,
        w: 3.05,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF",
        align: "right"
      }
    );
    slide3.addShape(pres.ShapeType.ellipse, { x: 11.52, y: 0.72, w: 0.05, h: 0.05, align: "center", fill: { type: "solid", color: "FFFFFF" } });
    slide3.addText(
      [
        { text: "Account #" + this.getSelectedClientObj.clientid, options: { breakLine: false } },
      ],
      {
        x: 11.55,
        y: 0.58,
        w: 1.53,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF"
      }
    );

    slide3.addText("Target Investment Allocation", { x: 1.94, y: 2.2, w: 3.78, h: 0.4, color: "FFFFFF", fontSize: 17, fontFace: "GOTHAM-LIGHT" });
    let dataChartAreaLine = [
      {
        labels: ["Capital Growth", "Capital Yielding",],
        values: [parseFloat(this.isCRRDeckData.capitalGrowth), parseFloat(this.isCRRDeckData.capitalYield)],
      }
    ];

    slide3.addChart(pres.ChartType.doughnut, dataChartAreaLine, {
      x: 1.32,
      y: 2.6,
      w: 4.96,
      h: 3.28,
      // showLegend: false,
      // legendPos: "b",
      // legendFontFace: "GOTHAM-LIGHT",
      // legendColor: 'FFFFFF',
      fontFace: "GOTHAM-LIGHT",
      dataLabelFontFace: "GOTHAM-LIGHT",
      catAxisTitleFontFace: "GOTHAM-LIGHT",
      titleFontFace: "GOTHAM-LIGHT",
      dataLabelFontSize: 12,
      barGapWidthPct: 50,
      dataLabelColor: "000000",
      holeSize: 50,
      chartColors: ["B99855", "A5D2DC", "5DA683"],
      dataLabelFormatCode: '0%\;\;\;'
    });
    slide3.addShape(pres.ShapeType.rect, { x: 2.38, y: 6.07, w: 0.14, h: 0.14, align: "center", fill: { type: "solid", color: "B99855" } });
    slide3.addText("Capital Growth", { x: 2.58, y: 6.01, w: 1.25, h: 0.27, color: "FFFFFF", fontSize: 10, fontFace: "GOTHAM-LIGHT" });

    slide3.addShape(pres.ShapeType.rect, { x: 3.95, y: 6.07, w: 0.14, h: 0.14, align: "center", fill: { type: "solid", color: "A5D2DC" } });
    slide3.addText("Capital Yielding", { x: 4.14, y: 6.01, w: 1.30, h: 0.27, color: "FFFFFF", fontSize: 10, fontFace: "GOTHAM-LIGHT" });


    var slide4 = pres.addSlide("MASTER_SLIDE");
    slide4.addText(
      [
        { text: "Relationship since ", options: { breakLine: false } },
        { text: this.app.getFormattedDate(new Date(this.isCRRDeckData.relationshipStartedIn), "MMMM yyyy"), options: { breakLine: false } }
      ],
      {
        x: 8.47,
        y: 0.58,
        w: 3.05,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF",
        align: "right"
      }
    );
    slide4.addShape(pres.ShapeType.ellipse, { x: 11.52, y: 0.72, w: 0.05, h: 0.05, align: "center", fill: { type: "solid", color: "FFFFFF" } });
    slide4.addText(
      [
        { text: "Account #" + this.getSelectedClientObj.clientid, options: { breakLine: false } },
      ],
      {
        x: 11.55,
        y: 0.58,
        w: 1.53,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF"
      }
    );

    slide4.addImage({ path: "./assets/img/wallet.png", x: 1.79, y: 1.76, h: 0.71, w: 0.71 });
    slide4.addText("$", { x: 2.88, y: 1.6, w: 0.46, h: 0.4, color: "FFFFFF", fontSize: 18, fontFace: "Gotham Thin" });
    slide4.addText(
      [
        { text: this.isCRRDeckData.totalDistributions ? this.isCRRDeckData.totalDistributions : "--", options: { breakLine: false } }
      ],
      {
        x: 3.09,
        y: 1.59,
        w: 2.5,
        h: 0.71,
        color: "FFFFFF",
        fontSize: 35,
        fontFace: "Gotham Thin"
      }
    );
    slide4.addText("Total distributions since inception", { x: 3.09, y: 2.22, w: 1.58, h: 0.4, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT" });

    if (parseFloat(this.isCRRDeckData.totalProfit) >= 0) {
      slide4.addImage({ path: "./assets/img/uparrow1.png", x: 2.11, y: 3.43, h: 0.71, w: 0.38 });
    } else {
      slide4.addImage({ path: "./assets/img/downArrow.png", x: 2.11, y: 3.43, h: 0.71, w: 0.38 });
    }
    slide4.addText("$", { x: 2.88, y: 3.23, w: 0.46, h: 0.4, color: "FFFFFF", fontSize: 18, fontFace: "Gotham Thin" });
    slide4.addText(
      [
        { text: this.isCRRDeckData.totalProfit ? this.isCRRDeckData.totalProfit : "--", options: { breakLine: false } }
      ],
      {
        x: 3.09,
        y: 3.21,
        w: 2.5,
        h: 0.71,
        color: "FFFFFF",
        fontSize: 35,
        fontFace: "Gotham Thin"
      }
    );
    slide4.addText("Total profit and loss since inception", { x: 3.09, y: 3.85, w: 1.58, h: 0.4, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT" });


    slide4.addImage({ path: "./assets/img/stack.png", x: 1.78, y: 4.93, h: 0.71, w: 0.71 });
    slide4.addText("$", { x: 2.88, y: 4.85, w: 0.46, h: 0.4, color: "FFFFFF", fontSize: 18, fontFace: "Gotham Thin" });
    slide4.addText(
      [
        { text: this.isCRRDeckData.totalUncalledCommitement ? this.isCRRDeckData.totalUncalledCommitement : "--", options: { breakLine: false } }
      ],
      {
        x: 3.09,
        y: 4.84,
        w: 2.5,
        h: 0.71,
        color: "FFFFFF",
        fontSize: 35,
        fontFace: "Gotham Thin"
      }
    );
    slide4.addText("Total uncalled commitment since inception", { x: 3.09, y: 5.47, w: 2.01, h: 0.4, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT" });

    var slide5 = pres.addSlide("MASTER_SLIDE");
    slide5.addText(
      [
        { text: "Relationship since ", options: { breakLine: false } },
        { text: this.app.getFormattedDate(new Date(this.isCRRDeckData.relationshipStartedIn), "MMMM yyyy"), options: { breakLine: false } }
      ],
      {
        x: 8.47,
        y: 0.58,
        w: 3.05,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF",
        align: "right"
      }
    );
    slide5.addShape(pres.ShapeType.ellipse, { x: 11.52, y: 0.72, w: 0.05, h: 0.05, align: "center", fill: { type: "solid", color: "FFFFFF" } });
    slide5.addText(
      [
        { text: "Account #" + this.getSelectedClientObj.clientid, options: { breakLine: false } },
      ],
      {
        x: 11.55,
        y: 0.58,
        w: 1.53,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF"
      }
    );

    slide5.addText(this.isCRRDeckData.capitalLessThanTwoYears ? this.isCRRDeckData.capitalLessThanTwoYears + "%" : "--", { x: 2.05, y: 2.43, w: 2.99, h: 0.69, color: "FFFFFF", fontSize: 35, fontFace: "Gotham Thin", align: "center" });
    slide5.addText("of Capital Invested", { x: 2.03, y: 3.12, w: 2.97, h: 0.34, color: "FFFFFF", fontSize: 14, fontFace: "GOTHAM-LIGHT", align: "center" });
    slide5.addText("(within 2 years)", { x: 2.56, y: 3.42, w: 1.94, h: 0.25, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT", align: "center" });

    slide5.addImage({ path: "./assets/img/watchstack.png", x: 1.76, y: 5.22, h: 0.71, w: 0.71 });
    slide5.addText(
      [
        { text: this.isCRRDeckData.avgAgeOfDeals ? this.isCRRDeckData.avgAgeOfDeals : "--", options: { breakLine: false } },
        { text: " years", options: { breakLine: false } }
      ],
      {
        x: 2.83,
        y: 5.09,
        w: 2.99,
        h: 0.69,
        color: "FFFFFF",
        fontSize: 35,
        fontFace: "Gotham Thin"
      }
    );
    slide5.addText("Average age of deals in the portfolio", { x: 2.83, y: 5.72, w: 2.78, h: 0.25, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT" });

    slide5.addText("Average age of deals in the portfolio", { x: 2.83, y: 5.72, w: 2.78, h: 0.25, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT" });

    let dataChartAreaLin = [
      {
        labels: ["Capital Invested", "Capital Investe"],
        values: [parseFloat(this.isCRRDeckData.capitalLessThanTwoYears), parseFloat(this.isCRRDeckData.capitalLessThanTwoYears) - 100],
      }
    ];

    slide5.addChart(pres.ChartType.doughnut, dataChartAreaLin, {
      x: 1.84,
      y: 1.5,
      w: 3.35,
      h: 3.21,
      showPercent: false,
      fontFace: "Gotham Book",
      dataLabelFontFace: "Gotham Book",
      catAxisTitleFontFace: "Gotham Book",
      holeSize: 90,
      chartColors: ["B99855", "222222"]
    });

    var slide6 = pres.addSlide("MASTER_SLIDE");
    slide6.addText(
      [
        { text: "Relationship since ", options: { breakLine: false } },
        { text: this.app.getFormattedDate(new Date(this.isCRRDeckData.relationshipStartedIn), "MMMM yyyy"), options: { breakLine: false } }
      ],
      {
        x: 8.47,
        y: 0.58,
        w: 3.05,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF",
        align: "right"
      }
    );
    slide6.addShape(pres.ShapeType.ellipse, { x: 11.52, y: 0.72, w: 0.05, h: 0.05, align: "center", fill: { type: "solid", color: "FFFFFF" } });
    slide6.addText(
      [
        { text: "Account #" + this.getSelectedClientObj.clientid, options: { breakLine: false } },
      ],
      {
        x: 11.55,
        y: 0.58,
        w: 1.53,
        h: 0.29,
        fontFace: "GOTHAM-LIGHT",
        fontSize: 10,
        color: "FFFFFF"
      }
    );
    if (parseFloat(this.isCRRDeckData.maximumDropdown) >= 0) {
      slide6.addImage({ path: "./assets/img/uparrow1.png", x: 2.11, y: 2.47, h: 0.71, w: 0.38 });
    } else {
      slide6.addImage({ path: "./assets/img/downArrow.png", x: 2.11, y: 2.47, h: 0.71, w: 0.38 });
    }
    slide6.addText(
      [
        { text: this.isCRRDeckData.maximumDropdown ? this.isCRRDeckData.maximumDropdown : "--", options: { breakLine: false } },
        { text: "%", options: { breakLine: false } }
      ],
      {
        x: 2.9,
        y: 2.26,
        w: 2.5,
        h: 0.69,
        color: "FFFFFF",
        fontSize: 35,
        fontFace: "Gotham Thin"
      }
    );
    slide6.addText("Maximum drop in portfolio(from inception to present date)", { x: 3.09, y: 2.9, w: 2.77, h: 0.4, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT" }); 6

    slide6.addText("compared to", { x: 3.1, y: 3.76, w: 2.77, h: 0.32, color: "FFFFFF", fontSize: 13, fontFace: "GOTHAM-LIGHT" });

    if (parseFloat(this.isCRRDeckData.msciWorldIndex) >= 0) {
      slide6.addImage({ path: "./assets/img/uparrow1.png", x: 2.11, y: 4.7, h: 0.71, w: 0.38 });
    } else {
      slide6.addImage({ path: "./assets/img/downArrow.png", x: 2.11, y: 4.7, h: 0.71, w: 0.38 });
    }
    slide6.addText(
      [
        { text: this.isCRRDeckData.msciWorldIndex ? this.isCRRDeckData.msciWorldIndex : "--", options: { breakLine: false } },
        { text: "%", options: { breakLine: false } }
      ],
      {
        x: 2.9,
        y: 4.52,
        w: 2.5,
        h: 0.69,
        color: "FFFFFF",
        fontSize: 35,
        fontFace: "Gotham Thin"
      }
    );
    slide6.addText("for the MSCI World Index in the same timeframe", { x: 3.09, y: 5.15, w: 2.77, h: 0.4, color: "FFFFFF", fontSize: 9, fontFace: "GOTHAM-LIGHT" });

    //pres.writeFile("Sample Presentation.pptx");
    pres.write('base64')
      .then(data => {
        var d = Date.now();
        var concatData = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + data;
        var fileData = this.app.convertToFile(concatData, this.getSelectedClientObj.clientid + "_" + d);
        this.isUploadFile.isCRRDeckFile = fileData;
        setTimeout(() => {
          this.generateImages();
        }, 500);
      })
      .catch(err => {
        // console.error(err);
      });
  }

  /* Generate DOM to Image */
  generateToDom(parentId, childId) {
    return new Promise(resolve => {
      domtoimage.toPng(document.getElementById(parentId)).then(function (dataUrl) {
        var img = new Image();
        img.id = childId;
        img.src = dataUrl;
        setTimeout(() => {
          document.getElementById('image-view').appendChild(img);
          resolve(img);
        }, 300);
      }).catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
    });

  }

  /* Generate Images */
  async generateImages() {
    document.getElementById('image-view').innerHTML = "";
    for (var i = 0; i < this.totalImages; i++) {
      this.app.isLoadingText = "Generating image " + (i + 1);
      var parentId = "imageGenerator" + i;
      var childId = "image" + i;
      const img = await this.generateToDom(parentId, childId);

      if (i == (this.totalImages - 1)) {
        setTimeout(() => {
          this.getFiles();
        }, 300);
      }
    }
  }

  /* Convert DATA URl to File */
  fileToSave: File = null;
  convertToFile(dataUrl, index) {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var d = Date.now();
    var fileName = this.getSelectedClientObj.clientid + "_file_" + index + "_" + d + ".png";
    return new File([u8arr], fileName, { type: mime });
  }

  totalImages: any = 6;
  isBatchCount: any = Math.round(this.totalImages / 2);
  isBeginCount: any = 0;

  /* Upload File Into SP */
  async getFiles() {
    var formData = new FormData();
    var fileToSave;
    if (this.isBeginCount === 0) {
      formData.append('File', this.isUploadFile.isCRRDeckFile);
    }

    for (var i = this.isBeginCount; i < this.isBatchCount; i++) {
      var childId = "image" + i;
      var isImage = document.getElementById(childId) as HTMLInputElement;
      fileToSave = await this.convertToFile(isImage.src, i);
      formData.append('File', fileToSave, fileToSave.name);
      if (i == (this.isBatchCount - 1)) {
        setTimeout(() => {
          this.uploadFiles(formData);
        }, 300);
      }
    }
  }

  synthesiaVideoID: string = "";

  /* Upload Files To SP */
  async uploadFiles(formData) {
    this.app.getLoader(true);
    var sendData = {
      fileType: 'files',
      data: [{
        createdAt: new Date(),
        clientId: this.getSelectedClientObj.clientid,
        clientMasterId: this.getSelectedClientObj.clientMasterId,
        batch: this.isBeginCount > 0 ? 2 : 1,
        crrDeckLogMasterId:this.getSelectedClientObj.clientLogMasterId,
      }]
    }
    formData.append('data', JSON.stringify(sendData));
    this.app.isLoadingText = "Uploading to server as a batch";
    var headerObj = await this.app.getHTTPHeaders();
    this.http.postFile(formData, headerObj).subscribe(response => {
      if (response.success) {

        if (this.isBeginCount == 0) {
          this.isBeginCount = 3;
          this.isBatchCount = this.totalImages;
          setTimeout(() => {
            this.getFiles();
          }, 100);

        } else {
          this.toastr.success("File uploaded successfully");
          this.isBeginCount = 0;
          this.isBatchCount = Math.round(this.totalImages / 2);
          this.isStatusText = true;
          this.synthesiaVideoID = response.synthesia_video_id;
          setTimeout(() => {
            this.isStatusText = false;
          }, 10000);

        }
      }
      this.app.getLoader(false);
    }, error => {
      this.toastr.success("Something went wrong");
      this.app.getLoader(false);
    });
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.toastr.clear();
  }
}
