import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService {
  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    var isLoggedIn = false;
    var userObj = JSON.parse(localStorage.getItem("userInfo"));
    if (userObj) {
      isLoggedIn = userObj.role == "INV" ? true : false;
    } else {
      isLoggedIn = false;
    }
    if (isLoggedIn) {
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
