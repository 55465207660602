import { Component, OnInit, HostListener, TemplateRef } from "@angular/core";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { ToastrService } from "ngx-toastr";
import * as credentialData from "credential.json";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
@Component({
  selector: "app-crr-rpa-progress",
  templateUrl: "./crr-rpa-progress.component.html",
  styleUrls: ["./crr-rpa-progress.component.scss"],
})
export class CrrRpaProgressComponent implements OnInit {
  credDataObj: any = (credentialData as any).default;
  getSelectedClientObj: any;
  rpaStepsStatusStr: string;
  stepperLoaderIs: boolean = false;
  getLoginUserData: any = {};
  //deckPreviewUrl: string = "";
  getStatusResponceObj: any = {};
  modalRef: BsModalRef;
  multiFileUploadedIs: boolean = false;
  constructor(
    public app: AppComponent,
    private http: CommonservicesService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    /* window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      event.returnValue = "RPA Process is Running...";
      return event;
    }); */
  }
  rpaProcessCompletedIs: boolean = false;
  @HostListener("window:beforeunload", ["$event"]) doSomething($event) {
    $event.preventDefault();
    if (!this.rpaProcessCompletedIs)
      $event.returnValue = confirm(
        "RPA Process is Running... Are you sure you want to leave this page?"
      );
    return $event;
  }
  ngOnInit() {
    this.app.setAppTitle("RPA Progress Status View");
    this.getSelectedClientObj = JSON.parse(
      localStorage.getItem("selectedClientObj")
    );
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
    setTimeout(() => {
      this.app.getLoader(true);
      this.getRpaStatusWithSync();
    }, 100);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "modal-lg" })
    );
  }

  async startRpaProcess() {
    this.app.getLoader(true);
    var sendObj = {
      logMasterId: this.getSelectedClientObj.clientLogMasterId,
      //"status": this.getSelectedClientObj.clientLogMasterId
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getupdateStep1Status(sendObj, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.rpaStepsStatusStr = "";
          this.toastr.success("RPA Started");
          console.log("response", response);
        } else {
          this.toastr.info("Issue Found");
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async getRpaStatusWithSync() {
    //this.stepperLoaderIs = true;
    var sendObj = {
      logMasterId: this.getSelectedClientObj.clientLogMasterId,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.fetchRpaStatus(sendObj, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          var resStatusData = response.data;
          this.getStatusResponceObj = response;
          for (let p = 0; p < resStatusData.length; p++) {
            var stsObj = resStatusData[p];
            if (stsObj.status.toLowerCase() == "done") {
              this.rpaStepsStatusStr = stsObj.steps;
            }
          }
          var lastStatus = JSON.parse(
            sessionStorage.getItem("lastStatusFrmBot")
          );
          if (lastStatus) {
            if (this.rpaStepsStatusStr != "File Ready") {
              if (
                lastStatus.indexOf("Data Scraped") !== -1 ||
                lastStatus.indexOf("Scraped Files Uploaded") !== -1
              ) {
                this.rpaStepsStatusStr = "DataScraped";
              }
            }
            if (lastStatus.indexOf("Start merging both the decks") !== -1) {
              this.rpaStepsStatusStr = "StartMerging";
            }

            var lastStatusMatch = "Final PPTX saved on bulk output folder";
            var lastStatusMatchbatch = "Final PPTX saved on output folder";

            if (
              lastStatus.indexOf(lastStatusMatch) !== -1 ||
              lastStatus.indexOf(lastStatusMatchbatch) !== -1
            ) {
              this.rpaStepsStatusStr = "FinalPPTXSaved";
            }
          }
          if (this.rpaStepsStatusStr != "File Ready") {
            this.startTimerAutoReload();
          }
        } else {
          this.toastr.info("Issue Found");
        }
        //this.stepperLoaderIs = false;
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  closeWindowFun() {
    this.toastr.info("Window popup closing");
    setTimeout(() => {
      window.close();
    }, 3000);
  }

  sessionTimerSec: number = 10;
  myInterval: any;
  countingForReload: number = 0;
  autoReloadIs: boolean = true;

  timerForAutoReload() {
    this.countingForReload++;
    if (this.countingForReload == this.sessionTimerSec) {
      this.stopTimerAutoReload();
      this.getRpaStatusWithSync();
    }
  }

  startTimerAutoReload() {
    this.myInterval = setInterval(() => {
      this.timerForAutoReload();
    }, 1000);
  }

  stopTimerAutoReload() {
    clearInterval(this.myInterval);
    this.countingForReload = 0;
  }

  crrDeckDownloadedIs: boolean = false;
  invDeckDownloadedIs: boolean = false;
  JsonDownloadedIs: boolean = false;
  invDeckDownloadedJSON: boolean = false;
  downloadFile(filePath, params) {
    window.open(filePath);
    if (params == "inv") {
      this.rpaProcessCompletedIs = true;
      this.invDeckDownloadedIs = true;
      // setTimeout(() => {
      //   this.closeWindowFun();
      // }, 15000);
    } else {
      this.crrDeckDownloadedIs = true;
    }
  }

  customFormURLCopy(inputElement) {
    const selBox = document.createElement("textarea");
    selBox.value = inputElement;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.toastr.success("URL Copied Successfully");
  }

  fileOnOverIs: boolean = false;
  //storingFileList = [];
  selectedFilesArrayList: any = [];
  storeSlctdTxtNdImgFiles: any;
  getSelectedFiles(event) {
    var selectedFileList = event;
    for (let x = 0; x < selectedFileList.length; x++) {
      const fileObj = selectedFileList[x];
      var tempObj: any = {};
      tempObj.fileUrl = fileObj.name;
      tempObj.fileObj = fileObj;
      this.selectedFilesArrayList.push(tempObj);
    }
    this.storeSlctdTxtNdImgFiles = "";
    //this.selectedFilesArrayList = this.storingFileList;
  }

  async fileOnDropped(event) {
    event.preventDefault();
    event.stopPropagation();
    let dataTransfer = event.dataTransfer.files;
    this.getSelectedFiles(dataTransfer);
    this.fileOnOverIs = false;
  }

  fileOnDragover(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOnOverIs = true;
  }

  fileOnDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOnOverIs = false;
  }

  fileClear() {
    this.selectedFilesArrayList = [];
  }

  async multiFileUploadFunc() {
    if (this.selectedFilesArrayList.length > 0) {
      this.app.getLoader(true);
      const formData = new FormData();
      this.selectedFilesArrayList.forEach((singleFileObj) => {
        var reg = "(.*?).(jpg|png|jpeg)$";
        console.log("singleFileObj", singleFileObj);
        if (singleFileObj.fileUrl.match(reg)) {
          formData.append("image", singleFileObj.fileObj);
        } else {
          formData.append("jsonFile", singleFileObj.fileObj);
        }
      });
      formData.append("id", this.getSelectedClientObj.clientLogMasterId);
      var headerObj = await this.app.getHTTPHeaders();
      this.http.getReports(formData, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            this.toastr.success(response.msg);
            this.multiFileUploadedIs = true;
            this.fileClear();
          } else {
            this.toastr.error("Issue Found");
          }
          this.app.getLoader(false);
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    } else {
      this.toastr.warning("Select a file");
    }
  }

  removePartFile(index) {
    this.selectedFilesArrayList.splice(index, 1);
  }

  robotInputMsgTxt: any = "";
  robotProcessLogList: any = [];
  updateRobotStatus() {

    if (this.robotInputMsgTxt) {
      sessionStorage.setItem(
        "lastStatusFrmBot",
        JSON.stringify(this.robotInputMsgTxt)
      );
      if (
        this.robotInputMsgTxt.indexOf("Data Scraped") !== -1 ||
        this.robotInputMsgTxt.indexOf("Scraped Files Uploaded") !== -1
      ) {
        this.rpaStepsStatusStr = "DataScraped";
      }
      if (
        this.robotInputMsgTxt.indexOf("Start merging both the decks") !== -1
      ) {
        this.rpaStepsStatusStr = "StartMerging";
      }
      var lastStatusMatch1 = "Final PPTX saved on bulk output folder";
      var lastStatusMatchbatch1 = "Final PPTX saved on output folder";

      if (
        this.robotInputMsgTxt.indexOf(lastStatusMatch1) !== -1 ||
        this.robotInputMsgTxt.indexOf(lastStatusMatchbatch1) !== -1
      ) {
        this.rpaStepsStatusStr = "FinalPPTXSaved";
      }
      // if (
      //   this.robotInputMsgTxt == "Final PPTX saved on bulk output folder" ||
      //   this.robotInputMsgTxt == "Final PPTX saved on output folder"
      // ) {
      //   this.rpaStepsStatusStr = "FinalPPTXSaved";
      // }
      this.robotProcessLogList.push(this.robotInputMsgTxt);
      this.robotInputMsgTxt = "";
    }
  }

  
  
  async downloadJSON() {
    this.app.getLoader(true);
    var sendObj = {
      logMasterId: this.getSelectedClientObj.clientLogMasterId,
      clientId: this.getSelectedClientObj.clientid,
      clientMasterId: this.getSelectedClientObj.clientMasterId
    };

    var headerObj = await this.app.getHTTPHeaders();
    this.http.downloadJsonfile(headerObj,sendObj).subscribe(
      (response: any) => {
        if(response.success==true) {
          this.JsonDownloadedIs = true;
          console.log(response.success, "response")
          var dataStr =
            'data:text/json;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(response.data));
            var fileName = "Client_"+response.data.slide1_data.client_id + ".json";
            console.log(fileName, "fileName")
          var dlAnchorElem = document.createElement('a');
          dlAnchorElem.setAttribute('href', dataStr);
          dlAnchorElem.setAttribute('download', fileName);
          dlAnchorElem.click();
          dlAnchorElem.remove();
        } else {
          this.toastr.info("Issue Found");
        }
        this.app.getLoader(false);
       
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );  
  }


  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.toastr.clear();
  }
}
