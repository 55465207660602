import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { QuestionnaireBasicComponent } from "./questionnaire-basic/questionnaire-basic.component";
import { DeckPreviewComponent } from "./deck-preview/deck-preview.component";
import { InvestmentSummaryComponent } from "./investment-summary/investment-summary.component";
import { InvestmentDashboardComponent } from "./investment-dashboard/investment-dashboard.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthGuardService } from "./guards/auth-guard.service";
import { RmAuthGuardService } from "./guards/rm-auth-guard.service";
import { CsAuthGuardService } from "./guards/cs-auth-guard.service";
import { RmInvAuthGuardService } from "./guards/rm-inv-auth-guard.service";
import { SimulatorComponent } from "./simulator/simulator.component";
import { MsalGuard } from "@azure/msal-angular";
import { QuestionnairePrintComponent } from "./questionnaire-print/questionnaire-print.component";
import { JsonEditorComp } from "./json-editor/json-editor.component";
import { CurrentFuturePortfolioComponent } from "./current-future-portfolio/current-future-portfolio.component";
import { InputFutureProposalComponent } from "./input-future-proposal/input-future-proposal.component";
import { DetailedProposedAAComponent } from "./detailed-proposed-aa/detailed-proposed-aa.component";
import { EmaTriggerComponent } from "./ema-trigger/ema-trigger.component";
import { ExcelUploaderComponent } from "./excel-uploader/excel-uploader.component";
import { CrrConfigurationComponent } from "./crr-configuration/crr-configuration.component";
import { CrrDashboardComponent } from "./crr-dashboard/crr-dashboard.component";
import { CrrRpaProgressComponent } from "./crr-rpa-progress/crr-rpa-progress.component";
import { InputSolutionComponent } from "./input-solution/input-solution.component";
import { OnboardingQuestionnaireFormComponent } from "./onboarding-questionnaire-form/onboarding-questionnaire-form.component";
import { VideoGenPptComponent } from "./video-gen-ppt/video-gen-ppt.component";
import { CrrImageComponent } from './crr-image/crr-image.component';

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "excelUploader", component: ExcelUploaderComponent },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [MsalGuard],
    children: [
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [RmAuthGuardService],
      },
      {
        path: "questionnaire",
        component: QuestionnaireBasicComponent,
        canActivate: [RmAuthGuardService],
      },
      {
        path: "onboardingQuestionnaire",
        component: OnboardingQuestionnaireFormComponent,
        canActivate: [RmAuthGuardService],
      },
      {
        path: "investmentSummary",
        component: InvestmentSummaryComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "deckPreview",
        component: DeckPreviewComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "investmentDashboard",
        component: InvestmentDashboardComponent,
        canActivate: [AuthGuardService],
      },
      { path: "emaView", component: EmaTriggerComponent },
      {
        path: "assumptionMasterEdit",
        component: JsonEditorComp,
        canActivate: [AuthGuardService],
      },
      {
        path: "questionnairePrint",
        component: QuestionnairePrintComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "simulator",
        component: SimulatorComponent,
        canActivate: [AuthGuardService],
      },

      {
        path: "currentFuturePortfolio",
        component: CurrentFuturePortfolioComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "inputFutureProposal",
        component: InputFutureProposalComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "detailedProposedAA",
        component: DetailedProposedAAComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "inputSolution",
        component: InputSolutionComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "currentFuturePortfolio",
        component: CurrentFuturePortfolioComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "inputFutureProposal",
        component: InputFutureProposalComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "detailedProposedAA",
        component: DetailedProposedAAComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "inputSolution",
        component: InputSolutionComponent,
        canActivate: [RmInvAuthGuardService],
      },
      {
        path: "crrDashboard",
        component: CrrDashboardComponent,
        canActivate: [CsAuthGuardService],
      },
      {
        path: "crrConfig",
        component: CrrConfigurationComponent,
        canActivate: [CsAuthGuardService],
      },
      {
        path: "crrImage",
        component: CrrImageComponent,
        canActivate: [CsAuthGuardService],
      },
      {
        path: "rpaProgress",
        component: CrrRpaProgressComponent,
        canActivate: [CsAuthGuardService],
      },
      {
        path: "pptgen",
        component: VideoGenPptComponent,
        canActivate: [CsAuthGuardService],
      },
    ],
  },

  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
