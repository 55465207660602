import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  BsModalService,
  BsModalRef,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { CanActivate, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-input-future-proposal",
  templateUrl: "./input-future-proposal.component.html",
  styleUrls: ["./input-future-proposal.component.scss"],
})
export class InputFutureProposalComponent implements OnInit {
  @ViewChild("cashflowBondvestFluctuationsModalBox", { static: false })
  cashflowBondvestFluctuationsMblBxRef: ModalDirective;
  @ViewChild("optionOneNdTwoDefinitionModalBox", { static: false })
  optionOneNdTwoDefinitionMdlBxRef: ModalDirective;
  userInfo: any = {};
  selectedClientDtls: any = {};
  inputPropScrnDataObj: any;
  inputPropMasterJson: any;
  inputProposedCommDataArr: any = [];
  cashflowBvFluctTotalObj: any;
  currentDateForView: any;
  inputProposedCommitmTotalObj: any = {
    possibleAmountTtl: 0,
    inputAmtTtl: 0,
    previousValueTtl: 0,
    ttlInPerc: 0,
    immediateBsUfTtl: 0,
  };
  updateInputArray: boolean = false;
  generateAaFormObj: any = {
    additionalFund: "",
    additiFundSbmtBtnShowIs: false,

    wealthAllocatorAmt: "",
    wealthAllocatorSbmtBtnShowIs: false,
    overrBuffAmt: "",
    overrBuffAmtSbmtBtnShowIs: false,
    inputYear1: "",
    inputYear2: "",
    inputYear1Nd2SbmtBtnShowIs: false,
    inputYear3: "",
    inputYear4: "",
    inputYear5: "",
    inputYear6: "",
  };
  isINVEditTrue: boolean = false;
  cashflowBvFluctuationAllObj: any = { dataList: [], totalList: {} };
  previousReInvestingOnOfIs: boolean = false;
  getReInvestingOnOfIs: boolean = false;
  syncOption1: boolean = false;

  constructor(
    public app: AppComponent,
    private modalService: BsModalService,
    private http: CommonservicesService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.currentDateForView = new Date();
  }
  selCashflowBVFluctuationsType: string = "ideal";
  aaEditIs: boolean = false;
  showOption2: boolean = true;
  ngOnInit() {
    this.app.setAppTitle("Input Future Proposal");
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.selectedClientDtls = JSON.parse(
      sessionStorage.getItem("selctedPersonaObj")
    );
    
    setTimeout(
      function () {
        if (this.userInfo.adminLoggedInIs && this.userInfo.role == "RM") {
          this.showOption2 = false;
        } else {
          this.showOption2 = true;
        }
        if (this.userInfo.role == "RM") {
          if (this.selectedClientDtls.rm_status) {
            if (
              this.selectedClientDtls.rm_status.toLowerCase() == "rejected" ||
              this.selectedClientDtls.rm_status.toLowerCase() ==
                "allocation created"
            ) {
              this.fetchInputSummINVData("edit");
              this.aaEditIs = true;
            } else {
              this.fetchInputSummDataFotRm(null);
            }
          } else {
            this.fetchInputSummDataFotRm(null);
          }
        } else if (this.userInfo.role == "INV") {
          this.fetchInputSummINVData("edit");
          this.aaEditIs = true;
        } else {
          this.fetchInputSummINVData(null);
        }
      }.bind(this),
      100
    );

// Show Option Definition model on page load 
if(this.userInfo.role == "RM")
{
    setTimeout(
      function () {
        var getMainJson = JSON.parse(sessionStorage.getItem("inputScreenMainObj"));
          if(this.app.loading==false && this.inputProposedCommDataArr){
            if(getMainJson.operation == 'option1' || getMainJson.operation == 'option2'){
              if (typeof getMainJson !== 'undefined' && getMainJson !== null){
              this.selectedOptionName = getMainJson.operation;
              this.optionOneNdTwoDefinitionMdlBxRef.show();
              }else {
                this.selectedOptionName = 'option1';
                this.optionOneNdTwoDefinitionMdlBxRef.show();
              }
            }
          }
      }.bind(this),
      5000
    );  
}  
    
  }

  inputPropScrnIpsDtObj: any;
  clientWtdNeedsObj: any = {
    year1: 0,
    year2: 0,
    year3: 0,
  };

  newLiquidityNeedList: any = {
    year1: 0,
    year2: 0,
  };
  selectedOptionName: string = "";
  getOptionsDefineView(getOption) {
    if (this.inputPropScrnDataObj.selectedOption != getOption) {
      this.selectedOptionName = getOption;
      this.optionOneNdTwoDefinitionMdlBxRef.show();
    }
  }

  async fetchInputSummDataFotRm(sendParam) {
    this.app.getLoader(true);
    var sendData = JSON.parse(sessionStorage.getItem("currAndFutDataObj"));
    var headerObj = await this.app.getHTTPHeaders();
    this.http.initializeProposedCommitments(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          sessionStorage.setItem(
            "inputScreenMainObj",
            JSON.stringify(response.data)
          );
          this.previousReInvestingOnOfIs =
            response.data.reinvesting == "yes" ? true : false;
          this.inptScreenDataModifyForView(response.data, sendParam);
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async fetchInputSummINVData(sendParam) {
    this.app.getLoader(true);
    var send_data = {
      instanceId: this.selectedClientDtls.instanceId,
      clientId: this.selectedClientDtls.client_id,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getInputSummary(send_data, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          sessionStorage.setItem(
            "inputScreenMainObj",
            JSON.stringify(response.data)
          );
          this.inptScreenDataModifyForView(response.data, sendParam);
          this.previousReInvestingOnOfIs =
            response.data.reinvesting == "yes" ? true : false;
        } else {
          this.toastr.info("Issue Found: " + response.message);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  inptScreenDataModifyForView(resDataObj, getParam) {
    this.inputPropScrnDataObj = resDataObj;
    var operationData = resDataObj.operation.split("_");
    this.inputPropScrnDataObj.selectedOption = operationData[0];
    this.inputPropMasterJson = resDataObj.masterJson;
    this.inputPropScrnIpsDtObj = resDataObj.ips;
    this.getReInvestingOnOfIs = resDataObj.reinvesting == "yes" ? true : false;
    this.selCashflowBVFluctuationsType = resDataObj.bvFluctuation;
    this.cashFlowViewChangeFun();
    var liqNedsObj =
      resDataObj.masterJson.idealCashflowBVFluctuations.incomeReq;
    this.newLiquidityNeedList.year1 =
      (liqNedsObj.q1y1 ? parseInt(liqNedsObj.q1y1) * -1 : 0) +
      (liqNedsObj.q2y1 ? parseInt(liqNedsObj.q2y1) * -1 : 0) +
      (liqNedsObj.q3y1 ? parseInt(liqNedsObj.q3y1) * -1 : 0) +
      (liqNedsObj.q4y1 ? parseInt(liqNedsObj.q4y1) * -1 : 0);
    this.newLiquidityNeedList.year2 =
      (liqNedsObj.q1y2 ? parseInt(liqNedsObj.q1y2) * -1 : 0) +
      (liqNedsObj.q2y2 ? parseInt(liqNedsObj.q2y2) * -1 : 0) +
      (liqNedsObj.q3y2 ? parseInt(liqNedsObj.q3y2) * -1 : 0) +
      (liqNedsObj.q4y2 ? parseInt(liqNedsObj.q4y2) * -1 : 0);
    if (this.userInfo.role == "RM") {
      this.newLiquidityNeedList.year1 =
        (liqNedsObj.q1y1 ? parseInt(liqNedsObj.q1y1) : 0) +
        (liqNedsObj.q2y1 ? parseInt(liqNedsObj.q2y1) : 0) +
        (liqNedsObj.q3y1 ? parseInt(liqNedsObj.q3y1) : 0) +
        (liqNedsObj.q4y1 ? parseInt(liqNedsObj.q4y1) : 0);
      this.newLiquidityNeedList.year2 =
        (liqNedsObj.q1y2 ? parseInt(liqNedsObj.q1y2) : 0) +
        (liqNedsObj.q2y2 ? parseInt(liqNedsObj.q2y2) : 0) +
        (liqNedsObj.q3y2 ? parseInt(liqNedsObj.q3y2) : 0) +
        (liqNedsObj.q4y2 ? parseInt(liqNedsObj.q4y2) : 0);
      if (getParam == "edit") {
        var liquidityear1 = this.newLiquidityNeedList.year1 * -1;
        var liquidityear2 = this.newLiquidityNeedList.year2 * -1;
        this.generateAaFormObj.additionalFund = resDataObj.additionalFunding
          ? resDataObj.additionalFunding
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
        this.generateAaFormObj.wealthAllocatorAmt = resDataObj.wealthAllocatorUsed
          ? resDataObj.wealthAllocatorUsed
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
        this.generateAaFormObj.overrBuffAmt = resDataObj.overrideBufferAmount
          ? resDataObj.overrideBufferAmount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
        this.generateAaFormObj.inputYear1 = this.newLiquidityNeedList.year1
          ? liquidityear1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
        this.generateAaFormObj.inputYear2 = this.newLiquidityNeedList.year2
          ? liquidityear2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
      }
    }

    if (this.userInfo.role == "INV") {
      if (getParam == "edit") {
        var liquidityear1 = this.newLiquidityNeedList.year1 * 1;
        var liquidityear2 = this.newLiquidityNeedList.year2 * 1;
        this.generateAaFormObj.additionalFund = resDataObj.additionalFunding
          ? resDataObj.additionalFunding
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
        this.generateAaFormObj.wealthAllocatorAmt = resDataObj.wealthAllocatorUsed
          ? resDataObj.wealthAllocatorUsed
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
        this.generateAaFormObj.overrBuffAmt = resDataObj.overrideBufferAmount
          ? resDataObj.overrideBufferAmount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
        this.generateAaFormObj.inputYear1 = this.newLiquidityNeedList.year1
          ? liquidityear1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
        this.generateAaFormObj.inputYear2 = this.newLiquidityNeedList.year2
          ? liquidityear2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
      }
    }

    this.generateAaFormObj.additiFundSbmtBtnShowIs = false;
    this.generateAaFormObj.wealthAllocatorSbmtBtnShowIs = false;
    this.generateAaFormObj.overrBuffAmtSbmtBtnShowIs = false;
    this.generateAaFormObj.inputYear1Nd2SbmtBtnShowIs = false;

    var cltWtdNedsObj = resDataObj.masterJson.previousIncomeNeeds;
    this.clientWtdNeedsObj.year1 =
      (cltWtdNedsObj.q1y1 ? parseInt(cltWtdNedsObj.q1y1) : 0) +
      (cltWtdNedsObj.q2y1 ? parseInt(cltWtdNedsObj.q2y1) : 0) +
      (cltWtdNedsObj.q3y1 ? parseInt(cltWtdNedsObj.q3y1) : 0) +
      (cltWtdNedsObj.q4y1 ? parseInt(cltWtdNedsObj.q4y1) : 0);
    this.clientWtdNeedsObj.year2 =
      (cltWtdNedsObj.q1y2 ? parseInt(cltWtdNedsObj.q1y2) : 0) +
      (cltWtdNedsObj.q2y2 ? parseInt(cltWtdNedsObj.q2y2) : 0) +
      (cltWtdNedsObj.q3y2 ? parseInt(cltWtdNedsObj.q3y2) : 0) +
      (cltWtdNedsObj.q4y2 ? parseInt(cltWtdNedsObj.q4y2) : 0);
    this.clientWtdNeedsObj.year3 =
      (cltWtdNedsObj.q1y3 ? parseInt(cltWtdNedsObj.q1y3) : 0) +
      (cltWtdNedsObj.q2y3 ? parseInt(cltWtdNedsObj.q2y3) : 0) +
      (cltWtdNedsObj.q3y3 ? parseInt(cltWtdNedsObj.q3y3) : 0) +
      (cltWtdNedsObj.q4y3 ? parseInt(cltWtdNedsObj.q4y3) : 0);

    var inputPrpsdCommData = this.inputPropMasterJson.inputProposedCommitments;
    this.inputProposedCommitmTotalObj.goalSeekValueTtl = 0;
    this.inputProposedCommitmTotalObj.inputAmtTtl = 0;
    this.inputProposedCommitmTotalObj.previousValueTtl = 0;
    this.inputProposedCommitmTotalObj.ttlInPerc = 0;
    this.inputProposedCommitmTotalObj.immediateBsUfTtl = 0;

    for (let i = 0; i < inputPrpsdCommData.length; i++) {
      var inpProComDtObj = inputPrpsdCommData[i];
      this.inputProposedCommitmTotalObj.goalSeekValueTtl +=
        inpProComDtObj.finalEligibleAmt > 0
          ? inpProComDtObj.finalEligibleAmt
          : 0;
      this.inputProposedCommitmTotalObj.previousValueTtl +=
        inpProComDtObj.tempPreVal;
      this.inputProposedCommitmTotalObj.ttlInPerc += inpProComDtObj.perc;
      this.inputProposedCommitmTotalObj.immediateBsUfTtl += inpProComDtObj.bsuf;
      this.inputProposedCommitmTotalObj.inputAmtTtl += inpProComDtObj.totalAmt;
      inputPrpsdCommData[i].previousAmt = inpProComDtObj.tempPreVal;
      if (inpProComDtObj.totalAmt) {
        inputPrpsdCommData[i].totalAmtMld =
          inpProComDtObj.totalAmt > 999
            ? inpProComDtObj.totalAmt
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : inpProComDtObj.totalAmt;
      } else {
        inputPrpsdCommData[i].totalAmtMld = "";
      }
      inputPrpsdCommData[i].goalSeekInProgressIs = false;
      inputPrpsdCommData[i].calculateBtnShowIs = false;
    }
    this.inputProposedCommDataArr = inputPrpsdCommData;
    this.app.getLoader(false);
  }

  cashFlowViewChangeFun() {
    var cashFlwTypeKeyName = "idealCashflowBVFluctuations";
    if (this.selCashflowBVFluctuationsType == "crisis") {
      cashFlwTypeKeyName = "crisisCashflowBVFluctuations";
    } else if (this.selCashflowBVFluctuationsType == "normal") {
      cashFlwTypeKeyName = "normalCashflowBVFluctuations";
    }
    this.cashflowBvFluctTotalObj = this.inputPropMasterJson[
      cashFlwTypeKeyName
    ].endingBVBalance;
    var cshflwBvFlucAllObj = this.inputPropMasterJson[cashFlwTypeKeyName];
    cshflwBvFlucAllObj.startingBVBalance.program = "Starting PFV Balance";
    cshflwBvFlucAllObj.capitalCall.program = "Capital Call";
    cshflwBvFlucAllObj.capitalDistribution.program = "Capital Distribution";
    cshflwBvFlucAllObj.netCapitalDistributions.program =
      "Net Capital Distributions";
    cshflwBvFlucAllObj.incomeReq.program = "Income Requirement";
    cshflwBvFlucAllObj.endingBVBalance.program = "Ending PFV Balance";
    var cshFlwFlucTtlArr = [];
    cshFlwFlucTtlArr.push(cshflwBvFlucAllObj.startingBVBalance);
    cshFlwFlucTtlArr.push(cshflwBvFlucAllObj.capitalCall);
    cshFlwFlucTtlArr.push(cshflwBvFlucAllObj.capitalDistribution);
    cshFlwFlucTtlArr.push(cshflwBvFlucAllObj.netCapitalDistributions);
    cshFlwFlucTtlArr.push(cshflwBvFlucAllObj.incomeReq);
    cshFlwFlucTtlArr.push(cshflwBvFlucAllObj.endingBVBalance);
    this.cashflowBvFluctuationAllObj.dataList = cshFlwFlucTtlArr;
    this.cashflowBvFluctuationAllObj.totalList =
      cshflwBvFlucAllObj.totalIncomeDistributions;
    //this.generateMinPfvBalance();
  }

  async generateMinPfvBalance(selectedData, updateArray) {
    if (this.generateAaFormObj.wealthAllocatorAmt) {
      var get_wlt_all_amt = parseInt(
        this.generateAaFormObj.wealthAllocatorAmt.replace(/,/g, "")
      );
      if (
        this.inputPropScrnDataObj.previousWealthAllocator.toFixed(0) <
        get_wlt_all_amt
      ) {
        this.toastr.warning(
          "You can't put greater then Previous Wealth Allocator"
        );
        return;
      }
    }

    this.app.getLoader(true);
    var getMainJson = JSON.parse(sessionStorage.getItem("inputScreenMainObj"));
    console.log(getMainJson.masterJson, "masterJson");
    getMainJson.reinvesting = this.getReInvestingOnOfIs ? "yes" : "no";
    getMainJson.operation = selectedData;
    if (updateArray == "update") {
      this.updateInputArray = true;
    } else {
      this.updateInputArray = false;
    }
    getMainJson.updateInputArray = this.updateInputArray;
    getMainJson.syncOption1 = this.syncOption1;

    getMainJson.role = this.userInfo.role.toLowerCase();
    getMainJson.bvFluctuation = this.selCashflowBVFluctuationsType;
    getMainJson.additionalFunding = this.generateAaFormObj.additionalFund
      ? parseInt(this.generateAaFormObj.additionalFund.replace(/,/g, ""))
      : 0;
    getMainJson.wealthAllocatorUsed = this.generateAaFormObj.wealthAllocatorAmt
      ? parseInt(this.generateAaFormObj.wealthAllocatorAmt.replace(/,/g, ""))
      : getMainJson.wealthAllocatorUsed;
    getMainJson.overrideBufferAmount = this.generateAaFormObj.overrBuffAmt
      ? parseInt(this.generateAaFormObj.overrBuffAmt.replace(/,/g, ""))
      : 0;
    var inputYearOne = this.generateAaFormObj.inputYear1
      ? parseInt(this.generateAaFormObj.inputYear1.replace(/,/g, ""))
      : 0;
    var inputYearTwo = this.generateAaFormObj.inputYear2
      ? parseInt(this.generateAaFormObj.inputYear2.replace(/,/g, ""))
      : 0;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q1y1 =
      (inputYearOne / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q2y1 =
      (inputYearOne / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q3y1 =
      (inputYearOne / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q4y1 =
      (inputYearOne / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q1y2 =
      (inputYearTwo / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q2y2 =
      (inputYearTwo / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q3y2 =
      (inputYearTwo / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q4y2 =
      (inputYearTwo / 4) * -1;
    var send_data = getMainJson;
    var headerObj = await this.app.getHTTPHeaders();
    this.http.goalSeekCalculations(send_data, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          if (response.data.syncOption1) {
            response.data.syncOption1 = false;
            response.data.operation = "mychoice";
            sessionStorage.setItem(
              "inputScreenMainObj",
              JSON.stringify(response.data)
            );
          } else {
            sessionStorage.setItem(
              "inputScreenMainObj",
              JSON.stringify(response.data)
            );
          }
          this.inptScreenDataModifyForView(response.data, null);
          this.optionOneNdTwoDefinitionMdlBxRef.hide();
        } else {
          this.toastr.info("Issue Found: " + response.message);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async get_selected_opt(selectedData) {
    if (this.inputPropScrnDataObj.selectedOption != selectedData) {
      this.app.getLoader(true);
      var getMainJson = JSON.parse(
        sessionStorage.getItem("inputScreenMainObj")
      );
      getMainJson.operation = selectedData;
      var send_data = getMainJson;
      var headerObj = await this.app.getHTTPHeaders();
      this.http.initializeProposedCommitments(send_data, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            sessionStorage.setItem(
              "inputScreenMainObj",
              JSON.stringify(response.data)
            );
            this.inptScreenDataModifyForView(response.data, null);
            this.inputPropScrnDataObj.selectedOption = selectedData;
            this.optionOneNdTwoDefinitionMdlBxRef.hide();
          } else {
            this.toastr.info("Issue Found: " + response.message);
            this.app.getLoader(false);
          }
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    }
  }

  async submitDataForGenAA() {
    if (
      this.userInfo.role == "RM" ||
      (this.inputPropScrnDataObj.selectedOption == "mychoice" &&
        this.isINVEditTrue)
    ) {
      this.app.getLoader(true);
      var getMainJson = JSON.parse(
        sessionStorage.getItem("inputScreenMainObj")
      );
      this.sendDataForGoalSeekAndGenAA(getMainJson, "genAA");
    } else {
      this.app.navigateThis("/home/detailedProposedAA");
    }
  }

  checkValueForSbmtBtn(preAmount, currAmount, fieldName) {
    var curr_amount = currAmount ? parseInt(currAmount.replace(/,/g, "")) : 0;
    if (fieldName != "inputYear1Nd2SbmtBtnShowIs") {
      if ((preAmount || curr_amount) && preAmount != curr_amount) {
        this.generateAaFormObj[fieldName] = true;
      } else {
        this.generateAaFormObj[fieldName] = false;
      }
    } else {
      var pre_amount_of_yr1 = this.newLiquidityNeedList.year1
        ? this.newLiquidityNeedList.year1 * -1
        : 0;
      var pre_amount_of_yr2 = this.newLiquidityNeedList.year2
        ? this.newLiquidityNeedList.year2 * -1
        : 0;
      var curr_amount_of_yr1 = this.generateAaFormObj.inputYear1
        ? parseInt(this.generateAaFormObj.inputYear1.replace(/,/g, ""))
        : 0;
      var curr_amount_of_yr2 = this.generateAaFormObj.inputYear2
        ? parseInt(this.generateAaFormObj.inputYear2.replace(/,/g, ""))
        : 0;
      if (
        (pre_amount_of_yr1 ||
          curr_amount_of_yr1 ||
          pre_amount_of_yr2 ||
          curr_amount_of_yr2) &&
        (pre_amount_of_yr1 != curr_amount_of_yr1 ||
          pre_amount_of_yr2 != curr_amount_of_yr2)
      ) {
        this.generateAaFormObj[fieldName] = true;
      } else {
        this.generateAaFormObj[fieldName] = false;
      }
    }
  }

  checkMaxValOfWealthAll() {
    var get_wlt_all_amt = this.generateAaFormObj.wealthAllocatorAmt
      ? parseInt(this.generateAaFormObj.wealthAllocatorAmt.replace(/,/g, ""))
      : 0;
    if (
      this.inputPropScrnDataObj.previousWealthAllocator.toFixed(0) <
      get_wlt_all_amt
    ) {
      this.toastr.warning(
        "You can't put greater then Previous Wealth Allocator"
      );
    } else {
      this.toastr.clear();
    }
  }

  checkTotalAmtForGoalSeek(getObj) {
    var get_total_amt = getObj.totalAmtMld
      ? parseInt(getObj.totalAmtMld.replace(/,/g, ""))
      : 0;
    if (parseInt(getObj.finalEligibleAmt.toFixed(0)) < get_total_amt) {
      this.toastr.warning(
        "You cannot put greater than Possible Commitment Amount "
      );
      getObj.calculateBtnShowIs = false;
      return;
    } else {
      this.toastr.clear();
      if (
        (getObj.totalAmt || get_total_amt) &&
        getObj.totalAmt != get_total_amt
      ) {
        getObj.calculateBtnShowIs = true;
      } else {
        getObj.calculateBtnShowIs = false;
      }
    }
  }

  async runGoalSeek(getObj, getInd) {
    var get_total_amt = getObj.totalAmtMld
      ? parseInt(getObj.totalAmtMld.replace(/,/g, ""))
      : 0;
    if (get_total_amt || getObj.totalAmt) {
      if (parseInt(getObj.finalEligibleAmt.toFixed(0)) < get_total_amt) {
        this.toastr.warning(
          "You cannot put greater than Possible Commitment Amount "
        );
      } else {
        this.app.getGoalseekLoader(true);
        var getMainJson = JSON.parse(
          sessionStorage.getItem("inputScreenMainObj")
        );
        for (let i = 0; i < this.inputProposedCommDataArr.length; i++) {
          this.inputProposedCommDataArr[i].goalSeekInProgressIs = true;
        }
        getObj.goalSeekInProgressIs = false;
        getMainJson.masterJson.inputProposedCommitments[
          getInd
        ].isUpdated = true;
        getMainJson.masterJson.inputProposedCommitments[
          getInd
        ].totalAmt = get_total_amt;
        getMainJson.masterJson.inputProposedCommitments[getInd].tempPreVal =
          getObj.totalAmt;
        if (get_total_amt == 0) {
          getMainJson.masterJson.inputProposedCommitments[getInd].bsuf = 0;
          getMainJson.masterJson.inputProposedCommitments[getInd].perc = 0;
          getMainJson.masterJson.inputProposedCommitments[
            getInd
          ].amtDeployed = 0;
          getMainJson.masterJson.inputProposedCommitments[
            getInd
          ].isUpdated = false;
        }
        this.sendDataForGoalSeekAndGenAA(getMainJson, "goalSeek");
      }
    }
  }

  async sendDataForGoalSeekAndGenAA(getMainJson, getCondition) {
    getMainJson.additionalFunding = this.generateAaFormObj.additionalFund
      ? parseInt(this.generateAaFormObj.additionalFund.replace(/,/g, ""))
      : 0;
    var operationData = getMainJson.operation.split("_");
    getMainJson.operation = operationData[0];
    getMainJson.wealthAllocatorUsed = this.generateAaFormObj.wealthAllocatorAmt
      ? parseInt(this.generateAaFormObj.wealthAllocatorAmt.replace(/,/g, ""))
      : getMainJson.wealthAllocatorUsed;
    getMainJson.overrideBufferAmount = this.generateAaFormObj.overrBuffAmt
      ? parseInt(this.generateAaFormObj.overrBuffAmt.replace(/,/g, ""))
      : 0;
    var inputYearOne = this.generateAaFormObj.inputYear1
      ? parseInt(this.generateAaFormObj.inputYear1.replace(/,/g, ""))
      : 0;
    var inputYearTwo = this.generateAaFormObj.inputYear2
      ? parseInt(this.generateAaFormObj.inputYear2.replace(/,/g, ""))
      : 0;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q1y1 =
      (inputYearOne / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q2y1 =
      (inputYearOne / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q3y1 =
      (inputYearOne / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q4y1 =
      (inputYearOne / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q1y2 =
      (inputYearTwo / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q2y2 =
      (inputYearTwo / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q3y2 =
      (inputYearTwo / 4) * -1;
    getMainJson.masterJson.idealCashflowBVFluctuations.incomeReq.q4y2 =
      (inputYearTwo / 4) * -1;
    getMainJson.syncOption1 = this.syncOption1;
    getMainJson.role = this.userInfo.role.toLowerCase();

    getMainJson.updateInputArray = false;
    var send_data = getMainJson;
    var headerObj = await this.app.getHTTPHeaders();
    if (getCondition == "goalSeek") {
      this.http.goalSeekCalculations(send_data, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            sessionStorage.setItem(
              "inputScreenMainObj",
              JSON.stringify(response.data)
            );
            this.inptScreenDataModifyForView(response.data, null);
            setTimeout(() => {
              for (let o = 0; o < this.inputProposedCommDataArr.length; o++) {
                this.inputProposedCommDataArr[o].goalSeekInProgressIs = false;
              }
            }, 1000);
            this.app.getGoalseekLoader(false);
          } else {
            this.toastr.info("Issue Found: " + response.message);
            this.app.getGoalseekLoader(false);
          }
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    } else {
      if (this.userInfo.role == "RM") {
        if (this.inputPropScrnDataObj.selectedOption != "mychoice") {
          send_data.edit = false;
        } else {
          send_data.edit = this.aaEditIs;
        }
      } else if (this.userInfo.role == "INV") {
        if (this.inputPropScrnDataObj.selectedOption == "mychoice") {
          send_data.edit = true;
        }
      } else {
        send_data.edit = this.aaEditIs;
      }

      send_data.instanceId = this.selectedClientDtls.instanceId;
      this.http
        .createQuarterlyAnnualCashflowAndSave(send_data, headerObj)
        .subscribe(
          (response: any) => {
            if (response.success) {
              //sessionStorage.setItem("proposedAAAndCashflow", JSON.stringify(response.data));
              this.selectedClientDtls.rm_status = response.data.rmStatus;
              this.selectedClientDtls.instanceId = response.data.instanceId;
              sessionStorage.setItem(
                "selctedPersonaObj",
                JSON.stringify(this.selectedClientDtls)
              );

              this.toastr.success("Proposed AA and cashflow save successfully");
              if (
                this.inputPropScrnDataObj.selectedOption == "mychoice" &&
                this.userInfo.role == "RM"
              ) {
                this.syncOption1 = true;
                sessionStorage.setItem(
                  "inputScreenMainObj",
                  JSON.stringify(response.data)
                );
                this.generateMinPfvBalance("option1", "");
              } else {
                this.syncOption1 = false;
              }
              setTimeout(() => {
                this.app.navigateThis("/home/detailedProposedAA");
              }, 2000);
            } else {
              this.toastr.info("Issue Found: " + response.message);
            }
            this.app.getGoalseekLoader(false);
          },
          (error) => {
            this.app.errorHandler(error);
          }
        );
    }
  }

  ngOnDestroy() {
    this.toastr.clear();
    if (this.cashflowBondvestFluctuationsMblBxRef) {
      this.cashflowBondvestFluctuationsMblBxRef.hide();
    }
  }

  showIVNEdited() {
    if (this.inputPropScrnDataObj.selectedOption != "mychoice") {
      this.generateMinPfvBalance("mychoice", "update");
    }
    this.isINVEditTrue = true;
    // this.inputPropScrnDataObj.selectedOption == "mychoice";
    // console.log(this.isINVEditTrue);
  }
}
