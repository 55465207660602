import {
  Component,
  OnInit,
  TemplateRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { CommonservicesService } from "../commonservices.service";
import { AppComponent } from "../app.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import "core-js";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: "app-summary-view",
  templateUrl: "./summary-view.component.html",
  styleUrls: ["./summary-view.component.scss"],
})
export class SummaryViewComponent implements OnInit {
  liquidOption: any = [];
  userInfo: any = {};
  opportunisticNameList: any = [];
  // tempOpportunisticListStrg: any = [];
  finalAllocationData: any = [];
  commentList: any = [];
  commentText: any = "";
  attitudanalFlags: boolean = false;
  commentBoxLoaderIs: boolean = false;
  rejectComment: any = "";
  WithdrawNeedsIsChecked: boolean = true;
  allocationModify: boolean = true;
  edit: boolean = false;
  rmJustification = "";
  isWarningPopup: boolean = false;
  opportunisticSelector: any = {
    funds: "",
    amt: "",
    pert: "",
  };
  netWorth: any = "";
  opportunisticFund: any = {
    totalAmt: 0,
    totalPert: 0,
  };
  liquidOptionWithdrawal: any = {
    year1: "",
    year2: "",
    year3: "",
  };
  portfolioObjectives = [
    {
      title: "Indefinite",
      desc: "Inter-generational wealth",
    },
    {
      title: "Long Term",
      desc: "Three to seven years",
    },
    {
      title: "Short Term",
      desc: "Less than three years",
    },
  ];
  geoAssetModel: any = {
    capitalGrowth: [true, false, false],
    capitalYielding: [false, true, true],
  };
  fundingSchedule: any = ["Immediately", "Year 1", "Year 2", "Above 2 Years"];
  invesmentSummary: any = {};
  questionsArray: any = {
    que1: "",
    que2: "",
    que3_root: "",
    que3: "",
    que3perc: 0,
    que4: "",
    que5: "",
    que6: {
      liquidity: "",
      year1: "",
      year2: "",
      year3: "",
      total_wealth: "",
    },
    que7: [],
    que8: {
      cg: {
        para1: {},
        para2: {},
        para3: {},
      },
      cy: {
        para1: {},
        para2: {},
        para3: {},
      },
    },
    que11: "",
    que14: {
      riskProfile1: [],
      riskProfile2: [],
      riskProfile3: [],
      riskProfile4: [],
      riskProfile5: [],
      responsiveness2: [],
      responsiveness1: [],
      responsiveness4: [],
      responsiveness3: [],
    },
  };

  capitalgrowth: any = 10;
  capitalyield: any = 10;
  ceotrigger: any = 5;
  selectDeals: boolean = false;

  cgcydeals: any = [5, 10, 20];

  rmEdited: any = {
    spv: "",
    systemGeneratedPortfolioA: [],
    InvEditedPortfolioA: [
      {
        allocationamount: 0,
        allocationamount_input: "",
        allocationPercent: 0,
        allocation_type: "invportfolioA",
        asset_class_type: "",
        generated_type: "investment",
        spv: "",
        strategy: "",
        target_return: 0,
        target_yield: 0,
      },
    ],

    customInvPortfolioB: [
      {
        allocationamount: 0,
        allocationPercent: 0,
        allocationamount_input: "",
        allocation_type: "customInvPortfolioB",
        asset_class_type: "",
        generated_type: "investment",
        spv: "",
        strategy: "",
        target_return: 0,
        target_yield: 0,
      },
    ],

    InvEditedPortfolioB: [
      {
        allocationamount: 0,
        allocationamount_input: "",
        allocationPercent: 0,
        allocation_type: "invportfolioB",
        asset_class_type: "",
        generated_type: "investment",
        spv: "",
        strategy: "",
        target_return: 0,
        target_yield: 0,
      },
    ],

    rmGenerated: [
      {
        allocationamount: 0,
        allocationPercent: 0,
        allocationamount_input: "",
        allocation_type: "rmGenerated",
        asset_class_type: "",
        generated_type: "rm",
        spv: "",
        strategy: "",
        target_return: 0,
        target_yield: 0,
      },
    ],

    systemGeneratedPortfolioB: [],
  };

  customInvB: any = {
    spv: "",
    rmGenerated: [
      {
        allocationamount: 0,
        allocationPercent: 0,
        allocationamount_input: "",
        allocation_type: "rmGenerated",
        asset_class_type: "",
        generated_type: "rm",
        spv: "",
        strategy: "",
        target_return: 0,
        target_yield: 0,
      },
    ],
    systemGeneratedPortfolioA: [],
    InvEditedPortfolioA: [
      {
        allocationamount: 0,
        allocationamount_input: "",
        allocationPercent: 0,
        allocation_type: "invportfolioA",
        asset_class_type: "",
        generated_type: "investment",
        spv: "",
        strategy: "",
        target_return: 0,
        target_yield: 0,
      },
    ],

    customInvPortfolioB: [
      {
        allocationamount: 0,
        allocationPercent: 0,
        allocationamount_input: "",
        allocation_type: "customInvPortfolioB",
        asset_class_type: "",
        generated_type: "investment",
        spv: "",
        strategy: "",
        target_return: 0,
        target_yield: 0,
      },
    ],

    InvEditedPortfolioB: [
      {
        allocationamount: 0,
        allocationamount_input: "",
        allocationPercent: 0,
        allocation_type: "invportfolioB",
        asset_class_type: "",
        generated_type: "investment",
        spv: "",
        strategy: "",
        target_return: 0,
        target_yield: 0,
      },
    ],

    systemGeneratedPortfolioB: [],
  };

  tableData: any = [];

  responsiveness: any = "";
  risk_score_perc: any = "";
  selectProspect: any = "";
  prospectObj = "";
  sendingData: string;

  @ViewChild("commScrollCont", { static: false })
  private myScrollContainer: ElementRef;
  systemgenerated: boolean = false;
  portfolioa: boolean = false;
  systemportfoliob: boolean = false;
  portfoliob: boolean = false;
  INVApprovalModalRef: BsModalRef;
  editEnablePortfolioA: boolean = false;
  portfolioaTotal: number = 0;
  portfoliobTotal: number = 0;
  portfolioaINVTotal: number = 0;
  portfoliobINVTotal: number = 0;
  storedResAllocationData: any = {};
  RMAllocation: boolean = false;
  rmSpvList: any;
  spvCol: string;
  amountCol: string;

  amoutRow: string;
  boxSize: string;
  editEnablePortfolioB: boolean = false;
  InvAtotalAmt: number = 0;
  totalINVPercA: number = 0;
  totalINVPercB: number = 0;
  rmGenerated: boolean = false;
  portfolioaPercTotal: number = 0;
  portfoliobPercTotal: number = 0;
  invGenerated: boolean = false;
  totalRMPerc: any;
  rmGeneratedTotal: number = 0;
  rmgeneratedPercTotal: number = 0;
  customPortfolioBTotal: number = 0;
  customPortfolioBPercTotal: number = 0;
  invPortfolioBTotal: number = 0;
  invPortfolioBPercTotal: number = 0;
  invPortfolioATotal: number = 0;
  invPortfolioAPercTotal: number = 0;
  RMallocationModify: boolean = true;
  totalCustomBPerc: number = 0;
  amountCol_rm: string;
  confirm: boolean = false;
  warning: boolean = false;
  RMDateSaved: boolean = false;
  constructor(
    private modalService: BsModalService,
    private http: CommonservicesService,
    public app: AppComponent,
    private toastr: ToastrService
  ) {
    am4core.useTheme(am4themes_animated);
  }
  selectedprospect: any = { rm_status: "" };
  AAAmount: number = 0;
  @ViewChild("template", { static: false })
  public templateref: TemplateRef<any>;
  opportunisticConfirmationModalRef: BsModalRef;
  @ViewChild("template1", { static: false })
  public templateref1: TemplateRef<any>;
  liquidityModalRef: BsModalRef;
  @ViewChild("template2", { static: false })
  public templateref2: TemplateRef<any>;
  justificationModalRef: BsModalRef;
  @ViewChild("justification", { static: false })
  public templateref4: TemplateRef<any>;

  InsertliquidityModalRef: BsModalRef;
  @ViewChild("withdrawalNeeds", { static: false })
  public templateref3: TemplateRef<any>;

  warningFlagsModalRef: BsModalRef;
  modalConfig = {
    class: "modal-md",
    backdrop: true,
    ignoreBackdropClick: true,
  };
  proposed_aa_summary: any;
  proposed_aa_data: any = { dataList: [], aaTotal: 0, aaTotalPerc: 0 };
  summaryViewerIs: boolean = false;
  currentAaGeneratedIs: boolean = false;

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.selectedprospect = JSON.parse(
      sessionStorage.getItem("selctedPersonaObj")
    );
    setTimeout(
      function () {
        if (
          this.selectedprospect.inv_status == "sent_to_rm" ||
          this.selectedprospect.inv_status == "approved" ||
          this.selectedprospect.inv_status == "self_directed" ||
          this.selectedprospect.inv_status == "sent_to_ceo" ||
          this.selectedprospect.inv_status == "accepted_by_ceo" ||
          this.selectedprospect.inv_status == "rejected_by_ceo" ||
          this.userInfo.role == "RM"
        ) {
          this.summaryViewerIs = true;
          this.get_aa_module_view_data();
        } else {
          this.summaryViewerIs = false;
          //this.getAllOpportunisticFundsAllocation();
          if (
            this.selectedprospect.inv_status == "allocation created" ||
            this.selectedprospect.inv_status == "rejected"
          ) {
            this.get_aa_module_view_data();
            this.currentAaGeneratedIs = true;
          }
        }
        // if (this.selectedprospect.inv_status == "pending") {
        //   if (!this.isWarningPopup) {
        //     this.sendOpportunisticData();
        //   }
        // }

        this.getAllSummaryQdata();
      }.bind(this),
      100
    );
  }

  exceededTotalAmt: number = 0;
  removeOppFund(index, data, modal) {
    this.questionsArray.que7.splice(index, 1);
    let amotGet = data.amt;
    let amotPert = parseFloat(data.pert);
    this.opportunisticFund.totalAmt = this.opportunisticFund.totalAmt - amotGet;
    //this.exceededTotalAmt = this.opportunisticFund.totalAmt + amotGet;
    this.opportunisticFund.totalPert =
      this.opportunisticFund.totalPert - amotPert;
    this.getOpsFundAllocation(this.opportunisticFund.totalAmt, modal);
    // this.removeSelectedSvpName();
  }

  addRMGenerated(obj, temp) {
    if (obj.rmGenerated[0].allocationamount_input && obj.rmGenerated[0].spv) {
      var oppAmt = obj.rmGenerated[0].allocationamount_input.replace(/,/g, "");
      obj.rmGenerated[0].allocationamount = oppAmt ? parseInt(oppAmt) : 0;
      var oppPert = obj.rmGenerated[0].allocationPercent;
      obj.rmGenerated[0].allocationPercent = oppPert ? parseFloat(oppPert) : 0;
      obj.spv = obj.rmGenerated[0].spv;

      this.tableData.unshift(obj);
      this.rmEdited = {
        spv: "",
        systemGeneratedPortfolioA: [],
        InvEditedPortfolioA: [
          {
            allocationamount: 0,
            allocationamount_input: "",
            allocationPercent: 0,
            allocation_type: "invportfolioA",
            asset_class_type: "",
            generated_type: "investment",
            spv: "",
            strategy: "",
            target_return: 0,
            target_yield: 0,
          },
        ],

        customInvPortfolioB: [
          {
            allocationamount: 0,
            allocationPercent: 0,
            allocationamount_input: "",
            allocation_type: "customInvPortfolioB",
            asset_class_type: "",
            generated_type: "investment",
            spv: "",
            strategy: "",
            target_return: 0,
            target_yield: 0,
          },
        ],

        InvEditedPortfolioB: [
          {
            allocationamount: 0,
            allocationamount_input: "",
            allocationPercent: 0,
            allocation_type: "invportfolioB",
            asset_class_type: "",
            generated_type: "investment",
            spv: "",
            strategy: "",
            target_return: 0,
            target_yield: 0,
          },
        ],

        rmGenerated: [
          {
            allocationamount: 0,
            allocationPercent: 0,
            allocationamount_input: "",
            allocation_type: "rmGenerated",
            asset_class_type: "",
            generated_type: "rm",
            spv: "",
            strategy: "",
            target_return: 0,
            target_yield: 0,
          },
        ],

        systemGeneratedPortfolioB: [],
      };
    }

    // let amotGet = obj.allocationamount;
    // let amotPert = parseFloat(obj.allocationPercent);
    // this.opportunisticFund.totalAmt =
    //   this.opportunisticFund.totalAmt + amotGet;
    // //this.exceededTotalAmt = this.opportunisticFund.totalAmt + amotGet;
    // this.opportunisticFund.totalPert =
    //   this.opportunisticFund.totalPert + amotPert;
    // // this.getOpsFundAllocation(this.opportunisticFund.totalAmt, temp);
    // console.log(this.tableData, "myTabdata");
    // this.removeSelectedSvpName();
  }

  addCustomPortfolioB(obj, temp) {
    if (
      obj.customInvPortfolioB[0].allocationamount_input &&
      obj.customInvPortfolioB[0].spv
    ) {
      var oppAmt = obj.customInvPortfolioB[0].allocationamount_input.replace(
        /,/g,
        ""
      );
      obj.customInvPortfolioB[0].allocationamount = oppAmt
        ? parseInt(oppAmt)
        : 0;
      var oppPert = obj.customInvPortfolioB[0].allocationPercent;
      obj.customInvPortfolioB[0].allocationPercent = oppPert
        ? parseFloat(oppPert)
        : 0;
      obj.spv = obj.customInvPortfolioB[0].spv;

      this.tableData.unshift(obj);
      this.customInvB = {
        spv: "",
        systemGeneratedPortfolioA: [],
        // InvEditedPortfolioA: [],
        rmGenerated: [
          {
            allocationamount: 0,
            allocationPercent: 0,
            allocationamount_input: "",
            allocation_type: "rmGenerated",
            asset_class_type: "",
            generated_type: "rm",
            spv: "",
            strategy: "",
            target_return: 0,
            target_yield: 0,
          },
        ],
        customInvPortfolioB: [
          {
            allocationamount: 0,
            allocationamount_input: "",
            allocationPercent: 0,
            allocation_type: "customInvPortfolioB",
            asset_class_type: "",
            generated_type: "investment",
            spv: "",
            strategy: "",
            target_return: 0,
            target_yield: 0,
          },
        ],
        InvEditedPortfolioA: [
          {
            allocationamount: 0,
            allocationamount_input: "",
            allocationPercent: 0,
            allocation_type: "invportfolioA",
            asset_class_type: "",
            generated_type: "investment",
            spv: "",
            strategy: "",
            target_return: 0,
            target_yield: 0,
          },
        ],
        InvEditedPortfolioB: [],

        systemGeneratedPortfolioB: [],
      };
    }

    // let amotGet = obj.allocationamount;
    // let amotPert = parseFloat(obj.allocationPercent);
    // this.opportunisticFund.totalAmt =
    //   this.opportunisticFund.totalAmt + amotGet;
    // //this.exceededTotalAmt = this.opportunisticFund.totalAmt + amotGet;
    // this.opportunisticFund.totalPert =
    //   this.opportunisticFund.totalPert + amotPert;
    // // this.getOpsFundAllocation(this.opportunisticFund.totalAmt, temp);
    // console.log(this.tableData, "myTabdata");
    // this.removeSelectedSvpName();
  }

  // removeSelectedSvpName() {
  //   this.opportunisticNameList = this.getliquditySpv;
  //   for (let i = 0; i < this.questionsArray.que7.length; i++) {
  //     const qus7Obj = this.questionsArray.que7[i];
  //     this.opportunisticNameList = this.opportunisticNameList.filter(
  //       (obj) => obj.spv !== qus7Obj.funds
  //     );
  //   }
  // }

  /* Generate Asset Allocation */
  async sendOpportunisticData() {
    if (
      this.questionsArray.que5 == "conventional" &&
      this.questionsArray.que6.liquidity == "Yes"
    ) {
      for (let l = 0; l < 3; l++) {
        const liquidityAmount = this.questionsArray.que6["year" + (l + 1)];
        const liquidityFunName = this.liquidOptionWithdrawal["year" + (l + 1)];
        if (liquidityAmount && !liquidityFunName) {
          this.toastr.error("Liquidity need's fund name is required");
          return false;
        }
      }
    }
    var sendData = {
      data: {
        ClientProspectMasterId: this.selectedprospect.prospect_id,
        instanceId: this.selectedprospect.instanceId,
        questionData: [
          {
            QuestionMasterId: 9,
            answer: [
              {
                liquidity: this.questionsArray.que6.liquidity,
              },
              {
                year1: this.questionsArray.que6.year1,
                fund: this.liquidOptionWithdrawal.year1,
              },
              {
                year2: this.questionsArray.que6.year2,
                fund: this.liquidOptionWithdrawal.year2,
              },
              {
                year3: this.questionsArray.que6.year3,
                fund: this.liquidOptionWithdrawal.year3,
              },
            ],
          },
          {
            QuestionMasterId: 26,
            answer: this.questionsArray.que7,
          },
        ],
      },
    };
    this.app.getLoader(true);
    sessionStorage.setItem(
      "opportunisticFundData",
      JSON.stringify(sendData.data)
    );
    var headerObj = await this.app.getHTTPHeaders();
    this.http.sendSummData(sendData, headerObj).subscribe(
      (response: any) => {
        const dataResponse = response;
        this.app.getLoader(false);
        if (dataResponse.success) {
          this.portfolioaTotal = 0;
          this.portfoliobTotal = 0;
          this.portfolioaPercTotal = 0;
          this.portfoliobPercTotal = 0;
          this.generateAssAllModel();
          // this.opportunisticConfirmationModalRef.hide();
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async getliquditySpv(sharia) {
    var sendData = {
      shariah: sharia,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getliquditySpv(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          //this.opportunisticNameList = response.data;
          this.liquidOption = response.data;
          console.log(this.liquidOption);
          // this.removeSelectedSvpName();
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }
  async getRMEditedSpv() {
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getRMEditedSpv(headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          //this.opportunisticNameList = response.data;
          this.rmSpvList = response.data;
          // this.removeSelectedSvpName();
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  openFlagModal(template: TemplateRef<any>) {
    this.warningFlagsModalRef = this.modalService.show(
      template,
      this.modalConfig
    );
  }
  openLiqModal(template3: TemplateRef<any>) {
    this.InsertliquidityModalRef = this.modalService.show(
      template3,
      this.modalConfig
    );
  }
  CEOFlowModal(template4: TemplateRef<any>) {
    this.justificationModalRef = this.modalService.show(
      template4,
      this.modalConfig
    );
  }
  async getAllSummaryQdata() {
    var sendData = {
      prospect_id: this.selectedprospect.prospect_id,
    };
    this.app.getLoader(true);
    this.selectProspect = sessionStorage.getItem("prospect");
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getSummary(sendData, headerObj).subscribe(
      (response: any) => {
        const dataResponse = response;
        if (dataResponse.success && dataResponse.data.length > 0) {
          this.invesmentSummary = dataResponse.data;
          sessionStorage.setItem(
            "investSummData",
            JSON.stringify(dataResponse)
          );

          // console.log(this.tableData);

          for (let x = 0; x < this.invesmentSummary.length; x++) {
            const data_obj = this.invesmentSummary[x];
            if (data_obj.question_no == 1) {
              this.questionsArray.que1 = parseInt(data_obj.answer);
            }
            if (data_obj.question_no == 2) {
              var question_2 = JSON.parse(data_obj.answer)[0];
              this.questionsArray.que2 = question_2;
            }
            if (data_obj.question_no == 6) {
              this.questionsArray.que5 = data_obj.answer;
            }

            if (data_obj.question_no == 7) {
              this.questionsArray.que3_root = data_obj.answer;
            }
            if (data_obj.question_no == 11) {
              this.questionsArray.que11 = parseInt(data_obj.answer);
              this.netWorth =
                (this.questionsArray.que1 / this.questionsArray.que11) * 100;
            }

            if (data_obj.question_no == 8) {
              let question_3 = data_obj.answer;
              this.questionsArray.que3 = parseInt(question_3);
            }

            if (data_obj.question_no == 9) {
              if (data_obj.answer) {
                let question_6 = JSON.parse(data_obj.answer);
                if (question_6.length > 1) {
                  // this.openLiqModal(this.templateref1);
                  this.questionsArray.que6.liquidity = question_6[0].liquidity;
                  this.questionsArray.que6.year1 = question_6[1].year1
                    ? parseInt(question_6[1].year1)
                    : 0;
                  this.questionsArray.que6.year2 = question_6[2].year2
                    ? parseInt(question_6[2].year2)
                    : 0;
                  this.questionsArray.que6.year3 = question_6[3].year3
                    ? parseInt(question_6[3].year3)
                    : 0;

                  this.liquidOptionWithdrawal.year1 = question_6[1].fund;
                  this.liquidOptionWithdrawal.year2 = question_6[2].fund;
                  this.liquidOptionWithdrawal.year3 = question_6[3].fund;
                  this.questionsArray.que6.total_wealth =
                    this.questionsArray.que6.year1 +
                    this.questionsArray.que6.year2 +
                    this.questionsArray.que6.year3;

                  if (this.questionsArray.que6.liquidity == "Yes") {
                    var immediateAmt = parseInt(
                      this.questionsArray.que2.immediately
                    );
                    var calAAAmount =
                      this.questionsArray.que6.total_wealth > 0
                        ? immediateAmt - this.questionsArray.que6.total_wealth
                        : 0;
                    this.AAAmount = calAAAmount / 2;
                  } else {
                    this.AAAmount =
                      parseInt(this.questionsArray.que2.immediately) / 2;
                  }
                } else {
                  this.questionsArray.que6 = question_6[0];
                  if (this.questionsArray.que6.liquidity == "Yes") {
                    var year1 = this.questionsArray.que6.year1
                      ? parseInt(this.questionsArray.que6.year1)
                      : 0;
                    var year2 = this.questionsArray.que6.year2
                      ? parseInt(this.questionsArray.que6.year2)
                      : 0;
                    var year3 = this.questionsArray.que6.year3
                      ? parseInt(this.questionsArray.que6.year3)
                      : 0;
                    this.questionsArray.que6.total_wealth =
                      year1 + year2 + year3;
                    var immediateAmt = parseInt(
                      this.questionsArray.que2.immediately
                    );
                    var calAAAmount =
                      this.questionsArray.que6.total_wealth > 0
                        ? immediateAmt - this.questionsArray.que6.total_wealth
                        : 0;
                    this.AAAmount = calAAAmount / 2;
                  } else {
                    this.AAAmount =
                      parseInt(this.questionsArray.que2.immediately) / 2;
                  }
                }
              }
            }

            if (data_obj.question_no == 10) {
              //this.questionsArray.que4 = data_obj; //.answer;
              if (data_obj.answer.includes("(")) {
                var setData = {
                  title: data_obj.answer.split("(")[0],
                  desc: data_obj.answer.split("(")[1].replace(")", ""),
                };
                this.questionsArray.que4 = setData;
              } else {
                for (var i = 0; i < this.portfolioObjectives.length; i++) {
                  var isTitle = this.portfolioObjectives[i].title;
                  if (isTitle.toLowerCase() == data_obj.answer.toLowerCase()) {
                    this.questionsArray.que4 = this.portfolioObjectives[i];
                    break;
                  }
                }
              }
            }
            var immediateAmt = parseInt(this.questionsArray.que2.immediately);
            // this.netWorth = isNaN(parseInt(this.netWorth)) ? 0 : parseInt(this.netWorth);
            if (this.netWorth && this.netWorth != NaN) {
              if (this.netWorth < 1000000) {
                if (!this.warningFlagsModalRef) {
                  // setTimeout(() => {
                  this.isWarningPopup = true;
                  this.openFlagModal(this.templateref2);
                  // }, 100);
                }
              }
            }

            if (
              this.risk1_1Valid == true ||
              this.risk1_2Valid == true ||
              this.risk2_1Valid == true ||
              this.risk2_2Valid == true ||
              this.risk3_1Valid == true ||
              this.risk3_2Valid == true ||
              this.risk4_1Valid == true ||
              this.risk4_2Valid == true ||
              this.risk4_3Valid == true ||
              this.risk5_1Valid == true ||
              this.risk5_2Valid == true ||
              this.risk5_3Valid == true ||
              this.responsive1Valid == true ||
              this.responsive2Valid == true ||
              this.responsive3_1Valid == true ||
              this.responsive3_2Valid == true ||
              this.responsive4_1Valid == true ||
              this.responsive4_2Valid == true
            ) {
              this.attitudanalFlags = true;
              if (!this.warningFlagsModalRef) {
                // setTimeout(() => {
                this.openFlagModal(this.templateref2);
                this.isWarningPopup = true;
                // }, 100);
              }
            }
            if (
              (data_obj.question_no == 10 && data_obj.answer == "Short Term") ||
              immediateAmt < 100000
            ) {
              if (!this.warningFlagsModalRef) {
                // setTimeout(() => {
                this.openFlagModal(this.templateref2);
                this.isWarningPopup = true;
                // }, 100);
              }
            }
            if (data_obj.question_no == 14) {
              let question_14 = JSON.parse(data_obj.answer);
              this.questionsArray.que14.riskProfile1 =
                question_14.riskProfile[0];
              this.questionsArray.que14.riskProfile2 =
                question_14.riskProfile[1];
              this.questionsArray.que14.riskProfile3 =
                question_14.riskProfile[2];
              this.questionsArray.que14.riskProfile4 =
                question_14.riskProfile[3];
              this.questionsArray.que14.riskProfile5 =
                question_14.riskProfile[4];
              this.questionsArray.que14.responsiveness1 =
                question_14.responsiveness[0];
              this.questionsArray.que14.responsiveness2 =
                question_14.responsiveness[1];
              this.questionsArray.que14.responsiveness3 =
                question_14.responsiveness[2];
              this.questionsArray.que14.responsiveness4 =
                question_14.responsiveness[3];
              this.riskContradict();
            }

            if (data_obj.question_no == 15) {
              let question_7 = JSON.parse(data_obj.answer);
              this.questionsArray.que7 = question_7;
              if (question_7.length > 0) {
                var total = 0;
                var totalPerc = 0;

                for (var i = 0; i < question_7.length; i++) {
                  total = total + question_7[i].amt;
                  totalPerc = totalPerc + parseFloat(question_7[i].pert);
                }
                this.opportunisticFund.totalAmt = total;
                this.opportunisticFund.totalPert = totalPerc;

                this.getOpsFundAllocation(
                  this.opportunisticFund.totalAmt,
                  null
                );
              }
            }

            if (data_obj.question_no == 16) {
              if (data_obj.answer && data_obj.answer != "null") {
                let question_8 = JSON.parse(data_obj.answer);
                this.questionsArray.que8.cg.para1 = question_8.capitalGrowth[0];
                this.questionsArray.que8.cg.para2 = question_8.capitalGrowth[1];
                this.questionsArray.que8.cg.para3 = question_8.capitalGrowth[2];
                this.questionsArray.que8.cy.para1 =
                  question_8.capitalYielding[0];
                this.questionsArray.que8.cy.para2 =
                  question_8.capitalYielding[1];
                this.questionsArray.que8.cy.para3 =
                  question_8.capitalYielding[2];
              } else {
              }
            }
          }
          if (
            this.questionsArray.que3 != "null" &&
            this.questionsArray.que3 &&
            this.questionsArray.que3_root == "Yes"
          ) {
            var incomePerc =
              (this.questionsArray.que3 /
                this.questionsArray.que2.immediately) *
              100;
            this.questionsArray.que3perc = incomePerc;
          }
          this.responsiveness = dataResponse.responsiveness.toLowerCase();
          this.risk_score_perc = dataResponse.risk_score_perc;
          setTimeout(() => {
            this.startRenderResChart();
          }, 200);

          if (this.questionsArray.que5 == "Shari'ah") {
            var sharia = "Y";
          } else {
            sharia = "N";
          }
          this.getliquditySpv(sharia);
          this.getRMEditedSpv();
          if (this.selectedprospect.inv_status == "pending") {
            if (this.isWarningPopup == false) {
              if (this.questionsArray.que6.liquidity == "Yes") {
                this.openLiqModal(this.templateref3);
              } else {
                this.sendOpportunisticData();
              }
            }
          }
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  startRenderResChart() {
    // create chart
    var chart = am4core.create("respnsnssChartView", am4charts.GaugeChart);
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

    chart.innerRadius = -45;
    chart.startAngle = 200;
    chart.endAngle = 340;
    chart.y = 0;
    chart.logo.disabled = true;

    var axis = chart.xAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
    );
    axis.min = 0;
    axis.max = 3;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.disabled = true;
    axis.renderer.grid.template.strokeOpacity = 1;
    axis.renderer.grid.template.stroke = am4core.color("#fff");
    axis.renderer.grid.template.strokeWidth = 4;

    var range0 = axis.axisRanges.create();
    range0.value = 0;
    range0.endValue = 1;
    range0.axisFill.fillOpacity = 1;
    range0.axisFill.fill = am4core.color("#8eda5c");
    range0.axisFill.zIndex = -1;

    var range1 = axis.axisRanges.create();
    range1.value = 1;
    range1.endValue = 2;
    range1.axisFill.fillOpacity = 1;
    range1.axisFill.fill = am4core.color("#ffb914");
    range1.axisFill.zIndex = -1;

    var range2 = axis.axisRanges.create();
    range2.value = 2;
    range2.endValue = 3;
    range2.axisFill.fillOpacity = 1;
    range2.axisFill.fill = am4core.color("#ff7b6d");
    range2.axisFill.zIndex = -1;

    var hand = chart.hands.push(new am4charts.ClockHand());
    hand.fill = am4core.color("#343a40");
    hand.stroke = am4core.color("#343a40");
    hand.axis = axis;
    //hand.pin.disabled = true;
    hand.pin.radius = 5;
    hand.startWidth = 10;
    hand.endWidth = 0;
    hand.radius = am4core.percent(70);
    hand.innerRadius = am4core.percent(0);

    if (this.responsiveness.toLowerCase() == "low") {
      hand.showValue(0.5);
    } else if (this.responsiveness.toLowerCase() == "moderate") {
      hand.showValue(1.5);
    } else {
      hand.showValue(2.5);
    }
  }

  exceededPercentageImmediateIs: boolean;
  exceededPercentageMoreThanhundredIs: boolean;
  roundOpp(value) {
    var oppAmt = value.target.value.replace(/,/g, "");
    this.rmEdited.rmGenerated[0].allocationamount =
      Math.round(oppAmt / 10000) * 10000;
    this.rmEdited.rmGenerated[0].allocationamount = Number(
      this.rmEdited.rmGenerated[0].allocationamount
    ).toLocaleString("en-GB");
  }
  calcFundAllocation(type, modal) {
    var immediateAmt = parseInt(this.questionsArray.que2.immediately);
    if (type == "rmGenerated") {
      let amountInt = this.rmEdited.rmGenerated[0].allocationamount_input.includes(
        ","
      )
        ? this.rmEdited.rmGenerated[0].allocationamount_input.replace(/,/g, "")
        : this.rmEdited.rmGenerated[0].allocationamount_input;
      var isPerc = (parseInt(amountInt) / immediateAmt).toFixed(3);
      this.rmEdited.rmGenerated[0].allocationPercent =
        isPerc != "Infinity" ? isPerc : 0;
      // this.getOpsFundAllocation(amountInt, modal);
    } else {
      let amountInt = this.customInvB.customInvPortfolioB[0].allocationamount_input.includes(
        ","
      )
        ? this.customInvB.customInvPortfolioB[0].allocationamount_input.replace(
            /,/g,
            ""
          )
        : this.customInvB.customInvPortfolioB[0].allocationamount_input;
      var isPerc = (parseInt(amountInt) / immediateAmt).toFixed(3);
      this.customInvB.customInvPortfolioB[0].allocationPercent =
        isPerc != "Infinity" ? isPerc : 0;
    }
  }
  calcFundAllocationRound() {
    var immediateAmt = parseInt(this.questionsArray.que2.immediately);

    let amountInt = this.rmEdited.rmGenerated[0].allocationamount_input.includes(
      ","
    )
      ? this.rmEdited.rmGenerated[0].allocationamount_input.replace(/,/g, "")
      : this.rmEdited.rmGenerated[0].allocationamount_input;
    var isPerc = (parseInt(amountInt) / immediateAmt).toFixed(3);
    this.rmEdited.rmGenerated[0].allocationPercent =
      isPerc != "Infinity" ? isPerc : 0;
  }

  isView: string = "";
  /* Opportunistic Fund Allocation Validation*/

  getOpsFundAllocation(amountInt, temp) {
    var totalAmt = this.AAAmount * 2;
    if (totalAmt > 0) {
      var isMostPerc = (amountInt / totalAmt) * 100;
      var isPerc =
        this.opportunisticSelector.pert != ""
          ? parseFloat(this.opportunisticSelector.pert)
          : 0;
      var isTotalPerc = parseFloat(this.opportunisticFund.totalPert) + isPerc;
      var isTotal =
        parseInt(this.opportunisticFund.totalAmt) + parseInt(amountInt);

      if (amountInt > this.AAAmount) {
        this.exceededPercentageImmediateIs = true;
        this.exceededPercentageMoreThanhundredIs = false;
      } else {
        this.exceededPercentageImmediateIs = false;
        this.exceededPercentageMoreThanhundredIs = false;
      }

      if (isMostPerc >= 100 || isTotalPerc >= 100) {
        this.exceededPercentageMoreThanhundredIs = true;
        this.exceededPercentageImmediateIs = true;
        if (isMostPerc === 100 || isTotalPerc === 100) {
          this.isView = "Confirmation";
        } else {
          this.isView = "Alert";
        }
        if (temp) {
          this.openModal(temp);
        }
      }
    } else {
      this.isView = "Alert";
      if (temp) {
        this.openModal(temp);
      }
    }
  }

  openModal(template: TemplateRef<any>) {
    this.opportunisticConfirmationModalRef = this.modalService.show(
      template,
      this.modalConfig
    );
  }

  calcFATotal() {
    var clipTwoAmt =
      this.questionsArray.que7.para1.Amount == ""
        ? 0
        : this.questionsArray.que7.para1.Amount;
    var underwritingFCB =
      this.questionsArray.que7.para2.Amount == ""
        ? 0
        : this.questionsArray.que7.para2.Amount;
    var underwritingFCC =
      this.questionsArray.que7.para3.Amount == ""
        ? 0
        : this.questionsArray.que7.para3.Amount;
    var getTotal =
      parseInt(clipTwoAmt) +
      parseInt(underwritingFCB) +
      parseInt(underwritingFCC);
    this.questionsArray.que6.total = getTotal;
  }

  commentMaxLength: any = 200;
  commentCompoOpenIs: boolean = false;
  async commentSubmitFun() {
    if (this.commentText) {
      if (this.commentText.length <= this.commentMaxLength) {
        this.commentBoxLoaderIs = true;
        var sendData = {
          comment: this.commentText,
          userId: this.userInfo.aaaUserId,
          InstanceId: this.selectedprospect.instanceId,
        };
        var headerObj = await this.app.getHTTPHeaders();
        this.http.addComments(sendData, headerObj).subscribe(
          (response: any) => {
            if (response.success) {
              this.getNdShowAllComments(true);
              this.toastr.success("Comment Added");
              this.commentText = "";
            }
          },
          (error) => {
            this.app.errorHandler(error);
          }
        );
      } else {
        this.toastr.error(
          "The Comment should be less than " + this.commentMaxLength
        );
      }
    } else {
      this.toastr.error("Enter Valid Comments");
    }
  }

  async getNdShowAllComments(param) {
    this.commentCompoOpenIs = param;
    if (param) {
      this.commentBoxLoaderIs = true;
      var sendData = {
        aaaInstanceMasterId: this.selectedprospect.instanceId,
      };
      //this.userInfo.aaaUserId
      var headerObj = await this.app.getHTTPHeaders();
      this.http.getAllComments(sendData, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            response.result.sort((a, b) => {
              return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
            });
            this.commentList = response.result.reverse();
            this.scrollToBottom();
          } else {
            this.toastr.error("Error: " + response.msg);
          }
          this.commentBoxLoaderIs = false;
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    }
  }

  scrollToBottom(): void {
    try {
      setTimeout(
        function () {
          this.myScrollContainer.nativeElement.scrollTop =
            this.myScrollContainer.nativeElement.scrollHeight + 200;
        }.bind(this),
        100
      );
    } catch (err) {}
  }

  getEnterCodeForSubmit(event) {
    var shiftPlusEnterIs = event.code == "Enter" && event.ctrlKey;
    var shiftPlusNumPadEnterIs = event.code == "NumpadEnter" && event.ctrlKey;
    if (shiftPlusEnterIs || shiftPlusNumPadEnterIs) {
      event.preventDefault();
      this.commentSubmitFun();
    }
  }

  clearForReallocate() {
    this.questionsArray.que7 = [];
    this.opportunisticFund.totalAmt = 0;
    this.opportunisticFund.totalPert = 0;
    this.opportunisticSelector.funds = "";
    this.opportunisticSelector.amt = "";
    this.opportunisticSelector.pert = "";
    this.exceededPercentageImmediateIs = false;
    this.exceededPercentageMoreThanhundredIs = false;
    // this.removeSelectedSvpName();
  }

  // Gen AA Comp

  async generateAssAllModel() {
    var sendObj = {};
    var summData = JSON.parse(sessionStorage.getItem("investSummData"));
    var getInvestSummData = summData.data;
    var ans_one = 0;
    var qstNoTwo_ans = {
      immediately: 0,
      year1: 0,
      year2: 0,
      above2Years: 0,
    };
    var shariaIs = "";
    var liquidityIs = "";
    var invHorizon = "";
    var incomeRequired_ans = 0;
    var excludeAssetClass_ans = {
      capitalGrowth: [],
      capitalYielding: [],
    };
    var capitalGrowth = [];
    var capitalYielding = [];

    for (let d = 0; d < getInvestSummData.length; d++) {
      var data_obj = getInvestSummData[d];
      if (data_obj.question_no == 1) {
        ans_one = parseInt(data_obj.answer);
      }
      if (data_obj.question_no == 2) {
        var qTwo_ans = JSON.parse(data_obj.answer)[0];
        qstNoTwo_ans.immediately = parseInt(qTwo_ans.immediately);
        qstNoTwo_ans.year1 = qTwo_ans.year1 ? parseInt(qTwo_ans.year1) : 0;
        qstNoTwo_ans.year2 = qTwo_ans.year2 ? parseInt(qTwo_ans.year2) : 0;
        //qstNoTwo_ans.year3 = qTwo_ans.year3 ? parseInt(qTwo_ans.year3) : 0;
        qstNoTwo_ans.above2Years = qTwo_ans.above2Years
          ? parseInt(qTwo_ans.above2Years)
          : 0;
      }
      if (data_obj.question_no == 6) {
        if (data_obj.answer == "Shari'ah") {
          shariaIs = "yes";
        } else {
          shariaIs = "no";
        }
      }
      if (data_obj.question_no == 8) {
        incomeRequired_ans = data_obj.answer ? parseInt(data_obj.answer) : 0;
      }
      if (data_obj.question_no == 10) {
        invHorizon = data_obj.answer ? data_obj.answer : 0;
      }
      if (data_obj.question_no == 9) {
        var liquidityNeeds_obj = JSON.parse(data_obj.answer)[0];
        liquidityIs = liquidityNeeds_obj.liquidity;
      }
      if (data_obj.question_no == 16) {
        if (data_obj.answer && data_obj.answer != "null") {
          excludeAssetClass_ans = JSON.parse(data_obj.answer);
          for (let j = 0; j < excludeAssetClass_ans.capitalGrowth.length; j++) {
            var cg_obj = excludeAssetClass_ans.capitalGrowth[j];
            var crtObj = {
              fund: cg_obj.fund,
              exclude: cg_obj.exclude.toLowerCase(),
            };
            capitalGrowth.push(crtObj);
          }
          for (
            let k = 0;
            k < excludeAssetClass_ans.capitalYielding.length;
            k++
          ) {
            var cy_obj = excludeAssetClass_ans.capitalYielding[k];
            var crtObj1 = {
              fund: cy_obj.fund,
              exclude: cy_obj.exclude.toLowerCase(),
            };
            capitalYielding.push(crtObj1);
          }
        } else {
          capitalGrowth = [
            {
              fund: "US PE",
              exclude: "no",
            },
            {
              fund: "Asian PE",
              exclude: "no",
            },
            {
              fund: "Asian RE",
              exclude: "no",
            },
          ];
          capitalYielding = [
            {
              fund: "US RE Debt",
              exclude: "no",
            },
            {
              fund: "US Private Credit",
              exclude: "no",
            },
            {
              fund: "US RE",
              exclude: "no",
            },
          ];
        }
      }
    }

    // var opportFundData = JSON.parse(
    //   sessionStorage.getItem("opportunisticFundData")
    // );
    var liquidity_withdrawal_needs = { answer: [] };
    // var opportunistic_fun_allocation = { answer: [] };
    // for (let o = 0; o < opportFundData.questionData.length; o++) {
    //   const oppoFundObj = opportFundData.questionData[o];
    //   if (oppoFundObj.QuestionMasterId == 9) {
    //     liquidity_withdrawal_needs = oppoFundObj;
    //   }
    //   if (oppoFundObj.QuestionMasterId == 26) {
    //     opportunistic_fun_allocation = oppoFundObj;
    //   }
    // }
    // var liquidityNeeds_ans = {
    //   year1: liquidity_withdrawal_needs.answer[1].year1,
    //   year2: liquidity_withdrawal_needs.answer[2].year2,
    //   year3: liquidity_withdrawal_needs.answer[3].year3,
    // };
    // var opportunisticFund_ans = [];
    // if (opportunistic_fun_allocation.answer.length > 0) {
    //   for (let i = 0; i < opportunistic_fun_allocation.answer.length; i++) {
    //     const elementObj = opportunistic_fun_allocation.answer[i];
    //     var commRemvdAmt = elementObj.amt;
    //     var crt_obj = {
    //       fund: elementObj.funds,
    //       amount: commRemvdAmt,
    //       perc: commRemvdAmt / qstNoTwo_ans.immediately,
    //     };
    //     opportunisticFund_ans.push(crt_obj);
    //   }
    // } else {
    //   opportunisticFund_ans.push({
    //     fund: "",
    //     amount: 0,
    //     perc: 0,
    //   });
    // }
    console.log(this.WithdrawNeedsIsChecked, "checked");
    if (liquidityIs == "No") {
      var IsLiqudity = "No";
    } else if (liquidityIs == "Yes" && this.WithdrawNeedsIsChecked == false) {
      IsLiqudity = "No";
    } else {
      IsLiqudity = "Yes";
    }
    var regenerateIs =
      this.selectedprospect.rm_status == "pending" ? false : true;
    sendObj = {
      // "investmentAmt": ans_one,
      fundingSchedule: {
        immediately: qstNoTwo_ans.immediately,
        year1: qstNoTwo_ans.year1,
        year2: qstNoTwo_ans.year2,
        //"year3": qstNoTwo_ans.year3,
        above2Year: qstNoTwo_ans.above2Years,
      },
      sharia: shariaIs,
      incomeRequired: incomeRequired_ans,
      investmentHorizon: invHorizon,
      isLiquidityNeed: IsLiqudity,
      liquidityNeeds: [
        {
          year: 1,
          fund: this.liquidOptionWithdrawal.year1,
          amount: this.questionsArray.que6.year1
            ? this.questionsArray.que6.year1
            : 0,
          perc: this.questionsArray.que6.year1
            ? this.questionsArray.que6.year1 / qstNoTwo_ans.immediately
            : 0,
        },
        {
          year: 2,
          fund: this.liquidOptionWithdrawal.year2,
          amount: this.questionsArray.que6.year2
            ? this.questionsArray.que6.year2
            : 0,
          perc: this.questionsArray.que6.year2
            ? this.questionsArray.que6.year2 / qstNoTwo_ans.immediately
            : 0,
        },
        {
          year: 3,
          fund: this.liquidOptionWithdrawal.year3,
          amount: this.questionsArray.que6.year3
            ? this.questionsArray.que6.year3
            : 0,
          perc: this.questionsArray.que6.year3
            ? this.questionsArray.que6.year3 / qstNoTwo_ans.immediately
            : 0,
        },
      ],

      riskScore: summData.risk_score,
      riskScorePerc: summData.risk_score_perc,
      responsiveness: summData.responsiveness.toLowerCase(),
      clientProspectId: this.selectedprospect.prospect_id,
      instanceId: this.selectedprospect.instanceId,
      // "regenerate": regenerateIs
    };

    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getProposedAAdata(sendObj, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.currentAaGeneratedIs = true;

          this.rmGeneratedTotal = 0;
          this.rmgeneratedPercTotal = 0;
          this.customPortfolioBTotal = 0;
          this.customPortfolioBPercTotal = 0;
          this.invPortfolioBTotal = 0;
          this.invPortfolioBPercTotal = 0;
          this.invPortfolioATotal = 0;
          this.invPortfolioAPercTotal = 0;
          this.portfolioaTotal = 0;
          this.portfolioaPercTotal = 0;
          this.portfoliobPercTotal = 0;
          this.portfoliobTotal = 0;

          this.selectedprospect.inv_status = "allocation created";
          this.toastr.success("Allocation created successfully");
          sessionStorage.setItem(
            "selctedPersonaObj",
            JSON.stringify(this.selectedprospect)
          );
          this.storedResAllocationData = response;
          this.tableData = [];
          var editableTableData = response.data;
          var tempTableData = [];
          console.log(editableTableData, "editableTableData");
          for (let index = 0; index < editableTableData.length; index++) {
            var allocationTableIs = editableTableData[index];

            var addPortfolioA = {
              allocationamount_input: "",
              allocationamount: 0,
              allocationPercent: 0,
              allocation_type: "portfolioA",
              asset_class_type: "",
              generated_type: "investment",
              spv: "",
              strategy: "",
              target_return: 0,
              target_yield: 0,
            };

            if (allocationTableIs.InvEditedPortfolioA.length === 0) {
              allocationTableIs.InvEditedPortfolioA.push(addPortfolioA);
            } else {
              this.portfolioa = true;
              console.log(this.portfolioa, "port33folioa");
              var allocationamount_input = allocationTableIs.InvEditedPortfolioA[0].allocationamount.toString();

              allocationTableIs.InvEditedPortfolioA[0].allocationamount_input = allocationamount_input;
            }

            var addPortfolioB = {
              allocationamount_input: "",
              allocationamount: 0,
              allocationPercent: 0,
              allocation_type: "portfolioB",
              asset_class_type: "",
              generated_type: "investment",
              spv: "",
              strategy: "",
              target_return: 0.0225,
              target_yield: 0,
            };
            if (allocationTableIs.InvEditedPortfolioB.length === 0) {
              allocationTableIs.InvEditedPortfolioB.push(addPortfolioB);
            } else {
              this.portfoliob == true;

              var allocationamount_input = allocationTableIs.InvEditedPortfolioB[0].allocationamount.toString();
              allocationTableIs.InvEditedPortfolioB[0].allocationamount_input = allocationamount_input;
            }

            var addPortfolioRm = {
              allocationamount_input: "",
              allocationamount: 0,
              allocationPercent: 0,
              allocation_type: "rmGenerated",
              asset_class_type: "",
              generated_type: "rm",
              spv: "",
              strategy: "",
              target_return: 0,
              target_yield: 0,
            };
            if (allocationTableIs.rmGenerated.length == 0) {
              allocationTableIs.rmGenerated.push(addPortfolioRm);
            } else {
              this.RMAllocation == true;
              var allocationamount_input = allocationTableIs.rmGenerated[0].allocationamount.toString();
              allocationTableIs.rmGenerated[0].allocationamount_input = allocationamount_input;
            }

            var customBPortfolio = {
              allocationamount_input: "",
              allocationamount: 0,
              allocationPercent: 0,
              allocation_type: "customInvPortfolioB",
              asset_class_type: "",
              generated_type: "investment",
              spv: "",
              strategy: "",
              target_return: 0,
              target_yield: 0,
            };
            if (allocationTableIs.customInvPortfolioB.length == 0) {
              allocationTableIs.customInvPortfolioB.push(customBPortfolio);
            } else {
              this.invGenerated == true;
              var allocationamount_input = allocationTableIs.customInvPortfolioB[0].allocationamount.toString();
              allocationTableIs.customInvPortfolioB[0].allocationamount_input = allocationamount_input;
            }

            if (allocationTableIs.systemGeneratedPortfolioA.length > 0) {
              this.portfolioaTotal += allocationTableIs
                .systemGeneratedPortfolioA[0].allocationamount
                ? parseInt(
                    allocationTableIs.systemGeneratedPortfolioA[0]
                      .allocationamount
                  )
                : 0;
              this.portfolioaPercTotal += allocationTableIs
                .systemGeneratedPortfolioA[0].allocationPercent
                ? allocationTableIs.systemGeneratedPortfolioA[0]
                    .allocationPercent
                : 0;
              this.editEnablePortfolioA = true;

              this.systemgenerated = true;
            } else {
              this.editEnablePortfolioA = false;
            }
            if (allocationTableIs.InvEditedPortfolioA.length > 0) {
              if (
                allocationTableIs.InvEditedPortfolioA[0].allocationamount > 0
              ) {
                this.invPortfolioATotal += allocationTableIs
                  .InvEditedPortfolioA[0].allocationamount
                  ? parseInt(
                      allocationTableIs.InvEditedPortfolioA[0].allocationamount
                    )
                  : 0;
                this.invPortfolioAPercTotal += allocationTableIs
                  .InvEditedPortfolioA[0].allocationPercent
                  ? allocationTableIs.InvEditedPortfolioA[0].allocationPercent
                  : 0;

                this.portfolioa = true;
              }
            }

            if (allocationTableIs.rmGenerated.length > 0) {
              if (allocationTableIs.rmGenerated[0].allocationamount > 0) {
                this.RMAllocation = true;
                this.rmGeneratedTotal += allocationTableIs.rmGenerated[0]
                  .allocationamount
                  ? parseInt(allocationTableIs.rmGenerated[0].allocationamount)
                  : 0;
                this.rmgeneratedPercTotal += allocationTableIs.rmGenerated[0]
                  .allocationPercent
                  ? allocationTableIs.rmGenerated[0].allocationPercent
                  : 0;
              }
            }

            if (allocationTableIs.customInvPortfolioB.length > 0) {
              if (
                allocationTableIs.customInvPortfolioB[0].allocationamount > 0
              ) {
                this.invGenerated = true;

                this.customPortfolioBTotal += allocationTableIs
                  .customInvPortfolioB[0].allocationamount
                  ? parseInt(
                      allocationTableIs.customInvPortfolioB[0].allocationamount
                    )
                  : 0;
                this.customPortfolioBPercTotal += allocationTableIs
                  .customInvPortfolioB[0].allocationPercent
                  ? allocationTableIs.customInvPortfolioB[0].allocationPercent
                  : 0;
              }
            }
            if (allocationTableIs.systemGeneratedPortfolioB.length > 0) {
              // var allocationAmtB = allocationTableIs.InvEditedPortfolioB[0]
              //   .allocationamount_input
              //   ? allocationTableIs.InvEditedPortfolioB[0].allocationamount_input.replace(
              //       /,/g,
              //       ""
              //     )
              //   : "";
              // allocationTableIs.InvEditedPortfolioB[0].allocationamount =
              //   allocationAmtB == "" ? "" : parseInt(allocationAmtB);
              this.systemportfoliob = true;
              this.portfoliobTotal += allocationTableIs
                .systemGeneratedPortfolioB[0].allocationamount
                ? parseInt(
                    allocationTableIs.systemGeneratedPortfolioB[0]
                      .allocationamount
                  )
                : 0;

              this.portfoliobPercTotal +=
                allocationTableIs.systemGeneratedPortfolioB[0].allocationPercent;
              console.log(this.portfoliobPercTotal, "this.portfoliobPercTotal");
              if (
                allocationTableIs.systemGeneratedPortfolioB[0]
                  .allocation_type == "portfolioB"
              ) {
                this.editEnablePortfolioB = true;
                // console.log(this.editEnablePortfolioB, "bb1");
                // allocationTableIs.InvEditedPortfolioA.push(this.addPortfolioB);
              }
            } else {
              this.editEnablePortfolioB = false;
            }

            if (allocationTableIs.InvEditedPortfolioB.length > 0) {
              if (
                allocationTableIs.InvEditedPortfolioB[0].allocationamount > 0
              ) {
                this.portfoliob = true;
                this.invPortfolioBTotal += allocationTableIs
                  .InvEditedPortfolioB[0].allocationamount
                  ? parseInt(
                      allocationTableIs.InvEditedPortfolioB[0].allocationamount
                    )
                  : 0;
                this.invPortfolioBPercTotal += allocationTableIs
                  .InvEditedPortfolioB[0].allocationPercent
                  ? allocationTableIs.InvEditedPortfolioB[0].allocationPercent
                  : 0;
                console.log(
                  this.invPortfolioBTotal,
                  this.invPortfolioBPercTotal,
                  "Total"
                );
              }
            }

            tempTableData.push(allocationTableIs);
            console.log(tempTableData, "tempTableData");
            this.app.getLoader(false);
          }

          this.tableData = tempTableData;

          console.log(this.tableData, " tableData");

          this.adjustTableColSize();
          // this.aa_data_modification(assetAllocationArray);
        } else {
          this.toastr.error("Error: " + response.message);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async get_aa_module_view_data() {
    this.app.getLoader(true);
    var sendObj = {
      instanceId: this.selectedprospect.instanceId,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getAssetAllocationDataForView(sendObj, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          //   this.toastr.success("Allocation created successfully");
          this.currentAaGeneratedIs = true;
          sessionStorage.setItem(
            "selctedPersonaObj",
            JSON.stringify(this.selectedprospect)
          );

          this.storedResAllocationData = response;
          this.rmGeneratedTotal = 0;
          this.rmgeneratedPercTotal = 0;
          this.customPortfolioBTotal = 0;
          this.customPortfolioBPercTotal = 0;
          this.invPortfolioBTotal = 0;
          this.invPortfolioBPercTotal = 0;
          this.invPortfolioATotal = 0;
          this.invPortfolioAPercTotal = 0;
          this.portfolioaTotal = 0;
          this.portfolioaPercTotal = 0;
          this.portfoliobPercTotal = 0;
          this.portfoliobTotal = 0;
          this.portfolioa = false;
          this.portfoliob = false;
          this.RMAllocation = false;
          this.invGenerated = false;
          // if (this.storedResAllocationData.rmGenerated.length > 0) {
          //   if (
          //     this.storedResAllocationData.rm_status == "allocation created"
          //   ) {
          //     this.selectedprospect.rm_status = "rmEdited";
          //     sessionStorage.setItem(
          //       "selctedPersonaObj",
          //       JSON.stringify(this.selectedprospect)
          //     );
          //   }
          // }
          this.capitalgrowth = this.storedResAllocationData.systemGeneratedPortfolioA[0].no_of_cg_deals;
          this.capitalyield = this.storedResAllocationData.systemGeneratedPortfolioA[0].no_of_cy_deals;
          this.WithdrawNeedsIsChecked = this.storedResAllocationData.liquidity_needs;
          this.tableData = [];
          var editableTableData = response.data;
          var tempTableData = [];

          for (let index = 0; index < editableTableData.length; index++) {
            var allocationTableIs = editableTableData[index];

            var addPortfolioA = {
              allocationamount_input: "",
              allocationamount: 0,
              allocationPercent: 0,
              allocation_type: "portfolioA",
              asset_class_type: "",
              generated_type: "investment",
              spv: "",
              strategy: "",
              target_return: 0,
              target_yield: 0,
            };

            if (allocationTableIs.InvEditedPortfolioA.length === 0) {
              allocationTableIs.InvEditedPortfolioA.push(addPortfolioA);
            } else {
              this.portfolioa = true;
              console.log(this.portfolioa, "port33folioa");
              var allocationamount_input = allocationTableIs.InvEditedPortfolioA[0].allocationamount.toString();

              allocationTableIs.InvEditedPortfolioA[0].allocationamount_input = allocationamount_input;
            }

            var addPortfolioB = {
              allocationamount_input: "",
              allocationamount: 0,
              allocationPercent: 0,
              allocation_type: "portfolioB",
              asset_class_type: "",
              generated_type: "investment",
              spv: "",
              strategy: "",
              target_return: 0.0225,
              target_yield: 0,
            };
            if (allocationTableIs.InvEditedPortfolioB.length === 0) {
              allocationTableIs.InvEditedPortfolioB.push(addPortfolioB);
            } else {
              this.portfoliob == true;

              var allocationamount_input = allocationTableIs.InvEditedPortfolioB[0].allocationamount.toString();
              allocationTableIs.InvEditedPortfolioB[0].allocationamount_input = allocationamount_input;
            }

            var addPortfolioRm = {
              allocationamount_input: "",
              allocationamount: 0,
              allocationPercent: 0,
              allocation_type: "rmGenerated",
              asset_class_type: "",
              generated_type: "rm",
              spv: "",
              strategy: "",
              target_return: 0,
              target_yield: 0,
            };
            if (allocationTableIs.rmGenerated.length == 0) {
              allocationTableIs.rmGenerated.push(addPortfolioRm);
            } else {
              this.RMAllocation == true;
              var allocationamount_input = allocationTableIs.rmGenerated[0].allocationamount.toString();
              allocationTableIs.rmGenerated[0].allocationamount_input = allocationamount_input;
            }

            var customBPortfolio = {
              allocationamount_input: "",
              allocationamount: 0,
              allocationPercent: 0,
              allocation_type: "customInvPortfolioB",
              asset_class_type: "",
              generated_type: "investment",
              spv: "",
              strategy: "",
              target_return: 0,
              target_yield: 0,
            };
            if (allocationTableIs.customInvPortfolioB.length == 0) {
              allocationTableIs.customInvPortfolioB.push(customBPortfolio);
            } else {
              this.invGenerated == true;
              var allocationamount_input = allocationTableIs.customInvPortfolioB[0].allocationamount.toString();
              allocationTableIs.customInvPortfolioB[0].allocationamount_input = allocationamount_input;
            }

            // var allocationAmtA = allocationTableIs.InvEditedPortfolioA[0]
            //   .allocationamount_input
            //   ? allocationTableIs.InvEditedPortfolioA[0].allocationamount_input.replace(
            //       /,/g,
            //       ""
            //     )
            //   : "";

            // allocationTableIs.InvEditedPortfolioA[0].allocationamount =
            //   allocationAmtA == "" ? "" : parseInt(allocationAmtA);

            // console.log(
            //   allocationTableIs.InvEditedPortfolioA[0].allocationamount
            // );

            if (allocationTableIs.systemGeneratedPortfolioA.length > 0) {
              this.portfolioaTotal += allocationTableIs
                .systemGeneratedPortfolioA[0].allocationamount
                ? parseInt(
                    allocationTableIs.systemGeneratedPortfolioA[0]
                      .allocationamount
                  )
                : 0;
              this.portfolioaPercTotal += allocationTableIs
                .systemGeneratedPortfolioA[0].allocationPercent
                ? allocationTableIs.systemGeneratedPortfolioA[0]
                    .allocationPercent
                : 0;
              this.editEnablePortfolioA = true;

              this.systemgenerated = true;
            } else {
              this.editEnablePortfolioA = false;
            }
            if (allocationTableIs.InvEditedPortfolioA.length > 0) {
              if (
                allocationTableIs.InvEditedPortfolioA[0].allocationamount > 0
              ) {
                this.invPortfolioATotal += allocationTableIs
                  .InvEditedPortfolioA[0].allocationamount
                  ? parseInt(
                      allocationTableIs.InvEditedPortfolioA[0].allocationamount
                    )
                  : 0;
                this.invPortfolioAPercTotal += allocationTableIs
                  .InvEditedPortfolioA[0].allocationPercent
                  ? allocationTableIs.InvEditedPortfolioA[0].allocationPercent
                  : 0;

                this.portfolioa = true;
              }
            }

            if (allocationTableIs.rmGenerated.length > 0) {
              if (allocationTableIs.rmGenerated[0].allocationamount > 0) {
                this.RMAllocation = true;
                this.rmGeneratedTotal += allocationTableIs.rmGenerated[0]
                  .allocationamount
                  ? parseInt(allocationTableIs.rmGenerated[0].allocationamount)
                  : 0;
                this.rmgeneratedPercTotal += allocationTableIs.rmGenerated[0]
                  .allocationPercent
                  ? allocationTableIs.rmGenerated[0].allocationPercent
                  : 0;
              }
            }

            if (allocationTableIs.customInvPortfolioB.length > 0) {
              if (
                allocationTableIs.customInvPortfolioB[0].allocationamount > 0
              ) {
                this.invGenerated = true;

                this.customPortfolioBTotal += allocationTableIs
                  .customInvPortfolioB[0].allocationamount
                  ? parseInt(
                      allocationTableIs.customInvPortfolioB[0].allocationamount
                    )
                  : 0;
                this.customPortfolioBPercTotal += allocationTableIs
                  .customInvPortfolioB[0].allocationPercent
                  ? allocationTableIs.customInvPortfolioB[0].allocationPercent
                  : 0;
              }
            }
            if (allocationTableIs.systemGeneratedPortfolioB.length > 0) {
              // var allocationAmtB = allocationTableIs.InvEditedPortfolioB[0]
              //   .allocationamount_input
              //   ? allocationTableIs.InvEditedPortfolioB[0].allocationamount_input.replace(
              //       /,/g,
              //       ""
              //     )
              //   : "";
              // allocationTableIs.InvEditedPortfolioB[0].allocationamount =
              //   allocationAmtB == "" ? "" : parseInt(allocationAmtB);
              this.systemportfoliob = true;
              this.portfoliobTotal += allocationTableIs
                .systemGeneratedPortfolioB[0].allocationamount
                ? parseInt(
                    allocationTableIs.systemGeneratedPortfolioB[0]
                      .allocationamount
                  )
                : 0;

              this.portfoliobPercTotal +=
                allocationTableIs.systemGeneratedPortfolioB[0].allocationPercent;
              console.log(this.portfoliobPercTotal, "this.portfoliobPercTotal");
              if (
                allocationTableIs.systemGeneratedPortfolioB[0]
                  .allocation_type == "portfolioB"
              ) {
                this.editEnablePortfolioB = true;
                // console.log(this.editEnablePortfolioB, "bb1");
                // allocationTableIs.InvEditedPortfolioA.push(this.addPortfolioB);
              }
            } else {
              this.editEnablePortfolioB = false;
            }

            if (allocationTableIs.InvEditedPortfolioB.length > 0) {
              if (
                allocationTableIs.InvEditedPortfolioB[0].allocationamount > 0
              ) {
                this.portfoliob = true;
                this.invPortfolioBTotal += allocationTableIs
                  .InvEditedPortfolioB[0].allocationamount
                  ? parseInt(
                      allocationTableIs.InvEditedPortfolioB[0].allocationamount
                    )
                  : 0;
                this.invPortfolioBPercTotal += allocationTableIs
                  .InvEditedPortfolioB[0].allocationPercent
                  ? allocationTableIs.InvEditedPortfolioB[0].allocationPercent
                  : 0;
                console.log(
                  this.invPortfolioBTotal,
                  this.invPortfolioBPercTotal,
                  "Total"
                );
              }
            }

            tempTableData.push(allocationTableIs);
            console.log(tempTableData, "tempTableData");
            this.app.getLoader(false);
          }
          this.tableData = tempTableData;
          this.adjustTableColSize();

          console.log(this.tableData, " tableData");
        } else {
          this.toastr.error("Error: " + response.message);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  openApproveConfirmationModal(template: TemplateRef<any>) {
    this.INVApprovalModalRef = this.modalService.show(template);
  }
  deckAcceptmodel: boolean = false;
  async acceptdeck(item) {
    if (item == "Yes") {
      this.app.getLoader(true);
      var sendObj = {
        prospect_id: this.selectedprospect.prospect_id,
        status: "sent_to_inv",
        inv_status: "pending",
        comments: "",
        instanceId: this.selectedprospect.instanceId,
        type: this.selectedprospect.type,
      };
      var headerObj = await this.app.getHTTPHeaders();
      this.http.updateStatus(sendObj, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            this.deckAcceptmodel = false;
            this.toastr.success("Submited For Investment Team's Approval");
            setTimeout(
              function () {
                this.app.navigateThis("home/dashboard");
                this.app.getLoader(false);
              }.bind(this),
              1000
            );
          } else {
            this.toastr.error("Error: " + response.msg);
            this.app.getLoader(false);
          }
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    } else {
      this.deckAcceptmodel = true;
    }
  }

  async submitInvDeckStatus(callback) {
    var sendData = {
      prospect_id: this.selectedprospect.prospect_id,
      status: callback,
      inv_status: callback,
      comments: this.rejectComment,
      name: this.userInfo.name,
      instanceId: this.selectedprospect.instanceId,
      type: this.selectedprospect.type,
    };

    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.updateStatus(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          //var msg = item == 'Yes' ? 'Approved' : 'Rejected';
          setTimeout(
            function () {
              this.app.navigateThis("home/dashboard");
            }.bind(this),
            1000
          );
        } else {
          this.toastr.error("Something went wrong!");
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  risk1_1Valid = false;
  risk1_2Valid = false;
  risk2_1Valid = false;
  risk2_2Valid = false;
  risk3_1Valid = false;
  risk3_2Valid = false;
  risk4_1Valid = false;
  risk4_2Valid = false;
  risk4_3Valid = false;
  risk5_1Valid = false;
  risk5_2Valid = false;
  risk5_3Valid = false;
  responsive1Valid = false;
  responsive2Valid = false;
  responsive3_1Valid = false;
  responsive3_2Valid = false;
  responsive4_1Valid = false;
  responsive4_2Valid = false;
  riskContradict() {
    if (
      Math.abs(
        this.questionsArray.que14.riskProfile2.value -
          this.questionsArray.que14.riskProfile1.value
      ) > 1
    ) {
      this.risk1_1Valid = true;
    } else {
      this.risk1_1Valid = false;
    }

    if (
      Math.abs(
        this.questionsArray.que14.riskProfile3.value -
          this.questionsArray.que14.riskProfile1.value
      ) > 1
    ) {
      this.risk1_2Valid = true;
    } else {
      this.risk1_2Valid = false;
    }

    if (
      Math.abs(
        this.questionsArray.que14.riskProfile3.value -
          this.questionsArray.que14.riskProfile2.value
      ) > 1
    ) {
      this.risk2_1Valid = true;
    } else {
      this.risk2_1Valid = false;
    }
    if (
      Math.abs(
        this.questionsArray.que14.riskProfile1.value -
          this.questionsArray.que14.riskProfile2.value
      ) > 1
    ) {
      this.risk2_2Valid = true;
    } else {
      this.risk2_2Valid = false;
    }

    if (
      Math.abs(
        this.questionsArray.que14.riskProfile1.value -
          this.questionsArray.que14.riskProfile3.value
      ) > 1
    ) {
      this.risk3_1Valid = true;
    } else {
      this.risk3_1Valid = false;
    }
    if (
      Math.abs(
        this.questionsArray.que14.riskProfile2.value -
          this.questionsArray.que14.riskProfile3.value
      ) > 1
    ) {
      this.risk3_2Valid = true;
    } else {
      this.risk3_2Valid = false;
      this.risk2_1Valid = false;
    }

    if (
      Math.abs(
        this.questionsArray.que14.riskProfile1.value -
          this.questionsArray.que14.riskProfile4.value
      ) > 1
    ) {
      this.risk4_1Valid = true;
    } else {
      this.risk4_1Valid = false;
    }
    if (
      Math.abs(
        this.questionsArray.que14.riskProfile2.value -
          this.questionsArray.que14.riskProfile4.value
      ) > 1
    ) {
      this.risk4_2Valid = true;
    } else {
      this.risk4_2Valid = false;
    }
    if (
      Math.abs(
        this.questionsArray.que14.riskProfile3.value -
          this.questionsArray.que14.riskProfile4.value
      ) > 1
    ) {
      this.risk4_3Valid = true;
    } else {
      this.risk4_3Valid = false;
    }

    if (
      Math.abs(
        this.questionsArray.que14.riskProfile1.value -
          this.questionsArray.que14.riskProfile5.value
      ) > 1
    ) {
      this.risk5_1Valid = true;
    } else {
      this.risk5_1Valid = false;
    }
    if (
      Math.abs(
        this.questionsArray.que14.riskProfile2.value -
          this.questionsArray.que14.riskProfile5.value
      ) > 1
    ) {
      this.risk5_2Valid = true;
    } else {
      this.risk5_2Valid = false;
    }
    if (
      Math.abs(
        this.questionsArray.que14.riskProfile3.value -
          this.questionsArray.que14.riskProfile5.value
      ) > 1
    ) {
      this.risk5_3Valid = true;
    } else {
      this.risk5_3Valid = false;
    }

    if (
      Math.abs(
        this.questionsArray.que14.responsiveness2.value -
          this.questionsArray.que14.responsiveness1.value
      ) > 1
    ) {
      this.responsive1Valid = true;
    } else {
      this.responsive1Valid = false;
    }

    if (
      Math.abs(
        this.questionsArray.que14.responsiveness1.value -
          this.questionsArray.que14.responsiveness2.value
      ) > 1
    ) {
      this.responsive2Valid = true;
    } else {
      this.responsive2Valid = false;
    }

    if (
      Math.abs(
        this.questionsArray.que14.responsiveness1.value -
          this.questionsArray.que14.responsiveness3.value
      ) > 1
    ) {
      this.responsive3_1Valid = true;
    } else {
      this.responsive3_1Valid = false;
    }
    if (
      Math.abs(
        this.questionsArray.que14.responsiveness3.value -
          this.questionsArray.que14.responsiveness2.value
      ) > 1
    ) {
      this.responsive3_2Valid = true;
    } else {
      this.responsive3_2Valid = false;
    }

    if (
      Math.abs(
        this.questionsArray.que14.responsiveness1.value -
          this.questionsArray.que14.responsiveness4.value
      ) > 1
    ) {
      this.responsive4_1Valid = true;
    } else {
      this.responsive4_1Valid = false;
    }
    if (
      Math.abs(
        this.questionsArray.que14.responsiveness4.value -
          this.questionsArray.que14.responsiveness2.value
      ) > 1
    ) {
      this.responsive4_2Valid = true;
    } else {
      this.responsive4_2Valid = false;
    }
  }

  ngOnDestroy() {
    if (this.opportunisticConfirmationModalRef) {
      this.opportunisticConfirmationModalRef.hide();
    }
    if (this.warningFlagsModalRef) {
      this.warningFlagsModalRef.hide();
    }
    if (this.InsertliquidityModalRef) {
      this.InsertliquidityModalRef.hide();
    }
    if (this.justificationModalRef) {
      this.justificationModalRef.hide();
    }
    this.toastr.clear();
  }

  //Liquidity / Withdrawal needs
  WithdrawNeedsIs() {
    console.log("sss");
    if (this.selectedprospect.inv_status == "pending") {
      console.log("eee");
      if (this.questionsArray.que6.liquidity == "Yes") {
        console.log("dd");
        this.openLiqModal(this.templateref3);
      } else {
        this.sendOpportunisticData();
      }
      //this.questionnaireModal.show();
    }
  }

  editAllocation(item) {
    // this.sendOpportunisticData();
    if (item == "edit") {
      this.portfolioa = true;
      if (this.systemportfoliob == true) {
        this.portfoliob = true;
      }
    } else {
      this.invGenerated = true;
    }
    this.allocationModify = false;
    this.edit = true;
    this.adjustTableColSize();
  }
  cancelAllocation() {
    this.get_aa_module_view_data();
    this.RMallocationModify = true;
    this.allocationModify = true;
    this.RMAllocation = false;
    this.edit = false;
    this.adjustTableColSize();
  }
  editRMAllocation() {
    this.RMAllocation = true;
    this.RMallocationModify = false;
    this.adjustTableColSize();
    this.edit = true;
  }

  async saveAllocation(modal) {
    this.allocationModify = true;
    this.edit = false;
    this.RMallocationModify = true;
    this.totalINVPercA = 0;
    this.totalINVPercB = 0;
    this.totalRMPerc = 0;
    this.totalCustomBPerc = 0;
    // console.log(this.allocationModify);
    var sendObj: any = {};
    var summData = JSON.parse(sessionStorage.getItem("investSummData"));
    var updatedAllocationData = this.tableData;
    var finalAllocationData = this.convertDataBeforeSave(updatedAllocationData);
    var shariaIs;
    console.log(finalAllocationData, "finalAllocationData");
    var getInvestSummData = summData.data;
    var ans_one = 0;
    var qstNoTwo_ans = {
      immediately: 0,
      year1: 0,
      year2: 0,
      above2Years: 0,
    };
    for (let e = 0; e < finalAllocationData.length; e++) {
      if (finalAllocationData[e].InvEditedPortfolioA.length > 0) {
        this.totalINVPercA +=
          finalAllocationData[e].InvEditedPortfolioA[0].allocationamount;
      }
      if (finalAllocationData[e].InvEditedPortfolioB.length > 0) {
        this.totalINVPercB +=
          finalAllocationData[e].InvEditedPortfolioB[0].allocationamount;
      }
      if (finalAllocationData[e].rmGenerated.length > 0) {
        this.totalRMPerc +=
          finalAllocationData[e].rmGenerated[0].allocationamount;
      }
      if (finalAllocationData[e].customInvPortfolioB.length > 0) {
        this.totalCustomBPerc +=
          finalAllocationData[e].customInvPortfolioB[0].allocationamount;
      }
    }
    for (let d = 0; d < getInvestSummData.length; d++) {
      var data_obj = getInvestSummData[d];

      if (data_obj.question_no == 2) {
        var qTwo_ans = JSON.parse(data_obj.answer)[0];
        qstNoTwo_ans.immediately = parseInt(qTwo_ans.immediately);
      }
      if (data_obj.question_no == 9) {
        var liquidityNeeds_obj = JSON.parse(data_obj.answer)[0];
        var liquidityIs = liquidityNeeds_obj.liquidity;
      }
      if (data_obj.question_no == 6) {
        if (data_obj.answer == "Shari'ah") {
          shariaIs = "yes";
        } else {
          shariaIs = "no";
        }
      }
    }

    console.log(this.WithdrawNeedsIsChecked, "checked");
    if (liquidityIs == "No") {
      var IsLiqudity = "No";
    } else if (liquidityIs == "Yes" && this.WithdrawNeedsIsChecked == false) {
      IsLiqudity = "No";
    } else {
      IsLiqudity = "Yes";
    }

    console.log(this.totalINVPercA, "totalINVPercA");
    console.log(this.totalCustomBPerc, "totalCustomBPerc");
    console.log(this.totalRMPerc, "totalCu4");
    if (
      this.totalINVPercA > this.portfolioaTotal ||
      this.totalINVPercB > this.portfolioaTotal ||
      this.totalRMPerc > this.portfolioaTotal ||
      this.totalCustomBPerc > this.portfolioaTotal
    ) {
      this.isView = "Alert";
      this.openModal(modal);
      if (this.userInfo.role == "INV") {
        this.allocationModify = false;
        this.edit = true;
      } else {
        this.RMallocationModify = false;
        this.edit = true;
      }
    } else if (
      (this.totalINVPercA != this.portfolioaTotal && this.totalINVPercA != 0) ||
      (this.totalINVPercB != this.portfolioaTotal && this.totalINVPercB != 0) ||
      (this.totalRMPerc != this.portfolioaTotal && this.totalRMPerc != 0) ||
      (this.totalCustomBPerc != this.portfolioaTotal &&
        this.totalCustomBPerc != 0)
    ) {
      this.isView = "Confirmation";
      if (this.userInfo.role == "INV") {
        this.allocationModify = false;
        this.edit = true;
      } else {
        this.RMallocationModify = false;
        this.edit = true;
      }
      this.openModal(modal);
    } else {
      finalAllocationData = this.convertData(updatedAllocationData);
      sendObj = {
        data: finalAllocationData,
        immediateFunding: qstNoTwo_ans.immediately,
        rm_status: this.storedResAllocationData.rm_status,
        inv_status: this.storedResAllocationData.inv_status,
        shariah: shariaIs,
        no_of_cg_deals: this.storedResAllocationData
          .systemGeneratedPortfolioA[0].no_of_cg_deals,
        no_of_cy_deals: this.storedResAllocationData
          .systemGeneratedPortfolioA[0].no_of_cy_deals,
        isLiquidityNeed: IsLiqudity,
        liquidityNeeds: [
          {
            year: 1,
            fund: this.liquidOptionWithdrawal.year1,
            amount: this.questionsArray.que6.year1
              ? this.questionsArray.que6.year1
              : 0,
            perc: this.questionsArray.que6.year1
              ? this.questionsArray.que6.year1 / qstNoTwo_ans.immediately
              : 0,
          },
          {
            year: 2,
            fund: this.liquidOptionWithdrawal.year2,
            amount: this.questionsArray.que6.year2
              ? this.questionsArray.que6.year2
              : 0,
            perc: this.questionsArray.que6.year2
              ? this.questionsArray.que6.year2 / qstNoTwo_ans.immediately
              : 0,
          },
          {
            year: 3,
            fund: this.liquidOptionWithdrawal.year3,
            amount: this.questionsArray.que6.year3
              ? this.questionsArray.que6.year3
              : 0,
            perc: this.questionsArray.que6.year3
              ? this.questionsArray.que6.year3 / qstNoTwo_ans.immediately
              : 0,
          },
        ],
        instanceId: this.selectedprospect.instanceId,

        // "regenerate": regenerateIs
      };

      this.app.getLoader(true);
      var headerObj = await this.app.getHTTPHeaders();
      this.http.editAllocationProspect(sendObj, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            this.rmGeneratedTotal = 0;
            this.rmgeneratedPercTotal = 0;
            this.customPortfolioBTotal = 0;
            this.customPortfolioBPercTotal = 0;
            this.invPortfolioBTotal = 0;
            this.invPortfolioBPercTotal = 0;
            this.invPortfolioATotal = 0;
            this.invPortfolioAPercTotal = 0;
            this.portfolioaTotal = 0;
            this.portfolioaPercTotal = 0;
            this.portfoliobPercTotal = 0;
            this.portfoliobTotal = 0;

            this.currentAaGeneratedIs = true;

            this.storedResAllocationData = response;

            this.selectedprospect.inv_status = this.storedResAllocationData.inv_status;
            this.toastr.success("Allocation Edited successfully");
            sessionStorage.setItem(
              "selctedPersonaObj",
              JSON.stringify(this.selectedprospect)
            );
            this.get_aa_module_view_data();
            // if (this.storedResAllocationData.rmGenerated.length > 0) {
            //   if (
            //     this.storedResAllocationData.rm_status == "allocation created"
            //   ) {
            //     this.selectedprospect.rm_status = "rmEdited";
            //     sessionStorage.setItem(
            //       "selctedPersonaObj",
            //       JSON.stringify(this.selectedprospect)
            //     );
            //   }
            // }

            this.tableData = [];
            var editableTableData = response.data;
            var tempTableData = [];

            for (let index = 0; index < editableTableData.length; index++) {
              var allocationTableIs = editableTableData[index];

              var addPortfolioA = {
                allocationamount_input: "",
                allocationamount: 0,
                allocationPercent: 0,
                allocation_type: "portfolioA",
                asset_class_type: "",
                generated_type: "investment",
                spv: "",
                strategy: "",
                target_return: 0,
                target_yield: 0,
              };

              if (allocationTableIs.InvEditedPortfolioA.length === 0) {
                allocationTableIs.InvEditedPortfolioA.push(addPortfolioA);
              } else {
                this.portfolioa = true;
                console.log(this.portfolioa, "port33folioa");
                var allocationamount_input = allocationTableIs.InvEditedPortfolioA[0].allocationamount.toString();

                allocationTableIs.InvEditedPortfolioA[0].allocationamount_input = allocationamount_input;
              }

              var addPortfolioB = {
                allocationamount_input: "",
                allocationamount: 0,
                allocationPercent: 0,
                allocation_type: "portfolioB",
                asset_class_type: "",
                generated_type: "investment",
                spv: "",
                strategy: "",
                target_return: 0.0225,
                target_yield: 0,
              };
              if (allocationTableIs.InvEditedPortfolioB.length === 0) {
                allocationTableIs.InvEditedPortfolioB.push(addPortfolioB);
              } else {
                this.portfoliob == true;

                var allocationamount_input = allocationTableIs.InvEditedPortfolioB[0].allocationamount.toString();
                allocationTableIs.InvEditedPortfolioB[0].allocationamount_input = allocationamount_input;
              }

              var addPortfolioRm = {
                allocationamount_input: "",
                allocationamount: 0,
                allocationPercent: 0,
                allocation_type: "rmGenerated",
                asset_class_type: "",
                generated_type: "rm",
                spv: "",
                strategy: "",
                target_return: 0,
                target_yield: 0,
              };
              if (allocationTableIs.rmGenerated.length == 0) {
                allocationTableIs.rmGenerated.push(addPortfolioRm);
              } else {
                this.RMAllocation == true;
                var allocationamount_input = allocationTableIs.rmGenerated[0].allocationamount.toString();
                allocationTableIs.rmGenerated[0].allocationamount_input = allocationamount_input;
              }

              var customBPortfolio = {
                allocationamount_input: "",
                allocationamount: 0,
                allocationPercent: 0,
                allocation_type: "customInvPortfolioB",
                asset_class_type: "",
                generated_type: "investment",
                spv: "",
                strategy: "",
                target_return: 0,
                target_yield: 0,
              };
              if (allocationTableIs.customInvPortfolioB.length == 0) {
                allocationTableIs.customInvPortfolioB.push(customBPortfolio);
              } else {
                this.invGenerated == true;
                var allocationamount_input = allocationTableIs.customInvPortfolioB[0].allocationamount.toString();
                allocationTableIs.customInvPortfolioB[0].allocationamount_input = allocationamount_input;
              }

              if (allocationTableIs.systemGeneratedPortfolioA.length > 0) {
                this.portfolioaTotal += allocationTableIs
                  .systemGeneratedPortfolioA[0].allocationamount
                  ? parseInt(
                      allocationTableIs.systemGeneratedPortfolioA[0]
                        .allocationamount
                    )
                  : 0;
                this.portfolioaPercTotal += allocationTableIs
                  .systemGeneratedPortfolioA[0].allocationPercent
                  ? allocationTableIs.systemGeneratedPortfolioA[0]
                      .allocationPercent
                  : 0;
                this.editEnablePortfolioA = true;

                this.systemgenerated = true;
              } else {
                this.editEnablePortfolioA = false;
              }
              if (allocationTableIs.InvEditedPortfolioA.length > 0) {
                if (
                  allocationTableIs.InvEditedPortfolioA[0].allocationamount > 0
                ) {
                  this.invPortfolioATotal += allocationTableIs
                    .InvEditedPortfolioA[0].allocationamount
                    ? parseInt(
                        allocationTableIs.InvEditedPortfolioA[0]
                          .allocationamount
                      )
                    : 0;
                  this.invPortfolioAPercTotal += allocationTableIs
                    .InvEditedPortfolioA[0].allocationPercent
                    ? allocationTableIs.InvEditedPortfolioA[0].allocationPercent
                    : 0;

                  this.portfolioa = true;
                }
              }

              if (allocationTableIs.rmGenerated.length > 0) {
                if (allocationTableIs.rmGenerated[0].allocationamount > 0) {
                  sessionStorage.setItem(
                    "selctedPersonaObj",
                    JSON.stringify(this.selectedprospect)
                  );
                  this.rmGeneratedTotal += allocationTableIs.rmGenerated[0]
                    .allocationamount
                    ? parseInt(
                        allocationTableIs.rmGenerated[0].allocationamount
                      )
                    : 0;
                  this.rmgeneratedPercTotal += allocationTableIs.rmGenerated[0]
                    .allocationPercent
                    ? allocationTableIs.rmGenerated[0].allocationPercent
                    : 0;
                }
              }

              if (allocationTableIs.customInvPortfolioB.length > 0) {
                if (
                  allocationTableIs.customInvPortfolioB[0].allocationamount > 0
                ) {
                  this.invGenerated = true;

                  this.customPortfolioBTotal += allocationTableIs
                    .customInvPortfolioB[0].allocationamount
                    ? parseInt(
                        allocationTableIs.customInvPortfolioB[0]
                          .allocationamount
                      )
                    : 0;
                  this.customPortfolioBPercTotal += allocationTableIs
                    .customInvPortfolioB[0].allocationPercent
                    ? allocationTableIs.customInvPortfolioB[0].allocationPercent
                    : 0;
                }
              }
              if (allocationTableIs.systemGeneratedPortfolioB.length > 0) {
                // var allocationAmtB = allocationTableIs.InvEditedPortfolioB[0]
                //   .allocationamount_input
                //   ? allocationTableIs.InvEditedPortfolioB[0].allocationamount_input.replace(
                //       /,/g,
                //       ""
                //     )
                //   : "";
                // allocationTableIs.InvEditedPortfolioB[0].allocationamount =
                //   allocationAmtB == "" ? "" : parseInt(allocationAmtB);
                this.systemportfoliob = true;
                this.portfoliobTotal += allocationTableIs
                  .systemGeneratedPortfolioB[0].allocationamount
                  ? parseInt(
                      allocationTableIs.systemGeneratedPortfolioB[0]
                        .allocationamount
                    )
                  : 0;

                this.portfoliobPercTotal +=
                  allocationTableIs.systemGeneratedPortfolioB[0].allocationPercent;
                console.log(
                  this.portfoliobPercTotal,
                  "this.portfoliobPercTotal"
                );
                if (
                  allocationTableIs.systemGeneratedPortfolioB[0]
                    .allocation_type == "portfolioB"
                ) {
                  this.editEnablePortfolioB = true;
                  // console.log(this.editEnablePortfolioB, "bb1");
                  // allocationTableIs.InvEditedPortfolioA.push(this.addPortfolioB);
                }
              } else {
                this.editEnablePortfolioB = false;
              }

              if (allocationTableIs.InvEditedPortfolioB.length > 0) {
                if (
                  allocationTableIs.InvEditedPortfolioB[0].allocationamount > 0
                ) {
                  this.portfoliob = true;
                  this.invPortfolioBTotal += allocationTableIs
                    .InvEditedPortfolioB[0].allocationamount
                    ? parseInt(
                        allocationTableIs.InvEditedPortfolioB[0]
                          .allocationamount
                      )
                    : 0;
                  this.invPortfolioBPercTotal += allocationTableIs
                    .InvEditedPortfolioB[0].allocationPercent
                    ? allocationTableIs.InvEditedPortfolioB[0].allocationPercent
                    : 0;
                  console.log(
                    this.invPortfolioBTotal,
                    this.invPortfolioBPercTotal,
                    "Total"
                  );
                }
              }

              tempTableData.push(allocationTableIs);
              console.log(tempTableData, "tempTableData");
              this.app.getLoader(false);
            }
            this.tableData = tempTableData;

            console.log(this.tableData, " tableData");

            this.adjustTableColSize();
            // this.aa_data_modification(assetAllocationArray);
          } else {
            this.toastr.error("Error: " + response.message);
            this.app.getLoader(false);
          }
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    }
  }
  percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  async updateCYandCGdeals() {
    var summData = JSON.parse(sessionStorage.getItem("investSummData"));
    var getInvestSummData = summData.data;
    var shariaIs;
    var qstNoTwo_ans = {
      immediately: 0,
      year1: 0,
      year2: 0,
      above2Years: 0,
    };
    for (let d = 0; d < getInvestSummData.length; d++) {
      var data_obj = getInvestSummData[d];
      if (data_obj.question_no == 2) {
        var qTwo_ans = JSON.parse(data_obj.answer)[0];
        qstNoTwo_ans.immediately = parseInt(qTwo_ans.immediately);
      }
      if (data_obj.question_no == 9) {
        var liquidityNeeds_obj = JSON.parse(data_obj.answer)[0];
        var liquidityIs = liquidityNeeds_obj.liquidity;
      }
      if (data_obj.question_no == 6) {
        if (data_obj.answer == "Shari'ah") {
          shariaIs = "yes";
        } else {
          shariaIs = "no";
        }
      }
    }

    if (liquidityIs == "No") {
      var IsLiqudity = "No";
    } else if (liquidityIs == "Yes" && this.WithdrawNeedsIsChecked == false) {
      IsLiqudity = "No";
    } else {
      IsLiqudity = "Yes";
    }
    var sendData = {
      instanceId: this.selectedprospect.instanceId,
      cyDeals: parseInt(this.capitalyield),
      cgDeals: parseInt(this.capitalgrowth),
      isLiquidityNeed: IsLiqudity,
      sharia: shariaIs,
      liquidityNeeds: [
        {
          year: 1,
          fund: this.liquidOptionWithdrawal.year1,
          amount: this.questionsArray.que6.year1
            ? this.questionsArray.que6.year1
            : 0,
          perc: this.questionsArray.que6.year1
            ? this.questionsArray.que6.year1 / qstNoTwo_ans.immediately
            : 0,
        },
        {
          year: 2,
          fund: this.liquidOptionWithdrawal.year2,
          amount: this.questionsArray.que6.year2
            ? this.questionsArray.que6.year2
            : 0,
          perc: this.questionsArray.que6.year2
            ? this.questionsArray.que6.year2 / qstNoTwo_ans.immediately
            : 0,
        },
        {
          year: 3,
          fund: this.liquidOptionWithdrawal.year3,
          amount: this.questionsArray.que6.year3
            ? this.questionsArray.que6.year3
            : 0,
          perc: this.questionsArray.que6.year3
            ? this.questionsArray.que6.year3 / qstNoTwo_ans.immediately
            : 0,
        },
      ],
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.sendCGandCYdeals(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.toastr.success("Updated Deals Successfully");
          this.get_aa_module_view_data();
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  selectDealIS(selected, modal) {
    if (selected == this.ceotrigger && this.responsiveness == "low") {
      this.selectDeals = true;
      this.warning = true;
      this.confirm = false;
      this.openModal(modal);
      console.log(this.warning, "warning1");
      console.log(this.confirm, "confirm1");
    } else {
      this.selectDeals = false;
      this.openModal(modal);
      this.confirm = true;
      this.warning = false;
      console.log(this.warning, "warning2");
      console.log(this.confirm, "confirm2");
    }
    // console.log(selected);
    // console.log(this.selectDeals);
  }

  async sendEMailToCEO(type) {
    var sendData = {
      instanceId: this.selectedprospect.instanceId,
      rmJustification: this.rmJustification,
      prospectId: this.selectedprospect.prospect_id,
      type: type,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.sendEMailToCEO(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          if (type == "self_directed") {
            setTimeout(
              function () {
                this.app.navigateThis("home/dashboard");
              }.bind(this),
              1000
            );
          } else {
            this.toastr.success("Sent Successfully for CEO Approval");
            // this.submitInvDeckStatus("email_pending");
            this.justificationModalRef.hide();
            setTimeout(
              function () {
                this.app.navigateThis("home/dashboard");
              }.bind(this),
              1000
            );
          }
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  calcInvPorfolioPerc(item, allocationObj, temp) {
    var immediateAmt = parseInt(this.questionsArray.que2.immediately);
    if (item == "porfolioA") {
      // if (this.WithdrawNeedsIsChecked) {
      //   var immediateAmt = this.AAAmount * 2;
      // } else {
      //   immediateAmt = parseInt(this.questionsArray.que2.immediately);
      // }
      var portfolioAamt;
      var totlAmt = 0;
      if (allocationObj.InvEditedPortfolioA[0].allocationamount_input != "") {
        portfolioAamt = allocationObj.InvEditedPortfolioA[0].allocationamount_input.replace(
          /,/g,
          ""
        );
        portfolioAamt = parseInt(portfolioAamt);
        // totlAmt = portfolioAamt;
        // this.invPortfolioATotal = totlAmt + portfolioAamt;
        // console.log(this.invPortfolioATotal, "invPortfolioATotal2");
      }

      console.log(portfolioAamt, "portfolioAamt");
      var isPerc = portfolioAamt / immediateAmt;

      allocationObj.InvEditedPortfolioA[0].allocationPercent = isPerc
        ? isPerc
        : 0;
      // this.InvAtotalAmt +=
      //   allocationObj.InvEditedPortfolioA[0].allocationPercent;
      console.log(allocationObj.InvEditedPortfolioA[0].allocationPercent, "AA");
      if (allocationObj.InvEditedPortfolioA[0].allocationPercent > 1) {
        console.log("ggg");
        this.isView = "Alert";

        this.openModal(temp);
      }
    } else if (item == "porfolioB") {
      // if (this.WithdrawNeedsIsChecked) {
      //   var immediateAmt = this.AAAmount * 2;
      // } else {
      //   immediateAmt = parseInt(this.questionsArray.que2.immediately);
      // }
      var portfolioBamt;
      if (allocationObj.InvEditedPortfolioB[0].allocationamount_input != "") {
        portfolioBamt = allocationObj.InvEditedPortfolioB[0].allocationamount_input.replace(
          /,/g,
          ""
        );
        portfolioBamt = parseInt(portfolioBamt);
      }

      console.log(portfolioBamt, "portfolioBamt");
      var isPerc = portfolioBamt / immediateAmt;

      allocationObj.InvEditedPortfolioB[0].allocationPercent = isPerc
        ? isPerc
        : 0;
      // this.InvAtotalAmt +=
      //   allocationObj.InvEditedPortfolioA[0].allocationPercent;
      console.log(allocationObj.InvEditedPortfolioB[0].allocationPercent, "AA");
      if (allocationObj.InvEditedPortfolioB[0].allocationPercent > 1) {
        console.log("ggg");
        this.isView = "Alert";

        this.openModal(temp);
      }
    } else if (item == "rmGenerated") {
      // if (this.WithdrawNeedsIsChecked) {
      //   var immediateAmt = this.AAAmount * 2;
      // } else {
      //   immediateAmt = parseInt(this.questionsArray.que2.immediately);
      // }
      var rmGeneratedAmt;
      if (allocationObj.rmGenerated[0].allocationamount_input != "") {
        rmGeneratedAmt = allocationObj.rmGenerated[0].allocationamount_input.replace(
          /,/g,
          ""
        );
        rmGeneratedAmt = parseInt(rmGeneratedAmt);
      }

      console.log(rmGeneratedAmt, "rmGeneratedAmt");
      var isPerc = rmGeneratedAmt / immediateAmt;

      allocationObj.rmGenerated[0].allocationPercent = isPerc ? isPerc : 0;
      // this.InvAtotalAmt +=
      //   allocationObj.rmGenerated[0].allocationPercent;
      console.log(allocationObj.rmGenerated[0].allocationPercent, "AA");
      if (allocationObj.rmGenerated[0].allocationPercent > 1) {
        console.log("ggg");
        this.isView = "Alert";

        this.openModal(temp);
      }
    } else {
      // if (this.WithdrawNeedsIsChecked) {
      //   var immediateAmt = this.AAAmount * 2;
      // } else {
      //   immediateAmt = parseInt(this.questionsArray.que2.immediately);
      // }
      var customportfolioBamt;
      if (allocationObj.customInvPortfolioB[0].allocationamount_input != "") {
        customportfolioBamt = allocationObj.customInvPortfolioB[0].allocationamount_input.replace(
          /,/g,
          ""
        );
        customportfolioBamt = parseInt(customportfolioBamt);
      }

      console.log(customportfolioBamt, "customportfolioBamt");
      var isPerc = customportfolioBamt / immediateAmt;

      allocationObj.customInvPortfolioB[0].allocationPercent = isPerc
        ? isPerc
        : 0;
      // this.InvAtotalAmt +=
      //   allocationObj.customInvPortfolioB[0].allocationPercent;
      console.log(allocationObj.customInvPortfolioB[0].allocationPercent, "AA");
      if (allocationObj.customInvPortfolioB[0].allocationPercent > 1) {
        console.log("ggg");
        this.isView = "Alert";

        this.openModal(temp);
      }
    }
    return this.invPortfolioATotal;
  }
  calcInvPorfolioPerTotal(item, allocationObj, temp) {
    var immediateAmt = parseInt(this.questionsArray.que2.immediately);
    if (item == "porfolioA") {
      // if (this.WithdrawNeedsIsChecked) {
      //   var immediateAmt = this.AAAmount * 2;
      // } else {
      //   immediateAmt = parseInt(this.questionsArray.que2.immediately);
      // }
      var portfolioAamt;
      this.invPortfolioATotal = 0;
      this.invPortfolioAPercTotal = 0;
      this.tableData.forEach((element) => {
        if (element.InvEditedPortfolioA[0].allocationamount_input != "") {
          portfolioAamt = element.InvEditedPortfolioA[0].allocationamount_input.replace(
            /,/g,
            ""
          );

          portfolioAamt = parseInt(portfolioAamt);

          this.invPortfolioATotal += portfolioAamt;
          console.log(this.invPortfolioATotal, "invPortfolioATotal2");
          console.log(portfolioAamt, "portfolioAamt");
          var isPerc = portfolioAamt / immediateAmt;
          this.invPortfolioAPercTotal += isPerc;
        }
      });
    } else if (item == "porfolioB") {
      // if (this.WithdrawNeedsIsChecked) {
      //   var immediateAmt = this.AAAmount * 2;
      // } else {
      //   immediateAmt = parseInt(this.questionsArray.que2.immediately);
      // }
      var portfolioBamt;
      this.invPortfolioBTotal = 0;
      this.invPortfolioBPercTotal = 0;
      this.tableData.forEach((element1) => {
        if (element1.InvEditedPortfolioB[0].allocationamount_input != "") {
          portfolioBamt = element1.InvEditedPortfolioB[0].allocationamount_input.replace(
            /,/g,
            ""
          );
          portfolioBamt = parseInt(portfolioBamt);
          this.invPortfolioBTotal += portfolioBamt;
          console.log(portfolioBamt, "portfolioBamt");
          console.log(portfolioBamt, "portfolioBamt");
          var isPerc = portfolioBamt / immediateAmt;
          this.invPortfolioBPercTotal += isPerc;
        }
      });
    } else if (item == "rmGenerated") {
      // if (this.WithdrawNeedsIsChecked) {
      //   var immediateAmt = this.AAAmount * 2;
      // } else {
      //   immediateAmt = parseInt(this.questionsArray.que2.immediately);
      // }
      var rmGeneratedAmt;
      this.rmGeneratedTotal = 0;
      this.rmgeneratedPercTotal = 0;
      this.tableData.forEach((element) => {
        if (element.rmGenerated[0].allocationamount_input != "") {
          rmGeneratedAmt = element.rmGenerated[0].allocationamount_input.replace(
            /,/g,
            ""
          );
          rmGeneratedAmt = parseInt(rmGeneratedAmt);
          this.rmGeneratedTotal += rmGeneratedAmt;
          console.log(rmGeneratedAmt, "rmGeneratedAmt");
          var isPerc = rmGeneratedAmt / immediateAmt;
          this.rmgeneratedPercTotal += isPerc;
        }
      });
    } else {
      // if (this.WithdrawNeedsIsChecked) {
      //   var immediateAmt = this.AAAmount * 2;
      // } else {
      //   immediateAmt = parseInt(this.questionsArray.que2.immediately);
      // }
      var customportfolioBamt;
      this.customPortfolioBTotal = 0;
      this.customPortfolioBPercTotal = 0;
      this.tableData.forEach((element) => {
        if (element.customInvPortfolioB[0].allocationamount_input != "") {
          customportfolioBamt = element.customInvPortfolioB[0].allocationamount_input.replace(
            /,/g,
            ""
          );
          customportfolioBamt = parseInt(customportfolioBamt);
          this.customPortfolioBTotal += customportfolioBamt;
          console.log(customportfolioBamt, "customportfolioBamt");
          var isPerc = customportfolioBamt / immediateAmt;
          this.customPortfolioBPercTotal += isPerc;
        }
      });
    }
  }
  convertDataBeforeSave(convertedData) {
    var returnData = [];
    convertedData.forEach((mainObj) => {
      if (mainObj.InvEditedPortfolioA.length > 0) {
        if (
          typeof mainObj.InvEditedPortfolioA[0].allocationamount_input ===
            "string" &&
          mainObj.InvEditedPortfolioA[0].allocationamount_input.includes(",")
        ) {
          var allocationAmtA = mainObj.InvEditedPortfolioA[0].allocationamount_input.replace(
            /,/g,
            ""
          );
        } else {
          allocationAmtA =
            mainObj.InvEditedPortfolioA[0].allocationamount_input;
        }

        mainObj.InvEditedPortfolioA[0].allocationamount = allocationAmtA
          ? parseInt(allocationAmtA)
          : 0;
        mainObj.InvEditedPortfolioA[0].allocationPercent = mainObj
          .InvEditedPortfolioA[0].allocationPercent
          ? parseFloat(mainObj.InvEditedPortfolioA[0].allocationPercent)
          : 0;
      }

      if (mainObj.InvEditedPortfolioB.length > 0) {
        if (
          typeof mainObj.InvEditedPortfolioB[0].allocationamount_input ===
            "string" &&
          mainObj.InvEditedPortfolioB[0].allocationamount_input.includes(",")
        ) {
          var allocationAmtB = mainObj.InvEditedPortfolioB[0].allocationamount_input.replace(
            /,/g,
            ""
          );
        } else {
          allocationAmtB =
            mainObj.InvEditedPortfolioB[0].allocationamount_input;
        }

        mainObj.InvEditedPortfolioB[0].allocationamount = allocationAmtB
          ? parseInt(allocationAmtB)
          : 0;
        mainObj.InvEditedPortfolioB[0].allocationPercent = mainObj
          .InvEditedPortfolioB[0].allocationPercent
          ? parseFloat(mainObj.InvEditedPortfolioB[0].allocationPercent)
          : 0;
      }

      if (mainObj.rmGenerated.length > 0) {
        if (
          typeof mainObj.rmGenerated[0].allocationamount_input === "string" &&
          mainObj.rmGenerated[0].allocationamount_input.includes(",")
        ) {
          var rmAllocation = mainObj.rmGenerated[0].allocationamount_input.replace(
            /,/g,
            ""
          );
        } else {
          rmAllocation = mainObj.rmGenerated[0].allocationamount_input;
        }

        mainObj.rmGenerated[0].allocationamount = rmAllocation
          ? parseInt(rmAllocation)
          : 0;
        mainObj.rmGenerated[0].allocationPercent = mainObj.rmGenerated[0]
          .allocationPercent
          ? parseFloat(mainObj.rmGenerated[0].allocationPercent)
          : 0;
      }

      if (mainObj.customInvPortfolioB.length > 0) {
        if (
          typeof mainObj.customInvPortfolioB[0].allocationamount_input ===
            "string" &&
          mainObj.customInvPortfolioB[0].allocationamount_input.includes(",")
        ) {
          var customAllocation = mainObj.customInvPortfolioB[0].allocationamount_input.replace(
            /,/g,
            ""
          );
        } else {
          customAllocation =
            mainObj.customInvPortfolioB[0].allocationamount_input;
        }

        mainObj.customInvPortfolioB[0].allocationamount = customAllocation
          ? parseInt(customAllocation)
          : 0;
        mainObj.customInvPortfolioB[0].allocationPercent = mainObj
          .customInvPortfolioB[0].allocationPercent
          ? parseFloat(mainObj.customInvPortfolioB[0].allocationPercent)
          : 0;
      }
      returnData.push(mainObj);
    });
    return returnData;
  }

  convertData(convertedData) {
    var returnData = [];
    convertedData.forEach((mainObj) => {
      if (mainObj.InvEditedPortfolioA.length > 0) {
        if (
          typeof mainObj.InvEditedPortfolioA[0].allocationamount_input ===
            "string" &&
          mainObj.InvEditedPortfolioA[0].allocationamount_input.includes(",")
        ) {
          var allocationAmtA = mainObj.InvEditedPortfolioA[0].allocationamount_input.replace(
            /,/g,
            ""
          );
        } else {
          allocationAmtA =
            mainObj.InvEditedPortfolioA[0].allocationamount_input;
        }

        mainObj.InvEditedPortfolioA[0].allocationamount = allocationAmtA
          ? parseInt(allocationAmtA)
          : 0;
        mainObj.InvEditedPortfolioA[0].allocationPercent = mainObj
          .InvEditedPortfolioA[0].allocationPercent
          ? parseFloat(mainObj.InvEditedPortfolioA[0].allocationPercent)
          : 0;
      }

      if (mainObj.InvEditedPortfolioB.length > 0) {
        if (
          typeof mainObj.InvEditedPortfolioB[0].allocationamount_input ===
            "string" &&
          mainObj.InvEditedPortfolioB[0].allocationamount_input.includes(",")
        ) {
          var allocationAmtB = mainObj.InvEditedPortfolioB[0].allocationamount_input.replace(
            /,/g,
            ""
          );
        } else {
          allocationAmtB =
            mainObj.InvEditedPortfolioB[0].allocationamount_input;
        }

        mainObj.InvEditedPortfolioB[0].allocationamount = allocationAmtB
          ? parseInt(allocationAmtB)
          : 0;
        mainObj.InvEditedPortfolioB[0].allocationPercent = mainObj
          .InvEditedPortfolioB[0].allocationPercent
          ? parseFloat(mainObj.InvEditedPortfolioB[0].allocationPercent)
          : 0;
      }

      if (mainObj.rmGenerated.length > 0) {
        if (
          typeof mainObj.rmGenerated[0].allocationamount_input === "string" &&
          mainObj.rmGenerated[0].allocationamount_input.includes(",")
        ) {
          var rmAllocation = mainObj.rmGenerated[0].allocationamount_input.replace(
            /,/g,
            ""
          );
        } else {
          rmAllocation = mainObj.rmGenerated[0].allocationamount_input;
        }

        mainObj.rmGenerated[0].allocationamount = rmAllocation
          ? parseInt(rmAllocation)
          : 0;
        mainObj.rmGenerated[0].allocationPercent = mainObj.rmGenerated[0]
          .allocationPercent
          ? parseFloat(mainObj.rmGenerated[0].allocationPercent)
          : 0;
      }

      if (mainObj.customInvPortfolioB.length > 0) {
        if (
          typeof mainObj.customInvPortfolioB[0].allocationamount_input ===
            "string" &&
          mainObj.customInvPortfolioB[0].allocationamount_input.includes(",")
        ) {
          var customAllocation = mainObj.customInvPortfolioB[0].allocationamount_input.replace(
            /,/g,
            ""
          );
        } else {
          customAllocation =
            mainObj.customInvPortfolioB[0].allocationamount_input;
        }

        mainObj.customInvPortfolioB[0].allocationamount = customAllocation
          ? parseInt(customAllocation)
          : 0;
        mainObj.customInvPortfolioB[0].allocationPercent = mainObj
          .customInvPortfolioB[0].allocationPercent
          ? parseFloat(mainObj.customInvPortfolioB[0].allocationPercent)
          : 0;
      }

      for (var i = 0; i < mainObj.InvEditedPortfolioB.length; i++) {
        if (mainObj.InvEditedPortfolioB[i].allocationamount == 0) {
          mainObj.InvEditedPortfolioB.splice(i, 1);
        }
      }
      for (var i = 0; i < mainObj.InvEditedPortfolioA.length; i++) {
        if (mainObj.InvEditedPortfolioA[i].allocationamount == 0) {
          mainObj.InvEditedPortfolioA.splice(i, 1);
        }
      }
      for (var i = 0; i < mainObj.rmGenerated.length; i++) {
        if (mainObj.rmGenerated[i].allocationamount == 0) {
          mainObj.rmGenerated.splice(i, 1);
        }
      }
      for (var i = 0; i < mainObj.customInvPortfolioB.length; i++) {
        if (mainObj.customInvPortfolioB[i].allocationamount == 0) {
          mainObj.customInvPortfolioB.splice(i, 1);
        }
      }

      returnData.push(mainObj);
    });
    return returnData;
  }
  adjustTableColSize() {
    // if (this.userInfo.role == "RM") {
    //   if (
    //     this.systemgenerated &&
    //     this.portfolioa &&
    //     this.systemportfoliob &&
    //     this.portfoliob
    //   ) {
    //     this.systemgenerated = false;
    //     this.systemportfoliob = false;
    //   } else if (
    //     this.systemgenerated &&
    //     this.portfolioa &&
    //     this.systemportfoliob &&
    //     !this.portfoliob
    //   ) {
    //     this.systemgenerated = false;
    //     this.systemportfoliob = true;
    //   } else if (
    //     this.systemgenerated &&
    //     !this.portfolioa &&
    //     this.systemportfoliob &&
    //     this.portfoliob
    //   ) {
    //     this.systemgenerated = true;
    //     this.systemportfoliob = false;
    //   } else if (
    //     this.systemgenerated &&
    //     !this.portfolioa &&
    //     !this.systemportfoliob &&
    //     this.portfoliob
    //   ) {
    //     this.systemgenerated = true;
    //     this.systemportfoliob = false;
    //   }
    // }

    if (this.userInfo.role == "RM") {
      if (
        this.systemgenerated &&
        !this.portfolioa &&
        !this.systemportfoliob &&
        !this.portfoliob &&
        !this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = true;
        this.systemportfoliob = false;
      }
      if (
        this.systemgenerated &&
        !this.portfolioa &&
        this.systemportfoliob &&
        this.portfoliob &&
        !this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = true;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        !this.portfolioa &&
        this.systemportfoliob &&
        !this.portfoliob &&
        !this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = true;
        this.systemportfoliob = true;
      } else if (
        this.systemgenerated &&
        this.portfolioa &&
        !this.systemportfoliob &&
        !this.portfoliob &&
        !this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        this.portfolioa &&
        this.systemportfoliob &&
        !this.portfoliob &&
        !this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = true;
      } else if (
        this.systemgenerated &&
        !this.portfolioa &&
        this.systemportfoliob &&
        !this.portfoliob &&
        this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = true;
        this.systemportfoliob = true;
      } else if (
        !this.systemgenerated &&
        this.portfolioa &&
        !this.systemportfoliob &&
        this.portfoliob &&
        this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        !this.portfolioa &&
        !this.systemportfoliob &&
        !this.portfoliob &&
        this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = true;
        this.systemportfoliob = false;
      } else if (
        !this.systemgenerated &&
        this.portfolioa &&
        !this.systemportfoliob &&
        !this.portfoliob &&
        this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        !this.portfolioa &&
        !this.systemportfoliob &&
        !this.portfoliob &&
        this.RMAllocation &&
        this.invGenerated
      ) {
        this.systemgenerated = true;
        this.systemportfoliob = false;
      } else if (
        !this.systemgenerated &&
        this.portfolioa &&
        !this.systemportfoliob &&
        !this.portfoliob &&
        this.RMAllocation &&
        this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        this.portfolioa &&
        !this.systemportfoliob &&
        !this.portfoliob &&
        !this.RMAllocation &&
        this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        !this.portfolioa &&
        !this.systemportfoliob &&
        !this.portfoliob &&
        !this.RMAllocation &&
        this.invGenerated
      ) {
        this.systemgenerated = true;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        this.portfolioa &&
        !this.systemportfoliob &&
        !this.portfoliob &&
        this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        this.portfolioa &&
        this.systemportfoliob &&
        this.portfoliob &&
        !this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        this.portfolioa &&
        this.systemportfoliob &&
        this.portfoliob &&
        this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = false;
      } else if (
        this.systemgenerated &&
        this.portfolioa &&
        this.systemportfoliob &&
        !this.portfoliob &&
        this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = false;
        this.systemportfoliob = true;
      } else if (
        this.systemgenerated &&
        !this.portfolioa &&
        this.systemportfoliob &&
        this.portfoliob &&
        this.RMAllocation &&
        !this.invGenerated
      ) {
        this.systemgenerated = true;
        this.systemportfoliob = false;
      }
    }

    if (
      this.systemgenerated &&
      !this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      !this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-12";
      this.amoutRow = "col-8";
      this.boxSize = "col-6";
    } else if (
      this.systemgenerated &&
      !this.portfolioa &&
      this.systemportfoliob &&
      this.portfoliob &&
      !this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      this.systemgenerated &&
      !this.portfolioa &&
      this.systemportfoliob &&
      !this.portfoliob &&
      !this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      !this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      this.systemgenerated &&
      this.portfolioa &&
      this.systemportfoliob &&
      !this.portfoliob &&
      !this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      this.systemgenerated &&
      !this.portfolioa &&
      this.systemportfoliob &&
      !this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      this.systemgenerated &&
      !this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      this.systemgenerated &&
      !this.portfolioa &&
      !this.systemportfoliob &&
      this.portfoliob &&
      !this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      this.systemgenerated &&
      !this.portfolioa &&
      !this.systemportfoliob &&
      this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      this.systemgenerated &&
      !this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      this.RMAllocation &&
      this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      this.RMAllocation &&
      this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      !this.RMAllocation &&
      this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      this.systemgenerated &&
      !this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      !this.RMAllocation &&
      this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      this.portfoliob &&
      !this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      !this.RMAllocation &&
      this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      this.RMAllocation &&
      this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      !this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-12";
      this.amoutRow = "col-8";
      this.boxSize = "col-6";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      !this.systemportfoliob &&
      !this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      this.systemportfoliob &&
      !this.portfoliob &&
      !this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-4";
      this.amountCol = "col-6";
      this.amoutRow = "col-8";
      this.boxSize = "col-8";
    } else if (
      !this.systemgenerated &&
      this.portfolioa &&
      this.systemportfoliob &&
      !this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated
    ) {
      this.spvCol = "col-3";
      this.amountCol = "col-4";
      this.amoutRow = "col-9";
      this.boxSize = "col-10";
    } else if (
      this.systemgenerated &&
      this.portfolioa &&
      this.systemportfoliob &&
      this.portfoliob &&
      this.RMAllocation &&
      !this.invGenerated &&
      this.userInfo.role == "INV"
    ) {
      this.spvCol = "col-2";
      this.amountCol = "col-2";
      this.amountCol_rm = "col-4";
      this.amoutRow = "col-10";
      this.boxSize = "col-12";
    } else {
      this.spvCol = "col-2";
      this.amountCol = "col-3";
      this.amoutRow = "col-10";
      this.boxSize = "col-12";
    }
  }
}
