import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CommonservicesService } from "../commonservices.service";
import { AppComponent } from "../app.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: "app-investment-summary",
  templateUrl: "./investment-summary.component.html",
  styleUrls: ["./investment-summary.component.scss"]
})
export class InvestmentSummaryComponent implements OnInit {
  constructor(private modalService: BsModalService, private http: CommonservicesService, public app: AppComponent, private toastr: ToastrService) { }
  selectedprospect: any;
  userInfo: any = {};
  ngOnInit() {
    this.app.setAppTitle("Investment Summary");
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.selectedprospect = JSON.parse(sessionStorage.getItem("selctedPersonaObj"));
    setTimeout(function () {
    }.bind(this), 200);
  }
  ngOnDestroy() {
    /* if (this.modalRef) {
      this.modalRef.hide();
    } */
    this.toastr.clear();
  }
}

















