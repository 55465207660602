import { Component, OnInit } from '@angular/core';
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import domtoimage from 'dom-to-image';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-crr-image',
  templateUrl: './crr-image.component.html',
  styleUrls: ['./crr-image.component.scss']
})
export class CrrImageComponent implements OnInit {
  hideHtml = false;
  getSelectedClientObj: any = {};
  getLoginUserData: any = {};
  responseDataMainObj: any = {};
  constructor(public app: AppComponent, private toastr: ToastrService, private http: CommonservicesService) { }
  ngOnInit() {
    this.getSelectedClientObj = JSON.parse(
      sessionStorage.getItem("selectedClientObj")
    );
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
    setTimeout(() => {
      //this.getCrrImage();
    }, 100);
  }


  generateToDom(parentId, childId) {
    return new Promise(resolve => {
      domtoimage.toPng(document.getElementById(parentId)).then(function (dataUrl) {
        var img = new Image();
        img.id = childId;
        img.src = dataUrl;
        document.getElementById('image-view').appendChild(img);
        resolve(img);
      }).catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
    });
  }

  /* Generate Images */
  async generateImages() {
    var totalImages = 13;
    this.app.getLoader(true);
    document.getElementById('image-view').innerHTML = "";
    for (var i = 0; i < totalImages; i++) {
      var parentId = "imageGenerator" + i;
      var childId = "image" + i;
      const img = await this.generateToDom(parentId, childId);

      if (i == (totalImages - 1)) {
        this.app.getLoader(false);
        this.toastr.success("Image Generated");
        setTimeout(() => {
          this.getFiles();
        }, 200);

      }
    }
  }

  /* Convert DATA URl to File */
  fileToSave: File = null;
  convertToFile(dataUrl, index) {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var fileName = this.getSelectedClientObj.clientid + "_file_" + index + ".png";
    return new File([u8arr], fileName, { type: mime });
  }

  /* Upload File Into SP */
  async getFiles() {
    var formData = new FormData();
    var totalImages = 13;
    var fileToSave;
    for (var i = 0; i < totalImages; i++) {
      var childId = "image" + i;
      var isImage = document.getElementById(childId) as HTMLInputElement;
      fileToSave = await this.convertToFile(isImage.src, i);
      formData.append('File', fileToSave, fileToSave.name);
      if (i == (totalImages - 1)) {
        setTimeout(() => {
          this.uploadFiles(formData);
        }, 200);

      }
    }
  }

  async getFileFromUrl(url, name, defaultType = 'video/mp4') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  }

  async saveVideo() {
    var fileName = this.getSelectedClientObj.clientid + "_file.mp4";
    const file = await this.getFileFromUrl('https://synthesia-ttv-data.s3-eu-west-1.amazonaws.com/video_data/f32e3a06-6a96-48fe-9ab6-523a31b511aa/transfers/target_transfer.mp4', fileName);
    var formData = new FormData();
    formData.append('File', file);
    if (file) {
      this.uploadFiles(formData);
    }
  }

  async uploadFiles(formData) {
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.postFile(formData, headerObj).subscribe(response => {
      if (response.success) {
        this.toastr.success("File uploaded successfully");
      }
      this.app.getLoader(false);
    }, error => {
      console.log(error);
      this.app.getLoader(false);
    });
  }

  /* Get CRR Data */
  /* async getCrrImage() {
    this.app.getLoader(true);
    var sendData = {
      clientMasterId: this.getSelectedClientObj.clientMasterId,
      clientId: this.getSelectedClientObj.clientid,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getCrrImage(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          sessionStorage.setItem(
            "preSettingData",
            JSON.stringify(response.slide_data)
          );
          response.selectAllIs = false;
          this.responseDataMainObj = response.data;
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  } */


}
