import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormFieldIDService {

  constructor() { }

  /* Form Input ID's */
  getFormIdList() {
    var questinnaireForm = {
      basic: ["firstname", "lastname", "email", "gender"],
      questinnaireIndex: [
        {
          questionID: "investmentAmt",
          index: 0
        },
        {
          questionID: "immediateamt",
          index: 1
        },
        {
          questionID: "behalfInvest",
          index: 2
        },
        {
          questionID: "ethicalInvestment",
          index: 4
        },
        {
          questionID: "investComplaint",
          index: 5
        },
        {
          questionID: "regularIncome",
          index: 6
        },
        {
          questionID: "withdrawalNeeds",
          index: 7
        },
        {
          questionID: "withdrawalNeedsSchedular",
          index: 8
        },
        {
          questionID: "portfolioObjectivesID",
          index: 9
        }
      ],
      riskProfile: ["invesmentRisk", "higherReturn", "preparedInvestmentsRisk", "myWealthSignificant", "losingMoneyRisk"],
      responsiveness: ["buysell", "sustainedLoss", "frequentInvestment", "uneasyRisk"]
    }
    return questinnaireForm;
  }
}
