import { Component, OnInit, TemplateRef } from "@angular/core";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import * as credentialData from "credential.json";

@Component({
  selector: "app-deck-preview",
  templateUrl: "./deck-preview.component.html",
  styleUrls: ["./deck-preview.component.scss"],
})
export class DeckPreviewComponent implements OnInit {
  credDataObj: any = (credentialData as any).default;
  userInfo: any = {};
  selectedPersonaDtlsObj: any = {};
  footNoteObj: any;
  addFootNotesModalRef: any;
  INVApprovalModalRef: BsModalRef;
  slideTitleList: any = [];
  rejectComment: any = "";
  //deckPreviewUrl: string = "http://ams.ilmux.com/OLD/PptxGenJS_Demo_Node.pptx";
  deckPreviewUrl: string = "";
  constructor(
    public app: AppComponent,
    private http: CommonservicesService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    this.footNoteObj = {
      slide_no: "",
      footnotes: "",
      aaaClientProspectMasterId: "",
    };
  }
  regenerate_is: boolean = false;
  slctedPersonRole: string;
  ngOnInit() {
    this.app.setAppTitle("Deck Preview");
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.selectedPersonaDtlsObj = JSON.parse(
      sessionStorage.getItem("selctedPersonaObj")
    );
    if (this.selectedPersonaDtlsObj.type.toLowerCase() == "prospect") {
      this.slctedPersonRole = "pros";
    } else {
      this.slctedPersonRole = "client";
    }
    setTimeout(
      function () {
        this.getGeneratedDeckUrl();
      }.bind(this),
      200
    );
  }

  openFootnoteModal(template: TemplateRef<any>) {
    this.addFootNotesModalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "add-footnotes-modal" })
    );
    this.modalService.onHide.subscribe((reason: string) => {
      this.clearFootNotesForm();
    });
  }

  async getDeckSlideTitleList(template) {
    if (this.slideTitleList.length == 0) {
      var headerObj = await this.app.getHTTPHeaders();
      this.http.fetchDeckSlideTitleList(headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            if (this.slctedPersonRole == "pros") {
              this.slideTitleList = response.data.prospect;
            } else {
              this.slideTitleList = response.data.client;
            }
            this.openFootnoteModal(template);
          } else {
            this.toastr.error("Error");
          }
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    } else {
      this.openFootnoteModal(template);
    }
  }

  /* Add Foot Notes */
  maxLength: any = 250;
  async addFootnoteForDeck() {
    this.footNoteObj.aaaClientProspectMasterId = this.selectedPersonaDtlsObj.instanceId;
    if (this.footNoteObj.slide_no && this.footNoteObj.footnotes) {
      if (this.footNoteObj.footnotes.length <= this.maxLength) {
        this.app.getLoader(true);
        var headerObj = await this.app.getHTTPHeaders();
        this.http.sendFootNotes(this.footNoteObj, headerObj).subscribe(
          (response: any) => {
            this.app.getLoader(false);
            if (response.success) {
              this.toastr.success("Footnotes Added Successfully");
              this.regenerate_is = true;
              this.getGeneratedDeckUrl();
              setTimeout(
                function () {
                  this.addFootNotesModalRef.hide();
                }.bind(this),
                500
              );
            }
          },
          (error) => {
            this.app.errorHandler(error);
          }
        );
      } else {
        this.toastr.error(
          "The Footnote length should be less than " + this.maxLength
        );
      }
    } else {
      this.toastr.info("All Fields Are Manditory");
    }
  }

  getEnterCodeForSubmit(event) {
    var shiftPlusEnterIs = event.code == "Enter" && event.ctrlKey;
    var shiftPlusNumPadEnterIs = event.code == "NumpadEnter" && event.ctrlKey;
    if (shiftPlusEnterIs || shiftPlusNumPadEnterIs) {
      event.preventDefault();
      this.addFootnoteForDeck();
    }
  }

  clearFootNotesForm() {
    this.footNoteObj.slide_no = "";
    this.footNoteObj.footnotes = "";
    this.footNoteObj.aaaClientProspectMasterId = "";
  }

  /* Get PPT Deck */
  async getGeneratedDeckUrl() {
    if (this.slctedPersonRole == "client") {
      this.app.getLoader(true);
      var sendClientObj = {
        regenerate: true,
        instanceId: this.selectedPersonaDtlsObj.instanceId,
      };
      var headerObj = await this.app.getHTTPHeaders();
      this.http
        .getGeneratedDeckFileForClient(sendClientObj, headerObj)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.deckPreviewUrl =
                this.credDataObj.config.rootURL + response.path;
            } else {
              this.toastr.info("Issue Found");
            }
            this.app.getLoader(false);
          },
          (error) => {
            this.app.errorHandler(error);
          }
        );
    } else {
      this.app.getLoader(true);
      var sendObj = {
        regenerate: true,
        client_id: this.selectedPersonaDtlsObj.prospect_id,
      };
      var headerObj = await this.app.getHTTPHeaders();
      this.http.getGeneratedDeckFile(sendObj, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            this.deckPreviewUrl =
              this.credDataObj.config.rootURL + response.path;
          } else {
            this.toastr.info("Issue Found");
          }
          this.app.getLoader(false);
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    }
  }

  downloadFile() {
    window.open(this.deckPreviewUrl);
  }

  /* Update PPT Deck Status */
  async submitInvDeckStatus(item) {
    var callback;
    if (item == "sent_to_rm") {
      callback = "allocation created";
    } else {
      callback = item;
    }
    var sendData = {
      prospect_id: this.selectedPersonaDtlsObj.prospect_id,
      status: callback,
      inv_status: item,
      comments: this.rejectComment,
      name: this.userInfo.name,
      instanceId: this.selectedPersonaDtlsObj.instanceId,
      type: this.selectedPersonaDtlsObj.type,
    };
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.updateStatus(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          //var msg = item == 'Yes' ? 'Approved' : 'Rejected';
          this.toastr.success("Asset Allocation status updated successfully");
          setTimeout(
            function () {
              this.app.navigateThis("home/investmentDashboard");
            }.bind(this),
            1000
          );
        } else {
          this.toastr.error("Something went wrong!");
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  openApproveConfirmationModal(template: TemplateRef<any>) {
    this.INVApprovalModalRef = this.modalService.show(template);
  }

  ngOnDestroy() {
    if (this.addFootNotesModalRef) {
      this.addFootNotesModalRef.hide();
    }
    if (this.INVApprovalModalRef) {
      this.INVApprovalModalRef.hide();
    }
    this.rejectComment = "";
    this.toastr.clear();
  }
}
