import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { ToastrService } from "ngx-toastr";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
@Component({
  selector: "app-investment-dashboard",
  templateUrl: "./investment-dashboard.component.html",
  styleUrls: ["./investment-dashboard.component.scss"],
})
export class InvestmentDashboardComponent implements OnInit {
  selected: string;
  modalIsActive: boolean = false;
  summaryModalRef: BsModalRef;
  //modalRef: BsModalRef;
  //apiEndPoint = environment.apiEndPoint;
  data: any = {
    Status: {
      Pending: 3,
      Approved: 7,
      Rejected: 1,
    },
    PendingApprovals: [
      {
        client_id: 37002,
        investment_amount: 100000000,
        risk_score: 85,
        WithDraw: true,
        responsiveness: "Moderate",
        Date: "12/02/2020",
      },
      {
        client_id: 37001,
        investment_amount: 50000000,
        risk_score: 96,
        WithDraw: true,
        responsiveness: "Moderate",
        Date: "12/02/2020",
      },
      {
        client_id: 37001,
        investment_amount: 1000000,
        risk_score: 85,
        WithDraw: false,
        responsiveness: "Moderate",
        Date: "12/02/2020",
      },
      {
        client_id: 37001,
        investment_amount: 800000000,
        risk_score: 68,
        WithDraw: true,
        responsiveness: "Moderate",
        Date: "22/02/2020",
      },
      {
        client_id: 37001,
        investment_amount: 800000,
        risk_score: 95,
        WithDraw: true,
        responsiveness: "Moderate",
        Date: "12/02/2020",
      },
    ],
  };
  invesmentdataHolder: any = {};
  dashboardData: any = [];
  statusCount: any = [];
  filter: any;
  filter1: any;
  currentPage: number = 1;
  spcIdList: any = [];
  data1: any = [];

  filterState: boolean = true;
  prospectList: any[] = [];
  infoText: string;
  showPagination: boolean;
  isDataList: any;
  constructor(
    public app: AppComponent,
    private modalService: BsModalService,
    private http: CommonservicesService,
    private toastr: ToastrService
  ) {
    this.spcIdList = [];
    /* this.filter = { first_name: "" }; */
    this.showPagination = false;
    this.filter1 = this.app.getFilterObj();
    this.filter1.type = "";
    this.filter1.ema_status = "";
  }
  keyword = "first_name";
  totalItems: any;
  activefilter: any;
  getLoginUserData: any = {};
  ngOnInit() {
    this.app.setAppTitle("INV Dashboard");
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
    this.activefilter = "";
    setTimeout(
      function () {
        this.getDashboardRecords();
      }.bind(this),
      100
    );
  }

  public clientlist = [];
  pageChanged(event: PageChangedEvent): void {
    this.filter1.offset = (event.page - 1) * event.itemsPerPage;
    this.filter1.max = 25;
    this.getDashboardRecords();
  }

  async getProspect() {
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getInvProspect(headerObj).subscribe(
      (response: any) => {
        if (response) {
          this.clientlist = response.data[0];
          this.prospectList = response.data[1];
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  filterStatus(item) {
    this.activefilter = item;
    this.filter1.offset = 0;
    this.filter1.max = 25;
    this.currentPage = 1;
    this.filter1.clientid = "";
    this.getDashboardRecords();
  }

  // Get records form DB for intial load
  async getDashboardRecords() {
    this.infoText = "Searching...";
    this.app.getLoader(true);
    var send_data = {
      id: this.getLoginUserData.id,
      name: this.getLoginUserData.name,
      email: this.getLoginUserData.email,
      max: this.filter1.max,
      offset: this.filter1.offset,
      status: this.activefilter,
      client_id: this.filter1.clientid,
      type: this.filter1.type,
      ema_status: this.filter1.ema_status,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getInvestmentRecords(send_data, headerObj).subscribe(
      (response: any) => {
        var dataResponse = response;
        if (dataResponse.success) {
          this.invesmentdataHolder = dataResponse;
          if (this.invesmentdataHolder.data.length > 0) {
            this.invesmentdataHolder.data.sort((a, b) => {
              return (
                <any>new Date(b.createdAt).getTime() -
                <any>new Date(a.createdAt).getTime()
              );
            });
            this.dashboardData = this.invesmentdataHolder.data;
            console.log(this.dashboardData);
            if (this.clientlist.length == 0) {
              this.getProspect();
            }
          } else {
            this.infoText = "No Data...";
            console.log(this.invesmentdataHolder.data, "abc");
          }
          /* if (this.activefilter == 'pending') {
          this.totalItems = this.invesmentdataHolder.pending;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else if (this.activefilter == 'approved') {
          this.totalItems = this.invesmentdataHolder.approved;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else if (this.activefilter == 'Questionnaire filled') {
          this.totalItems = this.invesmentdataHolder.Questionnaire_filled;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else if (this.activefilter == 'rejected') {
          this.totalItems = this.invesmentdataHolder.rejected;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } else {
          this.totalItems = this.invesmentdataHolder.count;
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        } */

          var fieldName = "";
          switch (this.activefilter) {
            case "pending":
              fieldName = "pending";
              break;
            case "approved":
              fieldName = "approved";
              break;
            case "allocation created":
              fieldName = "allocation_created";
              break;
            case "rejected":
              fieldName = "rejected";
              break;
            case "Questionnaire filled":
              fieldName = "Questionnaire_filled";
              break;
            case "sent_to_rm":
              fieldName = "sent_to_rm";
              break;
            case "sent_to_ceo":
              fieldName = "sent_to_ceo";
              break;
            case "rejected_by_ceo":
              fieldName = "rejected_by_ceo";
              break;
            case "accepted_by_ceo":
              fieldName = "accepted_by_ceo";
              break;
            case "self_directed":
              fieldName = "self_directed";
              break;
            default:
              fieldName = "count";
          }
          this.totalItems = this.invesmentdataHolder[fieldName];
          if (this.totalItems > 25) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }

          this.getLoginUserData.aaaUserId = dataResponse.aaaUserId;
          localStorage.setItem(
            "userInfo",
            JSON.stringify(this.getLoginUserData)
          );
        } else {
          this.infoText = "No Data...";
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.infoText = "Connection Issue";
        this.app.errorHandler(error);
      }
    );
  }

  reloadFunc() {
    this.activefilter = "";
    //this.filter.clientid = "";
    this.filter1.type = "";
    this.filter1.clientid = "";
    this.filter1.ema_status = "";
    this.filter1.offset = 0;
    this.filter1.max = 25;
    this.currentPage = 1;
    this.getDashboardRecords();
  }

  filterClear() {
    this.filter1.type = "";
    this.filter1.ema_status = "";
    this.filter1.clientid = "";
    this.activefilter = "";
    this.currentPage = 1;
    this.filter1.offset = 0;
    this.filter1.max = 25;
    this.getDashboardRecords();
  }

  showSummary(modalTemp, viewData) {
    viewData.viewIs = true;
    sessionStorage.setItem("selctedPersonaObj", JSON.stringify(viewData));
    this.summaryModalShow(modalTemp);
  }

  summaryModalShow(template: TemplateRef<any>) {
    this.summaryModalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "modal-lg investSummaryViewMdlBox" })
    );
  }

  /* calcFATotal() {
    var clipTwoAmt = this.questionsArray.que7.para1.Amount == "" ? 0 : this.questionsArray.que7.para1.Amount;
    var underwritingFCB = this.questionsArray.que7.para2.Amount == "" ? 0 : this.questionsArray.que7.para2.Amount;
    var underwritingFCC = this.questionsArray.que7.para3.Amount == "" ? 0 : this.questionsArray.que7.para3.Amount;

    var getTotal = parseInt(clipTwoAmt) + parseInt(underwritingFCB) + parseInt(underwritingFCC);
    this.questionsArray.que6.total = getTotal;
  } */

  // Filter records based on the status passed in item
  // filterStatus(item) {
  //   let temp = [];
  //   if (item == "all") {
  //     temp = this.invesmentdataHolder.data;
  //     this.activefilter = "all";
  //   } else {
  //     this.invesmentdataHolder.data.forEach(record => {
  //       if (record.inv_status.toLowerCase() == item.toLowerCase()) {
  //         temp.push(record);
  //         this.activefilter = item
  //       }
  //     });
  //   }
  //   this.filter.clientid = "";
  //   this.dashboardData = temp;
  // }
  selectFilter(value) {
    this.showList(true, "");
    this.filter1.offset = 0;
    this.filter1.max = 25;
    this.currentPage = 1;
    this.getDashboardRecords();
  }

  /* selectEvent(item) {
    let temp = [];
    this.filter.first_name =
      item.first_name !== "notfound" ? item.first_name : "";
    //this.activeTab = 'all';
    this.invesmentdataHolder.data.forEach(ele => {
      if (ele.first_name === item.first_name) {
        temp.push(ele);
      }
    });
    this.dashboardData = temp;
  } */

  showList(value, callback) {
    this.isDataList = value;
    if (callback == "clear") {
      this.getDashboardRecords();
    }
  }

  /* clearFilter() {
    this.filter.clientid = "";
    this.dashboardData = this.invesmentdataHolder.data;
  } */
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  showAssetAllocation(item) {
    if (item.type == "client") {
      if (item.inv_status != "Questionnaire filled") {
        sessionStorage.setItem("selctedPersonaObj", JSON.stringify(item));
        this.app.navigateThis("home/currentFuturePortfolio");
      } else {
      }
    } else {
      sessionStorage.setItem("selctedPersonaObj", JSON.stringify(item));
      if (this.formatDate(item.updated) < "2020-12-26") {
      } else {
        this.app.navigateThis("home/investmentSummary");
      }
    }
  }

  showQuesnnairePdf(getObj) {
    getObj.createdAt = getObj.date;
    sessionStorage.setItem("selctedPersonaObj", JSON.stringify(getObj));
    this.app.navigateThis("home/questionnairePrint");
  }

  ngOnDestroy() {
    if (this.summaryModalRef) {
      this.summaryModalRef.hide();
    }
    this.toastr.clear();
  }
}
