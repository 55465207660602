import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  BsModalService,
  BsModalRef,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { ToastrService } from "ngx-toastr";
import * as credentialData from "credential.json";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
@Component({
  selector: "app-detailed-proposed-aa",
  templateUrl: "./detailed-proposed-aa.component.html",
  styleUrls: ["./detailed-proposed-aa.component.scss"],
})
export class DetailedProposedAAComponent implements OnInit {
  @ViewChild("cmpareModalTemplate", { static: false })
  compareModalRef: ModalDirective;
  @ViewChild("RMAssetAllocationModal", { static: false })
  cltAaModalApproveMldRef: ModalDirective;
  @ViewChild("INVRejectModal", { static: false })
  cltAaRejectModalRef: ModalDirective;
  credDataObj: any = (credentialData as any).default;
  detailedProposedAAScrnObj: any;
  assAllFooterDataObj: any;
  showINVEdited = false;
  dtldPropAADataList: any = {
    dataList: [],
    PfvDataList: [],
    aaTotal: 0,
    aaTotalPerc: 0,
  };
  compareViewDataObj: any = {
    dataList: [],
    PfvDataList: [],
    aaTotal: 0,
    aaTotalPerc: 0,
  };
  assetAllocation: any = [
    {
      rmGeneratedAA: {
        amount: -3870.340000000014,
        perc: 0,
      },
      invEditedAA: {
        amount: "",
        perc: "",
      },
      type: "Liquid Assets",
      program: "Cash",
      strategy: "PFV",
      targetYield: 0,
      targetReturn: 0,
    },
    {
      rmGeneratedAA: {
        amount: 4090681.53,
        perc: 0.14232016262058733,
      },
      invEditedAA: {
        amount: "",
        perc: "",
      },
      type: "Illiquid Assets",
      program: "Access Fund",
      strategy: "CG",
      targetYield: 0.04,
      targetReturn: 0.08,
    },
    {
      rmGeneratedAA: {
        amount: 3622599.74,
        perc: 0.12603498471466132,
      },
      invEditedAA: {
        amount: "",
        perc: "",
      },
      type: "Illiquid Assets",
      program: "AGPECO",
      strategy: "CG",
      targetYield: 0,
      targetReturn: 0.1,
    },
    {
      rmGeneratedAA: {
        amount: 2327474.05,
        perc: 0.08097586743478341,
      },
      invEditedAA: {
        amount: "",
        perc: "",
      },
      type: "Illiquid Assets",
      program: "AGRECO",
      strategy: "CG",
      targetYield: 0,
      targetReturn: 0.1,
    },
    {
      rmGeneratedAA: {
        amount: 1641084.46,
        perc: 0,
      },
      invEditedAA: {
        amount: "",
        perc: "",
      },
      type: "Liquid Assets",
      program: "BondVest",
      strategy: "PFV",
      targetYield: 0,
      targetReturn: 0.0225,
    },
    {
      rmGeneratedAA: {
        amount: 926615.18,
        perc: 0.032238154482855774,
      },
      invEditedAA: {
        amount: "",
        perc: "",
      },
      type: "Illiquid Assets",
      program: "China Tech Fund",
      strategy: "CG",
      targetYield: 0,
      targetReturn: 0.15,
    },
  ];
  progAccAllExandedIs: boolean = false;
  userInfo: any = {};
  selectedClientDtls: any;
  proposedCashFlowDataArr: any = [];
  dtldProposedAaIpsData: any;
  currentPortfolioDate = new Date();
  rejectComment: any = "";
  idealCashflowBVFluctuations: any = { dataList: [], totalList: {} };
  crisisCashflowBVFluctuations: any = { dataList: [], totalList: {} };
  normalCashflowBVFluctuations: any = { dataList: [], totalList: {} };
  @ViewChild("commScrollCont", { static: false })
  private myScrollContainer: ElementRef;
  excelFileUrl: string;
  selectedClientInput: any;
  oppPercDiff: any;
  ARPercDiff: any;
  CGPercDiff: any;
  CYPercDiff: any;
  constructor(
    public app: AppComponent,
    private modalService: BsModalService,
    private http: CommonservicesService,
    private toastr: ToastrService
  ) {
    am4core.useTheme(am4themes_kelly);
    am4core.useTheme(am4themes_animated);
  }

  ngOnInit() {
    this.app.setAppTitle("Detailed Proposed AA");
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.selectedClientDtls = JSON.parse(
      sessionStorage.getItem("selctedPersonaObj")
    );
    this.selectedClientInput = JSON.parse(
      sessionStorage.getItem("inputScreenMainObj")
    );
    setTimeout(() => {
      this.getCurrAndFutPortData();
      // this.compareModalBoxCall();
    }, 100);
  }

  drowCgCyChartComp() {
    let currCmprsnChart = am4core.create(
      "chartCompForCurrCmparsn",
      am4charts.PieChart
    );
    // Add data
    currCmprsnChart.data = [
      {
        value: (this.dtldProposedAaIpsData.proposedIps.cgPerc * 100).toFixed(1),
        title: "Capital Growth",
        color: am4core.color("#d6c9b1"),
      },
      {
        value: (this.dtldProposedAaIpsData.proposedIps.cyPerc * 100).toFixed(1),
        title: "Capital Yield",
        color: am4core.color("#6d7b52"),
      },
      {
        value: (this.dtldProposedAaIpsData.proposedIps.arPerc * 100).toFixed(1),
        title: "Absolute Return",
        color: am4core.color("#c47438"),
      },
      {
        value: (this.dtldProposedAaIpsData.proposedIps.oppPerc * 100).toFixed(
          1
        ),
        title: "Opportunistic",
        color: am4core.color("#987b4b"),
      },
    ];
    // Set inner radius
    currCmprsnChart.innerRadius = am4core.percent(50);
    let currCompTitle = currCmprsnChart.seriesContainer.createChild(
      am4core.Label
    );
    currCompTitle.text = "Proposed";
    currCompTitle.horizontalCenter = "middle";
    currCompTitle.verticalCenter = "middle";
    currCompTitle.fontSize = 14;

    var currPieSeries = currCmprsnChart.series.push(new am4charts.PieSeries());
    currPieSeries.dataFields.value = "value";
    currPieSeries.dataFields.category = "title";
    currPieSeries.fontSize = 10;
    currPieSeries.slices.template.propertyFields.fill = "color";
    currPieSeries.slices.template.tooltipText =
      "{category}: [bold]{value.value}%";
    currPieSeries.labels.template.text = "{category}: {value.value}%";
    currCmprsnChart.logo.disabled = true;
  }

  async getCurrAndFutPortData() {
    this.app.getLoader(true);
    var sendData = {
      instanceId: this.selectedClientDtls.instanceId,
      clientId: this.selectedClientDtls.client_id,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.proposedClientAA(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.dtlsPageDataModifyFun(response.data);
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  dtlsPageDataModifyFun(getResData) {
    this.detailedProposedAAScrnObj = getResData;
    if (this.detailedProposedAAScrnObj.isINVEdited) {
      this.showINVEdited = true;
    } else {
      this.showINVEdited = false;
    }
    var operationData = getResData.operation.split("_");
    this.detailedProposedAAScrnObj.operation = operationData[0];
    console.log(this.detailedProposedAAScrnObj.operation, "operation");
    this.dtldProposedAaIpsData = getResData.ips;
    this.oppPercDiff = Math.abs(
      (this.dtldProposedAaIpsData.signedIps.oppPerc -
        this.dtldProposedAaIpsData.proposedIps.oppPerc) *
        100
    );
    console.log(this.oppPercDiff, "oppPercDiff");
    this.ARPercDiff = Math.abs(
      (this.dtldProposedAaIpsData.signedIps.arPerc -
        this.dtldProposedAaIpsData.proposedIps.arPerc) *
        100
    );
    console.log(this.ARPercDiff, "ARPercDiff");
    this.CGPercDiff = Math.abs(
      (this.dtldProposedAaIpsData.signedIps.cgPerc -
        this.dtldProposedAaIpsData.proposedIps.cgPerc) *
        100
    );
    console.log(this.CGPercDiff, "CGPercDiff");
    this.CYPercDiff = Math.abs(
      (this.dtldProposedAaIpsData.signedIps.cyPerc -
        this.dtldProposedAaIpsData.proposedIps.cyPerc) *
        100
    );
    console.log(this.CYPercDiff, "CYPercDiff");
    var prpsdDataObj = getResData.proposedCashflow;
    prpsdDataObj.capitalCall.program = "Capital Call";
    prpsdDataObj.capitalDistribution.program = "Capital Distribution";
    prpsdDataObj.incomeDistribution.program = "Income Distribution";
    prpsdDataObj.netCapitalCall.program = "Net Distribution";
    prpsdDataObj.totalDistribution.program = "Total Distribution";
    var prpsdDataListArr = [];
    prpsdDataListArr.push(prpsdDataObj.capitalCall);
    prpsdDataListArr.push(prpsdDataObj.capitalDistribution);
    prpsdDataListArr.push(prpsdDataObj.incomeDistribution);
    prpsdDataListArr.push(prpsdDataObj.netCapitalCall);
    prpsdDataListArr.push(prpsdDataObj.totalDistribution);
    for (let x = 0; x < prpsdDataListArr.length; x++) {
      var getPartObj = prpsdDataListArr[x];
      prpsdDataListArr[x].total =
        (getPartObj.y1 ? getPartObj.y1 : 0) +
        (getPartObj.y2 ? getPartObj.y2 : 0) +
        (getPartObj.y3 ? getPartObj.y3 : 0) +
        (getPartObj.y4 ? getPartObj.y4 : 0) +
        (getPartObj.y5 ? getPartObj.y5 : 0) +
        (getPartObj.y6 ? getPartObj.y6 : 0) +
        (getPartObj.y7 ? getPartObj.y7 : 0);
    }
    this.proposedCashFlowDataArr = prpsdDataListArr;

    var idealcshflwBvFlucAllObj = getResData.idealCashflowBVFluctuations;
    idealcshflwBvFlucAllObj.startingBVBalance.program = "Starting PFV Balance";
    idealcshflwBvFlucAllObj.capitalCall.program = "Capital Call";
    idealcshflwBvFlucAllObj.capitalDistribution.program =
      "Capital Distribution";
    idealcshflwBvFlucAllObj.netCapitalDistributions.program =
      "Net Capital Distributions";
    idealcshflwBvFlucAllObj.incomeReq.program = "Income Requirement";
    idealcshflwBvFlucAllObj.endingBVBalance.program = "Ending PFV Balance";
    var idealcshFlwFlucTtlArr = [];
    idealcshFlwFlucTtlArr.push(idealcshflwBvFlucAllObj.startingBVBalance);
    idealcshFlwFlucTtlArr.push(idealcshflwBvFlucAllObj.capitalCall);
    idealcshFlwFlucTtlArr.push(idealcshflwBvFlucAllObj.capitalDistribution);
    idealcshFlwFlucTtlArr.push(idealcshflwBvFlucAllObj.netCapitalDistributions);
    idealcshFlwFlucTtlArr.push(idealcshflwBvFlucAllObj.incomeReq);
    idealcshFlwFlucTtlArr.push(idealcshflwBvFlucAllObj.endingBVBalance);
    this.idealCashflowBVFluctuations.dataList = idealcshFlwFlucTtlArr;
    //this.idealCashflowBVFluctuations.totalList = idealcshflwBvFlucAllObj.totalIncomeDistributions;

    var normalcshflwBvFlucAllObj = getResData.normalCashflowBVFluctuations;
    normalcshflwBvFlucAllObj.startingBVBalance.program = "Starting PFV Balance";
    normalcshflwBvFlucAllObj.capitalCall.program = "Capital Call";
    normalcshflwBvFlucAllObj.capitalDistribution.program =
      "Capital Distribution";
    normalcshflwBvFlucAllObj.netCapitalDistributions.program =
      "Net Capital Distributions";
    normalcshflwBvFlucAllObj.incomeReq.program = "Income Requirement";
    normalcshflwBvFlucAllObj.endingBVBalance.program = "Ending PFV Balance";
    var normalcshFlwFlucTtlArr = [];
    normalcshFlwFlucTtlArr.push(normalcshflwBvFlucAllObj.startingBVBalance);
    normalcshFlwFlucTtlArr.push(normalcshflwBvFlucAllObj.capitalCall);
    normalcshFlwFlucTtlArr.push(normalcshflwBvFlucAllObj.capitalDistribution);
    normalcshFlwFlucTtlArr.push(
      normalcshflwBvFlucAllObj.netCapitalDistributions
    );
    normalcshFlwFlucTtlArr.push(normalcshflwBvFlucAllObj.incomeReq);
    normalcshFlwFlucTtlArr.push(normalcshflwBvFlucAllObj.endingBVBalance);
    this.normalCashflowBVFluctuations.dataList = normalcshFlwFlucTtlArr;
    //this.normalCashflowBVFluctuations.totalList = normalcshflwBvFlucAllObj.totalIncomeDistributions;

    var crisiscshflwBvFlucAllObj = getResData.crisisCashflowBVFluctuations;
    crisiscshflwBvFlucAllObj.startingBVBalance.program = "Starting PFV Balance";
    crisiscshflwBvFlucAllObj.capitalCall.program = "Capital Call";
    crisiscshflwBvFlucAllObj.capitalDistribution.program =
      "Capital Distribution";
    crisiscshflwBvFlucAllObj.netCapitalDistributions.program =
      "Net Capital Distributions";
    crisiscshflwBvFlucAllObj.incomeReq.program = "Income Requirement";
    crisiscshflwBvFlucAllObj.endingBVBalance.program = "Ending PFV Balance";
    var crisiscshFlwFlucTtlArr = [];
    crisiscshFlwFlucTtlArr.push(crisiscshflwBvFlucAllObj.startingBVBalance);
    crisiscshFlwFlucTtlArr.push(crisiscshflwBvFlucAllObj.capitalCall);
    crisiscshFlwFlucTtlArr.push(crisiscshflwBvFlucAllObj.capitalDistribution);
    crisiscshFlwFlucTtlArr.push(
      crisiscshflwBvFlucAllObj.netCapitalDistributions
    );
    crisiscshFlwFlucTtlArr.push(crisiscshflwBvFlucAllObj.incomeReq);
    crisiscshFlwFlucTtlArr.push(crisiscshflwBvFlucAllObj.endingBVBalance);
    this.crisisCashflowBVFluctuations.dataList = crisiscshFlwFlucTtlArr;
    //this.crisisCashflowBVFluctuations.totalList = crisiscshflwBvFlucAllObj.totalIncomeDistributions;

    var assetAllocDataForMdf = [];

    var assetAllocDataForMdf1 = [];

    for (let o = 0; o < getResData.assetAllocation.length; o++) {
      var getAaObj = getResData.assetAllocation[o];
      getAaObj.rmGeneratedAA.weightedYield =
        getAaObj.rmGeneratedAA.perc * getAaObj.targetYield;
      getAaObj.rmGeneratedAA.weightedReturn =
        getAaObj.rmGeneratedAA.perc * getAaObj.targetReturn;
      getAaObj.invEditedAA.weightedYield =
        getAaObj.invEditedAA.perc * getAaObj.targetYield;
      getAaObj.invEditedAA.weightedReturn =
        getAaObj.invEditedAA.perc * getAaObj.targetReturn;
      if (
        getAaObj.program.toLowerCase() != "bondvest" &&
        getAaObj.program.toLowerCase() != "cash" &&
        getAaObj.program.toLowerCase() != "sukukvest"
      ) {
        assetAllocDataForMdf1.push(getAaObj);
      } else {
        this.dtldPropAADataList.PfvDataList.push(getAaObj);
      }
    }
    for (
      let o = 0;
      o < getResData.proposedAssetAllocation.proposedAA.length;
      o++
    ) {
      var getAaObj = getResData.proposedAssetAllocation.proposedAA[o];
      getAaObj.weightedYield = getAaObj.perc * getAaObj.targetYield;
      getAaObj.weightedReturn = getAaObj.perc * getAaObj.targetReturn;
      if (
        getAaObj.fund.toLowerCase() != "bondvest" &&
        getAaObj.fund.toLowerCase() != "cash" &&
        getAaObj.fund.toLowerCase() != "sukukvest"
      ) {
        assetAllocDataForMdf.push(getAaObj);
      } else {
        this.dtldPropAADataList.PfvDataList.push(getAaObj);
      }
    }
    this.aa_data_modification(assetAllocDataForMdf);
    this.aa_data_modification_ProposedAA(assetAllocDataForMdf1);
    this.drowCgCyChartComp();
  }

  aa_data_modification(assetAllocationArray) {
    var resultData = assetAllocationArray;
    var resultData2 = resultData;
    var get_final_data = resultData
      .reduce(function (res, currentValue) {
        if (res.indexOf(currentValue.type) === -1) {
          res.push(currentValue.type);
        }
        return res;
      }, [])
      .map(function (value) {
        return {
          type: value,
          subList: resultData2
            .filter(function (_el) {
              return _el.type === value;
            })
            .map(function (_el) {
              return _el;
            }),
        };
      });

    for (let d = 0; d < get_final_data.length; d++) {
      var dt_obj = get_final_data[d];
      var dt_obj2 = get_final_data[d];
      var inner_data = dt_obj.subList
        .reduce(function (res, currentValue) {
          if (res.indexOf(currentValue.strategy) === -1) {
            res.push(currentValue.strategy);
          }
          return res;
        }, [])
        .map(function (value) {
          return {
            strategy: value,
            program: "Total " + value,
            aaTotalPerc: 0,
            aaTotalAmt: 0,
            WtdYieldGrpTtl: 0,
            WtdReturnGrpTtl: 0,
            collapseIs: false,
            innerList: dt_obj2.subList
              .filter(function (_el) {
                return _el.strategy === value;
              })
              .map(function (_el) {
                return _el;
              }),
          };
        });
      inner_data.forEach((strgObj, i) => {
        for (let p = 0; p < strgObj.innerList.length; p++) {
          var progObj = strgObj.innerList[p];
          inner_data[i].aaTotalPerc += progObj.perc;
          inner_data[i].aaTotalAmt += progObj.amount;
          inner_data[i].WtdYieldGrpTtl += progObj.weightedYield
            ? progObj.weightedYield
            : 0;
          inner_data[i].WtdReturnGrpTtl += progObj.weightedReturn
            ? progObj.weightedReturn
            : 0;
        }
      });
      get_final_data[d].subList = inner_data.sort(function (a, b) {
        if (a.strategy < b.strategy) {
          return -1;
        }
        if (a.strategy > b.strategy) {
          return 1;
        }
        return 0;
      });
    }

    this.dtldPropAADataList.dataList = get_final_data;
    this.dtldPropAADataList.aaTotal = 0;
    this.dtldPropAADataList.aaTotalPerc = 0;
    this.dtldPropAADataList.PfvDataList.forEach((getObj) => {
      this.dtldPropAADataList.aaTotal += getObj.amount ? getObj.amount : 0;
    });
    assetAllocationArray.forEach((getObj) => {
      this.dtldPropAADataList.aaTotal += getObj.amount;
      this.dtldPropAADataList.aaTotalPerc += getObj.perc;
    });
    this.app.getLoader(false);
  }

  dtlPropAaPrgmAllExpandIs: boolean = false;
  collapsibleOfDtlPropAaPrgms(getObj) {
    getObj.collapseIs = !getObj.collapseIs;
    var allOpenIs = false;
    var mainLoopLength = this.dtldPropAADataList.dataList.length - 1;
    for (let i = 0; i < this.dtldPropAADataList.dataList.length; i++) {
      var strgObj = this.dtldPropAADataList.dataList[i];
      var getLoopLength = strgObj.subList.length - 1;
      for (let m = 0; m < strgObj.subList.length; m++) {
        if (!strgObj.subList[m].collapseIs) {
          allOpenIs = false;
          break;
        } else {
          if (getLoopLength == m) {
            allOpenIs = true;
          }
        }
      }
      if (!allOpenIs) {
        this.dtlPropAaPrgmAllExpandIs = false;
        break;
      } else {
        if (mainLoopLength == i) {
          this.dtlPropAaPrgmAllExpandIs = true;
        }
      }
    }
  }

  expandAllProgramsAcc() {
    this.dtlPropAaPrgmAllExpandIs = !this.dtlPropAaPrgmAllExpandIs;
    this.dtldPropAADataList.dataList.forEach((strgObj, i) => {
      for (let m = 0; m < strgObj.subList.length; m++) {
        if (this.dtlPropAaPrgmAllExpandIs) {
          this.dtldPropAADataList.dataList[i].subList[m].collapseIs = true;
        } else {
          this.dtldPropAADataList.dataList[i].subList[m].collapseIs = false;
        }
      }
    });
  }

  aa_data_modification_ProposedAA(assetAllocationArray) {
    var resultData = assetAllocationArray;
    var resultData2 = resultData;
    var get_final_data = resultData
      .reduce(function (res, currentValue) {
        if (res.indexOf(currentValue.type) === -1) {
          res.push(currentValue.type);
        }
        return res;
      }, [])
      .map(function (value) {
        return {
          type: value,
          subList: resultData2
            .filter(function (_el) {
              return _el.type === value;
            })
            .map(function (_el) {
              return _el;
            }),
        };
      });

    for (let d = 0; d < get_final_data.length; d++) {
      var dt_obj = get_final_data[d];
      var dt_obj2 = get_final_data[d];
      var inner_data = dt_obj.subList
        .reduce(function (res, currentValue) {
          if (res.indexOf(currentValue.strategy) === -1) {
            res.push(currentValue.strategy);
          }
          return res;
        }, [])
        .map(function (value) {
          return {
            strategy: value,
            program: "Total " + value,
            rmGeneratedSubTotalPerc: 0,
            rmGeneratedSubTotalAmt: 0,
            rmGeneratedWtdYieldGrpTtl: 0,
            rmGeneratedWtdReturnGrpTtl: 0,
            invEditedSubTotalPerc: 0,
            invEditedSubTotalAmt: 0,
            invEditedWtdYieldTtl: 0,
            invEditedWtdReturnTtl: 0,
            collapseIs: false,
            innerList: dt_obj2.subList
              .filter(function (_el) {
                return _el.strategy === value;
              })
              .map(function (_el) {
                return _el;
              }),
          };
        });

      inner_data.forEach((strgObj, i) => {
        for (let p = 0; p < strgObj.innerList.length; p++) {
          var progObj = strgObj.innerList[p];
          inner_data[i].rmGeneratedSubTotalPerc += progObj.rmGeneratedAA.perc
            ? progObj.rmGeneratedAA.perc
            : 0;
          inner_data[i].rmGeneratedSubTotalAmt += progObj.rmGeneratedAA.amount
            ? progObj.rmGeneratedAA.amount
            : 0;
          inner_data[i].rmGeneratedWtdYieldGrpTtl += progObj.rmGeneratedAA
            .weightedYield
            ? progObj.rmGeneratedAA.weightedYield
            : 0;
          inner_data[i].rmGeneratedWtdReturnGrpTtl += progObj.rmGeneratedAA
            .weightedReturn
            ? progObj.rmGeneratedAA.weightedReturn
            : 0;
          inner_data[i].invEditedSubTotalPerc += progObj.invEditedAA.perc
            ? progObj.invEditedAA.perc
            : 0;
          inner_data[i].invEditedSubTotalAmt += progObj.invEditedAA.amount
            ? progObj.invEditedAA.amount
            : 0;
          inner_data[i].invEditedWtdYieldTtl += progObj.invEditedAA
            .weightedYield
            ? progObj.invEditedAA.weightedYield
            : 0;
          inner_data[i].invEditedWtdReturnTtl += progObj.invEditedAA
            .weightedReturn
            ? progObj.invEditedAA.weightedReturn
            : 0;
        }
      });
      get_final_data[d].subList = inner_data.sort(function (a, b) {
        if (a.strategy < b.strategy) {
          return -1;
        }
        if (a.strategy > b.strategy) {
          return 1;
        }
        return 0;
      });
    }

    this.dtldPropAADataList.dataList = get_final_data;
    this.dtldPropAADataList.rmGeneratedTotalAmt = 0;
    this.dtldPropAADataList.rmGeneratedTotalPerc = 0;
    this.dtldPropAADataList.invEditedTotalAmt = 0;
    this.dtldPropAADataList.invEditedTotalPerc = 0;
    this.dtldPropAADataList.PfvDataList.forEach((getObj) => {
      this.dtldPropAADataList.rmGeneratedTotalAmt += getObj.rmGeneratedAA.amount
        ? getObj.rmGeneratedAA.amount
        : 0;
      this.dtldPropAADataList.invEditedTotalAmt += getObj.invEditedAA.amount
        ? getObj.invEditedAA.amount
        : 0;
    });
    assetAllocationArray.forEach((getObj) => {
      this.dtldPropAADataList.rmGeneratedTotalAmt += getObj.rmGeneratedAA.amount
        ? getObj.rmGeneratedAA.amount
        : 0;
      this.dtldPropAADataList.rmGeneratedTotalPerc += getObj.rmGeneratedAA.perc
        ? getObj.rmGeneratedAA.perc
        : 0;
      this.dtldPropAADataList.invEditedTotalAmt += getObj.invEditedAA.amount
        ? getObj.invEditedAA.amount
        : 0;
      this.dtldPropAADataList.invEditedTotalPerc += getObj.invEditedAA.perc
        ? getObj.invEditedAA.perc
        : 0;
    });
    this.app.getLoader(false);
  }

  aa_data_modification_for_compare_view(assetAllocationArray) {
    var resultData = assetAllocationArray;
    var resultData2 = resultData;
    var get_final_data = resultData
      .reduce(function (res, currentValue) {
        if (res.indexOf(currentValue.type) === -1) {
          res.push(currentValue.type);
        }
        return res;
      }, [])
      .map(function (value) {
        return {
          type: value,
          subList: resultData2
            .filter(function (_el) {
              return _el.type === value;
            })
            .map(function (_el) {
              return _el;
            }),
        };
      });

    for (let d = 0; d < get_final_data.length; d++) {
      var dt_obj = get_final_data[d];
      var dt_obj2 = get_final_data[d];
      var inner_data = dt_obj.subList
        .reduce(function (res, currentValue) {
          if (res.indexOf(currentValue.strategy) === -1) {
            res.push(currentValue.strategy);
          }
          return res;
        }, [])
        .map(function (value) {
          return {
            strategy: value,
            program: "Total " + value,
            currentAaSubTotalPerc: 0,
            currentAaSubTotalAmt: 0,
            currentAaWtdYieldGrpTtl: 0,
            currentAaWtdReturnGrpTtl: 0,
            proposdAaSubTotalPerc: 0,
            proposdAaSubTotalAmt: 0,
            proposdAaWtdYieldTtl: 0,
            proposdAaWtdReturnTtl: 0,
            collapseIs: false,
            innerList: dt_obj2.subList
              .filter(function (_el) {
                return _el.strategy === value;
              })
              .map(function (_el) {
                return _el;
              }),
          };
        });

      inner_data.forEach((strgObj, i) => {
        for (let p = 0; p < strgObj.innerList.length; p++) {
          var progObj = strgObj.innerList[p];
          inner_data[i].currentAaSubTotalPerc += progObj.currentAA.perc
            ? progObj.currentAA.perc
            : 0;
          inner_data[i].currentAaSubTotalAmt += progObj.currentAA.amount
            ? progObj.currentAA.amount
            : 0;
          inner_data[i].currentAaWtdYieldGrpTtl += progObj.currentAA
            .weightedYield
            ? progObj.currentAA.weightedYield
            : 0;
          inner_data[i].currentAaWtdReturnGrpTtl += progObj.currentAA
            .weightedReturn
            ? progObj.currentAA.weightedReturn
            : 0;
          inner_data[i].proposdAaSubTotalPerc += progObj.proposedAA.perc
            ? progObj.proposedAA.perc
            : 0;
          inner_data[i].proposdAaSubTotalAmt += progObj.proposedAA.amount
            ? progObj.proposedAA.amount
            : 0;
          inner_data[i].proposdAaWtdYieldTtl += progObj.proposedAA.weightedYield
            ? progObj.proposedAA.weightedYield
            : 0;
          inner_data[i].proposdAaWtdReturnTtl += progObj.proposedAA
            .weightedReturn
            ? progObj.proposedAA.weightedReturn
            : 0;
        }
      });
      get_final_data[d].subList = inner_data.sort(function (a, b) {
        if (a.strategy < b.strategy) {
          return -1;
        }
        if (a.strategy > b.strategy) {
          return 1;
        }
        return 0;
      });
    }

    this.compareViewDataObj.dataList = get_final_data;
    this.compareViewDataObj.currAaTotalAmt = 0;
    this.compareViewDataObj.currAaTotalPerc = 0;
    this.compareViewDataObj.futuAaTotalAmt = 0;
    this.compareViewDataObj.futuAaTotalPerc = 0;
    this.compareViewDataObj.PfvDataList.forEach((getObj) => {
      this.compareViewDataObj.currAaTotalAmt += getObj.currentAA.amount
        ? getObj.currentAA.amount
        : 0;
      this.compareViewDataObj.futuAaTotalAmt += getObj.proposedAA.amount
        ? getObj.proposedAA.amount
        : 0;
    });
    assetAllocationArray.forEach((getObj) => {
      this.compareViewDataObj.currAaTotalAmt += getObj.currentAA.amount
        ? getObj.currentAA.amount
        : 0;
      this.compareViewDataObj.currAaTotalPerc += getObj.currentAA.perc
        ? getObj.currentAA.perc
        : 0;
      this.compareViewDataObj.futuAaTotalAmt += getObj.proposedAA.amount
        ? getObj.proposedAA.amount
        : 0;
      this.compareViewDataObj.futuAaTotalPerc += getObj.proposedAA.perc
        ? getObj.proposedAA.perc
        : 0;
    });
    this.app.getLoader(false);
  }

  collapseDetailOfPrograms(getObj) {
    getObj.collapseIs = !getObj.collapseIs;
    var allOpenIs = false;
    var mainLoopLength = this.compareViewDataObj.dataList.length - 1;
    for (let i = 0; i < this.compareViewDataObj.dataList.length; i++) {
      var strgObj = this.compareViewDataObj.dataList[i];
      var getLoopLength = strgObj.subList.length - 1;
      for (let m = 0; m < strgObj.subList.length; m++) {
        if (!strgObj.subList[m].collapseIs) {
          allOpenIs = false;
          break;
        } else {
          if (getLoopLength == m) {
            allOpenIs = true;
          }
        }
      }
      if (!allOpenIs) {
        this.progAccAllExandedIs = false;
        break;
      } else {
        if (mainLoopLength == i) {
          this.progAccAllExandedIs = true;
        }
      }
    }
  }

  collapseForAllPrograms() {
    this.progAccAllExandedIs = !this.progAccAllExandedIs;
    this.compareViewDataObj.dataList.forEach((strgObj, i) => {
      for (let m = 0; m < strgObj.subList.length; m++) {
        if (this.progAccAllExandedIs) {
          this.compareViewDataObj.dataList[i].subList[m].collapseIs = true;
        } else {
          this.compareViewDataObj.dataList[i].subList[m].collapseIs = false;
        }
      }
    });
  }

  async compareModalBoxCall() {
    this.app.getLoader(true);
    var sendData = {
      instanceId: this.selectedClientDtls.instanceId,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.fetchCompDataForAa(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          var getCmprData = response.data;
          if (getCmprData.assetAllocation.length > 0) {
            this.assAllFooterDataObj = getCmprData;
            this.progAccAllExandedIs = false;
            var currNdPropAssetAllocData = [];
            this.compareViewDataObj.PfvDataList = [];
            for (let o = 0; o < getCmprData.assetAllocation.length; o++) {
              var getAaObj = getCmprData.assetAllocation[o];
              getCmprData.assetAllocation[o].currentAA.weightedYield =
                getAaObj.currentAA.perc * getAaObj.targetYield;
              getCmprData.assetAllocation[o].currentAA.weightedReturn =
                getAaObj.currentAA.perc * getAaObj.targetReturn;
              getCmprData.assetAllocation[o].proposedAA.weightedYield =
                getAaObj.proposedAA.perc * getAaObj.targetYield;
              getCmprData.assetAllocation[o].proposedAA.weightedReturn =
                getAaObj.proposedAA.perc * getAaObj.targetReturn;
              if (
                getAaObj.program.toLowerCase() != "bondvest" &&
                getAaObj.program.toLowerCase() != "cash" &&
                getAaObj.program.toLowerCase() != "sukukvest"
              ) {
                currNdPropAssetAllocData.push(getAaObj);
              } else {
                this.compareViewDataObj.PfvDataList.push(getAaObj);
              }
            }
            this.aa_data_modification_for_compare_view(
              currNdPropAssetAllocData
            );
            this.compareModalRef.show();
          } else {
            this.toastr.info("Data Not Found");
            this.app.getLoader(false);
          }
        } else {
          this.toastr.info("Issue Found: " + response.message);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  commentList: any = [];
  commentText: any = "";
  commentMaxLength: any = 200;
  commentCompoOpenIs: boolean = false;
  commentBoxLoaderIs: boolean = false;
  async commentSubmitFun() {
    if (this.commentText) {
      if (this.commentText.length <= this.commentMaxLength) {
        this.commentBoxLoaderIs = true;
        var sendData = {
          comment: this.commentText,
          userId: this.userInfo.aaaUserId,
          InstanceId: this.selectedClientDtls.instanceId,
        };
        var headerObj = await this.app.getHTTPHeaders();
        this.http.addComments(sendData, headerObj).subscribe(
          (response: any) => {
            if (response.success) {
              this.getNdShowAllComments(true);
              this.toastr.success("Comment Added");
              this.commentText = "";
            } else {
              this.toastr.info("Issue Found: " + response.message);
              this.commentBoxLoaderIs = false;
            }
          },
          (error) => {
            this.app.errorHandler(error);
          }
        );
      } else {
        this.toastr.error(
          "The Comment should be less than " + this.commentMaxLength
        );
      }
    } else {
      this.toastr.error("Enter Valid Comments");
    }
  }

  async getNdShowAllComments(param) {
    this.commentCompoOpenIs = param;
    if (param) {
      this.commentBoxLoaderIs = true;
      var sendData = {
        aaaInstanceMasterId: this.selectedClientDtls.instanceId,
      };
      var headerObj = await this.app.getHTTPHeaders();
      this.http.getAllComments(sendData, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            response.result.sort((a, b) => {
              return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
            });
            this.commentList = response.result.reverse();
            this.scrollToBottom();
          } else {
            this.toastr.info("Issue Found: " + response.message);
          }
          this.commentBoxLoaderIs = false;
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    }
  }

  scrollToBottom(): void {
    try {
      setTimeout(
        function () {
          this.myScrollContainer.nativeElement.scrollTop =
            this.myScrollContainer.nativeElement.scrollHeight + 200;
        }.bind(this),
        100
      );
    } catch (err) {}
  }

  getEnterCodeForSubmit(event) {
    var shiftPlusEnterIs = event.code == "Enter" && event.ctrlKey;
    var shiftPlusNumPadEnterIs = event.code == "NumpadEnter" && event.ctrlKey;
    if (shiftPlusEnterIs || shiftPlusNumPadEnterIs) {
      event.preventDefault();
      this.commentSubmitFun();
    }
  }

  async sendInvNdCeoTeamForApproval(getPrm) {
    console.log(this.selectedClientDtls.type, "type");
    this.app.getLoader(true);
    var sendObj: any = {
      prospect_id: this.selectedClientDtls.client_id,
      status: "sent_to_ceo",
      comments: "",
      instanceId: this.selectedClientDtls.instanceId,
      type: this.selectedClientDtls.type,
    };
    var succMsg = "Submited For CEO Approval";
    if (getPrm == "inv") {
      sendObj.status = "sent_to_inv";
      sendObj.inv_status = "pending";
      succMsg = "Submited For Investment Team's Approval";
    }
    var headerObj = await this.app.getHTTPHeaders();
    this.http.updateStatus(sendObj, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.toastr.success(succMsg);
          setTimeout(
            function () {
              this.app.navigateThis("home/dashboard");
              this.app.getLoader(false);
            }.bind(this),
            1000
          );
        } else {
          this.toastr.info("Issue Found: " + response.message);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async actionConfirmationOnClientAa(callback) {
    var sendData = {
      prospect_id: this.selectedClientDtls.client_id,
      status: callback,
      inv_status: callback,
      comments: this.rejectComment,
      name: this.userInfo.name,
      instanceId: this.selectedClientDtls.instanceId,
      type: this.selectedClientDtls.type,
    };
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.updateStatus(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.selectedClientDtls.inv_status = callback;
          sessionStorage.setItem(
            "selctedPersonaObj",
            JSON.stringify(this.selectedClientDtls)
          );
          this.toastr.success("Rejected Successfully");
          this.cltAaRejectModalRef.hide();
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async downloadExcel() {
    var sendData = {
      instanceId: this.selectedClientDtls.instanceId,
      download: true,
    };
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getDownloadedExcel(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.excelFileUrl = this.credDataObj.config.rootURL + response.path;
          window.open(this.excelFileUrl);
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async sendMailtoCEO() {
    var sendData = {
      instanceId: this.selectedClientDtls.instanceId,
      clientId: this.selectedClientDtls.client_id,
    };
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.sendClientMailtoCEO(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.toastr.success("Successfully sent for CEO approval");
          this.app.navigateThis("home/dashboard");
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  ngOnDestroy() {
    this.toastr.clear();
    if (this.compareModalRef) {
      this.compareModalRef.hide();
    }
    if (this.cltAaRejectModalRef) {
      this.cltAaRejectModalRef.hide();
    }
  }
}
