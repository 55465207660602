import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CommonservicesService } from "../commonservices.service";
import { AppComponent } from "../app.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ema-trigger',
  templateUrl: './ema-trigger.component.html',
  styleUrls: ['./ema-trigger.component.scss']
})
export class EmaTriggerComponent implements OnInit {
  //selectedprospect: any;
  userInfo: any = {};
  constructor(private modalService: BsModalService, private http: CommonservicesService, public app: AppComponent, private toastr: ToastrService) { }
  emaStatus: any = "";
  ngOnInit() {
    this.app.setAppTitle("EMA View");
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //this.selectedprospect = JSON.parse(sessionStorage.getItem("selctedPersonaObj"));
    setTimeout(function () {
      this.getEmaStatusForView();
    }.bind(this), 200);
  }

  async getEmaStatusForView() {
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.checkemastatus(headerObj).subscribe((response: any) => {
      if (response.success) {
        this.emaStatus = response.status;
        this.toastr.success(response.message);
      } else {
        this.toastr.info("Issue Found");
      }
      this.app.getLoader(false);
    }, error => {
      this.app.errorHandler(error);
    });
  }

  async triggerInitiateEma() {
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.initiateema(headerObj).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success("Initiated Successfully");
      } else {
        this.toastr.info("Issue Found");
      }
      this.app.getLoader(false);
    }, error => {
      this.app.errorHandler(error);
    });
  }

  ngOnDestroy() {
    /* if (this.modalRef) {
      this.modalRef.hide();
    } */
    this.toastr.clear();
  }
}
