import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { ToastrService } from "ngx-toastr";
import { FormFieldIDService } from "../../services/form-field-id.service";

@Component({
  selector: "app-onboarding-questionnaire-form",
  templateUrl: "./onboarding-questionnaire-form.component.html",
  styleUrls: ["./onboarding-questionnaire-form.component.scss"],
})
export class OnboardingQuestionnaireFormComponent implements OnInit {
  constructor(
    public app: AppComponent,
    private modalService: BsModalService,
    private http: CommonservicesService,
    private toastr: ToastrService,
    public formFieldID: FormFieldIDService
  ) {
    var isDate = new Date();
    isDate.setFullYear(isDate.getFullYear() - 18);
    this.sendData.firstname = "";
    this.sendData.lastname = "";
    this.sendData.email = "";
    this.sendData.gender = "";
    this.sendData.dob = ""; //isDate;
    this.sendData.region = "";
    this.sendData.isRegion = "";
    this.sendData.risk_score = "";
    this.sendData.responsiveness = "";
  }

  maxDate: any = new Date();

  @ViewChild("confirmationModalBox", { static: false })
  public confirmationModalBox: TemplateRef<any>;
  riskError: boolean = false;
  responseError: boolean = false;
  qstrConfirmationModalRef: BsModalRef;
  title = "questionnaireForm";
  incomeError: boolean = false;
  capital_Yeild: any = "";
  quesIndex: number = 0;
  riskValidations: any = [];
  resValidations: any = [];
  schedularAmt: number = 0;
  avaliableAmt: any = {
    val: 0,
    isNeg: true,
    liquidityNeedVal: 0,
    liquidityNeedCheck: false,
  };

  modalConfig = {
    class: "modal-md",
    backdrop: true,
    ignoreBackdropClick: true,
  };

  sendData: any = {};
  // checkAmount: any = this.http.credDataObj.checkAmount;
  checkAmount: any = 10000000;
  editForm: any = [];
  selectedprospect: any;
  selExistingProsObj: any;
  existingProsEditIs: boolean = false;
  getLoginUserData: any = {};
  ngOnInit() {
    this.app.setAppTitle("Questionnaire Form");
    this.selectedprospect = JSON.parse(
      sessionStorage.getItem("selctedPersonaObj")
    );
    this.selExistingProsObj = JSON.parse(
      sessionStorage.getItem("selExistingProsObj")
    );
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
    setTimeout(
      function () {
        if (this.selExistingProsObj) {
          this.setData();
          this.getCyPercentage(false);
        } else {
          if (
            this.selectedprospect &&
            (this.selectedprospect.rm_status == "pending" ||
              this.selectedprospect.rm_status == "allocation created" ||
              this.selectedprospect.rm_status == "rejected")
          ) {
            this.existingProsEditIs = true;
            this.quesEdit();
          } else {
            this.getCyPercentage(false);
          }
        }
      }.bind(this),
      100
    );
  }

  /* Bind Data If Existing Client/Prospect */
  setData() {
    this.sendData.firstname =
      this.selExistingProsObj.first_name != null
        ? this.selExistingProsObj.first_name
        : "";
    this.sendData.middlename = this.selExistingProsObj.middle_name;
    this.sendData.lastname =
      this.selExistingProsObj.last_name != null
        ? this.selExistingProsObj.last_name
        : "";
    this.sendData.dob =
      this.selExistingProsObj.dob != "" && this.selExistingProsObj.dob != null
        ? new Date(this.selExistingProsObj.dob)
        : "";
    this.sendData.email =
      this.selExistingProsObj.email_id != null
        ? this.selExistingProsObj.email_id
        : "";
    this.sendData.contact = this.selExistingProsObj.contact_no;
    this.sendData.region = this.selExistingProsObj.region;
    this.sendData.gender =
      this.selExistingProsObj.gender != null
        ? this.selExistingProsObj.gender
        : "";
  }
  /* Get Allocated Capital Yield Percentage */
  async getCyPercentage(callingIs) {
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getCYperc(headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.capital_Yeild = response.CY_Percentage;
          if (callingIs) {
            this.investmentSchedular();
          }
        } else {
          this.toastr.error("There is No Email ID");
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  /* Set Geo Asset Value */
  setGeoAsset(type, index) {
    var isType = type == "cg" ? "capitalGrowth" : "capitalYielding";
    var setStatus = this.questionnare[14].answer[isType][index].exclude;
    this.questionnare[14].answer[isType][index].exclude =
      setStatus == "yes" ? "no" : "yes";
    var count = 0;
    for (var g = 0; g < this.questionnare[14].answer[isType].length; g++) {
      if (this.questionnare[14].answer[isType][g].exclude == "yes") {
        count++;
      }
      if (this.questionnare[14].answer[isType].length - 1 == g) {
        if (this.questionnare[14].answer[isType].length == count) {
          this.questionnare[14].answer[isType][index].exclude = "no";
        }
      }
    }
  }

  async quesEdit() {
    var sendData = {
      instanceId: this.selectedprospect.instanceId,
    };
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getprintData(sendData, headerObj).subscribe((response: any) => {
      const dataResponse = response;
      this.app.getLoader(false);
      if (dataResponse.success) {
        this.editForm = dataResponse.data;
        this.sendData.firstname = this.editForm.basic.first_name
          ? this.editForm.basic.first_name
          : "";
        this.sendData.middlename = this.editForm.basic.middle_name;
        this.sendData.lastname = this.editForm.basic.last_name
          ? this.editForm.basic.last_name
          : "";
        this.sendData.dob =
          this.editForm.basic.dob != "" && this.editForm.basic.dob
            ? new Date(this.editForm.basic.dob)
            : "";
        this.sendData.email = this.editForm.basic.email
          ? this.editForm.basic.email
          : "";
        this.sendData.contact = this.editForm.basic.contact_no;
        this.sendData.region = this.editForm.basic.location;
        this.sendData.gender = this.editForm.basic.gender
          ? this.editForm.basic.gender
          : "";
        var questionnaire1 = this.editForm.questionnare;
        for (let x = 0; x < questionnaire1.length; x++) {
          const data_obj = questionnaire1[x];
          if (data_obj.question_no == 1) {
            this.questionnare[0].answer = data_obj.answer
              ? data_obj.answer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "";
          }
          if (data_obj.question_no == 2) {
            var question_2 = JSON.parse(data_obj.answer)[0];
            this.questionnare[1].answer[0].immediately = question_2.immediately
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.questionnare[1].answer[0].year1 = question_2.year1
              ? question_2.year1
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "";
            this.questionnare[1].answer[0].year2 = question_2.year2
              ? question_2.year2
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "";
            this.questionnare[1].answer[0].above2Years = question_2.above2Years
              ? question_2.above2Years
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "";
          }
          if (data_obj.question_no == 3) {
            this.questionnare[2].answer = data_obj.answer
              ? data_obj.answer
              : "";
          }
          if (data_obj.question_no == 4) {
            this.questionnare[3].answer = data_obj.answer
              ? data_obj.answer
              : "";
          }
          if (data_obj.question_no == 5) {
            this.questionnare[4].answer = data_obj.answer
              ? data_obj.answer
              : "";
          }
          if (data_obj.question_no == 6) {
            this.questionnare[5].answer = data_obj.answer
              ? data_obj.answer
              : "";
          }
          if (data_obj.question_no == 7) {
            this.questionnare[6].answer = data_obj.answer
              ? data_obj.answer
              : "";
          }
          if (data_obj.question_no == 8) {
            if (this.questionnare[6].answer == "Yes") {
              this.questionnare[7].answer = data_obj.answer
                ? data_obj.answer
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "";
              this.incomeValidation();
            }
          }
          if (data_obj.question_no == 10) {
            //this.questionnare[9].answer = data_obj.answer ? data_obj.answer : "";
            /* portfolioObjectives = [
    {
      title: "Indefinite",
      desc: "Inter-generational wealth"
    }, {
      title: "Long Term",
      desc: "Three to seven years"
    },
    {
      title: "Short Term",
      desc: "Less than three years"
    }
  ]; */
            if (data_obj.answer) {
              for (let x = 0; x < this.portfolioObjectives.length; x++) {
                const portFolioObj = this.portfolioObjectives[x];
                if (portFolioObj.title == data_obj.answer) {
                  this.selectObjecive(portFolioObj);
                  break;
                }
              }
            }
          }
          if (data_obj.question_no == 11) {
            this.questionnare[10].answer = data_obj.answer
              ? data_obj.answer
              : "";
          }
          if (data_obj.question_no == 12) {
            this.questionnare[11].answer = data_obj.answer
              ? data_obj.answer
              : "";
          }
          if (data_obj.question_no == 17) {
            this.questionnare[15].answer = data_obj.answer
              ? data_obj.answer
              : "";
          }
          if (data_obj.question_no == 18) {
            this.questionnare[16].answer = data_obj.answer
              ? data_obj.answer
              : "";
          }
          if (data_obj.question_no == 16) {
            if (data_obj.answer && data_obj.answer != "null") {
              let question_14 = JSON.parse(data_obj.answer);
              this.questionnare[14].answer.capitalGrowth[0].exclude =
                question_14.capitalGrowth[0].exclude;
              this.questionnare[14].answer.capitalGrowth[1].exclude =
                question_14.capitalGrowth[1].exclude;
              this.questionnare[14].answer.capitalGrowth[2].exclude =
                question_14.capitalGrowth[2].exclude;
              this.questionnare[14].answer.capitalYielding[0].exclude =
                question_14.capitalYielding[0].exclude;
              this.questionnare[14].answer.capitalYielding[1].exclude =
                question_14.capitalYielding[1].exclude;
              this.questionnare[14].answer.capitalYielding[2].exclude =
                question_14.capitalYielding[2].exclude;
            }
          }
          if (data_obj.question_no == 13) {
            if (data_obj.answer) {
              let question_12 = JSON.parse(data_obj.answer)[0];
              this.questionnare[12].answer[0].cash = question_12.cash;
              this.questionnare[12].answer[0].realestate =
                question_12.realestate;
              this.questionnare[12].answer[0].privateequity =
                question_12.privateequity;
              this.questionnare[12].answer[0].fixedincome =
                question_12.fixedincome;
              this.questionnare[12].answer[0].equities = question_12.equities;
              this.calcTotalWealth(null);
            }
          }
          // if (data_obj.question_no == 14) {
          //   let question13 = JSON.parse(data_obj.answer);
          //   this.attitudinalData.riskProfile = question13.riskProfile;
          //   this.attitudinalData.responsiveness = question13.responsiveness;
          // }
          this.sendData.risk_score = this.editForm.basic.risk_score;
          this.sendData.responsiveness = this.editForm.basic.responsiveness;
          if (data_obj.question_no == 9) {
            if (data_obj.answer) {
              let liqData = JSON.parse(data_obj.answer);
              if (liqData.length > 1) {
                var question_8 = JSON.parse(data_obj.answer);
                this.questionnare[8].answer[0].liquidity = question_8[0]
                  .liquidity
                  ? question_8[0].liquidity
                  : "";
                this.questionnare[8].answer[0].year1 = question_8[1].year1
                  ? question_8[1].year1
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "";
                this.questionnare[8].answer[0].year2 = question_8[2].year2
                  ? question_8[2].year2
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "";
                this.questionnare[8].answer[0].year3 = question_8[3].year3
                  ? question_8[3].year3
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "";
              } else {
                let question_8 = liqData[0];
                this.questionnare[8].answer[0].liquidity = question_8.liquidity
                  ? question_8.liquidity
                  : "";
                this.questionnare[8].answer[0].year1 = question_8.year1
                  ? question_8.year1
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "";
                this.questionnare[8].answer[0].year2 = question_8.year2
                  ? question_8.year2
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "";
                this.questionnare[8].answer[0].year3 = question_8.year3
                  ? question_8.year3
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "";
              }
            }
          }
          // this.sendData.risk_score=this.editForm.basic.risk_score;
          // this.sendData.responsiveness=this.editForm.basic.responsiveness;
        }
        this.getCyPercentage(true);
      }
    });
  }

  /* Save Questionnaire Form */
  onSaveValidationForScroll(isSubmit, params) {
    if (isSubmit) {
      this.openModal(this.confirmationModalBox);
    } else {
      //alert("Please fill the all required fields");
      this.toastr.error("Please fill the all required fields");
      this.validateForm(params);
    }
  }

  async saveQuestionnaireData(params) {
    var isSubmit = false;
    if (params == "finalSubmit") {
      isSubmit = true;
    } else {
      if (params.form.status == "VALID") {
        if (this.questionnare[0].answer) {
          if (this.questionnare[1].answer[0].immediately) {
            isSubmit = true;
          }
        }
        if (this.sendData.firstname && this.sendData.lastname) {
          isSubmit = true;
        } else {
          isSubmit = false;
          this.onSaveValidationForScroll(isSubmit, params);
          return false;
        }
        if (this.sendData.risk_score && this.sendData.responsiveness) {
          if (!this.riskError && !this.responseError) {
            isSubmit = true;
            console.log("aaa");
          } else {
            isSubmit = false;
            console.log("ccc");
            return false;
          }
        }
        // if (this.sendData.region == "Other" && isSubmit) {
        //   if (this.sendData.isRegion != "") {
        //     isSubmit = true;
        //   } else {
        //     isSubmit = false;
        //     this.onSaveValidationForScroll(isSubmit, params);
        //     return false;
        //   }
        // }

        // if (this.questionnare[2].answer == "" && isSubmit) {
        //   if (this.questionnare[2].others) {
        //     isSubmit = true;
        //   } else {
        //     isSubmit = false;
        //     this.onSaveValidationForScroll(isSubmit, params);
        //     return false;
        //   }
        // }
        if (this.questionnare[6].answer == "Yes" && isSubmit) {
          if (this.questionnare[7].answer && !this.incomeError) {
            isSubmit = true;
          } else {
            isSubmit = false;
            this.onSaveValidationForScroll(isSubmit, params);
            return false;
          }
        }
        isSubmit = this.questionnare[9].answer ? true : false;
        if (this.questionnare[8].answer[0].liquidity == "Yes" && isSubmit) {
          if (!this.avaliableAmt.liquidityNeedCheck) {
            if (
              this.questionnare[8].answer[0].year1 ||
              this.questionnare[8].answer[0].year2 ||
              this.questionnare[8].answer[0].year3
            ) {
              isSubmit = true;
            } else {
              isSubmit = false;
            }
          } else {
            isSubmit = false;
          }
        }
        if (this.avaliableAmt.val) {
          isSubmit = false;
        }
        if (this.capital_Yeild < this.incomePerc) {
          isSubmit = false;
        }
      }

      // let riskEle = this.attitudinalData.riskProfile;
      // let resEle = this.attitudinalData.responsiveness;
      // for (let i = 0; i < riskEle.length; i++) {
      //   if (riskEle[i].value == 0) {
      //     this.riskValidations[i] = "inValid";
      //     isSubmit = false;
      //   } else {
      //     this.riskValidations[i] = "";
      //   }
      // }
      // for (let i = 0; i < resEle.length; i++) {
      //   if (resEle[i].value == 0) {
      //     this.resValidations[i] = "inValid";
      //     isSubmit = false;
      //   } else {
      //     this.resValidations[i] = "";
      //   }
      // }

      this.onSaveValidationForScroll(isSubmit, params);
    }

    if (isSubmit && params == "finalSubmit") {
      var questionnareData = JSON.stringify(this.questionnare);
      questionnareData = this.convertData(questionnareData);
      console.log(this.questionnare);
      // var todayDate =
      //   new Date().getFullYear() +
      //   "-" +
      //   ("0" + (new Date().getMonth() + 1)).slice(-2) +
      //   "-" +
      //   ("0" + new Date().getDate()).slice(-2);

      // var isDob = "";
      // var age: any = "";
      // var dob = "";
      // if (this.sendData.dob != "" && this.sendData.dob != undefined) {
      //   isDob =
      //     this.sendData.dob.getFullYear() +
      //     "/" +
      //     (this.sendData.dob.getMonth() + 1) +
      //     "/" +
      //     this.sendData.dob.getDate();

      //   dob = this.sendData.dob;
      //   var today = new Date();
      //   var birthDate = new Date(dob);
      //   age = today.getFullYear() - birthDate.getFullYear();
      //   var m = today.getMonth() - birthDate.getMonth();
      //   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      //     age--;
      //   }
      // }

      var sendData = {
        id: "",
        instanceId: "",
        rmId: this.getLoginUserData.id,
        type: "",
        status: "",
        basic: {
          first_name: !this.sendData.firstname ? "" : this.sendData.firstname,
          last_name: !this.sendData.lastname ? "" : this.sendData.lastname,
          middle_name: !this.sendData.middlename
            ? ""
            : this.sendData.middlename,
          // age: age == "" ? 0 : age,
          // dob: isDob == "" ? null : isDob,
          // gender: this.sendData.gender,
          // location: this.sendData.region,
          // email: this.sendData.email,
          // contact_no: this.sendData.contact,
          // questionnaireDate: todayDate,
          clientId: "",
          csv: this.schedularAmt,
          app_status: "",
          user_id: "",
          privacy_note: this.sendData.privacynote,
        },
        questionnare: [],
        risk_score: !this.sendData.risk_score
          ? ""
          : parseInt(this.sendData.risk_score),
        responsiveness: !this.sendData.responsiveness
          ? ""
          : parseInt(this.sendData.responsiveness),
      };
      if (this.selExistingProsObj) {
        sendData.id = this.selExistingProsObj.id;
        sendData.type = "existing";
      } else {
        if (this.existingProsEditIs) {
          sendData.id = this.selectedprospect.prospect_id;
          sendData.instanceId = this.selectedprospect.instanceId;
          sendData.type = "edit";
          sendData.status = this.selectedprospect.rm_status;
        } else {
          sendData.id = "";
          sendData.type = "new";
        }
      }

      sendData.questionnare.push(questionnareData)[0];
      if (this.investmentAmt < this.checkAmount) {
        var setData = {
          question_no: 16,
          answer: null,
        };
        sendData.questionnare[0][14] = setData;
      }
      sendData.questionnare = sendData.questionnare[0];

      this.app.getLoader(true);
      var headerObj = await this.app.getHTTPHeaders();
      this.http.onBoardingProspectForm(sendData, headerObj).subscribe(
        (response: any) => {
          var dataResponse = response;
          if (dataResponse.success) {
            sessionStorage.setItem(
              "selctedPersonaObj",
              JSON.stringify(dataResponse.data)
            );
            this.toastr.success("Thank You for submitting form");
            sessionStorage.removeItem("selExistingProsObj");
            setTimeout(
              function () {
                this.app.navigateThis("home/dashboard");
                this.app.getLoader(false);
              }.bind(this),
              1000
            );
          } else {
            this.toastr.error(dataResponse.msg);
            this.app.getLoader(false);
          }
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    }
  }
  isregion = false;
  /* Form Validation And Scroll */
  validateForm(form) {
    // if (this.sendData.region == 'Other' && this.sendData.isRegion == '') {
    //   this.isregion = true;

    // }
    // else {
    //   this.isregion = false;
    // }
    var validForm = true;
    var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var validEmail = emailRegex.test(this.sendData.email);
    var isJsonData = this.formFieldID.getFormIdList();
    if (
      this.sendData.firstname &&
      this.sendData.lastname &&
      validEmail &&
      this.sendData.gender &&
      this.sendData.region &&
      this.isregion == false
    ) {
      for (var j = 0; j < isJsonData.questinnaireIndex.length; j++) {
        var formField = isJsonData.questinnaireIndex[j];
        var isData = this.questionnare[formField.index];
        var isElement = document.getElementById(formField.questionID);
        isElement.scrollIntoView();
        if (
          formField.index == 1 ||
          formField.index == 6 ||
          formField.index == 8
        ) {
          if (
            isData.answer[0].immediately == "" ||
            this.avaliableAmt.val != 0
          ) {
            validForm = false;
            break;
          }

          if (isData.answer == "Yes") {
            if (
              this.questionnare[formField.index + 1].answer == "" ||
              this.incomeError
            ) {
              validForm = false;
              break;
            }
          }

          if (isData.answer[0].liquidity == "Yes") {
            if (
              isData.answer[0].year1 == "" &&
              isData.answer[0].year2 == "" &&
              isData.answer[0].year3 == ""
            ) {
              validForm = false;
              break;
            }
          }
        } else {
          if (isData.answer == "") {
            if (formField.index == 2) {
              if (isData.answer == "" && isData.others == "") {
                validForm = false;
                break;
              }
            } else {
              validForm = false;
              break;
            }
          }
        }
      }
    } else {
      // if (!validEmail) {
      //   var isElement = document.getElementById("email");
      //   isElement.scrollIntoView();
      //   return false;
      // }
      // if (this.sendData.region == "") {
      //   var isElement = document.getElementById("region");
      //   isElement.scrollIntoView();
      //   return false;
      // }
      // if (this.isregion == true) {
      //   var isElement = document.getElementById("region");
      //   isElement.scrollIntoView();
      //   return false;
      // }
      for (var i = 0; i < isJsonData.basic.length; i++) {
        var isFormField = isJsonData.basic[i];
        var isFormData = this.sendData[isFormField];
        var isElement = document.getElementById(isFormField);
        isElement.scrollIntoView();
        if (isFormData == "") {
          validForm = false;
          break;
        }
      }
    }

    if (validForm) {
      let riskEle = this.attitudinalData.riskProfile;
      let resEle = this.attitudinalData.responsiveness;
      var riskResponseData = this.formFieldID.getFormIdList();
      for (let i = 0; i < riskEle.length; i++) {
        var inputRiskProfile = riskResponseData.riskProfile[i];
        var isElement = document.getElementById(inputRiskProfile);
        isElement.scrollIntoView();
        if (riskEle[i].value == 0) {
          break;
        }

        if (i == riskEle.length - 1) {
          for (let i = 0; i < resEle.length; i++) {
            var inputResponsiveness = riskResponseData.responsiveness[i];
            var isElement = document.getElementById(inputResponsiveness);
            isElement.scrollIntoView();
            if (resEle[i].value == 0) {
              break;
            }
          }
        }
      }
    }
  }

  /* Convert Numbers Input */
  convertData(questionnareData) {
    var questionnare = JSON.parse(questionnareData);
    //Converting Investment Amount
    // var isInvestmentAmt =
    //   questionnare[0].answer == ""
    //     ? ""
    //     : questionnare[0].answer.replace(/,/g, "");
    // questionnare[0].answer =
    //   isInvestmentAmt == "" ? "" : parseInt(isInvestmentAmt);

    //Converting Investment Schedular Amount
    var immediateAmt =
      questionnare[1].answer[0].immediately == ""
        ? ""
        : questionnare[1].answer[0].immediately.replace(/,/g, "");
    questionnare[1].answer[0].immediately =
      immediateAmt == "" ? "" : parseInt(immediateAmt);

    var scheduleYear1 =
      questionnare[1].answer[0].year1 == ""
        ? ""
        : questionnare[1].answer[0].year1.replace(/,/g, "");
    questionnare[1].answer[0].year1 =
      scheduleYear1 == "" ? "" : parseInt(scheduleYear1);

    var scheduleYear2 =
      questionnare[1].answer[0].year2 == ""
        ? ""
        : questionnare[1].answer[0].year2.replace(/,/g, "");
    questionnare[1].answer[0].year2 =
      scheduleYear2 == "" ? "" : parseInt(scheduleYear2);

    var above2Years =
      questionnare[1].answer[0].above2Years == ""
        ? ""
        : questionnare[1].answer[0].above2Years.replace(/,/g, "");
    questionnare[1].answer[0].above2Years =
      above2Years == "" ? "" : parseInt(above2Years);

    var isInvestmentAmt =
      questionnare[1].answer[0].immediately +
      questionnare[1].answer[0].year1 +
      questionnare[1].answer[0].year2 +
      questionnare[1].answer[0].above2Years;

    questionnare[0].answer = parseInt(isInvestmentAmt);
    //Converting Income Requirement Amount
    var incomeReqAmt =
      questionnare[7].answer == ""
        ? ""
        : questionnare[7].answer.replace(/,/g, "");
    questionnare[7].answer = incomeReqAmt == "" ? "" : parseInt(incomeReqAmt);

    //Converting Loquidity Needs Amount
    var year1LNeeds =
      questionnare[8].answer[0].year1 == ""
        ? ""
        : questionnare[8].answer[0].year1.replace(/,/g, "");
    questionnare[8].answer[0].year1 =
      year1LNeeds == "" ? "" : parseInt(year1LNeeds);

    var year2LNeeds =
      questionnare[8].answer[0].year2 == ""
        ? ""
        : questionnare[8].answer[0].year2.replace(/,/g, "");
    questionnare[8].answer[0].year2 =
      year2LNeeds == "" ? "" : parseInt(year2LNeeds);

    var year3LNeeds =
      questionnare[8].answer[0].year3 == ""
        ? ""
        : questionnare[8].answer[0].year3.replace(/,/g, "");
    questionnare[8].answer[0].year3 =
      year3LNeeds == "" ? "" : parseInt(year3LNeeds);

    //Assigning Onbehalf
    if (questionnare[2].answer == "") {
      questionnare[2].answer = questionnare[2].others;
    }

    //Assigning Appropriate Objectives
    if (questionnare[3].answer == "Other") {
      questionnare[3].answer = questionnare[3].others;
    }
    if (this.sendData.region == "Other") {
      this.sendData.region = this.sendData.isRegion;
    }

    /* Schedular Percentage */
    var investmentAmt = parseInt(questionnare[0].answer);
    questionnare[1].answer[0].immediatelyPerc =
      questionnare[1].answer[0].immediately != ""
        ? (
            (parseInt(questionnare[1].answer[0].immediately) / investmentAmt) *
            100
          ).toFixed(2)
        : "";

    questionnare[1].answer[0].year1Perc =
      questionnare[1].answer[0].year1 != ""
        ? (
            (parseInt(questionnare[1].answer[0].year1) / investmentAmt) *
            100
          ).toFixed(2)
        : "";

    questionnare[1].answer[0].year2Perc =
      questionnare[1].answer[0].year2 != ""
        ? (
            (parseInt(questionnare[1].answer[0].year2) / investmentAmt) *
            100
          ).toFixed(2)
        : "";

    questionnare[1].answer[0].above2YearsPerc =
      questionnare[1].answer[0].above2Years != ""
        ? (
            (parseInt(questionnare[1].answer[0].above2Years) / investmentAmt) *
            100
          ).toFixed(2)
        : "";

    return questionnare;
  }

  /* Open Modal */
  openModal(template: TemplateRef<any>) {
    this.qstrConfirmationModalRef = this.modalService.show(
      template,
      this.modalConfig
    );
  }

  attitudinalData: any = {
    riskProfile: [
      {
        answer: "",
        value: 0,
        question: "I tend to avoid investment risks.",
      },
      {
        answer: "",
        value: 0,
        question:
          "It is more importent for me to invest safely then take risk for the Chance of higher return.",
      },
      {
        answer: "",
        value: 0,
        question: "I am more prepared to take investment risk than others.",
      },
      {
        answer: "",
        value: 0,
        question:
          "I am willing to Put a significant part of my wealth in high-risk investment.",
      },
      {
        answer: "",
        value: 0,
        question:
          "I am prepared to risk losing money if there is also a Chance to gain..",
      },
    ],
    responsiveness: [
      {
        answer: "",
        value: 0,
        question: " I tend to buy and sell investment more then I should .",
      },
      {
        answer: "",
        value: 0,
        question:
          "I have previously sold significant investment after sustained losses.",
      },
      {
        answer: "",
        value: 0,
        question: "I Tend to Review My Investment Frequently.",
      },
      {
        answer: "",
        value: 0,
        question: "Uncertainty makes me uneasy.",
      },
    ],
  };

  questionnare = [
    {
      question_no: 1,
      answer: "",
    },
    {
      question_no: 2,
      answer: [
        {
          immediately: "",
          year1: "",
          year2: "",
          above2Years: "",
          immediatelyPerc: "",
          year1Perc: "",
          year2Perc: "",
          above2YearsPerc: "",
        },
      ],
    },
    {
      question_no: 3,
      answer: "",
      others: "",
    },
    {
      question_no: 4,
      answer: "Growth",
      others: "",
    },
    {
      question_no: 5,
      answer: "",
    },
    {
      question_no: 6,
      answer: "",
    },
    {
      question_no: 7,
      answer: "",
    },
    {
      question_no: 8,
      answer: "",
    },
    {
      question_no: 9,
      answer: [
        {
          liquidity: "",
          year1: "",
          year2: "",
          year3: "",
          total_wealth: "",
        },
      ],
    },
    {
      question_no: 10,
      answer: "",
      desc: "",
    },
    {
      question_no: 11,
      answer: "",
    },
    {
      question_no: 12,
      answer: "",
    },
    {
      question_no: 13,
      answer: [
        {
          cash: 100,
          realestate: 0,
          privateequity: 0,
          fixedincome: 0,
          equities: 0,
        },
      ],
    },
    {
      question_no: 14,
      answer: this.attitudinalData,
    },
    {
      question_no: 16,
      answer: {
        capitalGrowth: [
          {
            fund: "US PE",
            exclude: "no",
          },
          {
            fund: "Asian PE",
            exclude: "no",
          },
          {
            fund: "Asian RE",
            exclude: "no",
          },
        ],
        capitalYielding: [
          {
            fund: "US RE Debt",
            exclude: "no",
          },
          {
            fund: "US Private Credit",
            exclude: "no",
          },
          {
            fund: "US RE",
            exclude: "no",
          },
        ],
      },
    },
    {
      question_no: 17,
      answer: "",
    },
    {
      question_no: 18,
      answer: "",
    },
  ];

  portfolioObjectives = [
    {
      title: "Indefinite",
      desc: "Inter-generational wealth",
    },
    {
      title: "Long Term",
      desc: "Three to seven years",
    },
    {
      title: "Short Term",
      desc: "Less than three years",
    },
  ];
  riskContradictQues = [0, 0, 0, 0, 0];

  riskContradict() {
    if (
      Math.abs(this.riskContradictQues[1] - this.riskContradictQues[0]) > 1 &&
      Math.abs(this.riskContradictQues[2] - this.riskContradictQues[0]) > 1
    ) {
    } else {
    }
    if (
      Math.abs(this.riskContradictQues[2] - this.riskContradictQues[1]) > 1 &&
      Math.abs(this.riskContradictQues[0] - this.riskContradictQues[1]) > 1
    ) {
    } else {
    }
  }

  /* Calculate Risk Score */
  calcRiskScore(score, type, callback, index, quesIndex) {
    if (callback == "riskProfile") {
      this.riskValidations[index] = "";
      this.quesIndex = quesIndex;
      for (var r = 0; r < this.attitudinalData.riskProfile.length; r++) {
        this.attitudinalData.riskProfile[index].value = score;
        this.attitudinalData.riskProfile[index].answer = type;
      }
      this.riskContradictQues[index] = score;
    } else {
      this.resValidations[index] = "";
    }

    if (callback == "responsiveness") {
      this.quesIndex = quesIndex;
      for (var r = 0; r < this.attitudinalData.responsiveness.length; r++) {
        this.attitudinalData.responsiveness[index].value = score;
        this.attitudinalData.responsiveness[index].answer = type;
      }
    }
    this.riskContradict();
  }

  /* Investment Schedular */
  warnMsg: any;
  isDisableYearOne: any;
  isDisableYearTwo: any;
  isDisableYearThree: any;
  isDisableYearAbove: any;
  investmentAmt: any = 0;
  roundImmediately(value, para) {
    if (para == "investment") {
      var investment = value.target.value.replace(/,/g, "");
      this.questionnare[0].answer = Math.round(investment / 10000) * 10000;
      this.questionnare[0].answer = Number(
        this.questionnare[0].answer
      ).toLocaleString("en-GB");
    } else if (para == "year1") {
      var year1 = value.target.value.replace(/,/g, "");
      this.questionnare[1].answer[0].year1 = Math.round(year1 / 10000) * 10000;
      this.questionnare[1].answer[0].year1 = Number(
        this.questionnare[1].answer[0].year1
      ).toLocaleString("en-GB");
    } else {
      var immediate = value.target.value.replace(/,/g, "");
      this.questionnare[1].answer[0].immediately =
        Math.round(immediate / 10000) * 10000;
      this.questionnare[1].answer[0].immediately = Number(
        this.questionnare[1].answer[0].immediately
      ).toLocaleString("en-GB");
    }
  }
  investmentSchedular() {
    var investmentAmt =
      this.questionnare[0].answer == ""
        ? 0
        : this.questionnare[0].answer.replace(/,/g, "");
    if (parseInt(investmentAmt) > 0) {
      var immediately =
        this.questionnare[1].answer[0].immediately == ""
          ? 0
          : this.questionnare[1].answer[0].immediately.replace(/,/g, "");
      var yearOne =
        this.questionnare[1].answer[0].year1 == ""
          ? 0
          : this.questionnare[1].answer[0].year1.replace(/,/g, "");
      var yearTwo =
        this.questionnare[1].answer[0].year2 == ""
          ? 0
          : this.questionnare[1].answer[0].year2.replace(/,/g, "");

      var aboveThree =
        this.questionnare[1].answer[0].above2Years == ""
          ? 0
          : this.questionnare[1].answer[0].above2Years.replace(/,/g, "");

      var schedularAmt =
        parseInt(immediately) +
        parseInt(yearOne) +
        parseInt(yearTwo) +
        parseInt(aboveThree);
      this.schedularAmt = schedularAmt;
      this.avaliableAmt.val = investmentAmt - schedularAmt;
      if (Math.sign(this.avaliableAmt.val) == -1) {
        this.avaliableAmt.isNeg = true;
      } else {
        this.avaliableAmt.isNeg = false;
      }
      if (schedularAmt > investmentAmt) {
        if (investmentAmt == immediately) {
          this.questionnare[1].answer[0].year1 = "";
          this.questionnare[1].answer[0].year2 = "";
          this.questionnare[1].answer[0].above2Years = "";
          this.warnMsg = null;
        } else {
          this.warnMsg =
            "Schedule amount should be less than the investment amount..";
        }
      } else {
        this.warnMsg = null;
      }

      if (schedularAmt == investmentAmt) {
        this.getDisabledInput(true, "schedular");
      }
      if (schedularAmt < investmentAmt) {
        this.getDisabledInput(false, "schedular");
      }
      this.investmentAmt = investmentAmt;

      var geoAssets = ["capitalGrowth", "capitalYielding"];

      if (this.investmentAmt < this.app.credDataObj.checkAmount) {
        for (var i = 0; i < geoAssets.length; i++) {
          var isType = geoAssets[i];
          for (
            var g = 0;
            g < this.questionnare[14].answer[isType].length;
            g++
          ) {
            this.questionnare[14].answer[isType][g].exclude = "no";
          }
        }
      }
      this.incomeValidation();
      this.calcLiquidityNeeds();
    }
    // else {
    //   this.avaliableAmt.val = 0;
    //   this.toastr.info("Please enter investment amount");
    // }
  }

  /* Disabled Un wanted Input In Schedular*/
  getDisabledInput(value, callback) {
    if (callback == "schedular") {
      if (this.questionnare[1].answer[0].year1 == "") {
        this.isDisableYearOne = value;
      }
      if (this.questionnare[1].answer[0].year2 == "") {
        this.isDisableYearTwo = value;
      }

      if (this.questionnare[1].answer[0].above2Years == "") {
        this.isDisableYearAbove = value;
      }
    } else {
      if (this.questionnare[8].answer[0].year2 == "") {
        this.isLNDisableYearTwo = value;
      }
      if (this.questionnare[8].answer[0].year3 == "") {
        this.isLNDisableYearThree = value;
      }
    }
  }

  /* Income Validation */
  incomePerc: any = 0;
  incomeValidation() {
    var immediateAmt =
      this.questionnare[1].answer[0].immediately != ""
        ? this.questionnare[1].answer[0].immediately.replace(/,/g, "")
        : 0;
    var incomeAmt = this.questionnare[7].answer.replace(/,/g, "");
    var incReqAmountPerc = ((incomeAmt / immediateAmt) * 100).toFixed(2);
    //var amount = (incomeAmt / 100) * this.capital_Yeild;
    if (this.capital_Yeild < incReqAmountPerc) {
      this.incomeError = true;
    } else {
      this.incomeError = false;
    }
    //var isIcomePerc = ((incomeAmt / immediateAmt) * 100).toFixed(2);
    this.incomePerc = incReqAmountPerc;
  }

  RiskResponseValidation() {
    if (this.sendData.risk_score) {
      if (
        parseInt(this.sendData.risk_score) < 5 ||
        parseInt(this.sendData.risk_score) > 25
      ) {
        this.riskError = true;
        console.log(this.sendData.risk_score);
        console.log(this.riskError);
      } else {
        this.riskError = false;
      }
    }
    if (this.sendData.responsiveness) {
      if (
        parseInt(this.sendData.responsiveness) < 4 ||
        parseInt(this.sendData.responsiveness) > 20
      ) {
        this.responseError = true;
      } else {
        this.responseError = false;
      }
    }
  }

  /* Calculate Liquidity Needs */
  liquidityNeedsWarnMsg: any;
  isLNDisableYearTwo: any;
  isLNDisableYearThree: any;
  roundLiquidity(value, callback) {
    var liquidity = value.target.value.replace(/,/g, "");
    var abc = Math.round(liquidity / 10000) * 10000;
    if (callback == "year1") {
      this.questionnare[8].answer[0].year1 = Number(abc).toLocaleString(
        "en-GB"
      );
    } else if (callback == "year2") {
      this.questionnare[8].answer[0].year2 = Number(abc).toLocaleString(
        "en-GB"
      );
    } else {
      this.questionnare[8].answer[0].year3 = Number(abc).toLocaleString(
        "en-GB"
      );
    }
  }
  calcLiquidityNeeds() {
    var immediately =
      this.questionnare[1].answer[0].immediately == ""
        ? 0
        : this.questionnare[1].answer[0].immediately.replace(/,/g, "");

    var yearOne =
      this.questionnare[8].answer[0].year1 == ""
        ? 0
        : this.questionnare[8].answer[0].year1.replace(/,/g, "");
    var yearTwo =
      this.questionnare[8].answer[0].year2 == ""
        ? 0
        : this.questionnare[8].answer[0].year2.replace(/,/g, "");
    var yearThree =
      this.questionnare[8].answer[0].year3 == ""
        ? 0
        : this.questionnare[8].answer[0].year3.replace(/,/g, "");

    var liquidityNeeds =
      parseInt(yearOne) + parseInt(yearTwo) + parseInt(yearThree);

    this.avaliableAmt.liquidityNeedVal = immediately - liquidityNeeds;
    if (Math.sign(this.avaliableAmt.liquidityNeedVal) == -1) {
      this.avaliableAmt.liquidityNeedCheck = true;
    } else {
      this.avaliableAmt.liquidityNeedCheck = false;
    }

    if (liquidityNeeds > immediately) {
      if (immediately == yearOne) {
        this.questionnare[8].answer[0].year2 = "";
        this.questionnare[8].answer[0].year3 = "";
        this.liquidityNeedsWarnMsg = null;
      } else {
        this.liquidityNeedsWarnMsg =
          "Liquidity needs amount should be less than the immediate fund amount..";
      }
    } else {
      this.liquidityNeedsWarnMsg = null;
    }

    if (liquidityNeeds == immediately) {
      this.getDisabledInput(true, "liquidityNeeds");
    }
    if (liquidityNeeds < immediately) {
      this.getDisabledInput(false, "liquidityNeeds");
    }

    this.questionnare[8].answer[0].total_wealth = liquidityNeeds;
  }

  /* Calculate Total Wealth */
  calcTotalWealth(getMdlName) {
    var totalOfOthers =
      this.questionnare[12].answer[0].realestate +
      this.questionnare[12].answer[0].privateequity +
      this.questionnare[12].answer[0].fixedincome +
      this.questionnare[12].answer[0].equities;
    if (totalOfOthers <= 100) {
      this.questionnare[12].answer[0].cash = 100 - totalOfOthers;
    } else {
      var extraVal = 0;
      if (getMdlName == "realestate") {
        extraVal =
          100 -
          (this.questionnare[12].answer[0].privateequity +
            this.questionnare[12].answer[0].fixedincome +
            this.questionnare[12].answer[0].equities);
      }
      if (getMdlName == "privateequity") {
        extraVal =
          100 -
          (this.questionnare[12].answer[0].realestate +
            this.questionnare[12].answer[0].fixedincome +
            this.questionnare[12].answer[0].equities);
      }
      if (getMdlName == "fixedincome") {
        extraVal =
          100 -
          (this.questionnare[12].answer[0].realestate +
            this.questionnare[12].answer[0].privateequity +
            this.questionnare[12].answer[0].equities);
      }
      if (getMdlName == "equities") {
        extraVal =
          100 -
          (this.questionnare[12].answer[0].realestate +
            this.questionnare[12].answer[0].privateequity +
            this.questionnare[12].answer[0].fixedincome);
      }
      setTimeout(() => {
        this.questionnare[12].answer[0][getMdlName] = extraVal;
      });
    }
  }

  wealthError: boolean;
  calcWealthPerc(event) {
    var immediateAmt =
      this.questionnare[1].answer[0].immediately != ""
        ? this.questionnare[1].answer[0].immediately.replace(/,/g, "")
        : 0;
    var isWealthAmt = (immediateAmt / 100) * event;
    if (immediateAmt >= isWealthAmt) {
      this.wealthError = false;
    } else {
      this.wealthError = true;
    }
  }

  /* Behalf Investment */
  behalfOthers(event) {
    if (event.length > 2) {
      this.questionnare[2].answer = "";
    }
  }

  /* Select Objecives */
  selectObjecive(item) {
    this.questionnare[9].answer = item.title;
    this.questionnare[9].desc = item.desc;
  }

  resetForm = () => {
    location.reload(true);
  };

  isConfAlert: boolean;
  ngOnDestroy() {
    if (this.qstrConfirmationModalRef) {
      this.qstrConfirmationModalRef.hide();
    }
    this.toastr.clear();
  }
}
