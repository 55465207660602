import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AppComponent } from "../app.component";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { CommonservicesService } from "../commonservices.service";
import { Subscription } from "rxjs";
import { number } from "@amcharts/amcharts4/core";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-input-solution",
  templateUrl: "./input-solution.component.html",
  styleUrls: ["./input-solution.component.scss"],
})
export class InputSolutionComponent implements OnInit {
  @ViewChild("staticTabs", { static: false }) staticTabs: TabsetComponent;
  conventionalShariahList: any;
  createInputSolutionList: any;
  modalRef: BsModalRef;
  deleteConfirmationRef: BsModalRef;
  editId: any;
  editType: any;
  editValue: any;
  totalAUM: number = 0;
  hideShowAddEditButton: boolean = false;
  isValidSolutionAum: boolean = false;
  singleEditModaldata: any = {
    id: 0,
    score: "",
    quesScore: "",
    CapitalGrowth: "",
    CapitalYielding: "",
    absolutereturn: "",
    Opportunistic: "",
    riskCategory: "",
  };
  modalConfig = {
    class: "modal-lg",
    backdrop: true,
    ignoreBackdropClick: true,
  };
  modalConfig1 = {
    class: "modal-md",
    backdrop: true,
    ignoreBackdropClick: true,
  };
  isOpen: boolean = true;

  deleteid: number = 0;
  deleteType: any;
  deletevalue: any;
  getMsg: any;
  accEvent: boolean;
  accEvent2: boolean;
  constructor(
    public app: AppComponent,
    private modalService: BsModalService,
    private http: CommonservicesService,
    private toastr: ToastrService
  ) {}
  @ViewChild("editModal", { static: false })
  public templateref: TemplateRef<any>;

  @ViewChild("deleteConfirmation", { static: false })
  public deleteConfirmation: TemplateRef<any>;

  ngOnInit() {
    setTimeout(() => {
      this.getallInputSolution("with_opp", "conventional");
      // this.getallInputSolution("without_opp", "conventional");
    }, 300);
    this.staticTabs.tabs[0].active = true;
    // this.getallInputSolution();
  }
  Acc1Eventlog(event: boolean) {
    console.log(`Accordion1 has been ${event ? "opened" : "closed"}`);
    this.accEvent = event;
  }
  Acc2Eventlog(event: boolean) {
    console.log(`Accordion2 has been ${event ? "opened" : "closed"}`);
    this.accEvent2 = event;
  }
  selectTab(tabId: number) {
    this.staticTabs.tabs[tabId].active = true;
    if (tabId == 0 && this.accEvent) {
      this.getallInputSolution("with_opp", "conventional");
    } else if (tabId == 0 && !this.accEvent) {
      this.getallInputSolution("with_opp", "shariah");
    } else if (tabId == 1 && this.accEvent2) {
      this.getallInputSolution("without_opp", "conventional");
    } else if (tabId == 1 && !this.accEvent2) {
      this.getallInputSolution("without_opp", "shariah");
    }
  }
  openModal(editModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(editModal, this.modalConfig);
  }

  deleteConfirmationModal(deleteConfirmation: TemplateRef<any>) {
    this.deleteConfirmationRef = this.modalService.show(
      deleteConfirmation,
      this.modalConfig1
    );
  }

  editmodalIs(modalData, type, value, modalType) {
    if (modalType == "edit") {
      this.hideShowAddEditButton = false;
      this.singleEditModaldata = modalData;
      this.editId = modalData.id;
      this.editType = type;
      this.editValue = value;
      this.singleEditModaldata = {
        id: modalData.id,
        score: modalData.risk_profile,
        quesScore: modalData.questionnaire_scores,
        CapitalGrowth: (modalData.capital_growth * 100).toFixed(1),
        CapitalYielding: (modalData.capital_yielding * 100).toFixed(1),
        absolutereturn: (modalData.absolute_return * 100).toFixed(1),
        Opportunistic: (modalData.opportunistic * 100).toFixed(1),
        riskCategory: modalData.risk_category,
      };

      this.calcAum();
    } else if (modalType == "add") {
      this.editType = type;
      this.editValue = value;
      this.singleEditModaldata.score = "";
      this.totalAUM = 0;
      this.singleEditModaldata.quesScore = "";
      this.singleEditModaldata.CapitalGrowth = "";
      this.singleEditModaldata.CapitalYielding = "";
      this.singleEditModaldata.absolutereturn = "";
      this.singleEditModaldata.Opportunistic = "";
      this.singleEditModaldata.riskCategory = "";
      this.hideShowAddEditButton = true;
    }

    this.openModal(this.templateref);
    //  this.updateInputSoln(id,type,value);
  }
  calcAum() {
    this.singleEditModaldata.CapitalGrowth =
      this.singleEditModaldata.CapitalGrowth == ""
        ? "0"
        : this.singleEditModaldata.CapitalGrowth;
    this.singleEditModaldata.Opportunistic =
      this.singleEditModaldata.Opportunistic == ""
        ? "0"
        : this.singleEditModaldata.Opportunistic;
    this.singleEditModaldata.CapitalYielding =
      this.singleEditModaldata.CapitalYielding == ""
        ? "0"
        : this.singleEditModaldata.CapitalYielding;
    this.singleEditModaldata.absolutereturn =
      this.singleEditModaldata.absolutereturn == ""
        ? "0"
        : this.singleEditModaldata.absolutereturn;
    this.totalAUM =
      parseFloat(this.singleEditModaldata.CapitalGrowth) +
      parseFloat(this.singleEditModaldata.Opportunistic) +
      parseFloat(this.singleEditModaldata.CapitalYielding) +
      parseFloat(this.singleEditModaldata.absolutereturn);
    console.log(this.totalAUM, "totalAUM");
    if (this.totalAUM != 100) {
      this.isValidSolutionAum = false;
    } else {
      this.isValidSolutionAum = true;
    }
  }
  async getallInputSolution(type, value) {
    this.app.getLoader(true);
    var sendData = {
      type: type,
      value: value,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http
      .GetallInputSolution(sendData, headerObj)
      .subscribe((response: any) => {
        if (response.success) {
          this.app.getLoader(false);
          this.conventionalShariahList = response.data;
          this.getMsg = response.msg;
        }
      });
  }
  async deleteInputSolnConfirm(id, type, value) {
    this.deleteid = id;
    this.deleteType = type;
    this.deletevalue = value;
    this.deleteConfirmationModal(this.deleteConfirmation);
  }

  async deleteInputSoln(id, type, value) {
    this.app.getLoader(true);
    var sendData = {
      id: id,
      type: type,
      value: value,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http
      .deleteInputSolution(sendData, headerObj)
      .subscribe((response: any) => {
        if (response.success) {
          this.app.getLoader(false);
          // this.conventionalShariahList=response.data
          this.deleteConfirmationRef.hide();
          this.getallInputSolution(type, value);
        }
      });
  }
  async updateInputSoln() {
    if (
      this.singleEditModaldata.score &&
      this.singleEditModaldata.riskCategory &&
      this.singleEditModaldata.quesScore &&
      this.isValidSolutionAum
    ) {
      var sendData = {
        id: this.editId,
        type: this.editType,
        value: this.editValue,
        risk_profile: this.singleEditModaldata.score,
        risk_category: this.singleEditModaldata.riskCategory,
        absolute_return: this.singleEditModaldata.absolutereturn / 100,
        capital_yielding: this.singleEditModaldata.CapitalYielding / 100,
        capital_growth: this.singleEditModaldata.CapitalGrowth / 100,
        opportunistic: this.singleEditModaldata.Opportunistic / 100,
        questionnaire_scores: this.singleEditModaldata.quesScore,
      };
      this.app.getLoader(true);
      var headerObj = await this.app.getHTTPHeaders();
      this.http
        .updateInputSolution(sendData, headerObj)
        .subscribe((response: any) => {
          if (response.success) {
            this.app.getLoader(false);
            this.modalRef.hide();
            this.modalService.onHide.subscribe((reason: string) => {
              this.clearForm();
            });
            // this.conventionalShariahList=response.data
            this.getallInputSolution(this.editType, this.editValue);
          }
        });
    } else if (
      this.singleEditModaldata.score &&
      this.singleEditModaldata.riskCategory &&
      this.singleEditModaldata.quesScore &&
      !this.isValidSolutionAum
    ) {
      this.toastr.error("Total strategy percentage must be 100%");
    } else {
      this.toastr.error("Please fill all mandatory fields");
    }
  }

  async createAllInputSolution() {
    console.log(this.singleEditModaldata.score, "score");
    console.log(this.singleEditModaldata.riskCategory, "riskCategory");
    console.log(this.singleEditModaldata.quesScore, "quesScore");
    console.log(this.isValidSolutionAum, "isValidSolutionAum");
    console.log(this.totalAUM, "totalAUM Create");
    if (this.hideShowAddEditButton) {
      if (
        this.singleEditModaldata.score &&
        this.singleEditModaldata.riskCategory &&
        this.singleEditModaldata.quesScore &&
        this.isValidSolutionAum
      ) {
        var sendData = {
          type: this.editType,
          value: this.editValue,
          risk_profile: this.singleEditModaldata.score,
          risk_category: this.singleEditModaldata.riskCategory,
          absolute_return: this.singleEditModaldata.absolutereturn / 100,
          capital_yielding: this.singleEditModaldata.CapitalYielding / 100,
          capital_growth: this.singleEditModaldata.CapitalGrowth / 100,
          opportunistic: this.singleEditModaldata.Opportunistic / 100,
          questionnaire_scores: this.singleEditModaldata.quesScore,
        };
        this.app.getLoader(true);
        var headerObj = await this.app.getHTTPHeaders();
        this.http
          .createInputSolution(sendData, headerObj)
          .subscribe((response: any) => {
            if (response.success) {
              this.app.getLoader(false);
              this.modalRef.hide();
              this.modalService.onHide.subscribe((reason: string) => {
                this.clearForm();
              });
              this.createInputSolutionList = response.data;
              this.getallInputSolution(this.editType, this.editValue);
            }
          });
      } else if (
        this.singleEditModaldata.score &&
        this.singleEditModaldata.riskCategory &&
        this.singleEditModaldata.quesScore &&
        !this.isValidSolutionAum
      ) {
        this.toastr.error("Total percentage must be 100%");
      } else {
        this.toastr.error("Please fill all mandatory fields");
      }
    } else {
      this.updateInputSoln();
    }
  }
  clearForm() {
    this.singleEditModaldata.score = "";
    this.totalAUM = 0;
    this.singleEditModaldata.quesScore = "";
    this.singleEditModaldata.CapitalGrowth = "";
    this.singleEditModaldata.CapitalYielding = "";
    this.singleEditModaldata.absolutereturn = "";
    this.singleEditModaldata.Opportunistic = "";
    this.singleEditModaldata.riskCategory = "";
    this.isValidSolutionAum = false;
  }
}
