import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  BsModalService,
  BsModalRef,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { ToastrService } from "ngx-toastr";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";
import * as credentialData from "credential.json";
import { tokenize } from "@angular/compiler/src/ml_parser/lexer";
@Component({
  selector: "app-current-future-portfolio",
  templateUrl: "./current-future-portfolio.component.html",
  styleUrls: ["./current-future-portfolio.component.scss"],
})
export class CurrentFuturePortfolioComponent implements OnInit {
  userInfo: any = {};
  credDataObj: any = (credentialData as any).default;
  selectedClientDtls: any = {};
  currentFutureScreenAllData: any;
  ipsDataArrayList: any;
  oustandingSummaryArrayList: any = [];
  quarterlyAnnualCashflowArrayList: any;
  currentPortfolioDate = new Date();
  clientIdForModelStr: string;
  clientIdList: any = [];
  progAccAllExandedIs: boolean = false;
  modalRef: BsModalRef;
  currAndFutAaDataObj: any = {
    dataList: [],
    PfvDataList: [],
    aaTotal: 0,
    aaTotalPerc: 0,
  };
  iconVisible: boolean;
  showMainContent: boolean;
  clientIdEditableIs: boolean;
  annlCashflowArrayList: any = [];
  qurtrlyCashflowArrayList: any = [];
  //qrtrlyCfTotalDistribution: any;
  //anlCfNetCapitalCall: any;
  dataPresentIs: boolean = false;
  client_url: any;
  client_key: any;
  genUrlFormObj: any = {
    existingProsId: "",
    clientId: "",
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    url: "",
    rmId: "",
    type: "",
    token: "",
  };
  outCommitmentsTotalObj: any = {
    calledTotal: 0,
    commitedTotal: 0,
    uncalledTotal: 0,
    calledPerc: 0,
    uncalledPerc: 0,
    roundTotalcommited: 0,
    roundTotalUncalled: 0,
  };

  cashflowDate: any;
  showGeneratedURLis: boolean = false;
  getLoginUserData: any;
  constructor(
    public app: AppComponent,
    private modalService: BsModalService,
    private http: CommonservicesService,
    private toastr: ToastrService
  ) {
    // Themes begin
    am4core.useTheme(am4themes_kelly);
    am4core.useTheme(am4themes_animated);
  }

  ngOnInit() {
    this.app.setAppTitle("Current And Future Portfolio");
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.selectedClientDtls = JSON.parse(
      sessionStorage.getItem("selctedPersonaObj")
    );

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    this.cashflowDate = yesterday;
    setTimeout(() => {
      if (this.userInfo.role == "RM") {
        this.getCurrentFuturePortfolioData();
      } else {
        this.getCurrentFuturePortfolioInvData();
      }
      this.getClientIdList();
    }, 100);
  }

  async getClientIdList() {
    //this.app.getLoader(true);
    var sendData = {
      id: this.userInfo.aaaUserId,
      rm_name: this.userInfo.name,
      group: this.userInfo.userRole
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getClientEmailId(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.clientIdList = response.data;
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        //this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async getCurrentFuturePortfolioData() {
    this.app.getLoader(true);
    var send_data = {
      clientId: this.selectedClientDtls.client_id,
      //"reconciliationDate": this.selectedClientDtls.reconciliationDate,
      userId: this.userInfo.aaaUserId,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.fetchCltAssAllData(send_data, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.dataPresentIs = true;
          this.aaScreenDataModifyFun(response.data);
        } else {
          this.dataPresentIs = false;
          this.toastr.info("Issue Found: " + response.message);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  aaScreenDataModifyFun(getResData) {
    sessionStorage.setItem("currAndFutDataObj", JSON.stringify(getResData));
    //sessionStorage.setItem("inputScreenMainObj", JSON.stringify(getResData));
    this.currentFutureScreenAllData = getResData;
    this.ipsDataArrayList = getResData.ips;
    this.oustandingSummaryArrayList = getResData.oustandingSummary;

    for (var l = 0; l < this.oustandingSummaryArrayList.length; l++) {
      this.outCommitmentsTotalObj.calledTotal += this
        .oustandingSummaryArrayList[l].called
        ? this.oustandingSummaryArrayList[l].called
        : 0;
      this.outCommitmentsTotalObj.uncalledTotal += this
        .oustandingSummaryArrayList[l].uncalled
        ? this.oustandingSummaryArrayList[l].uncalled
        : 0;
      this.outCommitmentsTotalObj.commitedTotal += this
        .oustandingSummaryArrayList[l].commited
        ? this.oustandingSummaryArrayList[l].commited
        : 0;
    }

    /* var commitedRound = this.outCommitmentsTotalObj.uncalledTotal + this.outCommitmentsTotalObj.commitedTotal;
    this.outCommitmentsTotalObj.roundTotalUncalled = Math.round(this.outCommitmentsTotalObj.uncalledTotal / 50000) * 50000;
    this.outCommitmentsTotalObj.roundTotalcommited = Math.round(commitedRound / 50000) * 50000; */
    this.outCommitmentsTotalObj.calledPerc =
      this.outCommitmentsTotalObj.calledTotal /
      this.outCommitmentsTotalObj.commitedTotal;
    this.outCommitmentsTotalObj.uncalledPerc =
      this.outCommitmentsTotalObj.uncalledTotal /
      this.outCommitmentsTotalObj.commitedTotal;

    var qtrlyAnulCashflowList = getResData.quarterlyCashflow;
    var anulCashflowDtListObj = getResData.yearlyCashflow;
    var qtrChFlwArr = [];
    qtrlyAnulCashflowList.capitalCall.spvNm = "Capital Calls";
    qtrlyAnulCashflowList.capitalCall.total = 0;
    qtrlyAnulCashflowList.incomeDistribution.spvNm = "Income Distribution";
    qtrlyAnulCashflowList.incomeDistribution.total = 0;
    qtrlyAnulCashflowList.capitalDistribution.spvNm = "Capital Distribution";
    qtrlyAnulCashflowList.capitalDistribution.total = 0;
    qtrlyAnulCashflowList.netCapitalCall.spvNm = "Net CapitalCalls";
    qtrlyAnulCashflowList.netCapitalCall.total = 0;
    qtrlyAnulCashflowList.totalDistribution.spvNm = "Total Distribution";
    qtrlyAnulCashflowList.totalDistribution.total = 0;

    qtrChFlwArr.push(qtrlyAnulCashflowList.capitalCall);
    qtrChFlwArr.push(qtrlyAnulCashflowList.incomeDistribution);
    qtrChFlwArr.push(qtrlyAnulCashflowList.capitalDistribution);
    qtrChFlwArr.push(qtrlyAnulCashflowList.netCapitalCall);
    qtrChFlwArr.push(qtrlyAnulCashflowList.totalDistribution);

    for (let x = 0; x < qtrChFlwArr.length; x++) {
      var getPartObj = qtrChFlwArr[x];
      qtrChFlwArr[x].total =
        (getPartObj.q1y1 ? getPartObj.q1y1 : 0) +
        (getPartObj.q2y1 ? getPartObj.q2y1 : 0) +
        (getPartObj.q3y1 ? getPartObj.q3y1 : 0) +
        (getPartObj.q4y1 ? getPartObj.q4y1 : 0) +
        (getPartObj.q1y2 ? getPartObj.q1y2 : 0) +
        (getPartObj.q2y2 ? getPartObj.q2y2 : 0) +
        (getPartObj.q3y2 ? getPartObj.q3y2 : 0) +
        (getPartObj.q4y2 ? getPartObj.q4y2 : 0);
    }
    this.qurtrlyCashflowArrayList = qtrChFlwArr;

    /* qtrlyAnulCashflowList.totalDistribution.total =
      (qtrlyAnulCashflowList.totalDistribution.q1y1 ? qtrlyAnulCashflowList.totalDistribution.q1y1 : 0) +
      (qtrlyAnulCashflowList.totalDistribution.q2y1 ? qtrlyAnulCashflowList.totalDistribution.q2y1 : 0) +
      (qtrlyAnulCashflowList.totalDistribution.q3y1 ? qtrlyAnulCashflowList.totalDistribution.q3y1 : 0) +
      (qtrlyAnulCashflowList.totalDistribution.q4y1 ? qtrlyAnulCashflowList.totalDistribution.q4y1 : 0) +
      (qtrlyAnulCashflowList.totalDistribution.q1y2 ? qtrlyAnulCashflowList.totalDistribution.q1y2 : 0) +
      (qtrlyAnulCashflowList.totalDistribution.q2y2 ? qtrlyAnulCashflowList.totalDistribution.q2y2 : 0) +
      (qtrlyAnulCashflowList.totalDistribution.q3y2 ? qtrlyAnulCashflowList.totalDistribution.q3y2 : 0) +
      (qtrlyAnulCashflowList.totalDistribution.q4y2 ? qtrlyAnulCashflowList.totalDistribution.q4y2 : 0);
    this.qrtrlyCfTotalDistribution = qtrlyAnulCashflowList.totalDistribution; */

    var yrlyChFlwArr = [];
    anulCashflowDtListObj.capitalCall.spvNm = "Capital Calls";
    anulCashflowDtListObj.capitalCall.total = 0;
    anulCashflowDtListObj.incomeDistribution.spvNm = "Income Distribution";
    anulCashflowDtListObj.incomeDistribution.total = 0;
    anulCashflowDtListObj.capitalDistribution.spvNm = "Capital Distribution";
    anulCashflowDtListObj.capitalDistribution.total = 0;
    anulCashflowDtListObj.netCapitalCall.spvNm = "Net CapitalCalls";
    anulCashflowDtListObj.netCapitalCall.total = 0;
    anulCashflowDtListObj.totalDistribution.spvNm = "Total Distribution";
    anulCashflowDtListObj.totalDistribution.total = 0;

    yrlyChFlwArr.push(anulCashflowDtListObj.capitalCall);
    yrlyChFlwArr.push(anulCashflowDtListObj.incomeDistribution);
    yrlyChFlwArr.push(anulCashflowDtListObj.capitalDistribution);
    yrlyChFlwArr.push(anulCashflowDtListObj.netCapitalCall);
    yrlyChFlwArr.push(anulCashflowDtListObj.totalDistribution);

    for (let x = 0; x < yrlyChFlwArr.length; x++) {
      var getPartObj = yrlyChFlwArr[x];
      yrlyChFlwArr[x].total =
        (getPartObj.y1 ? getPartObj.y1 : 0) +
        (getPartObj.y2 ? getPartObj.y2 : 0) +
        (getPartObj.y3 ? getPartObj.y3 : 0) +
        (getPartObj.y4 ? getPartObj.y4 : 0) +
        (getPartObj.y5 ? getPartObj.y5 : 0) +
        (getPartObj.y6 ? getPartObj.y6 : 0) +
        (getPartObj.y7 ? getPartObj.y7 : 0) +
        (getPartObj.y8 ? getPartObj.y8 : 0);
    }
    this.annlCashflowArrayList = yrlyChFlwArr;

    /* anulCashflowDtListObj.totalDistribution.total =
      (anulCashflowDtListObj.totalDistribution.y1 ? anulCashflowDtListObj.totalDistribution.y1 : 0) +
      (anulCashflowDtListObj.totalDistribution.y2 ? anulCashflowDtListObj.totalDistribution.y2 : 0) +
      (anulCashflowDtListObj.totalDistribution.y3 ? anulCashflowDtListObj.totalDistribution.y3 : 0) +
      (anulCashflowDtListObj.totalDistribution.y4 ? anulCashflowDtListObj.totalDistribution.y4 : 0) +
      (anulCashflowDtListObj.totalDistribution.y5 ? anulCashflowDtListObj.totalDistribution.y5 : 0) +
      (anulCashflowDtListObj.totalDistribution.y6 ? anulCashflowDtListObj.totalDistribution.y6 : 0) +
      (anulCashflowDtListObj.totalDistribution.y7 ? anulCashflowDtListObj.totalDistribution.y7 : 0) +
      (anulCashflowDtListObj.totalDistribution.y8 ? anulCashflowDtListObj.totalDistribution.y8 : 0);
    this.anlCfNetCapitalCall = anulCashflowDtListObj.totalDistribution; */

    var assetAllocDataForMdf = [];
    this.currAndFutAaDataObj.PfvDataList = [];
    //cash, sukukvest, BondVest
    for (let o = 0; o < getResData.assetAllocation.length; o++) {
      var getAaObj = getResData.assetAllocation[o];
      getResData.assetAllocation[o].currentAA.weightedYield =
        getAaObj.currentAA.perc * getAaObj.targetYield;
      getResData.assetAllocation[o].currentAA.weightedReturn =
        getAaObj.currentAA.perc * getAaObj.targetReturn;
      getResData.assetAllocation[o].futureAA.weightedYield =
        getAaObj.futureAA.perc * getAaObj.targetYield;
      getResData.assetAllocation[o].futureAA.weightedReturn =
        getAaObj.futureAA.perc * getAaObj.targetReturn;
      if (
        getAaObj.program.toLowerCase() != "bondvest" &&
        getAaObj.program.toLowerCase() != "cash" &&
        getAaObj.program.toLowerCase() != "sukukvest"
      ) {
        if (getAaObj.show || this.userInfo.role == "INV") {
          assetAllocDataForMdf.push(getAaObj);
        }
      } else {
        if (getAaObj.show || this.userInfo.role == "INV") {
          this.currAndFutAaDataObj.PfvDataList.push(getAaObj);
        }
      }
    }
    this.aa_data_modification(assetAllocDataForMdf);
    this.drowCgCyChartComp();
  }

  drowCgCyChartComp() {
    var currCmprsnChart = am4core.create(
      "chartCompForCurrCmparsn",
      am4charts.PieChart
    );
    // Add data
    currCmprsnChart.data = [
      {
        value: (this.ipsDataArrayList.currentIps.cgPerc * 100).toFixed(1),
        title: "Capital Growth",
        color: am4core.color("#d6c9b1"),
      },
      {
        value: (this.ipsDataArrayList.currentIps.cyPerc * 100).toFixed(1),
        title: "Capital Yield",
        color: am4core.color("#6d7b52"),
      },
      {
        value: (this.ipsDataArrayList.currentIps.arPerc * 100).toFixed(1),
        title: "Absolute Return",
        color: am4core.color("#c47438"),
      },
      {
        value: (this.ipsDataArrayList.currentIps.oppPerc * 100).toFixed(1),
        title: "Opportunistic",
        color: am4core.color("#987b4b"),
      },
    ];
    // Set inner radius
    currCmprsnChart.innerRadius = am4core.percent(50);
    let currCompTitle = currCmprsnChart.seriesContainer.createChild(
      am4core.Label
    );
    currCompTitle.text = "Current";
    currCompTitle.horizontalCenter = "middle";
    currCompTitle.verticalCenter = "middle";
    currCompTitle.fontSize = 14;

    var currPieSeries = currCmprsnChart.series.push(new am4charts.PieSeries());
    currPieSeries.dataFields.value = "value";
    currPieSeries.dataFields.category = "title";
    currPieSeries.fontSize = 10;
    currPieSeries.slices.template.propertyFields.fill = "color";
    currPieSeries.slices.template.tooltipText =
      "{category}: [bold]{value.value}%";
    currPieSeries.labels.template.text = "{category}: {value.value}%";
    currCmprsnChart.logo.disabled = true;

    ////////////2//////////
    var yrs2CmprsnChart = am4core.create(
      "chartCompFor2yrsCmparsn",
      am4charts.PieChart
    );
    // Add data
    yrs2CmprsnChart.data = [
      {
        value: (this.ipsDataArrayList.noActionIps.cgPerc * 100).toFixed(1),
        title: "Capital Growth",
        color: am4core.color("#d6c9b1"),
      },
      {
        value: (this.ipsDataArrayList.noActionIps.cyPerc * 100).toFixed(1),
        title: "Capital Yield",
        color: am4core.color("#6d7b52"),
      },
      {
        value: (this.ipsDataArrayList.noActionIps.arPerc * 100).toFixed(1),
        title: "Absolute Return",
        color: am4core.color("#c47438"),
      },
      {
        value: (this.ipsDataArrayList.noActionIps.oppPerc * 100).toFixed(1),
        title: "Opportunistic",
        color: am4core.color("#987b4b"),
      },
    ];
    yrs2CmprsnChart.innerRadius = am4core.percent(50);
    let yrs2CompTitle = yrs2CmprsnChart.seriesContainer.createChild(
      am4core.Label
    );
    yrs2CompTitle.text = "2-Yrs";
    yrs2CompTitle.horizontalCenter = "middle";
    yrs2CompTitle.verticalCenter = "middle";
    yrs2CompTitle.fontSize = 14;

    var yrs2PieSeries = yrs2CmprsnChart.series.push(new am4charts.PieSeries());
    yrs2PieSeries.dataFields.value = "value";
    yrs2PieSeries.dataFields.category = "title";
    yrs2PieSeries.fontSize = 10;
    yrs2PieSeries.slices.template.propertyFields.fill = "color";
    yrs2PieSeries.slices.template.tooltipText =
      "{category}: [bold]{value.value}%";
    yrs2PieSeries.labels.template.text = "{category}: {value.value}%";
    yrs2CmprsnChart.logo.disabled = true;
  }

  async getCurrentFuturePortfolioInvData() {
    this.app.getLoader(true);
    var send_data = {
      instanceId: this.selectedClientDtls.instanceId,
      clientId: this.selectedClientDtls.client_id,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.currentPortfolioSummary(send_data, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.dataPresentIs = true;
          this.aaScreenDataModifyFun(response.data);
        } else {
          this.dataPresentIs = false;
          this.toastr.info("Issue Found: " + response.message);
          this.app.getLoader(false);
        }
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  aa_data_modification(assetAllocationArray) {
    var resultData = assetAllocationArray;
    var resultData2 = resultData;
    var get_final_data = resultData
      .reduce(function (res, currentValue) {
        if (res.indexOf(currentValue.type) === -1) {
          res.push(currentValue.type);
        }
        return res;
      }, [])
      .map(function (value) {
        return {
          type: value,
          subList: resultData2
            .filter(function (_el) {
              return _el.type === value;
            })
            .map(function (_el) {
              return _el;
            }),
        };
      });

    for (let d = 0; d < get_final_data.length; d++) {
      var dt_obj = get_final_data[d];
      var dt_obj2 = get_final_data[d];
      var inner_data = dt_obj.subList
        .reduce(function (res, currentValue) {
          if (res.indexOf(currentValue.strategy) === -1) {
            res.push(currentValue.strategy);
          }
          return res;
        }, [])
        .map(function (value) {
          return {
            strategy: value,
            program: "Total " + (value ? value : "?"),
            currentAaTotalPerc: 0,
            currentAaTotalAmt: 0,
            currentAaWtdYieldGrpTtl: 0,
            currentAaWtdReturnGrpTtl: 0,
            futureAaTotalPerc: 0,
            futureAaTotalAmt: 0,
            futureAaWtdYieldTtl: 0,
            futureAaWtdReturnTtl: 0,
            collapseIs: false,
            innerList: dt_obj2.subList
              .filter(function (_el) {
                return _el.strategy === value;
              })
              .map(function (_el) {
                return _el;
              }),
          };
        });

      inner_data.forEach((strgObj, i) => {
        for (let p = 0; p < strgObj.innerList.length; p++) {
          var progObj = strgObj.innerList[p];
          inner_data[i].currentAaTotalPerc += progObj.currentAA.perc
            ? progObj.currentAA.perc
            : 0;
          inner_data[i].currentAaTotalAmt += progObj.currentAA.amount
            ? progObj.currentAA.amount
            : 0;
          inner_data[i].currentAaWtdYieldGrpTtl += progObj.currentAA
            .weightedYield
            ? progObj.currentAA.weightedYield
            : 0;
          inner_data[i].currentAaWtdReturnGrpTtl += progObj.currentAA
            .weightedReturn
            ? progObj.currentAA.weightedReturn
            : 0;
          inner_data[i].futureAaTotalPerc += progObj.futureAA.perc
            ? progObj.futureAA.perc
            : 0;
          inner_data[i].futureAaTotalAmt += progObj.futureAA.amount
            ? progObj.futureAA.amount
            : 0;
          inner_data[i].futureAaWtdYieldTtl += progObj.futureAA.weightedYield
            ? progObj.futureAA.weightedYield
            : 0;
          inner_data[i].futureAaWtdReturnTtl += progObj.futureAA.weightedReturn
            ? progObj.futureAA.weightedReturn
            : 0;
        }
      });
      get_final_data[d].subList = inner_data.sort(function (a, b) {
        if (a.strategy < b.strategy) {
          return -1;
        }
        if (a.strategy > b.strategy) {
          return 1;
        }
        return 0;
      });
    }

    this.currAndFutAaDataObj.dataList = get_final_data;
    this.currAndFutAaDataObj.currAaTotalAmt = 0;
    this.currAndFutAaDataObj.currAaTotalPerc = 0;
    this.currAndFutAaDataObj.futuAaTotalAmt = 0;
    this.currAndFutAaDataObj.futuAaTotalPerc = 0;
    this.currAndFutAaDataObj.PfvDataList.forEach((getObj) => {
      this.currAndFutAaDataObj.currAaTotalAmt += getObj.currentAA.amount
        ? getObj.currentAA.amount
        : 0;
      this.currAndFutAaDataObj.futuAaTotalAmt += getObj.futureAA.amount
        ? getObj.futureAA.amount
        : 0;
    });
    assetAllocationArray.forEach((getObj) => {
      this.currAndFutAaDataObj.currAaTotalAmt += getObj.currentAA.amount
        ? getObj.currentAA.amount
        : 0;
      this.currAndFutAaDataObj.currAaTotalPerc += getObj.currentAA.perc
        ? getObj.currentAA.perc
        : 0;
      this.currAndFutAaDataObj.futuAaTotalAmt += getObj.futureAA.amount
        ? getObj.futureAA.amount
        : 0;
      this.currAndFutAaDataObj.futuAaTotalPerc += getObj.futureAA.perc
        ? getObj.futureAA.perc
        : 0;
    });
    this.app.getLoader(false);
  }

  collapseDetailOfPrograms(getObj) {
    getObj.collapseIs = !getObj.collapseIs;
    var allOpenIs = false;
    var mainLoopLength = this.currAndFutAaDataObj.dataList.length - 1;
    for (let i = 0; i < this.currAndFutAaDataObj.dataList.length; i++) {
      var strgObj = this.currAndFutAaDataObj.dataList[i];
      var getLoopLength = strgObj.subList.length - 1;
      for (let m = 0; m < strgObj.subList.length; m++) {
        if (!strgObj.subList[m].collapseIs) {
          allOpenIs = false;
          break;
        } else {
          if (getLoopLength == m) {
            allOpenIs = true;
          }
        }
      }
      if (!allOpenIs) {
        this.progAccAllExandedIs = false;
        break;
      } else {
        if (mainLoopLength == i) {
          this.progAccAllExandedIs = true;
        }
      }
    }
  }

  collapseForAllPrograms() {
    this.progAccAllExandedIs = !this.progAccAllExandedIs;
    this.currAndFutAaDataObj.dataList.forEach((strgObj, i) => {
      for (let m = 0; m < strgObj.subList.length; m++) {
        if (this.progAccAllExandedIs) {
          this.currAndFutAaDataObj.dataList[i].subList[m].collapseIs = true;
        } else {
          this.currAndFutAaDataObj.dataList[i].subList[m].collapseIs = false;
        }
      }
    });
  }

  showInputBoxOnIconClick() {
    if (this.clientIdEditableIs && this.selectedClientDtls.client_id) {
      sessionStorage.setItem(
        "selctedPersonaObj",
        JSON.stringify(this.selectedClientDtls)
      );
      this.getCurrentFuturePortfolioData();
      this.clientIdEditableIs = false;
    } else {
      this.clientIdForModelStr = this.selectedClientDtls.client_id.toString();
      this.clientIdEditableIs = true;
    }
  }

  selectingCltIdFromList(event) {
    this.selectedClientDtls = event.item;
    /* Manual ID */
    //this.selectedClientDtls.reconciliationDate = "2020-05-31";
  }

  async customForm(modalTemp: TemplateRef<any>, getParam) {
    this.modalRef = this.modalService.show(modalTemp);
    this.modalService.onHide.subscribe((reason: string) => {
      this.clearForm();
    });
  }

  async getToken() {
    if (this.genUrlFormObj.email) {
      var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!emailRegex.test(this.genUrlFormObj.email)) {
        this.toastr.error("You have entered an invalid email address!");
        return false;
      }
      var sendData = {
        userId: this.userInfo.aaaUserId,
        rmId: this.userInfo.id,
        id: this.selectedClientDtls.client_id,
        Email: this.genUrlFormObj.email,
        type: "client",
      };

      this.app.getLoader(true);
      var headerObj = await this.app.getHTTPHeaders();
      this.http.genCustomFormUniqueLink(sendData, headerObj).subscribe(
        (response: any) => {
          if (response.success) {
            var getResObj = response.userData;
            this.genUrlFormObj.email = getResObj.Email;
            this.genUrlFormObj.url =
              this.credDataObj.config.questionnaireFormLinkForClient +
              getResObj.key;
            this.genUrlFormObj.token = getResObj.key;
            // this.sendUniqueLink();
            this.showGeneratedURLis = true;
            this.app.getLoader(false);
          } else {
            this.toastr.error("There is No Email ID");
            this.app.getLoader(false);
          }
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    }
  }

  customFormURLCopyEC(inputElement) {
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
    console.log(inputElement);
    // console.log(this.genUrlFormObj.token);
    // this.client_url=this.genUrlFormObj.url;
    // this.client_key=this.genUrlFormObj.token;
    // this.client_url=this.client_url+this.client_key;
    // console.log(this.client_url);
    // var my_url=this.genUrlFormObj.url;
    // my_url.select();
    // document.execCommand("copy");
    // this.client_url.setSelectionRange(0, 0);
    this.toastr.success("URL Copied Successfully");
  }

  async sendUniqueLink() {
    var sendData = {
      // url: this.genUrlFormObj.url,
      //id: this.getLoginUserData.id,
      token: this.genUrlFormObj.token,
      clientId: this.selectedClientDtls.client_id,
      type: "client",
      email: this.genUrlFormObj.email,
    };

    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.sendMailWithURL(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.toastr.success("Mail sent successfully");
          this.modalRef.hide();
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  clearForm() {
    this.genUrlFormObj.existingProsId = "";
    this.genUrlFormObj.clientId = "";
    this.genUrlFormObj.existingPros = "";
    this.genUrlFormObj.firstname = "";
    this.genUrlFormObj.lastname = "";
    this.genUrlFormObj.email = "";
    this.genUrlFormObj.mobile = "";
    this.genUrlFormObj.url = "";
    this.genUrlFormObj.rmId = "";
    this.genUrlFormObj.type = "";
    this.showGeneratedURLis = false;
  }
  /* cashFlowFullViewModalBxOpener(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-xxl' }));
  } */

  /* chartCompModlBxOpener(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg' }));
  } */

  ngOnDestroy() {
    this.toastr.clear();
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
