import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ToastrService } from 'ngx-toastr';
//import { Options, LabelType } from 'ng5-slider';
import *  as  credentialData from 'credential.json';
@Component({
  selector: 'app-crr-dashboard',
  templateUrl: './crr-dashboard.component.html',
  styleUrls: ['./crr-dashboard.component.scss']
})
export class CrrDashboardComponent {
  modalRef: BsModalRef;
  show: any;
  isDataList: any;
  filter: any;
  infoText: string;
  constructor(public app: AppComponent,
    private modalService: BsModalService,
    private http: CommonservicesService, private toastr: ToastrService) {
    this.filter = this.app.getFilterObj();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  getLoginUserData: any = {};
  ngOnInit() {
    this.app.setAppTitle("CRR Dashboard");
    this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
    setTimeout(() => {
      this.clientLog();
      this.getClientID();
    }, 100);
  }
  
  clientIdList: any = [];
  clientDataList: any = [];
  async clientLog() {
    this.infoText = "Searching...";
    this.app.getLoader(true);
    var sendData = {
      id: this.getLoginUserData.id,
      name: this.getLoginUserData.name,
      email: this.getLoginUserData.email,
    }
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getDashbordlog(sendData, headerObj).subscribe((response: any) => {
      if (response.success) {
        if (response.list.length > 0) {
          this.clientDataList = response.list;
        } else {
          this.infoText = "No Data Found";
        }
        this.getLoginUserData.aaaUserId = response.aaaUserId;
        localStorage.setItem("userInfo", JSON.stringify(this.getLoginUserData));
      } else {
        this.infoText = "No Data Found";
      }
      this.app.getLoader(false);
    }, error => {
      this.infoText = "Connection Issue";
      this.app.errorHandler(error);
    });
  }
  async getClientID() {
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getCRRClientList(headerObj).subscribe((response: any) => {
      if (response.success) {
        this.clientIdList = response.clientlist;
      }
    }, error => {
      this.app.errorHandler(error);
    });
  }

  getThisConfigView(getObj, objRrom) {
    if (objRrom == "list") {
      getObj.clientid = getObj.client_id
    }
    sessionStorage.setItem("selectedClientObj", JSON.stringify(getObj));
    this.app.navigateThis("home/crrConfig");
  }

  selCltIdObj: any = {};
  getSelectedClientId(id_obj) {
    this.selCltIdObj = id_obj.item;
  }

  /* Check Video Status */
  async checkCRRVideoStatus() {
    var formData = new FormData();
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.checkCRRVideoStatus(headerObj).subscribe(response => {
      this.app.getLoader(false);
      if (response.success) {
        this.crrVideoList = response.data;
        this.app.getLoader(false);
        if (this.crrVideoList.length > 0) {
          this.crrVideoList.forEach((item, index) => {
            this.generateVideo(item, index, formData);
          });
        } else {
          this.toastr.warning("No videos to upload");
        }
      } else {
        this.toastr.error("Something went wrong!");
      }
    }, error => {
      console.log(error);
      this.app.getLoader(false);
    });
  }

  /* Convert to File from URL */
  async getFileFromUrl(url, name, defaultType = 'video/mp4') {
    const response = await fetch(url);
    const data = await response.blob();
    console.log("data", data);
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  }

  crrVideoList: any = [];
  /* Generate CRR Video */
  async generateVideo(data, index, formData) {
    this.app.getLoader(true);
    var isData = data;
    var fileName = isData.clientid + "_file.mp4";
    var isDataURL = isData.downloadUrl; //.split("mp4?");
    const file = await this.getFileFromUrl(isDataURL, fileName);
    this.crrVideoList[index].videoFile = file;
    formData.append('File', file, file.name);

    if ((this.crrVideoList.length - 1) == index) {
      setTimeout(() => {
        this.uploadFiles(formData);
      }, 100);
    }
  }

  /* Upload Files To SP */
  async uploadFiles(formData) {
    var sendData = {
      fileType: 'video',
      data: this.crrVideoList
    }
    formData.append('data', JSON.stringify(sendData));
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.postFile(formData, headerObj).subscribe(response => {
      if (response.success) {
        this.toastr.success("File uploaded successfully");
      }
      this.app.getLoader(false);
    }, error => {
      console.log(error);
      this.app.getLoader(false);
    });
  }

  async convertVideo(formData) {
    var sendData = {
      fileType: 'video',
      data: this.crrVideoList
    }
    formData.append('data', JSON.stringify(sendData));
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.postFile(formData, headerObj).subscribe(response => {
      if (response.success) {
        this.toastr.success("File uploaded successfully");
      }
      this.app.getLoader(false);
    }, error => {
      console.log(error);
      this.app.getLoader(false);
    });
  }


  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.toastr.clear();
  }
}


