import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonservicesService } from "../commonservices.service";
import { AppComponent } from "../app.component";
import { ToastrService } from 'ngx-toastr';
import *  as  credentialData from 'credential.json';
@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit {
  @ViewChild("template", { static: false }) public templateref: TemplateRef<
    any
  >;
  @ViewChild("confirmationModalBox", { static: false })
  public confirmationModalBox: TemplateRef<any>;
  credDataObj: any = (credentialData as any).default;
  modalRef: BsModalRef;
  opportunisticConfirmationModalRef: BsModalRef;
  modalConfig = {
    class: "modal-md",
    backdrop: true,
    ignoreBackdropClick: true
  };

  showBtn = false;
  title = "questionnaireForm";
  formOpenModal: boolean = false;
  customFormData: any = {};
  riskPlacement: number = 0;
  EquivalentPerc: number = 0;
  responsivenesResult: string = "";
  refCode: string = "";
  incomeError: boolean = false;
  capital_Yeild: any = "";
  rm_Name: any = "";
  quesIndex: number = 0;
  funds: any;
  proposed_aa_summary: any = {
    expectedReturn: 0, expectedYield: 0,
    income: 0,
    maxDealCincentrationInCg: 0,
    maxDealConcentrationInCY: 0,
    recommendedNoCGDeals: 0,
    recommendedNoCYDeals: 0,
    actualCGDealConcentration: 0,
    actualCYDealConcentration: 0,
    actualWeightedAvgDealConc: 0
  };

  proposed_aa_data: any = { dataList: [], aaTotal: 0, aaTotalPerc: 0 };
  assetAllocationStatus: any;
  opportunisticSelector: any = {
    fund: "",
    amount: "",
    perc: ""
  };
  liquidOption: any = [
    "Underwriting Fund Class B",
    "Underwriting Fund Class C",
    "Bondvest",
  ];
  liquidOptionWithdrawal: any = {
    liquidity: "no",
    year1: {
      "year": 1,
      "fund": "",
      "amount": "",
      "perc": ""
    },
    year2: {
      "year": 2,
      "fund": "",
      "amount": "",
      "perc": ""
    },
    year3: {
      "year": 3,
      "fund": "",
      "amount": "",
      "perc": ""
    }
  }
  invstSmryObj: any = {
    riskProfile: {}
  };
  riskValidations: any = [];
  resValidations: any = [];
  totalAmountofLiquidty: any;
  prospectData: any;

  responsivenessArr: any = ['low', 'moderate', 'high'];

  riskObj: any = { responsiveness: "", risk_per: "", riskScore: "" }

  avaliableAmt: any = {
    val: 0,
    isNeg: true,
    liquidityNeedVal: 0,
    liquidityNeedCheck: false,
    totalWealthPerc: 100
  };
  portfolioObjectives = [
    {
      title: "Indefinite",
      desc: "Inter-generational wealth"
    }, {
      title: "Long Term",
      desc: "Three to seven years"
    },
    {
      title: "Short Term",
      desc: "Less than three years"
    }
  ];
  geoAssetModel: any =
    {
      capitalGrowth: [
        true,
        false,
        false
      ],
      capitalYielding: [
        false,
        true,
        true,
      ]
    };


  questionsArray: any = {
    que1: "",
    que2: "",
    que3: "",
    que4: "",
    que5: "",
    que6: {
      para1: "",
      para2: "",
      para3: "",
      para4: "",
      para5: "",
      para6: "",
      para7: ""
    },
    que7: [],
    que8: {
      cg: {
        para1: {},
        para2: {},
        para3: {}
      },
      cy: {
        para1: {},
        para2: {},
        para3: {}
      },
    }
  };

  errorMsg: any = "";

  opportunistic: any = [
    { spv: 'Clip II' },
    { spv: 'Underwriting Fund Class B' },
    { spv: 'Underwriting Fund Class C' },
    { spv: 'Bondvest' }
  ];

  opportunisticFund: any = {
    totalAmt: 0,
    totalPerc: 0
  }
  liquidityneeds: any = {
    totalAmt: ""

  }

  checkAmount: number = 10000000;

  sendData: any = {};
  AAAmount: number;
  pptFileUrl: any;
  prospect_id: any;
  instanceId: any;
  isView: string;
  opportunisticNameList: any;
  tempOpportunisticListStrg: any;
  getLoginUserData: any = {};

  constructor(
    public app: AppComponent,
    private http: CommonservicesService,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }
  ngOnInit() {
    this.app.setAppTitle("Simulator");
    setTimeout(function () {
      this.sendData.riskCategory = '';
      this.questionnare[3].answer = 'Growth';
      this.getCyPercentage();
      this.getLoginUserData = JSON.parse(localStorage.getItem("userInfo"));
      this.getAllOpportunisticFundsAllocation();
    }.bind(this), 200);
  }
  openModal(template: TemplateRef<any>) {
    this.opportunisticConfirmationModalRef = this.modalService.show(template, this.modalConfig);
  }
  exceededTotalAmt: number = 0;

  removeOppFund(index, data, modal) {
    this.questionsArray.que7.splice(index, 1);
    let amotGet = data.amt;
    let amotPert = parseFloat(data.perc);
    this.opportunisticFund.totalAmt = this.opportunisticFund.totalAmt - amotGet;
    //this.exceededTotalAmt = this.opportunisticFund.totalAmt + amotGet;
    this.opportunisticFund.totalPerc = this.opportunisticFund.totalPerc - amotPert;
    this.getOpsFundAllocation(this.opportunisticFund.totalAmt, modal);
    this.removeSelectedSvpName();
  }

  addOppFund(obj, temp) {
    if (obj.amount && obj.fund) {
      var oppAmt = obj.amount.replace(/,/g, "");
      obj.amount = oppAmt ? parseInt(oppAmt) : 0;
      var oppPert = obj.perc;
      obj.perc = oppPert ? parseFloat(oppPert).toFixed(3) : 0;
      this.questionsArray.que7.push(obj);
      this.opportunisticSelector = {
        funds: '',
        amount: '',
        perc: ''
      };
      let amotGet = obj.amount;
      let amotPert = parseFloat(obj.perc);
      this.opportunisticFund.totalAmt = this.opportunisticFund.totalAmt + amotGet;
      //this.exceededTotalAmt = this.opportunisticFund.totalAmt + amotGet;
      this.opportunisticFund.totalPerc = this.opportunisticFund.totalPerc + amotPert;
      this.getOpsFundAllocation(this.opportunisticFund.totalAmt, temp);
      this.removeSelectedSvpName();
    }
  }

  // addOppFund(obj, temp) {

  //   obj.amount = obj.amount.replace(/,/g, "");
  //   this.questionsArray.que7.push(obj);
  //   this.opportunisticSelector = {
  //     fund: "",
  //     amount: "",
  //     perc: ""
  //   };
  //   let amotGet = parseInt(obj.amount);
  //   this.opportunisticFund.totalAmt += amotGet;
  //   this.opportunisticFund.totalPerc += parseFloat(obj.perc);
  //   this.getOpsFundAllocation(this.opportunisticFund.totalAmt, temp);
  // }

  maxAllocationAmt: any = 0;
  calcFATotal() {

    var lnYear1 = this.liquidOptionWithdrawal.year1.amount == "" ? 0 : this.liquidOptionWithdrawal.year1.amount.replace(/,/g, "");
    var lnYear2 = this.liquidOptionWithdrawal.year2.amount == "" ? 0 : this.liquidOptionWithdrawal.year2.amount.replace(/,/g, "");
    var lnYear3 = this.liquidOptionWithdrawal.year3.amount == "" ? 0 : this.liquidOptionWithdrawal.year3.amount.replace(/,/g, "");

    var clipTwoAmt = 0;
    if (this.liquidOptionWithdrawal.year1.amount) {
      var yr1 = this.liquidOptionWithdrawal.year1.amount;
      clipTwoAmt = yr1.includes(',') ? yr1.replace(/,/g, "") : parseInt(yr1);
      clipTwoAmt;
    } else {
      clipTwoAmt = 0;

    }

    var underwritingFCB = 0;
    if (this.liquidOptionWithdrawal.year2.amount) {
      var yr2 = this.liquidOptionWithdrawal.year2.amount;
      underwritingFCB = yr2.includes(',') ? yr2.replace(/,/g, "") : parseInt(yr2);
    } else {
      underwritingFCB = 0;
    }

    var underwritingFCC = 0;
    if (this.liquidOptionWithdrawal.year3.amount) {
      var yr3 = this.liquidOptionWithdrawal.year3.amount;
      underwritingFCC = yr3.includes(',') ? yr3.replace(/,/g, "") : parseInt(yr3);
    } else {
      underwritingFCC = 0;
    }

    var getTotal = parseInt(lnYear1) + parseInt(lnYear2) + parseInt(lnYear3);
    this.liquidityneeds.totalAmt = getTotal;
    var immediateAmt = parseInt(this.questionnare[1].answer[0].immediately.replace(/,/g, ""));
    var calAAAmount = getTotal ? (immediateAmt - getTotal) : 0;
    this.AAAmount = calAAAmount / 2;

    this.liquidOptionWithdrawal.year1.perc = clipTwoAmt / immediateAmt;
    this.liquidOptionWithdrawal.year2.perc = underwritingFCB / immediateAmt;
    this.liquidOptionWithdrawal.year3.perc = underwritingFCC / immediateAmt;

    this.avaliableAmt.liquidityNeedVal = immediateAmt - this.liquidityneeds.totalAmt;
    if (Math.sign(this.avaliableAmt.liquidityNeedVal) == -1) {
      this.avaliableAmt.liquidityNeedCheck = true;
    } else {
      this.avaliableAmt.liquidityNeedCheck = false;
    }

    if (this.liquidityneeds.totalAmt > immediateAmt) {
      if (immediateAmt == lnYear1) {
        this.questionnare[8].answer[0].year2 = "";
        this.questionnare[8].answer[0].year3 = "";
        this.liquidityNeedsWarnMsg = null;
      } else {
        this.liquidityNeedsWarnMsg =
          "Liquidity needs amount should be less than the immediate fund amount..";
      }
    } else {
      this.liquidityNeedsWarnMsg = null;
    }

    if (this.liquidityneeds.totalAmt == immediateAmt) {
      this.getDisabledInput(true, 'liquidityNeeds');
    }
    if (this.liquidityneeds.totalAmt < immediateAmt) {
      this.getDisabledInput(false, 'liquidityNeeds');
    }

    this.questionnare[8].answer[0].total_wealth = this.liquidityneeds.totalAmt;

    /* this.liquidOptionWithdrawal.year1.perc = this.liquidOptionWithdrawal.year1.amount == "" ? "" : this.liquidOptionWithdrawal.year1.amount / immediateAmt;
    this.liquidOptionWithdrawal.year2.perc = this.liquidOptionWithdrawal.year2.amount == "" ? "" : this.liquidOptionWithdrawal.year2.amount / immediateAmt;
    this.liquidOptionWithdrawal.year3.perc = this.liquidOptionWithdrawal.year3.amount == "" ? "" : this.liquidOptionWithdrawal.year3.amount / immediateAmt; */
  }
  roundOpp(value) {

    var oppAmt = value.target.value.replace(/,/g, "");
    this.opportunisticSelector.amount = Math.round(oppAmt / 50000) * 50000;
    this.opportunisticSelector.amount = Number(this.opportunisticSelector.amount).toLocaleString("en-GB");

  }
  calcFundAllocation(modal) {
    if (this.liquidityneeds.totalAmt) {
      var immediateAmt = parseInt(this.questionnare[1].answer[0].immediately.replace(/,/g, ""));
      var calAAAmount = this.liquidityneeds.totalAmt ? (immediateAmt - this.liquidityneeds.totalAmt) : 0;

      immediateAmt = calAAAmount;
    }
    else {
      immediateAmt = parseInt(this.questionnare[1].answer[0].immediately.replace(/,/g, ""));
    }

    let amountInt = this.opportunisticSelector.amount.includes(',') ? this.opportunisticSelector.amount.replace(/,/g, "") : this.opportunisticSelector.amount;
    var isPerc = (parseFloat(amountInt) / immediateAmt).toFixed(3);
    this.opportunisticSelector.perc = isPerc != "Infinity" ? isPerc : 0;
    this.getOpsFundAllocation(amountInt, modal);
  }
  calcFundAllocationRound() {
    if (this.liquidityneeds.totalAmt) {
      var immediateAmt = parseInt(this.questionnare[1].answer[0].immediately.replace(/,/g, ""));

      var calAAAmount = this.liquidityneeds.totalAmt ? (immediateAmt - this.liquidityneeds.totalAmt) : 0;
      immediateAmt = calAAAmount;
    }
    else {
      immediateAmt = parseInt(this.questionnare[1].answer[0].immediately.replace(/,/g, ""));;
    }

    let amountInt = this.opportunisticSelector.amount.includes(',') ? this.opportunisticSelector.amount.replace(/,/g, "") : this.opportunisticSelector.amount;
    var isPerc = (parseFloat(amountInt) / immediateAmt).toFixed(3);
    this.opportunisticSelector.perc = isPerc != "Infinity" ? isPerc : 0;

  }

  exceededPercentageImmediateIs: boolean;
  exceededPercentageMoreThanhundredIs: boolean;
  getOpsFundAllocation(amountInt, temp) {
    var totalAmt = 0;

    if (this.AAAmount) {
      totalAmt = this.AAAmount * 2;
    } else {
      totalAmt = parseInt(this.questionnare[1].answer[0].immediately.replace(/,/g, ""));
    }
    if (totalAmt > 0) {
      var isMostPerc = (amountInt / totalAmt) * 100;

      var isPerc = this.opportunisticSelector.perc != "" ? parseFloat(this.opportunisticSelector.perc) : 0;
      var isTotalPerc = parseFloat(this.opportunisticFund.totalPerc) + isPerc;
      var isTotal = parseInt(this.opportunisticFund.totalAmt) + parseInt(amountInt);

      if (amountInt > this.AAAmount) {
        this.exceededPercentageImmediateIs = true;
        this.exceededPercentageMoreThanhundredIs = false;
      } else {
        this.exceededPercentageImmediateIs = false;
        this.exceededPercentageMoreThanhundredIs = false;
      }

      if (isMostPerc >= 100 || isTotalPerc >= 100) {
        this.exceededPercentageMoreThanhundredIs = true;
        this.exceededPercentageImmediateIs = true;
        if (isMostPerc === 100 || isTotalPerc === 100) {
          this.isView = "Confirmation";
        } else {
          this.isView = "Alert";
        }
        if (temp) {
          this.openModal(temp);
        }
      }
    } else {
      this.isView = "Alert";
      if (temp) {
        this.openModal(temp);
      }
    }
  }
  // getOpsFundAllocation(amountIntt, temp) {
  //   var totalAmt = 0;
  //   if (this.AAAmount) {
  //     totalAmt = this.AAAmount * 2;
  //   } else {
  //     totalAmt = parseInt(this.questionnare[1].answer[0].immediately.replace(/,/g, ""));
  //   }
  //   var isMostPerc = ((parseInt(amountIntt)) / totalAmt) * 100;
  //   //var isPerc = this.opportunisticSelector.perc != "" ? parseFloat(this.opportunisticSelector.perc) : 0;
  //   var isTotalPerc = this.opportunisticFund.totalperc + isMostPerc;
  //   if (amountIntt > this.AAAmount) {
  //     this.exceededPercentageImmediateIs = true;
  //     this.exceededPercentageMoreThanhundredIs = false;
  //   } else {
  //     this.exceededPercentageImmediateIs = false;
  //     this.exceededPercentageMoreThanhundredIs = false;
  //   }
  //   if (isMostPerc >= 100 || isTotalPerc >= 100) {
  //     this.exceededPercentageMoreThanhundredIs = true;
  //     this.exceededPercentageImmediateIs = true;
  //     this.openModal(temp);
  //   }

  // }
  async getCyPercentage() {
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getCYperc(headerObj).subscribe((response: any) => {
      if (response.success) {
        this.capital_Yeild = response.CY_Percentage;
      }
      this.app.getLoader(false);
    }, error => {
      this.toastr.error("Error: " + error.error);
      this.app.getLoader(false);
    });
  }
  /* Set Geo Asset Value */
  setGeoAsset(type, index) {
    if (type == "cg") {
      var setStatus = this.questionnare[14].answer.capitalGrowth[index].exclude;
      this.questionnare[14].answer.capitalGrowth[index].exclude = setStatus == "yes" ? "no" : "yes";
      var count = 0;
      for (var g = 0; g < this.questionnare[14].answer.capitalGrowth.length; g++) {
        if (this.questionnare[14].answer.capitalGrowth[g].exclude == "yes") {
          count++;
        }
        if (this.questionnare[14].answer.capitalGrowth.length - 1 == g) {
          if (this.questionnare[14].answer.capitalGrowth.length == count) {
            this.questionnare[14].answer.capitalGrowth[index].exclude = "no";
          }
        }
      }
    } else {
      var setStatus = this.questionnare[14].answer.capitalYielding[index].exclude;
      this.questionnare[14].answer.capitalYielding[index].exclude = setStatus == "yes" ? "no" : "yes";
      var count = 0;
      for (var g = 0; g < this.questionnare[14].answer.capitalYielding.length; g++) {
        if (this.questionnare[14].answer.capitalYielding[g].exclude == "yes") {
          count++;
        }
        if (this.questionnare[14].answer.capitalYielding.length - 1 == g) {
          if (this.questionnare[14].answer.capitalYielding.length == count) {
            this.questionnare[14].answer.capitalYielding[index].exclude = "no";
          }
        }
      }
    }
  }

  attitudinalData: any = {
    riskProfile: [
      {
        answer: "",
        value: 0,
        question: "I tend to avoid investment risks."
      },
      {
        answer: "",
        value: 0,
        question:
          "I am more prepared to take investment risk than others It is more importent for me to invest safely then take risk for the Chance of higher return."
      },
      {
        answer: "",
        value: 0,
        question: "I am more prepared to take investment risk than others."
      },
      {
        answer: "",
        value: 0,
        question:
          "I am willing to Put a significant part of my wealth in high-risk investment."
      },
      {
        answer: "",
        value: 0,
        question:
          "I am prepared to risk losing money if there is also a Chance to gain.."
      }
    ],
    responsiveness: [
      {
        answer: "",
        value: 0,
        question: " I tend to buy and sell investment more then I should ."
      },
      {
        answer: "",
        value: 0,
        question:
          "I have previously sold significant investment after sustained losses."
      },
      {
        answer: "",
        value: 0,
        question: "I Tend to Review My Investment Frequently."
      },
      {
        answer: "",
        value: 0,
        question: "Uncertainty makes me uneasy."
      }
    ]
  };

  questionnare = [
    {
      question_no: 1,
      answer: ""
    },
    {
      question_no: 2,
      answer: [
        {
          immediately: "",
          year1: "",
          year2: "",
          year3: "",
          above2Years: "",
          immediatelyPerc: "",
          year1Perc: "",
          year2Perc: "",
          year3Perc: "",
          above2YearsPerc: ""
        }
      ]
    },
    {
      question_no: 3,
      answer: "",
      others: ""
    },
    {
      question_no: 4,
      answer: ""
    },
    {
      question_no: 5,
      answer: ""
    },
    {
      question_no: 6,
      answer: ""
    },
    {
      question_no: 7,
      answer: ""
    },
    {
      question_no: 8,
      answer: ""
    },
    {

      question_no: 9,
      answer: [
        {
          liquidity: "",
          year1: "",
          year2: "",
          year3: "",
          total_wealth: ""
        }
      ]
    },
    {
      question_no: 10,
      answer: "",
      desc: "",
    },
    {
      question_no: 11,
      answer: ""
    },
    {
      question_no: 12,
      answer: ""
    },
    {
      question_no: 13,
      answer: [
        {
          cash: 100,
          realestate: 0,
          privateequity: 0,
          fixedincome: 0,
          equities: 0
        }
      ]
    },
    {
      question_no: 14,
      answer: this.attitudinalData
    },
    {
      question_no: 16,
      answer: {
        capitalGrowth: [
          {
            fund: "US PE",
            exclude: "no",
          }, {
            fund: "Asian PE",
            exclude: "no",
          },
          {
            fund: "Asian RE",
            exclude: "no",
          }
        ],
        capitalYielding: [
          {
            fund: "US RE Debt",
            exclude: "no",
          },
          {
            fund: "US Private Credit",
            exclude: "no",
          },
          {
            fund: "US RE",
            exclude: "no",
          }
        ]
      }
    },
    {
      question_no: 17,
      answer: ""
    },
    {
      question_no: 18,
      answer: ""
    }
  ];

  async saveQuestionnaireData(para, modal) {
    if ((this.opportunisticFund.totalPerc * 100) > 100 || (this.opportunisticSelector.perc * 100) > 100) {
      this.isView = "Alert";
      this.openModal(modal);
    } else {
      var isSubmit = false;
      this.questionsArray.que6.liquidity = this.questionnare[8].answer[0].liquidity;
      if (this.questionnare[5].answer == "conventional" && this.questionsArray.que6.liquidity == "Yes") {
        for (let l = 0; l < 3; l++) {
          let liquidityAmount = this.liquidOptionWithdrawal["year" + (l + 1)].amount;
          let liquidityFunName = this.liquidOptionWithdrawal["year" + (l + 1)].fund;
          if (liquidityAmount && !liquidityFunName) {
            this.toastr.error("Liquidity need's fund name is required");
            return false
          }
        }
      }
      if (this.questionnare[0].answer) {
        if (this.questionnare[1].answer[0].immediately) {
          isSubmit = true;
        }
        else {
          isSubmit = false;
          return false
        }
      }
      if (this.sendData.firstname && this.sendData.lastname && this.sendData.gender) {
        isSubmit = true;
      } else {
        isSubmit = false;
        return false
      }
      if (this.riskObj.riskScore && this.riskObj.responsiveness && this.riskObj.risk_per) {
        isSubmit = true;
      } else {
        isSubmit = false;
        return false
      }
      // if (this.questionnare[2].answer) 
      // {
      //   isSubmit = true;
      // } else {
      //   isSubmit = false;
      //   return false
      // }

      // if (this.questionnare[2].answer == "") {
      //   if (this.questionnare[2].others) {
      //     isSubmit = true;
      //   } else {
      //     isSubmit = false;
      //     return false

      //   }
      // }
      if (this.questionnare[6].answer == '') {
        isSubmit = false;
        return false
      }

      if (this.questionnare[6].answer == 'Yes') {
        if (this.questionnare[7].answer && !this.incomeError) {
          isSubmit = true;
        } else {
          isSubmit = false;
          return false
        }
      }
      isSubmit = this.questionnare[9].answer ? true : false;
      if (this.questionnare[8].answer[0].liquidity == 'Yes') {
        if (!this.avaliableAmt.liquidityNeedCheck) {
          if (this.liquidOptionWithdrawal.year1.amount || this.liquidOptionWithdrawal.year2.amount || this.liquidOptionWithdrawal.year3.amount) {
            isSubmit = true;
          } else {
            isSubmit = false;
            return false
          }
        } else {
          isSubmit = false;
          return false
        }
      }
      if (this.avaliableAmt.val) {
        isSubmit = false;
        return false
      }
      if (this.capital_Yeild < this.incomePerc) {
        isSubmit = false;
        return false
      }
      if (this.riskObj.riskScore == 12) {
        if (this.sendData.riskCategory && this.sendData.cgDeals && this.sendData.cyDeals && this.sendData.expectedVolatility) {
          isSubmit = true;
          var cgPerc = parseFloat(this.sendData.cgDeals);
          var cyPerc = parseFloat(this.sendData.cyDeals);
          var totalcycg = cyPerc + cgPerc;

          if (totalcycg != 100) {
            this.toastr.error("Total of CG & CY deals must be equal to 100");

            isSubmit = false;
            return false;
          }
          else {
            isSubmit = true;
          }
        } else {
          isSubmit = false;
          return false

        }

      }

      if (isSubmit == true) {
        var questionnareData = JSON.stringify(this.questionnare);
        questionnareData = this.convertData(questionnareData);
        var sendData = {
          id: "",
          instanceId: "",
          rmId: this.getLoginUserData.id,
          type: "inv",
          status: "",
          basic: {
            first_name: !this.sendData.firstname ? "" : this.sendData.firstname,
            last_name: !this.sendData.lastname ? "" : this.sendData.lastname,
            middle_name: !this.sendData.middlename ? "" : this.sendData.middlename,
            age: "",
            dob: "",
            gender: !this.sendData.gender ? "" : this.sendData.gender,
            location: '',
            email: '',
            contact_no: '',
            questionnaireDate: '',
            clientId: "",
            csv: "",
            app_status: "",
            user_id: "",
            privacy_note: '',


          },
          questionnare: [],
          risk_score: parseInt(this.riskObj.riskScore),
          responsiveness: this.riskObj.responsiveness,

        };

        sendData.questionnare.push(questionnareData)[0];
        if (this.investmentAmt < this.checkAmount) {
          var setData = {
            question_no: 16,
            answer: null
          }
          sendData.questionnare[0][14] = setData;
        }
        sendData.questionnare = sendData.questionnare[0];
        this.app.getLoader(true);
        var headerObj = await this.app.getHTTPHeaders();
        this.http.SendRMQuestinnaire(sendData, headerObj).subscribe((response: any) => {
          var dataResponse = response;
          if (dataResponse.success) {
            this.prospect_id = dataResponse.data.prospect_id;
            this.instanceId = dataResponse.data.instanceId;
            this.sendOpportunisticData(modal);

          } else {
            this.toastr.error(dataResponse.msg);
            this.app.getLoader(false);
          }
        }, error => {
          this.app.errorHandler(error);
        });
      }
    }
  }

  /* Save Questionnaire Form */
  async saveData() {
    var incomeRequired_ans = 0;
    incomeRequired_ans = this.questionnare[7].answer.includes(",") ? this.questionnare[7].answer.replace(/,/g, "") : parseInt(this.questionnare[7].answer);
    let fundingSchedule = [
      this.questionnare[1].answer[0].immediately || 0,
      this.questionnare[1].answer[0].year1 || 0,
      this.questionnare[1].answer[0].year2 || 0,
      this.questionnare[1].answer[0].above2Years || 0]

    let capitalGrowth = this.questionnare[14].answer.capitalGrowth;
    let capitalYielding = this.questionnare[14].answer.capitalYielding;
    let liquidityNeeds = [];
    var isLiquidityNeedsData = JSON.stringify(this.liquidOptionWithdrawal);
    var lnData = JSON.parse(isLiquidityNeedsData);
    liquidityNeeds.push(lnData.year1);
    liquidityNeeds.push(lnData.year2);
    liquidityNeeds.push(lnData.year3);
    liquidityNeeds.forEach(ele => {
      if (ele.amount) {
        var isAmount = ele.amount.includes(",") ? ele.amount.replace(/,/g, "") : parseInt(ele.amount);
        ele.amount = parseInt(isAmount);
      }
    });
    var tempInvestmentAmt = 0, tempImmediately = 0, tempYear1 = 0, tempYear2 = 0, tempAbove2Year = 0;
    if (this.questionnare[0].answer) {
      tempInvestmentAmt = this.questionnare[0].answer.includes(",") ? parseInt(this.questionnare[0].answer.replace(/,/g, "")) : parseInt(this.questionnare[0].answer);
    }
    if (fundingSchedule[0]) {
      tempImmediately = fundingSchedule[0].includes(",") ? parseInt(fundingSchedule[0].replace(/,/g, "")) : parseInt(fundingSchedule[0])
    }
    if (fundingSchedule[1]) {
      tempYear1 = fundingSchedule[1].includes(",") ? parseInt(fundingSchedule[1].replace(/,/g, "")) : parseInt(fundingSchedule[1])
    }
    if (fundingSchedule[2]) {
      tempYear2 = fundingSchedule[2].includes(",") ? parseInt(fundingSchedule[2].replace(/,/g, "")) : parseInt(fundingSchedule[2])
    }
    if (fundingSchedule[3]) {
      tempAbove2Year = fundingSchedule[3].includes(",") ? parseInt(fundingSchedule[3].replace(/,/g, "")) : parseInt(fundingSchedule[3])
    }
    if (this.riskObj.riskScore == 12) {
      var responsiveness_val = 'moderate';
    }
    else {
      responsiveness_val = this.riskObj.responsiveness
    }

    var opportunisticFund_ans = [];
    if (this.questionsArray.que7.length > 0) {
      var qus7Ans = this.questionsArray.que7;
      for (let x = 0; x < qus7Ans.length; x++) {
        const elmObj = qus7Ans[x];
        var obj = {
          "fund": elmObj.fund,
          "amount": elmObj.amount,
          "perc": (elmObj.amount / tempImmediately)
        }
        opportunisticFund_ans.push(obj);
      }
    } else {
      var obj2 = {
        "fund": "",
        "amount": 0,
        "perc": 0
      };
      opportunisticFund_ans.push(obj2);
    }
    var data = {
      "investmentAmt": tempInvestmentAmt,
      "fundingSchedule": {
        "immediately": tempImmediately,
        "year1": tempYear1,
        "year2": tempYear2,
        "above2Year": tempAbove2Year
      },
      "sharia": this.questionnare[5].answer != 'conventional' ? 'yes' : 'no',
      "incomeRequired": incomeRequired_ans || 0,
      "liquidityNeeds": liquidityNeeds,
      "excludeAssetClass": {
        capitalGrowth, capitalYielding
      },
      "opportunisticFund": opportunisticFund_ans,
      "riskScore": parseInt(this.riskObj.riskScore),
      "riskScorePerc": parseInt(this.riskObj.risk_per),
      "responsiveness": responsiveness_val,
      "clientProspectId": this.prospect_id,
      "instanceId": this.instanceId,
    }

    data.opportunisticFund.forEach(ele => {
      ele.amount = parseInt(ele.amount)
      ele.perc = parseFloat(ele.perc)
    });
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getProposedAAdata(data, headerObj).subscribe((response: any) => {
      var dataResponse = response;
      if (dataResponse.success) {
        this.prospectData = dataResponse.data;
        var assetAllocationArray = this.prospectData.strategicAssetAllocation;
        this.proposed_aa_summary = this.prospectData;
        this.showBtn = true;


        this.aa_data_modification(assetAllocationArray);
      } else {
        this.toastr.error("Error: " + response.message);
        this.app.getLoader(false);
      }
    }, error => {
      this.toastr.error("Error: " + error.error);
      this.app.getLoader(false);
    });

  }

  aa_data_modification(assetAllocationArray) {
    var resultData = assetAllocationArray;
    var resultData2 = resultData;
    var get_final_data = resultData.reduce(function (res, currentValue) {
      if (res.indexOf(currentValue.type) === -1) {
        res.push(currentValue.type);
      }
      return res;
    }, []).map(function (value) {
      return {
        type: value,
        subList: resultData2.filter(function (_el) {
          return _el.type === value;
        }).map(function (_el) {
          return _el;
        })
      };
    });

    for (let d = 0; d < get_final_data.length; d++) {
      var dt_obj = get_final_data[d];
      var dt_obj2 = get_final_data[d];
      var inner_data = dt_obj.subList.reduce(function (res, currentValue) {
        if (res.indexOf(currentValue.strategy) === -1) {
          res.push(currentValue.strategy);
        }
        return res;

      }, []).map(function (value) {
        return {
          strategy: value,
          innerList: dt_obj2.subList.filter(function (_el) {
            return _el.strategy === value;
          }).map(function (_el) {
            return _el;
          })
        };
      });
      get_final_data[d].subList = inner_data;
    }

    this.proposed_aa_data.dataList = get_final_data;
    this.proposed_aa_data.aaTotal = 0;
    this.proposed_aa_data.aaTotalPerc = 0;
    assetAllocationArray.forEach(getObj => {

      this.proposed_aa_data.aaTotal += getObj.amount;
      this.proposed_aa_data.aaTotalPerc += getObj.perc;
    });

    this.app.getLoader(false);
  }
  async updateRiskProfile() {
    this.app.getLoader(true);
    var sendObj = {
      "cg": this.sendData.cgDeals,
      "cy": this.sendData.cyDeals,

      "fv": this.sendData.expectedVolatility
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.updateRiskProfile(sendObj, headerObj).subscribe((response: any) => {
      if (response.success) {
        this.updateRiskCategory();
      } else {
        this.toastr.error("Error: " + response.message);
        this.app.getLoader(false);
      }
    }, error => {
      this.toastr.error("Error: " + error.error);
      this.app.getLoader(false);
    });
  }
  async updateRiskCategory() {
    this.app.getLoader(true);
    var sendObj = {

      "riskCategory": this.sendData.riskCategory,

    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.updateRiskCategory(sendObj, headerObj).subscribe((response: any) => {
      if (response.success) {
        this.saveData();
      } else {
        this.toastr.error("Error: " + response.message);
        this.app.getLoader(false);
      }
    }, error => {
      this.toastr.error("Error: " + error.error);
      this.app.getLoader(false);
    });
  }

  /* Generate Asset Allocation */
  async sendOpportunisticData(modal) {

    this.questionsArray.que6.liquidity = this.questionnare[8].answer[0].liquidity;
    if (this.questionsArray.que5 == "conventional" && this.questionsArray.que6.liquidity == "Yes") {
      for (let l = 0; l < 3; l++) {
        let liquidityAmount = this.questionsArray.que6["year" + (l + 1)];
        let liquidityFunName = this.liquidOptionWithdrawal["year" + (l + 1)];
        if (liquidityAmount && !liquidityFunName) {
          this.toastr.error("Liquidity need's fund name is required");
          return false;
        }
      }
    }
    var sendData = {
      "data": {
        "ClientProspectMasterId": this.prospect_id,
        "instanceId": this.instanceId,
        "questionData": [{
          "QuestionMasterId": 9,
          "answer":
            [{
              "liquidity": this.questionnare[8].answer[0].liquidity
            }, {
              "year1": this.liquidOptionWithdrawal.year1.amount == "" ? 0 : parseInt(this.liquidOptionWithdrawal.year1.amount.replace(/,/g, "")),
              "fund": this.liquidOptionWithdrawal.year1.fund
            }, {
              "year2": this.liquidOptionWithdrawal.year2.amount == "" ? 0 : parseInt(this.liquidOptionWithdrawal.year2.amount.replace(/,/g, "")),
              "fund": this.liquidOptionWithdrawal.year2.fund
            }, {
              "year3": this.liquidOptionWithdrawal.year3.amount == "" ? 0 : parseInt(this.liquidOptionWithdrawal.year3.amount.replace(/,/g, "")),
              "fund": this.liquidOptionWithdrawal.year3.fund
            }]
        }, {
          "QuestionMasterId": 26,
          "answer": this.questionsArray.que7
        }]
      }
    };
    this.app.getLoader(true);
    sessionStorage.setItem("opportunisticFundData", JSON.stringify(sendData.data));
    var headerObj = await this.app.getHTTPHeaders();
    this.http.sendSummData(sendData, headerObj).subscribe((response: any) => {
      const dataResponse = response;
      this.app.getLoader(false);
      if (dataResponse.success) {
        if (this.riskObj.riskScore == 12) {
          this.updateRiskProfile();

        }

      }
    }, error => {
      this.app.errorHandler(error);
    });
  }




  /* Get PPT Deck */
  async getGeneratedDeckUrl() {
    var sendObj = {
      "client_id": this.prospect_id,
      "regenerate": false
    };
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getGeneratedDeckFile(sendObj, headerObj).subscribe((response: any) => {
      if (response.success) {
        this.pptFileUrl = this.credDataObj.config.rootURL + response.path;

        window.open(this.pptFileUrl);
      }
      this.app.getLoader(false);
    }, error => {
      this.app.errorHandler(error);
    });
  }



  async getAllOpportunisticFundsAllocation() {
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getOpportunisticFundsAllocation(headerObj).subscribe((response: any) => {
      if (response.success) {
        this.opportunistic = response.data;
      }
    }, error => {
      this.toastr.error("Error: " + error.error);
      this.app.getLoader(false);
    });

  }

  async get_aa_module_view_data() {
    this.app.getLoader(true);
    var sendObj = {
      "prospect_id": this.prospect_id,

    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getAssetAllocationDataForView(sendObj, headerObj).subscribe((response: any) => {
      if (response.success) {
        var assetAllocationArray = response.data.strategicAssetAllocation;
        this.proposed_aa_summary = response.data;
        this.aa_data_modification(assetAllocationArray);
      } else {
        this.toastr.error("Error: " + response.message);
        this.app.getLoader(false);
      }
    }, error => {
      this.toastr.error("Error: " + error.error);
      this.app.getLoader(false);
    });
  }


  /* Investment Schedular */
  warnMsg: any;
  isDisableYearOne: any;
  isDisableYearTwo: any;
  isDisableYearThree: any;
  isDisableYearAbove: any;
  investmentAmt: any;
  roundImmediately(value, para) {
    if (para == "investment") {
      var investment = value.target.value.replace(/,/g, "");
      this.questionnare[0].answer = Math.round(investment / 50000) * 50000;
      this.questionnare[0].answer = Number(this.questionnare[0].answer).toLocaleString("en-GB");

    }
    else if (para == "year1") {
      var year1 = value.target.value.replace(/,/g, "");
      this.questionnare[1].answer[0].year1 = Math.round(year1 / 50000) * 50000;
      this.questionnare[1].answer[0].year1 = Number(this.questionnare[1].answer[0].year1).toLocaleString("en-GB");
    }
    else {
      var immediate = value.target.value.replace(/,/g, "");
      this.questionnare[1].answer[0].immediately = Math.round(immediate / 50000) * 50000;
      this.questionnare[1].answer[0].immediately = Number(this.questionnare[1].answer[0].immediately).toLocaleString("en-GB");

    }
  }
  investmentSchedular() {
    var investmentAmt =
      this.questionnare[0].answer == ""
        ? 0
        : this.questionnare[0].answer.replace(/,/g, "");
    var immediately =
      this.questionnare[1].answer[0].immediately == ""
        ? 0
        : this.questionnare[1].answer[0].immediately.replace(/,/g, "");
    this.AAAmount = parseInt(immediately) / 2;
    var yearOne =
      this.questionnare[1].answer[0].year1 == ""
        ? 0
        : this.questionnare[1].answer[0].year1.replace(/,/g, "");
    var yearTwo =
      this.questionnare[1].answer[0].year2 == ""
        ? 0
        : this.questionnare[1].answer[0].year2.replace(/,/g, "");
    /* var yearThree =
      this.questionnare[1].answer[0].year3 == ""
        ? 0
        : this.questionnare[1].answer[0].year3.replace(/,/g, ""); */
    var aboveThree =
      this.questionnare[1].answer[0].above2Years == ""
        ? 0
        : this.questionnare[1].answer[0].above2Years.replace(/,/g, "");

    var schedularAmt =
      parseInt(immediately) +
      parseInt(yearOne) +
      parseInt(yearTwo) +
      parseInt(aboveThree);
    this.avaliableAmt.val = investmentAmt - schedularAmt;
    if (Math.sign(this.avaliableAmt.val) == -1) {
      this.avaliableAmt.isNeg = true;
    } else {
      this.avaliableAmt.isNeg = false;
    }
    if (schedularAmt > investmentAmt) {
      if (investmentAmt == immediately) {
        this.questionnare[1].answer[0].year1 = "";
        this.questionnare[1].answer[0].year2 = "";
        //this.questionnare[1].answer[0].year3 = "";
        this.questionnare[1].answer[0].above2Years = "";
        this.warnMsg = null;
      } else {
        this.warnMsg =
          "Schedule amount should be less than the investment amount..";
      }
    } else {
      this.warnMsg = null;
    }

    if (schedularAmt == investmentAmt) {
      this.getDisabledInput(true, 'schedular');
    }
    if (schedularAmt < investmentAmt) {
      this.getDisabledInput(false, 'schedular');
    }
    this.investmentAmt = investmentAmt;
  }
  /* Convert Numbers Input */
  convertData(questionnareData) {
    var questionnare = JSON.parse(questionnareData);
    //Converting Investment Amount
    var isInvestmentAmt =
      questionnare[0].answer == ""
        ? ""
        : questionnare[0].answer.replace(/,/g, "");
    questionnare[0].answer =
      isInvestmentAmt == "" ? "" : parseInt(isInvestmentAmt);

    //Converting Investment Schedular Amount
    var immediateAmt =
      questionnare[1].answer[0].immediately == ""
        ? ""
        : questionnare[1].answer[0].immediately.replace(/,/g, "");
    questionnare[1].answer[0].immediately =
      immediateAmt == "" ? "" : parseInt(immediateAmt);

    var scheduleYear1 =
      questionnare[1].answer[0].year1 == ""
        ? ""
        : questionnare[1].answer[0].year1.replace(/,/g, "");
    questionnare[1].answer[0].year1 =
      scheduleYear1 == "" ? "" : parseInt(scheduleYear1);

    var scheduleYear2 =
      questionnare[1].answer[0].year2 == ""
        ? ""
        : questionnare[1].answer[0].year2.replace(/,/g, "");
    questionnare[1].answer[0].year2 =
      scheduleYear2 == "" ? "" : parseInt(scheduleYear2);

    var above2Years =
      questionnare[1].answer[0].above2Years == ""
        ? ""
        : questionnare[1].answer[0].above2Years.replace(/,/g, "");
    questionnare[1].answer[0].above2Years =
      above2Years == "" ? "" : parseInt(above2Years);

    //Converting Income Requirement Amount
    var incomeReqAmt =
      questionnare[7].answer == ""
        ? ""
        : questionnare[7].answer.replace(/,/g, "");
    questionnare[7].answer =
      incomeReqAmt == "" ? "" : parseInt(incomeReqAmt);

    //Converting Loquidity Needs Amount
    var year1LNeeds =
      this.liquidOptionWithdrawal.year1.amount == ""
        ? ""
        : this.liquidOptionWithdrawal.year1.amount.replace(/,/g, "");
    questionnare[8].answer[0].year1 =
      year1LNeeds == "" ? "" : parseInt(year1LNeeds);

    var year2LNeeds =
      this.liquidOptionWithdrawal.year2.amount == ""
        ? ""
        : this.liquidOptionWithdrawal.year2.amount.replace(/,/g, "");
    questionnare[8].answer[0].year2 =
      year2LNeeds == "" ? "" : parseInt(year2LNeeds);

    var year3LNeeds =
      this.liquidOptionWithdrawal.year3.amount == ""
        ? ""
        : this.liquidOptionWithdrawal.year3.amount.replace(/,/g, "");
    questionnare[8].answer[0].year3 =
      year3LNeeds == "" ? "" : parseInt(year3LNeeds);

    //Assigning Onbehalf
    if (questionnare[2].answer == "") {
      questionnare[2].answer = questionnare[2].others;
    }

    //Assigning Appropriate Objectives
    if (questionnare[3].answer == "Other") {
      questionnare[3].answer = questionnare[3].others;
    }
    if (this.sendData.region == "Other") {
      this.sendData.region = this.sendData.isRegion;
    }

    /* Schedular Percentage */
    var investmentAmt = parseInt(questionnare[0].answer);
    questionnare[1].answer[0].immediatelyPerc =
      questionnare[1].answer[0].immediately != ""
        ? (
          (parseInt(questionnare[1].answer[0].immediately) /
            investmentAmt) *
          100
        ).toFixed(2)
        : "";

    questionnare[1].answer[0].year1Perc =
      questionnare[1].answer[0].year1 != ""
        ? (
          (parseInt(questionnare[1].answer[0].year1) / investmentAmt) *
          100
        ).toFixed(2)
        : "";

    questionnare[1].answer[0].year2Perc =
      questionnare[1].answer[0].year2 != ""
        ? (
          (parseInt(questionnare[1].answer[0].year2) / investmentAmt) *
          100
        ).toFixed(2)
        : "";

    questionnare[1].answer[0].above2YearsPerc =
      questionnare[1].answer[0].above2Years != ""
        ? (
          (parseInt(questionnare[1].answer[0].above2Years) /
            investmentAmt) *
          100
        ).toFixed(2)
        : "";

    return questionnare;
  }


  /* Disabled Un wanted Input In Schedular*/
  getDisabledInput(value, callback) {
    if (callback == 'schedular') {
      if (this.questionnare[1].answer[0].year1 == "") {
        this.isDisableYearOne = value;
      }
      if (this.questionnare[1].answer[0].year2 == "") {
        this.isDisableYearTwo = value;
      }
      /* if (this.questionnare[1].answer[0].year3 == "") {
        this.isDisableYearThree = value;
      } */
      if (this.questionnare[1].answer[0].above2Years == "") {
        this.isDisableYearAbove = value;
      }
    } else {
      if (this.questionnare[8].answer[0].year2 == "") {
        this.isLNDisableYearTwo = value;
      }
      if (this.questionnare[8].answer[0].year3 == "") {
        this.isLNDisableYearThree = value;
      }
    }

  }


  /* Income Validation */
  incomePerc: any;
  incomeValidation = () => {
    var immediateAmt = this.questionnare[1].answer[0].immediately != "" ? this.questionnare[1].answer[0].immediately.replace(/,/g, "") : 0;
    var incomeAmt = this.questionnare[7].answer.replace(/,/g, "")
    var amount = (immediateAmt / 100) * this.capital_Yeild;
    if (amount >= incomeAmt) {
      this.incomeError = false;
    } else {
      this.incomeError = true;
    }
    var isIcomePerc = ((incomeAmt / immediateAmt) * 100).toFixed(2);
    this.incomePerc = isIcomePerc;
  };


  /* Calculate Liquidity Needs */
  liquidityNeedsWarnMsg: any;
  isLNDisableYearTwo: any;
  isLNDisableYearThree: any;
  roundLiquidity(value, callback) {

    var liquidity = value.target.value.replace(/,/g, "");
    var abc = Math.round(liquidity / 50000) * 50000;
    if (callback == 'year1') {
      this.liquidOptionWithdrawal.year1.amount = Number(abc).toLocaleString("en-GB");
    }
    else if (callback == 'year2') {
      this.liquidOptionWithdrawal.year2.amount = Number(abc).toLocaleString("en-GB");
    }
    else {
      this.liquidOptionWithdrawal.year3.amount = Number(abc).toLocaleString("en-GB");
    }

  }
  calcLiquidityNeeds() {
    var immediately =
      this.questionnare[1].answer[0].immediately == ""
        ? 0
        : this.questionnare[1].answer[0].immediately.replace(/,/g, "");

    var lnYear1 = this.liquidOptionWithdrawal.year1.amount == "" ? 0 : this.liquidOptionWithdrawal.year1.amount.replace(/,/g, "");
    var lnYear2 = this.liquidOptionWithdrawal.year2.amount == "" ? 0 : this.liquidOptionWithdrawal.year2.amount.replace(/,/g, "");
    var lnYear3 = this.liquidOptionWithdrawal.year3.amount == "" ? 0 : this.liquidOptionWithdrawal.year3.amount.replace(/,/g, "");



    var liquidityNeeds =
      parseInt(lnYear1) +
      parseInt(lnYear2) +
      parseInt(lnYear3);

    this.avaliableAmt.liquidityNeedVal = immediately - liquidityNeeds;
    if (Math.sign(this.avaliableAmt.liquidityNeedVal) == -1) {
      this.avaliableAmt.liquidityNeedCheck = true;
    } else {
      this.avaliableAmt.liquidityNeedCheck = false;
    }

    if (liquidityNeeds > immediately) {
      if (immediately == lnYear1) {
        this.questionnare[8].answer[0].year2 = "";
        this.questionnare[8].answer[0].year3 = "";
        this.liquidityNeedsWarnMsg = null;
      } else {
        this.liquidityNeedsWarnMsg =
          "Liquidity needs amount should be less than the immediate fund amount..";
      }
    } else {
      this.liquidityNeedsWarnMsg = null;
    }

    if (liquidityNeeds == immediately) {
      this.getDisabledInput(true, 'liquidityNeeds');
    }
    if (liquidityNeeds < immediately) {
      this.getDisabledInput(false, 'liquidityNeeds');
    }

    this.questionnare[8].answer[0].total_wealth = liquidityNeeds;
  }


  behalfOthers(event) {
    if (event.length > 2) {
      this.questionnare[2].answer = "";
    }
  }

  resetForm = () => {
    location.reload(true);
  };
  /* Select Objecives */
  selectObjecive(item) {
    this.questionnare[9].answer = item.title;
    this.questionnare[9].desc = item.desc;
  }
  removeSelectedSvpName() {
    this.opportunisticNameList = this.tempOpportunisticListStrg;
    for (let i = 0; i < this.questionsArray.que7.length; i++) {
      const qus7Obj = this.questionsArray.que7[i];
      this.opportunisticNameList = this.opportunisticNameList.filter(obj => obj.spv !== qus7Obj.funds);
    }
  }
  clearForReallocate() {
    this.questionsArray.que7 = [];
    this.opportunisticFund.totalAmt = 0;
    this.opportunisticFund.totalPerc = 0;
    this.opportunisticSelector.fund = "";
    this.opportunisticSelector.amount = "";
    this.opportunisticSelector.perc = "";
    this.exceededPercentageImmediateIs = false; this.exceededPercentageMoreThanhundredIs = false;
    this.removeSelectedSvpName();
  }
  Reload() {
    this.questionsArray.que7 = [];
    this.opportunisticFund.totalAmt = 0;
    this.opportunisticFund.totalPerc = 0;
    this.ngOnDestroy();
    this.exceededPercentageImmediateIs = false; this.exceededPercentageMoreThanhundredIs = false;
  }
  isConfAlert: boolean;

  ngOnDestroy() {
    if (this.opportunisticConfirmationModalRef) {
      this.opportunisticConfirmationModalRef.hide();
    }
    this.toastr.clear();
  }
}
