import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MsalService, BroadcastService } from "@azure/msal-angular";
import { HttpClient } from "@angular/common/http";
import { AppComponent } from "../app.component";
import { CanActivate, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  BsModalService,
  BsModalRef,
  ModalDirective,
} from "ngx-bootstrap/modal";
import * as Msal from "msal";
import * as credentialData from "credential.json";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  credDataObj: any = (credentialData as any).default;
  public userInfo: any = null;
  public isIframe: boolean;
  loggedIn: boolean;
  userCredentialInfo: any = {};
  loginObj: any;
  userRole: "";
  access_token: any;
  msalConfig: any = {
    auth: {
      clientId: this.credDataObj.clientID,
      authority: this.credDataObj.authority,
      response_type: this.credDataObj.response_type,
      validateAuthority: this.credDataObj.validateAuthority,
      expireOffsetSeconds: 1200,
    },
    cache: {
      cacheLocation: this.credDataObj.cacheLocation,
      storeAuthStateInCookie: this.credDataObj.storeAuthStateInCookie,
    },
  };
  requestObj = {
    scopes: this.credDataObj.consentScopes,
  };

  @ViewChild("invalidUserErrorModal", { static: false })
  invalidUserErrorModal: ModalDirective;
  @ViewChild("adminFlowRedirectingModalBox", { static: false })
  adminFlowRedirectingModalBoxRef: ModalDirective;
  constructor(
    private broadcastService: BroadcastService,
    private authService: MsalService,
    public app: AppComponent,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    this.loginObj = {
      name: "",
      email: "",
      role: "",
      id: "",
      group_id: "",
      group_name: "",
      adminLoggedInIs: false,
      userRole: "",
      token: "",
    };
    this.authCallback = this.authCallback.bind(this);
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    /* this.isIframe = window !== window.parent && !window.opener;
    if (this.authService.getUser()) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    } */
  }
  multiCallingIs: boolean = true;

  ngOnInit() {
    this.app.setAppTitle("Login");
    document.cookie = "cross-site-cookie=bar; SameSite=None; Secure";
    this.broadcastService.subscribe("msal:loginFailure", (payload) => {
      console.log("loginFailure", payload);
    });
    this.broadcastService.subscribe("msal:loginSuccess", (payload) => {
      this.loginObj.token = payload.token;
      this.loggedIn = true;
      if (this.multiCallingIs) {
        this.getLoginCredentials();
        this.multiCallingIs = false;
      }
    });

    this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {
      localStorage.clear();
      this.authService.loginRedirect(this.requestObj.scopes);
    });
  }

  login() {
    const isIE =
      window.navigator.userAgent.indexOf("MSIE ") > -1 ||
      window.navigator.userAgent.indexOf("Trident/") > -1;
    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup(this.requestObj.scopes);
      //   this.authService.loginPopup();
    }
  }

  getLoginCredentials = () => {
    this.app.getLoader(true);
    var myMSALObj = new Msal.UserAgentApplication(this.msalConfig);
    if (myMSALObj.getAccount()) {
      myMSALObj
        .acquireTokenSilent(this.requestObj)
        .then((tokenResponse) => {
          this.access_token = tokenResponse.accessToken;
          this.getUserDetails();
        })
        .catch((err) => {
          //  if (err.name === "InteractionRequiredAuthError") {
          return myMSALObj
            .acquireTokenPopup(this.requestObj)
            .then((response) => {
              this.access_token = response.accessToken;
              this.getUserDetails();
            })
            .catch((err) => {
              this.authService.loginRedirect(this.requestObj.scopes);
            });
          // }
        });
    } else {
      this.authService.loginRedirect(this.requestObj.scopes);
    }
  };

  authCallback = (erroDesc, token, error, tokenType) => {
    var myMSALObj = new Msal.UserAgentApplication(this.msalConfig);
    if (tokenType == "id_token") {
      myMSALObj.acquireTokenSilent(this.requestObj).then(
        function (accessToken) { },
        function (error) {
          console.log(error);
        }
      );
    }
  };

  getUserDetails() {
    this.http
      .get("https://graph.microsoft.com/v1.0/me", {
        headers: { Authorization: `Bearer ${this.access_token}` },
      })
      .subscribe(
        (res1: any) => {
          this.loginObj.name = res1.displayName;
          this.loginObj.email = res1.userPrincipalName;
          this.loginObj.id = res1.id;
          this.http
            .get("https://graph.microsoft.com/v1.0/me/memberOf", {
              headers: { Authorization: `Bearer ${this.access_token}` },
            })
            .subscribe(
              (res2: any) => {
                var userCredList = res2.value;
                var userExistInInvGrpIs = false;
                var userExistInRmGrpIs = false;
                var userExistInCsGrpIs = false;
                var userExistInRMAdminGrpIs = false;
                var userExistInAAASuperAdminGrpIs = false;
                // AAARMAdmin
                console.log(userCredList, "userCredList");
                var group_names_list = this.credDataObj.groupNames;
                var getGroupDetailsObj: any = {};
                for (let x = 0; x < userCredList.length; x++) {
                  var getObj = userCredList[x];
                  if (getObj.displayName) {
                    for (let n = 0; n < group_names_list.length; n++) {
                      const grp_name_obj = group_names_list[n];
                      if (getObj.displayName == grp_name_obj.name) {
                        /* if (grp_name_obj.role == "RM") {
                          userExistInRmGrpIs = true;
                          if (getObj.displayName == "AAARMAdmin") {
                            userExistInRMAdminGrpIs = true;
                          }
                          if (getObj.displayName == "AAASuperAdmin") {
                            userExistInAAASuperAdminGrpIs = true;
                          }
                        } else if (grp_name_obj.role == "INV") {
                          userExistInInvGrpIs = true;
                          if (getObj.displayName == "AAASuperAdmin") {
                            userExistInAAASuperAdminGrpIs = true;
                          }
                        } else if (grp_name_obj.role == "CS") {
                          userExistInCsGrpIs = true;
                        } */
                        if (grp_name_obj.role == "CS") {
                          userExistInCsGrpIs = true;
                        }

                        getGroupDetailsObj = grp_name_obj;
                      }
                    }
                  }
                }
                this.loginObj.group_id = getGroupDetailsObj.id;
                this.loginObj.group_name = getGroupDetailsObj.displayName;
                this.loginObj.adminLoggedInIs = false;
                this.loginObj.userRole = "";

                if (userExistInCsGrpIs) {
                  this.loginObj.userRole = "CRRCSTeam";
                  this.redirectFlowWindowForAdmin("CS");
                } else {
                  this.invalidUserErrorModal.show();
                  this.invalidUserErrorModal.onHide.subscribe(
                    (reason: string) => {
                      localStorage.clear();
                      sessionStorage.clear();

                      location.reload();
                    }
                  );
                }
                /* if (userExistInInvGrpIs && userExistInRmGrpIs) {
                  this.loginObj.adminLoggedInIs = true;
                  this.loginObj.userRole = "SuperAdmin";
                  this.adminFlowRedirectingModalBoxRef.show();
                } else if (userExistInRMAdminGrpIs) {
                  this.loginObj.userRole = "RMAdmin";
                  this.redirectFlowWindowForAdmin("RM");
                  
                } else if (userExistInInvGrpIs) {
                  this.loginObj.userRole = "AAAInvestmentTeam";
                  this.redirectFlowWindowForAdmin("INV");
                } else if (userExistInRmGrpIs) {
                  this.loginObj.userRole = "AAARMTeam";
                  this.redirectFlowWindowForAdmin("RM");
                } else if (userExistInCsGrpIs) {
                  this.loginObj.userRole = "CRRCSTeam";
                  this.redirectFlowWindowForAdmin("CS");
                } else {
                  this.invalidUserErrorModal.show();
                  this.invalidUserErrorModal.onHide.subscribe(
                    (reason: string) => {
                      localStorage.clear();
                      sessionStorage.clear();

                      location.reload();
                    }
                  );
                } */
                this.app.getLoader(false);
              },
              (error) => {
                this.toastr.error("Error: " + error.error);
                this.app.getLoader(false);
              }
            );
        },
        (error) => {
          this.toastr.error("Error: " + error.error);
          this.app.getLoader(false);
        }
      );
  }

  redirectFlowWindowForAdmin(getRole) {
    this.loginObj.role = getRole;
    localStorage.setItem("userInfo", JSON.stringify(this.loginObj));
    if (getRole == "INV") {
      this.router.navigate(["home/investmentDashboard"]);
    } else if (getRole == "RM") {
      this.router.navigate(["home/dashboard"]);
    } else if (getRole == "CS") {
      this.router.navigate(["home/crrDashboard"]);
    } else {
      this.toastr.error("Something went wrong");
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
  }

  ngOnDestroy() {
    if (this.invalidUserErrorModal) {
      this.invalidUserErrorModal.hide();
    }
    if (this.adminFlowRedirectingModalBoxRef) {
      this.adminFlowRedirectingModalBoxRef.hide();
    }
    this.toastr.clear();
  }
}
