import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import * as credentialData from "credential.json";

@Injectable({
  providedIn: "root",
})
export class CommonservicesService {
  post(arg0: string, sendData: { id: any }) {
    throw new Error("Method not implemented.");
  }

  credDataObj: any = (credentialData as any).default;
  rootURL = this.credDataObj.config.rootURL;
  constructor(private http: HttpClient) { }

  genCustomFormUniqueLink(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "generateUrl", data, {
      headers: headerObj,
    });
  }
  fetchCrtAssestAllocationList(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "rmDashboard", data, {
      headers: headerObj,
    });
  }
  getSummary(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "prospectSummary", data, {
      headers: headerObj,
    });
  }
  getprintData(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "viewForm", data, {
      headers: headerObj,
    });
  }
  sendSummData(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "addsummaryans", data, {
      headers: headerObj,
    });
  }
  sendMailWithURL(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "sendEmail", data, {
      headers: headerObj,
    });
  }
  getProspectList(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "prospectNames", data, {
      headers: headerObj,
    });
  }

  getInvProspect(headerObj: any) {
    return this.http.get(this.rootURL + "prospectNames", {
      headers: headerObj,
    });
  }

  getRMEditedSpv(headerObj: any) {
    return this.http.get(this.rootURL + "getRMEditedSpv", {
      headers: headerObj,
    });
  }

  getRegionList(headerObj: any) {
    return this.http.get(this.rootURL + "regionList", { headers: headerObj });
  }
  existingMyself(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "existingPropsect", data, {
      headers: headerObj,
    });
  }
  getAllComments(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getcomments", data, {
      headers: headerObj,
    });
  }
  addComments(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "addcomments", data, {
      headers: headerObj,
    });
  }

  // -----
  sendQuestData(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getUserData", data, {
      headers: headerObj,
    });
  }
  onBoardingProspectForm(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "onBoardingProspectForm", data, {
      headers: headerObj,
    });
  }

  sendFootNotes(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "addfootnotes", data, {
      headers: headerObj,
    });
  }
  getGeneratedDeckFile(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "genppt", data, {
      headers: headerObj,
    });
  }
  getProposedAAdata(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "proposedAssetAllocationnew", data, {
      headers: headerObj,
    });
  }
  getAssetAllocationDataForView(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "assetAllocationnew", data, {
      headers: headerObj,
    });
  }
  updateStatus(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "markas", data, {
      headers: headerObj,
    });
  }
  SendRMQuestinnaire(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "rmUserData", data, {
      headers: headerObj,
    });
  }
  getCYperc(headerObj: any) {
    return this.http.get(this.rootURL + "getRmForm", { headers: headerObj });
  }
  sendCGandCYdeals(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "updateCYandCGdeals", data, {
      headers: headerObj,
    });
  }
  sendEMailToCEO(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "triggerEMARequest", data, {
      headers: headerObj,
    });
  }

  // Investment Rest API
  getOpportunisticFundsAllocation(headerObj: any) {
    return this.http.get(this.rootURL + "getspvList", { headers: headerObj });
  }
  getliquditySpv(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getspvList", data, {
      headers: headerObj,
    });
  }
  // getliquditySpv(headerObj: any) {
  //   return this.http.get(this.rootURL + "getspvList", { headers: headerObj });
  // }

  getInvestmentRecords(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getDashboardData", data, {
      headers: headerObj,
    });
  }
  editAllocationProspect(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "editAllocationProspect", data, {
      headers: headerObj,
    });
  }

  fetchDeckSlideTitleList(headerObj: any) {
    return this.http.get(this.rootURL + "deckslidelist", {
      headers: headerObj,
    });
  }
  getInvestmentSummary(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "prospectSummary", data, {
      headers: headerObj,
    });
  }

  // JSON Funs
  sendMainJSON(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "sendJSON", data, {
      headers: headerObj,
    });
  }
  getMainJSON(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getJSON", data, {
      headers: headerObj,
    });
  }
  getAssumptionMasterData(headerObj: any) {
    return this.http.get(this.rootURL + "getAssumptionMaster", {
      headers: headerObj,
    });
  }
  getBsUfSetting(headerObj: any) {
    return this.http.get(this.rootURL + "getBsUfSetting", {
      headers: headerObj,
    });
  }
  getMarketData(headerObj: any) {
    return this.http.get(this.rootURL + "marketdata", {
      headers: headerObj,
    });
  }
  updateAssumptionMasterData(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "updateAssumptionMaster", data, {
      headers: headerObj,
    });
  }
  addAssumptionMasterDataRow(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "addAssumptionMaster", data, {
      headers: headerObj,
    });
  }
  updateRiskProfile(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "updateRiskProfile", data, {
      headers: headerObj,
    });
  }
  updateRiskCategory(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "updateRiskCategory", data, {
      headers: headerObj,
    });
  }
  saveMarketData(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "saveMarketData", data, {
      headers: headerObj,
    });
  }

  /* Client Flow Api's */
  getClientEmailId(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "downloadOutputJSON/", data, {
      headers: headerObj,
    });
  }
  fetchCltAssAllData(data: object, headerObj: any): Observable<any> {
    return this.http.post(
      this.rootURL + "generateClientAssetAllocation",
      data,
      { headers: headerObj }
    );
    //return this.http.post("http://2841f25cdbba.ngrok.io/generateClientAssetAllocation", data, { headers: headerObj });
  }
  
 


  fetchCompDataForAa(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "compareAA", data, {
      headers: headerObj,
    });
  }
  currentPortfolioSummary(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "currentPortfolio", data, {
      headers: headerObj,
    });
  }
  initializeProposedCommitments(data: object, headerObj: any): Observable<any> {
    return this.http.post(
      this.rootURL + "initializeProposedCommitments",
      data,
      { headers: headerObj }
    );
    //return this.http.post("http://2841f25cdbba.ngrok.io/initializeProposedCommitments", data, { headers: headerObj });
  }
  getInputSummary(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "inputSummary", data, {
      headers: headerObj,
    });
  }
  updateBsUfSetting(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "updateBsUfSetting", data, {
      headers: headerObj,
    });
  }
  goalSeekCalculations(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "goalSeekCalculations", data, {
      headers: headerObj,
    });
    //return this.http.post("http://2841f25cdbba.ngrok.io/goalSeekCalculations", data, { headers: headerObj });
  }
  proposedClientAA(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "proposedClientAA", data, {
      headers: headerObj,
    });
  }
  createQuarterlyAnnualCashflowAndSave(
    data: object,
    headerObj: any
  ): Observable<any> {
    return this.http.post(
      this.rootURL + "createQuarterlyAnnualCashflowAndSave",
      data,
      { headers: headerObj }
    );
  }
  getDownloadedExcel(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "downloadExcel", data, {
      headers: headerObj,
    });
  }

  getGeneratedDeckFileForClient(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "genclientppt", data, {
      headers: headerObj,
    });
    //return this.http.post("http://7e0972500b98.ngrok.io/genpptForClient", data, { headers: headerObj });
  }
  /* generateMinPfvBalance(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "generateMinPfvBalance", data, { headers: headerObj });
  } */
  checkemastatus(headerObj: any): Observable<any> {
    return this.http.get(this.rootURL + "checkemastatus", {
      headers: headerObj,
    });
  }

  downloadJsonfile(headerObj: any, data:any): Observable<any> {
    return this.http.get(this.rootURL + "downloadOutputJSON/"+ data.clientId +"/"+data.clientMasterId +"/"+data.logMasterId, {
      headers: headerObj,
    });
  }

  initiateema(headerObj: any): Observable<any> {
    return this.http.get(this.rootURL + "initiateema", { headers: headerObj });
  }

  loginValidation(data: object): Observable<any> {
    return this.http.post(this.rootURL + "loginValidation", data);
  }
  fileUpload(data: any): Observable<any> {
    return this.http.post(this.rootURL + "fileUpload", data);
  }
  sendClientMailtoCEO(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "sendMailtoCEO", data, {
      headers: headerObj,
    });
  }

  //inputsolution client
  GetallInputSolution(data: any, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "GetallInputSolution", data, {
      headers: headerObj,
    });
  }
  deleteInputSolution(data: any, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "deleteInputSolution", data, {
      headers: headerObj,
    });
  }
  updateInputSolution(data: any, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "updateInputSolution", data, {
      headers: headerObj,
    });
  }
  createInputSolution(data: any, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "createInputSolution", data, {
      headers: headerObj,
    });
  }

  //CRR's HTTPs
  uploadMarketDataFile(data: any, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "uploadInvDeck", data, {
      headers: headerObj,
    });
  }
  getCRRClientList(headerObj: any): Observable<any> {
    return this.http.get(this.rootURL + "getClientList", {
      headers: headerObj,
    });
  }
  getDashbordlog(data: any, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getDashbordlog", data, {
      headers: headerObj,
    });
  }
  getLastUpdatedDate(headerObj: any): Observable<any> {
    return this.http.get(this.rootURL + "getLastUpdated", {
      headers: headerObj,
    });
  }
  getDeckSettings(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getSettings", data, {
      headers: headerObj,
    });
  }
  saveDeckSettingsAndProgress(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "saveDeckSettingsAndProgress", data, {
      headers: headerObj,
    });
  }
  getCRRDeck(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "crrGenDeck", data, {
      headers: headerObj,
    });
  }
  fetchRpaStatus(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getStatus", data, {
      headers: headerObj,
    });
  }
  getInvDeckPath(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getInvDeckPath", data, {
      headers: headerObj,
    });
  }
  getupdateStep1Status(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "updateStep1Status", data, {
      headers: headerObj,
    });
  }
  getReports(data: any, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getReports", data, {
      headers: headerObj,
    });
  }
  downloadCRRDeck(data: any, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "downloadCRRDeck", data, {
      headers: headerObj,
    });
  }

  downloadCRRVideo(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getCRRVideoData", data, {
      headers: headerObj,
    });
  }

  getCrrImage(data: object, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "getCRRVideoData", data, {
      headers: headerObj,
    });
  }

  postFile(formData: any, headerObj: any): Observable<any> {
    return this.http.post(this.rootURL + "saveFileToSP", formData, {
      headers: headerObj,
    });
  }

  checkCRRVideoStatus(headerObj: any): Observable<any> {
    return this.http.get(this.rootURL + "checkVideoStatus", {
      headers: headerObj,
    });
  }

}
