import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CommonservicesService } from "../commonservices.service";
import { AppComponent } from "../app.component";
import { BsModalService, BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-excel-uploader',
  templateUrl: './excel-uploader.component.html',
  styleUrls: ['./excel-uploader.component.scss']
})
export class ExcelUploaderComponent implements OnInit {
  selectedprospect: any;
  userInfo: any = {};
  credFormObj: any = {
    email: "",
    password: ""
  };
  excelFileObj: any = {
    fileUrl: "",
    fileName: "",
    fileObj: {}
  };
  viewIsReadyToShow: boolean = false;
  constructor(private modalService: BsModalService, private http: CommonservicesService, public app: AppComponent, private toastr: ToastrService) { }

  @ViewChild('authenticationModalBox', { static: false }) authenticationModalBoxRef: ModalDirective;

  ngOnInit() {
    this.app.setAppTitle("Excel Uploader");
    //this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //this.selectedprospect = JSON.parse(sessionStorage.getItem("selctedPersonaObj"));
    setTimeout(function () {
      this.authenticationModalBoxRef.show();
    }.bind(this), 200);
  }

  validatingCredentials() {
    if (this.credFormObj.password) {
      this.app.getLoader(true);
      var send_data = {
        key: this.credFormObj.password
      };
      this.http.loginValidation(send_data).subscribe((response: any) => {
        if (response.success) {
          this.toastr.success(response.msg);
          setTimeout(() => {
            this.authenticationModalBoxRef.hide();
            this.viewIsReadyToShow = true;
          }, 3000);
        } else {
          this.toastr.error("Issue Found");
        }
        this.app.getLoader(false);
      }, error => {
        this.app.errorHandler(error);
      });
    } else {
      this.toastr.error("Fill Password");
    }
  }

  getSelectedFile(event) {
    var selectedFileObj = event.target.files[0];
    this.excelFileObj.fileName = selectedFileObj.name;
    this.excelFileObj.fileObj = selectedFileObj;
  }

  fileClear() {
    this.excelFileObj.fileUrl = "";
    this.excelFileObj.fileName = "";
  }

  excelUploadFunc() {
    if (this.excelFileObj.fileName) {
      var validExts = new Array(".xlsx", ".xls");
      var fileExt = this.excelFileObj.fileUrl.substring(this.excelFileObj.fileUrl.lastIndexOf('.'));
      console.log("validExts.indexOf(fileExt) < 0", validExts.indexOf(fileExt) < 0);
      if (validExts.indexOf(fileExt) < 0) {
        this.toastr.error("File extension not supported!");
      } else {
        this.app.getLoader(true);
        const formData = new FormData();
        formData.append("uploadfile", this.excelFileObj.fileObj);
        this.http.fileUpload(formData).subscribe((response: any) => {
          if (response.success) {
            this.toastr.success(response.msg);
            this.fileClear();
          } else {
            this.toastr.error("Issue Found");
          }
          this.app.getLoader(false);
        }, error => {
          this.app.errorHandler(error);
        });
      }
    } else {
      this.toastr.info("Select a file");
    }
  }

  ngOnDestroy() {
    /* if (this.modalRef) {
      this.modalRef.hide();
    } */
    this.toastr.clear();
  }
}
