import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { JsonEditorComponent, JsonEditorOptions } from "ang-jsoneditor";
import { AppComponent } from "../app.component";
import { CommonservicesService } from "../commonservices.service";
import { ToastrService } from "ngx-toastr";
import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";
import * as credentialData from "credential.json";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: "app-json-editor",
  templateUrl: "./json-editor.component.html",
  styleUrls: ["./json-editor.component.scss"],
})
export class JsonEditorComp implements OnInit {
  credDataObj: any = (credentialData as any).default;
  [x: string]: any;
  public editorOptions: JsonEditorOptions;
  @ViewChild("confirmationModalBox", { static: false })
  public confirmationModalBox: TemplateRef<any>;
  qstrConfirmationModalRef: BsModalRef;
  editedJsonData: any;
  jsonDataForEdit: any;
  editedJsonName: any = "";
  excelFileObj: any = {
    fileUrl: "",
    fileName: "",
    fileObj: {},
  };
  jsonEditorCompShowIs: Boolean = false;
  submitBtnSecIs: Boolean = false;
  /* enableEdit = false;
  enableEditIndex = null; */
  assumptionMstrList: any;
  masterDataTblIs: boolean = true;
  jsonDataTblIs: boolean = false;
  uploaderDataTblIs: boolean = false;
  @ViewChild(JsonEditorComponent, { static: true }) editor: JsonEditorComponent;
  tableConfirmationModal: BsModalRef;
  selecteduser: any;
  assMstrDataObj: any = {
    id: "",
    asset_class_type: "",
    spv: "",
    strategy: "",
    autoallocate_conventional_spv: "",
    autoallocate_shariah_spv: "",
    asset_class_name: "",
    expected_return: "",
    expected_yield: "",
    type: "",
    active_spv: "",
    ema_id: "",
    ema_benchmark: "",
    pm1_spv_name: "",
    expected_return_range: "",
    expected_yield_range: "",
    max_allocation: "",
    account: "",
    shariah_compliant_is: false,
    deploymentData: {
      deployment: [
        {
          Q1: "",
          Q2: "",
          Q3: "",
          Q4: "",
          Year: "",
        },
        {
          Q1: "",
          Q2: "",
          Q3: "",
          Q4: "",
          Year: "",
        },
        {
          Q1: "",
          Q2: "",
          Q3: "",
          Q4: "",
          Year: "",
        },
      ],
      exitYear: "",
    },
  };
  marketData: "";
  fileUrl: any;
  constructor(
    private http: CommonservicesService,
    public app: AppComponent,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ["tree"]; // set all allowed modes
  }
  ngOnInit() {
    this.app.setAppTitle("Master JSON Editor");
    this.selecteduser = JSON.parse(localStorage.getItem("userInfo"));
    setTimeout(
      function () {
        this.get_assumption_master();
        this.fetchUnplacedDealsOnOf();
        // this.callHtml();
      }.bind(this),
      100
    );
  }

  openModal = (template: TemplateRef<any>) => {
    this.qstrConfirmationModalRef = this.modalService.show(template);
  };

  modalBoxCallingFor = "";
  editModalshow(tablemodal: TemplateRef<any>, getData, callMethod) {
    if (callMethod == "edit" || callMethod == "addwith") {
      //this.assMstrDataObj = Object.assign({}, getData);
      this.assMstrDataObj.id = getData.id;
      this.assMstrDataObj.spv = getData.spv;
      this.assMstrDataObj.asset_class_type = getData.asset_class_type;
      // this.assMstrDataObj.autoallocate_conventional_spv = getData.autoallocate_conventional_spv;
      // this.assMstrDataObj.autoallocate_shariah_spv = getData.autoallocate_shariah_spv;

      this.assMstrDataObj.strategy = getData.strategy;
      this.assMstrDataObj.asset_class_name = getData.asset_class_name;
      this.assMstrDataObj.expected_return = (
        getData.expected_return * 100
      ).toFixed(2);
      this.assMstrDataObj.expected_yield = (
        getData.expected_yield * 100
      ).toFixed(2);
      this.assMstrDataObj.type = getData.type;
      this.assMstrDataObj.active_spv = getData.active_spv;
      this.assMstrDataObj.ema_id = getData.ema_id;
      this.assMstrDataObj.ema_benchmark = getData.ema_benchmark;
      this.assMstrDataObj.pm1_spv_name = getData.pm1_spv_name;
      this.assMstrDataObj.expected_return_range = getData.expected_return_range;
      this.assMstrDataObj.expected_yield_range = getData.expected_yield_range;
      this.assMstrDataObj.max_allocation = getData.max_allocation;
      this.assMstrDataObj.account = getData.account;
      this.assMstrDataObj.shariah_compliant_is =
        getData.shariah_compliant == "Y" ? true : false;
      this.assMstrDataObj.deploymentData.exitYear =
        getData.deploymentData.exitYear;
      this.assMstrDataObj.deploymentData.deployment[0].Q1 =
        getData.deploymentData.deployment[0].Q1 * 100;
      this.assMstrDataObj.deploymentData.deployment[0].Q2 =
        getData.deploymentData.deployment[0].Q2 * 100;
      this.assMstrDataObj.deploymentData.deployment[0].Q3 =
        getData.deploymentData.deployment[0].Q3 * 100;
      this.assMstrDataObj.deploymentData.deployment[0].Q4 =
        getData.deploymentData.deployment[0].Q4 * 100;
      this.assMstrDataObj.deploymentData.deployment[0].Year =
        getData.deploymentData.deployment[0].Year * 100;
      this.assMstrDataObj.deploymentData.deployment[1].Q1 =
        getData.deploymentData.deployment[1].Q1 * 100;
      this.assMstrDataObj.deploymentData.deployment[1].Q2 =
        getData.deploymentData.deployment[1].Q2 * 100;
      this.assMstrDataObj.deploymentData.deployment[1].Q3 =
        getData.deploymentData.deployment[1].Q3 * 100;
      this.assMstrDataObj.deploymentData.deployment[1].Q4 =
        getData.deploymentData.deployment[1].Q4 * 100;
      this.assMstrDataObj.deploymentData.deployment[1].Year =
        getData.deploymentData.deployment[1].Year * 100;
      this.assMstrDataObj.deploymentData.deployment[2].Q1 =
        getData.deploymentData.deployment[2].Q1 * 100;
      this.assMstrDataObj.deploymentData.deployment[2].Q2 =
        getData.deploymentData.deployment[2].Q2 * 100;
      this.assMstrDataObj.deploymentData.deployment[2].Q3 =
        getData.deploymentData.deployment[2].Q3 * 100;
      this.assMstrDataObj.deploymentData.deployment[2].Q4 =
        getData.deploymentData.deployment[2].Q4 * 100;
      this.assMstrDataObj.deploymentData.deployment[2].Year =
        getData.deploymentData.deployment[2].Year * 100;
    } else {
      this.makeaddmasterdataclear();
    }
    this.modalBoxCallingFor = callMethod;
    this.tableConfirmationModal = this.modalService.show(
      tablemodal,
      Object.assign({}, { class: "gray modal-lg" })
    );
    /* this.modalService.onHide.subscribe((reason: string) => {
      this.makeaddmasterdataclear();
    }); */
  }

  makeaddmasterdataclear() {
    this.assMstrDataObj.id = "";
    this.assMstrDataObj.spv = "";
    this.assMstrDataObj.asset_class_type = "";
    this.assMstrDataObj.strategy = "";
    this.assMstrDataObj.asset_class_name = "";
    this.assMstrDataObj.expected_return = "";
    this.assMstrDataObj.expected_yield = "";
    this.assMstrDataObj.type = "";
    this.assMstrDataObj.active_spv = "";
    this.assMstrDataObj.ema_id = "";
    this.assMstrDataObj.ema_benchmark = "";
    this.assMstrDataObj.pm1_spv_name = "";
    this.assMstrDataObj.expected_return_range = "";
    this.assMstrDataObj.expected_yield_range = "";
    this.assMstrDataObj.max_allocation = "";
    this.assMstrDataObj.account = "";
    this.assMstrDataObj.shariah_compliant_is = false;
    this.assMstrDataObj.deploymentData.deployment[0].Q1 = "";
    this.assMstrDataObj.deploymentData.deployment[0].Q2 = "";
    this.assMstrDataObj.deploymentData.deployment[0].Q3 = "";
    this.assMstrDataObj.deploymentData.deployment[0].Q4 = "";
    this.assMstrDataObj.deploymentData.deployment[0].Year = "";
    this.assMstrDataObj.deploymentData.deployment[1].Q1 = "";
    this.assMstrDataObj.deploymentData.deployment[1].Q2 = "";
    this.assMstrDataObj.deploymentData.deployment[1].Q3 = "";
    this.assMstrDataObj.deploymentData.deployment[1].Q4 = "";
    this.assMstrDataObj.deploymentData.deployment[1].Year = "";
    this.assMstrDataObj.deploymentData.deployment[2].Q1 = "";
    this.assMstrDataObj.deploymentData.deployment[2].Q2 = "";
    this.assMstrDataObj.deploymentData.deployment[2].Q3 = "";
    this.assMstrDataObj.deploymentData.deployment[2].Q4 = "";
    this.assMstrDataObj.deploymentData.deployment[2].Year = "";
    this.assMstrDataObj.deploymentData.exitYear = "";
  }

  async get_assumption_master() {
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getAssumptionMasterData(headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          var assMstrDataSet = response.data;
          var returnedData = [];
          for (let i = 0; i < assMstrDataSet.length; i++) {
            const dataObj = assMstrDataSet[i];
            var deploymentdata = dataObj.deployment
              ? JSON.parse(dataObj.deployment)
              : [];
            dataObj.deploymentData = deploymentdata[0];
            dataObj.deploymentData.exitYear =
              dataObj.deploymentData["exit_year"].ExitYear;
            returnedData.push(dataObj);
          }
          this.assumptionMstrList = returnedData;
          // console.log(this.assumptionMstrList[0].autoallocate_conventional_spv)
        } else {
          this.toastr.error("Error: " + response.msg);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  addasumptionquaters(param) {
    if (param == "y1") {
      var q1 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[0].Q1)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[0].Q1);
      var q2 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[0].Q2)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[0].Q2);
      var q3 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[0].Q3)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[0].Q3);
      var q4 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[0].Q4)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[0].Q4);
      this.assMstrDataObj.deploymentData.deployment[0].Year = q1 + q2 + q3 + q4;
      this.assMstrDataObj.deploymentData.deployment[0].Year = parseFloat(
        this.assMstrDataObj.deploymentData.deployment[0].Year
      );
    } else if (param == "y2") {
      var q1 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[1].Q1)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[1].Q1);
      var q2 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[1].Q2)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[1].Q2);
      var q3 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[1].Q3)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[1].Q3);
      var q4 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[1].Q4)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[1].Q4);
      this.assMstrDataObj.deploymentData.deployment[1].Year = q1 + q2 + q3 + q4;
      this.assMstrDataObj.deploymentData.deployment[1].Year = parseFloat(
        this.assMstrDataObj.deploymentData.deployment[1].Year
      );
    } else {
      var q1 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[2].Q1)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[2].Q1);
      var q2 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[2].Q2)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[2].Q2);
      var q3 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[2].Q3)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[2].Q3);
      var q4 = isNaN(
        parseFloat(this.assMstrDataObj.deploymentData.deployment[2].Q4)
      )
        ? 0
        : parseFloat(this.assMstrDataObj.deploymentData.deployment[2].Q4);
      this.assMstrDataObj.deploymentData.deployment[2].Year = q1 + q2 + q3 + q4;
      this.assMstrDataObj.deploymentData.deployment[2].Year = parseFloat(
        this.assMstrDataObj.deploymentData.deployment[2].Year
      );
    }
  }

  Istotalyears: boolean = false;
  async updateAssumtion() {
    /* var innerData = this.assMstrDataObj;
    innerData.expected_return = this.assMstrDataObj.expected_return ? (this.assMstrDataObj.expected_return / 100) : 0;
    innerData.expected_yield = this.assMstrDataObj.expected_yield ? (this.assMstrDataObj.expected_yield / 100) : 0;
    innerData.max_allocation = this.assMstrDataObj.max_allocation ? this.assMstrDataObj.max_allocation : 0;
    innerData.deployment[0].exit_year.ExitYear = this.assMstrDataObj.deploymentData.exitYear ? this.assMstrDataObj.deploymentData.exitYear : "";
    innerData.deployment[0].deployment[0].Q1 = this.assMstrDataObj.deploymentData.deployment[0].Q1 / 100;
    innerData.deployment[0].deployment[0].Q2 = this.assMstrDataObj.deploymentData.deployment[0].Q2 / 100;
    innerData.deployment[0].deployment[0].Q3 = this.assMstrDataObj.deploymentData.deployment[0].Q3 / 100;
    innerData.deployment[0].deployment[0].Q4 = this.assMstrDataObj.deploymentData.deployment[0].Q4 / 100;
    innerData.deployment[0].deployment[0].Year = this.assMstrDataObj.deploymentData.deployment[0].Year / 100;
    innerData.deployment[0].deployment[1].Q1 = this.assMstrDataObj.deploymentData.deployment[1].Q1 / 100;
    innerData.deployment[0].deployment[1].Q2 = this.assMstrDataObj.deploymentData.deployment[1].Q2 / 100;
    innerData.deployment[0].deployment[1].Q3 = this.assMstrDataObj.deploymentData.deployment[1].Q3 / 100;
    innerData.deployment[0].deployment[1].Q4 = this.assMstrDataObj.deploymentData.deployment[1].Q4 / 100;
    innerData.deployment[0].deployment[1].Year = this.assMstrDataObj.deploymentData.deployment[1].Year / 100;
    innerData.deployment[0].deployment[2].Q1 = this.assMstrDataObj.deploymentData.deployment[2].Q1 / 100;
    innerData.deployment[0].deployment[2].Q2 = this.assMstrDataObj.deploymentData.deployment[2].Q2 / 100;
    innerData.deployment[0].deployment[2].Q3 = this.assMstrDataObj.deploymentData.deployment[2].Q3 / 100;
    innerData.deployment[0].deployment[2].Q4 = this.assMstrDataObj.deploymentData.deployment[2].Q4 / 100;
    innerData.deployment[0].deployment[2].Year = this.assMstrDataObj.deploymentData.deployment[2].Year / 100; */
    // console.log(this.tabledata,"tabledata");
    // console.log(this.assMstrDataObj.autoallocate_conventional_spv)
    var innerData = {
      spv: this.assMstrDataObj.spv,
      asset_class_type: this.assMstrDataObj.asset_class_type,
      strategy: this.assMstrDataObj.strategy,
      asset_class_name: this.assMstrDataObj.asset_class_name,
      autoallocate_conventional_spv: this.assMstrDataObj
        .autoallocate_conventional_spv,
      autoallocate_shariah_spv: this.assMstrDataObj.autoallocate_shariah_spv,
      expected_return: this.assMstrDataObj.expected_return
        ? this.assMstrDataObj.expected_return / 100
        : 0,
      expected_yield: this.assMstrDataObj.expected_yield
        ? this.assMstrDataObj.expected_yield / 100
        : 0,
      type: this.assMstrDataObj.type,
      active_spv: this.assMstrDataObj.active_spv,
      ema_id: this.assMstrDataObj.ema_id,
      ema_benchmark: this.assMstrDataObj.ema_benchmark,
      pm1_spv_name: this.assMstrDataObj.pm1_spv_name,
      expected_return_range: this.assMstrDataObj.expected_return_range,
      expected_yield_range: this.assMstrDataObj.expected_yield_range,
      max_allocation: this.assMstrDataObj.max_allocation
        ? this.assMstrDataObj.max_allocation
        : 0,
      account: this.assMstrDataObj.account,
      shariah_compliant: this.assMstrDataObj.shariah_compliant_is ? "Y" : "N",
      deployment: [
        {
          deployment: [
            {
              Q1: this.assMstrDataObj.deploymentData.deployment[0].Q1 / 100,
              Q2: this.assMstrDataObj.deploymentData.deployment[0].Q2 / 100,
              Q3: this.assMstrDataObj.deploymentData.deployment[0].Q3 / 100,
              Q4: this.assMstrDataObj.deploymentData.deployment[0].Q4 / 100,
              Year: this.assMstrDataObj.deploymentData.deployment[0].Year / 100,
            },
            {
              Q1: this.assMstrDataObj.deploymentData.deployment[1].Q1 / 100,
              Q2: this.assMstrDataObj.deploymentData.deployment[1].Q2 / 100,
              Q3: this.assMstrDataObj.deploymentData.deployment[1].Q3 / 100,
              Q4: this.assMstrDataObj.deploymentData.deployment[1].Q4 / 100,
              Year: this.assMstrDataObj.deploymentData.deployment[1].Year / 100,
            },
            {
              Q1: this.assMstrDataObj.deploymentData.deployment[2].Q1 / 100,
              Q2: this.assMstrDataObj.deploymentData.deployment[2].Q2 / 100,
              Q3: this.assMstrDataObj.deploymentData.deployment[2].Q3 / 100,
              Q4: this.assMstrDataObj.deploymentData.deployment[2].Q4 / 100,
              Year: this.assMstrDataObj.deploymentData.deployment[2].Year / 100,
            },
          ],
          exit_year: {
            ExitYear: this.assMstrDataObj.deploymentData.exitYear
              ? this.assMstrDataObj.deploymentData.exitYear
              : "",
          },
        },
      ],
    };

    var totalyears =
      this.assMstrDataObj.deploymentData.deployment[0].Year +
      this.assMstrDataObj.deploymentData.deployment[1].Year +
      this.assMstrDataObj.deploymentData.deployment[2].Year;
    totalyears = parseFloat(totalyears).toFixed();
    if (totalyears != 0 && totalyears != 100) {
      this.Istotalyears = true;
    } else {
      this.Istotalyears = false;
    }
    // console.log(this.assMstrDataObj)
    if (
      this.assMstrDataObj.spv &&
      this.assMstrDataObj.strategy &&
      this.assMstrDataObj.type &&
      this.assMstrDataObj.asset_class_name &&
      this.assMstrDataObj.asset_class_type &&
      this.assMstrDataObj.deploymentData.exitYear &&
      this.assMstrDataObj.active_spv &&
      this.assMstrDataObj.ema_id &&
      this.assMstrDataObj.ema_benchmark &&
      this.assMstrDataObj.pm1_spv_name &&
      this.assMstrDataObj.account &&
      this.Istotalyears == false
    ) {
      this.app.getLoader(true);
      var headerObj = await this.app.getHTTPHeaders();
      if (this.modalBoxCallingFor == "edit") {
        var dataForUpdate = {
          id: this.assMstrDataObj.id,
          userId: this.selecteduser.id,
          name: this.selecteduser.name,
          data: innerData,
        };
        this.http
          .updateAssumptionMasterData(dataForUpdate, headerObj)
          .subscribe(
            (response: any) => {
              if (response.success) {
                this.tableConfirmationModal.hide();
                this.toastr.success("Updated Successfully");
                this.get_assumption_master();
              } else {
                this.toastr.error("Error: " + response.msg);
                this.app.getLoader(false);
              }
            },
            (error) => {
              this.app.errorHandler(error);
            }
          );
      } else {
        var dataForAdd = {
          data: innerData,
        };
        this.http.addAssumptionMasterDataRow(dataForAdd, headerObj).subscribe(
          (response: any) => {
            if (response.success) {
              this.tableConfirmationModal.hide();
              this.toastr.success("Added Successfully");
              this.get_assumption_master();
            } else {
              this.toastr.error("Error: " + response.msg);
            }
          },
          (error) => {
            this.app.errorHandler(error);
            this.app.getLoader(false);
          }
        );
      }
    } else if (
      this.assMstrDataObj.spv &&
      this.assMstrDataObj.strategy &&
      this.assMstrDataObj.type &&
      this.assMstrDataObj.asset_class_name &&
      this.assMstrDataObj.asset_class_type &&
      this.assMstrDataObj.deploymentData.exitYear &&
      this.assMstrDataObj.active_spv &&
      this.assMstrDataObj.ema_id &&
      this.assMstrDataObj.ema_benchmark &&
      this.assMstrDataObj.pm1_spv_name &&
      this.assMstrDataObj.account &&
      this.Istotalyears == true
    ) {
      this.toastr.error("Total percentage must be 100%");
      this.app.getLoader(false);
      return false;
    } else {
      this.toastr.error("Please fill all mandatory fields");
      this.app.getLoader(false);
      return false;
    }
  }

  getEditedDataForSend(event) {
    this.submitBtnSecIs = true;
    this.editedJsonData = event;
  }

  async getJsonForEdit(jsonName: any) {
    this.app.getLoader(true);
    this.editedJsonName = jsonName;
    var sendData = {
      jsonName: this.editedJsonName,
    };
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getMainJSON(sendData, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.jsonDataForEdit = response.data;
          this.submitBtnSecIs = false;
          this.jsonEditorCompShowIs = true;
        } else {
          this.toastr.error("Please reload and try again");
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
    // if(jsonName == 'investment_solution_inputs'){
    //   this.editorJsonNameIs = true;
    // }else if(jsonName == 'cg_and_cy_processing'){
    //   this.editorJsonNameIs = false;
    // }
    // else{
    //   this.editorJsonNameIs = false;
    // }
  }

  async submitEditedJson() {
    if (this.editedJsonName) {
      var sendData = {
        jsonName: this.editedJsonName,
        data: this.editedJsonData,
      };
      var headerObj = await this.app.getHTTPHeaders();
      this.http.sendMainJSON(sendData, headerObj).subscribe(
        (response: any) => {
          this.qstrConfirmationModalRef.hide();
          if (response.success) {
            this.toastr.success("Updated Successfully");
            this.submitBtnSecIs = false;
          } else {
            this.toastr.error("Issue Found: " + response.msg);
            this.getJsonForEdit(this.editedJsonName);
          }
          //location.reload();
        },
        (error) => {
          this.app.errorHandler(error);
        }
      );
    } else {
      this.toastr.info("Select JSON");
    }
  }

  /* enableEditMethod(e: any, i: any) {
    this.enableEdit = true;
    this.enableEditIndex = i;
  } */

  sectionToggleFun(getParam) {
    if (getParam == "master") {
      this.masterDataTblIs = true;
      this.jsonDataTblIs = false;
      this.uploaderDataTblIs = false;
    } else if (getParam == "editor") {
      if (this.masterDataTblIs || this.uploaderDataTblIs) {
        this.masterDataTblIs = false;
        this.jsonDataTblIs = true;
        this.uploaderDataTblIs = false;
        this.editedJsonName = "";
        this.submitBtnSecIs = false;
        this.jsonEditorCompShowIs = false;
      }
    } else {
      this.masterDataTblIs = false;
      this.jsonDataTblIs = false;
      this.uploaderDataTblIs = true;
    }
  }

  getUnplacesDealOnOf: boolean = false;
  unplacesDealsIs: string = "";
  async fetchUnplacedDealsOnOf() {
    var headerObj = await this.app.getHTTPHeaders();
    this.http.getBsUfSetting(headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.unplacesDealsIs = response.data[0].bs_uf_setting;
          this.getUnplacesDealOnOf =
            response.data[0].bs_uf_setting == "on" ? true : false;
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  async reInvestingUnplacesDealsOnOf() {
    this.app.getLoader(true);
    var headerObj = await this.app.getHTTPHeaders();
    var send_data = {
      setting: this.getUnplacesDealOnOf ? "on" : "off",
    };
    this.http.updateBsUfSetting(send_data, headerObj).subscribe(
      (response: any) => {
        if (response.success) {
          this.toastr.success(response.msg);
          this.unplacesDealsIs = send_data.setting;
        } else {
          this.toastr.info("Issue Found: " + response.message);
        }
        this.app.getLoader(false);
      },
      (error) => {
        this.app.errorHandler(error);
      }
    );
  }

  checkInputConventional() {
    if (this.autoallocate_conventional_spv == 0) {
      this.assMstrDataObj.autoallocate_conventional_spv = false;
    } else {
      this.assMstrDataObj.autoallocate_conventional_spv = true;
    }
  }
  checkInputShariah() {
    if (this.autoallocate_shariah_spv == 0) {
      this.assMstrDataObj.autoallocate_shariah_spv = false;
    } else {
      this.assMstrDataObj.autoallocate_shariah_spv = true;
    }
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.toastr.clear();
  }
  fileOnOverIs: boolean = false;
  getSelectedFile(event) {
    var selectedFileObj = event;
    this.excelFileObj.fileName = selectedFileObj.name;
    this.excelFileObj.fileObj = selectedFileObj;
  }

  fileOnDropped(event) {
    event.preventDefault();
    event.stopPropagation();
    let dataTransfer = event.dataTransfer.files[0];
    this.getSelectedFile(dataTransfer);
    this.fileOnOverIs = false;
  }

  fileOnDragover(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOnOverIs = true;
  }

  fileOnDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOnOverIs = false;
  }

  fileClear() {
    this.excelFileObj.fileUrl = "";
    this.excelFileObj.fileName = "";
    this.marketData = "";
  }
  // async excelUploadFunc() {
  //   if (this.excelFileObj.fileName) {
  //     var reg = "([a-zA-Z0-9s_\\.-:])+(.html)$";
  //     if (!this.excelFileObj.fileName.match(reg)) {
  //       this.toastr.warning("Only HTML Files Are Allowed");
  //       return;
  //     }
  //     this.app.getLoader(true);
  //     const formData = new FormData();
  //     formData.append("marketdata", this.excelFileObj.fileObj);
  //     // formData.append("user_id", this.getLoginUserData.aaaUserId);
  //     var headerObj = await this.app.getHTTPHeaders();
  //     this.http.saveMarketData(formData, headerObj).subscribe(
  //       (response: any) => {
  //         if (response.success) {
  //           this.toastr.success(response.msg);
  //           this.fileClear();
  //         } else {
  //           this.toastr.error(response.msg);
  //         }
  //         this.app.getLoader(false);
  //       },
  //       (error) => {
  //         this.app.errorHandler(error);
  //       }
  //     );
  //   } else {
  //     this.toastr.warning("Select a file");
  //   }
  // }

  // async addMarketData() {
  //   this.app.getLoader(true);
  //   var headerObj = await this.app.getHTTPHeaders();
  //   var send_data = {
  //     data: this.marketData,
  //   };

  //   this.http.saveMarketData(send_data, headerObj).subscribe(
  //     (response: any) => {
  //       if (response.success) {
  //         this.toastr.success("Save Market data successfully");
  //         this.fileClear();
  //       } else {
  //         this.toastr.error(response.msg);
  //       }
  //       this.app.getLoader(false);
  //     },
  //     (error) => {
  //       this.app.errorHandler(error);
  //     }
  //   );
  // }
  // async callHtml() {
  //   var headerObj = await this.app.getHTTPHeaders();
  //   this.http.getMarketData(headerObj).subscribe((response: any) => {
  //     console.log(response);

  //     const blob = new Blob([response.data], {
  //       type: "application/octet-stream",
  //     });

  //     this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
  //       window.URL.createObjectURL(blob)
  //     );
  //   });
  // }
  // openHtml() {
  //   window.open(this.credDataObj.config.rootURL + "marketdata");
  // }
}
